import { useQuery, usePaginatedQuery } from 'react-query'
import { useAxios } from 'packages/core'

const MANAGEMENT_ROLE_KEY_PREFIX = 'MANAGEMENT_ROLE'

export const useManagementRole = (id, targetPersonId) => {
    const callApi = useAxios()

    return useQuery(
        [MANAGEMENT_ROLE_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `api/managementRoles/${id}?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleManageAccess = (id, targetPersonId) => {
    const callApi = useAxios()

    return useQuery(
        [MANAGEMENT_ROLE_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `api/managementRoles/manageAccess/${id}?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckManagementRoleAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${MANAGEMENT_ROLE_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/managementRoles/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useManagementRoleOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${MANAGEMENT_ROLE_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleLocalFunctions = (
    id,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${MANAGEMENT_ROLE_KEY_PREFIX}_LOCAL_FUNCTIONS`,
            id,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/BusinessFunctions/localFunctions?roleId=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleApplicationRolesGranted = (
    id,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${MANAGEMENT_ROLE_KEY_PREFIX}_ACCESS_ROLES_GRANTED`,
            id,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/managementRoles/applicationRolesGranted/${id}?skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleManagementRolesGranted = (
    id,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${MANAGEMENT_ROLE_KEY_PREFIX}_MANAGEMENT_ROLES_GRANTED`,
            id,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/managementRoles/managementRolesGranted/${id}?skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleSuggestedApplicationRoles = (
    id,
    targetPersonId,
    referencePersonId,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()

    const queryParams = [`skip=${skip}`, `take=${take}`]
    if (search) {
        queryParams.push(`searchTerm=${search}`)
    }
    if (referencePersonId) {
        queryParams.push(`referencePersonId=${referencePersonId}`)
    }
    const url =
        `/api/ManagementRoles/applicationRolesSuggested/${id}/${targetPersonId}` +
        `?${queryParams.join('&')}`

    return usePaginatedQuery(
        [
            `${MANAGEMENT_ROLE_KEY_PREFIX}_SUGGESTED_APPLICATION_ROLES`,
            id,
            targetPersonId,
            referencePersonId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url,
            }),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useManagementRoleApplications = (id, skip, take = 10, search) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [`${MANAGEMENT_ROLE_KEY_PREFIX}_APPLICATIONS`, id, skip, take, search],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/ProtectedAppResources/linkedApplications?managementRoleId=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoleBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            MANAGEMENT_ROLE_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/managementRoles/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId),
        },
    )
}
