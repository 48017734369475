import React from 'react'
import ApplicationRoleDrawer from './ApplicationRoles'
import AzureLicensesDrawer from './AzureLicenses'
import BusinessRoleDrawer from './BusinessRoles'
import ManagementRoleDrawer from './ManagementRoles'
import SharedFolderDrawer from './SharedFolders'
import MailboxDrawer from './Mailboxes'
import { AzureRoles } from './AzureRoles'
import ComputerDrawer from './Computers'
import CredentialDrawer from './Credentials'
import ApplicationsDrawer from './Applications'

const SideDrawer = ({ type, ...rest }) => {
    if (type === 'ApplicationRoles') return <ApplicationRoleDrawer {...rest} />
    else if (type === 'AzureRoles') return <AzureRoles {...rest} />
    else if (type === 'AzureLicenses') return <AzureLicensesDrawer {...rest} />
    else if (type === 'BusinessRoles') return <BusinessRoleDrawer {...rest} />
    else if (type === 'ManagementRoles')
        return <ManagementRoleDrawer {...rest} />
    else if (type === 'SharedFolders') return <SharedFolderDrawer {...rest} />
    else if (type === 'Mailboxes') return <MailboxDrawer {...rest} />
    else if (type === 'Computers') return <ComputerDrawer {...rest} />
    else if (type === 'Credentials') return <CredentialDrawer {...rest} />
    else if (type === 'Applications') return <ApplicationsDrawer {...rest} />

    return <></>
}
export default SideDrawer
