import { FC, Fragment, useEffect, useState } from 'react'
import {
    useAddItemsToCart,
    useAddItemToCart,
    useApplicationFieldTypes,
    useCart,
    useRemoveItemsFromCart,
    useTargetPerson,
} from 'hooks'
import { Skeleton } from '@mui/material'
import {
    ACCESS_LEVEL_TYPE,
} from 'utils'
import AppRoleRight from './AppRoleRight'
import { Box, Typography, useTheme, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from 'resourceTypeContext'
import { Button } from 'components'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'
import { Loader, Tooltip, useNotification } from 'packages/eid-ui'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ACTION_TYPES, checkAllRequiredFieldTypeFilled, checkFieldTypeWithValidSourceValues, checkIfValidFreeLablValues, FIELD_TYPES_SELECTION_RULE, findChangedFieldTypesForAppRight, findChangedFieldTypesForAppRole, getModifiedFieldTypeValue, mergeCartAndApiJson, updateJsonMappingWithApiFormat, useQuery } from 'packages/core'
import { v4 as uuidv4 } from 'uuid'
import { RenewRevoke } from 'components/RenewRevoke'

export type DynamicFieldTypesSelectorProps = {
    accessLevel?: any
    resourceItem?: any
    isFormValidatedFromAccessLevel?: boolean
    applicationType?: any
    toggleDrawer?: any
}
const updateJsonToAddParentIdToFieldTypes = (data: any, defaultGuuid: any) => {
    try {
        if (data?.Rights?.length) {
            const newData = data?.Rights?.map((right: any) => {
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((i: any) => ({
                        ...i,
                        parentRightId: right.AzLocalRightID,
                    }))
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...data, Rights: newData }
        } else {
            if (data?.FieldType?.length) {
                const fieldTypes = data.FieldType.map((i: any) => ({
                    ...i,
                    parentRightId: data.AzLocalRightID,
                }))
                if (data?.AzAssigneeLocalRightScopeID || !defaultGuuid) {
                    return { ...data, FieldType: fieldTypes }
                } else {
                    return {
                        ...data,
                        AzAssigneeLocalRightScopeID: defaultGuuid,
                        FieldType: fieldTypes,
                    }
                }
            }
            return data
        }
    } catch (err) { }
    return data
}

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '2rem 1.6rem',
        background: '#fbfbfd',
    },
    header: {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
        padding: '1.7rem 3.2rem 1.2rem',
        borderTop: 'solid 1px #e8e8e8',
        borderBottom: 'solid 1px #e8e8e8',
        background: `linear-gradient(90deg, rgba(246,246,248,1) 0%, rgba(244,244,246,1) 0%, rgba(238,103,35,1) 0%, rgba(23,154,170,0.95) 0%, rgba(23,129,188,0.95) 100%),repeating-linear-gradient(${theme?.palette?.common?.white}, ${theme?.palette?.common?.white} 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 1.02) 4px)`,
        '& h2': {
            fontSize: '1.6rem',
            fontWeight: '600',
            color: '#fff',
        },
    },
    subHeader: {
        display: 'flex',
        marginTop: '20px',
        padding: '14px 30px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h3': {
            fontSize: '18px',
            fontWeight: '600',
        },
        '& p': {
            fontSize: '12px',
            color: theme?.palette?.success?.main,
        },
    },
    saveButton: {
        minWidth: '15rem',
        cursor: 'pointer',
        position: 'relative',
        height: '40px',
        padding: '8px 12px',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontSize: '1.6rem',
        backgroundColor: theme?.palette?.primary?.main,
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        color: `${theme?.palette?.common?.white} !important`,
        '&:hover': {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.common?.white,
            cursor: 'hand',
        },
    },
    saveBtnContainer: {
        width: 'fit-content',
        '& .Mui-disabled': {
            backgroundColor: `${theme?.palette?.disabled?.main} !important`,
            color: `${theme?.palette?.common?.white} !important`,
            borderColor: `${theme?.palette?.disabled?.main} !important`,
        },
    },
    footerButton: {
        display: 'flex',
        '& button': {
            height: '4.8rem',
        },
    },
}))

const GradientSpacer = styled(Box)({
    background:
        'linear-gradient(180deg, #e9e9eb 0%, #f7f7f9 50%, #f1f1f3 100%)',
    marginTop: '2.4rem',
})

const DynamicFieldTypesSelector: FC<DynamicFieldTypesSelectorProps> = ({
    accessLevel,
    resourceItem,
    isFormValidatedFromAccessLevel = false,
    toggleDrawer,
    applicationType,
}) => {
    const [isFormValidated, setIsFormValidated] = useState(false)
    const classes = useStyles()
    const { t } = useTranslation()
    const [roleId, setRoleId] = useState(null)
    const [rightId, setRightd] = useState(null)
    const [{ appRightsFieldTypes }, dispatch]: any = useResourceTypeContext()
    const history = useHistory()
    const [targetPerson] = useTargetPerson()
    const theme = useTheme()
    const { showWarningMessage } = useNotification()
    const [addItemToCart, { isLoading: addToCartLoading }] = useAddItemToCart()
    const [addItemsToCart, { isLoading: addItemsToCartLoading }] =
        useAddItemsToCart()
    const [apiResponseJson, setApiResponseJson] = useState<any | null>(null)
    const [cartItem, setCartItem] = useState<any>(null)
    const { data: cart } = useCart()
    const query = useQuery()
    const [
        removeItemsFromCart,
        { isLoading: isRemoving },
    ] = useRemoveItemsFromCart()

    useEffect(() => {
        setIsFormValidated(isFormValidatedFromAccessLevel)
    }, [isFormValidatedFromAccessLevel])

    const isManageAccess =
        history.location.pathname
            .toLowerCase()
            .indexOf('/manageAccess'.toLowerCase()) >= 0

    useEffect(() => {
        if (accessLevel) {
            if (
                accessLevel?.resourceTypeName === ACCESS_LEVEL_TYPE.AZURE_ROLE
            ) {
                setRoleId(
                    isManageAccess
                        ? accessLevel?.azLocalRoleId
                        : accessLevel?.id,
                )
                setRightd(null)
            } else if (
                accessLevel?.resourceTypeName ===
                ACCESS_LEVEL_TYPE.AZ_LOCAL_RIGHT
            ) {
                setRoleId(null)
                setRightd(
                    isManageAccess
                        ? accessLevel?.localRightId
                        : accessLevel?.id,
                )
            } else {
                setRoleId(null)
                setRightd(null)
            }
        } else {
            setRoleId(null)
            setRightd(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLevel])

    const { data, isLoading, refetch } = useApplicationFieldTypes(
        accessLevel?.id,
        roleId,
        rightId,
        (isManageAccess || accessLevel?.hasFieldTypes)
    )

    useEffect(() => {
        if (data) {
            mapCartAndApiJson()
        } else if (appRightsFieldTypes) {
            setApiResponseJson(null)
            dispatch({
                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                payload: null,
            })
        }
        return () => resetFieldTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const resetFieldTypes = () => {
        setApiResponseJson(null)
        dispatch({
            type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
            payload: null,
        })
    }

    const mapCartAndApiJson = () => {
        try {

            const apiDataResponse = JSON.parse(data)
            const dataObject = Array.isArray(apiDataResponse)
                ? apiDataResponse[0]
                : apiDataResponse

            const parsedObject = updateJsonMappingWithApiFormat(dataObject, false)

            let itemCart =
                cart?.cartItems?.filter(
                    (item: any) =>
                        item.requestableResourceId ===
                        (resourceItem?.resource?.[
                            roleId ? 'azLocalRoleId' : 'localRightId'
                        ] || accessLevel?.id),
                ) || null
            itemCart = itemCart.filter(
                (x: any) => x?.assignmentId === resourceItem?.resource?.id,
            )
            if (
                itemCart &&
                !parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue
            ) {
                const fieldType = itemCart?.[0]?.fieldTypeValueJson

                if (fieldType) {
                    const cartJson = JSON.parse(fieldType)
                    const cartDataObj = Array.isArray(cartJson)
                        ? cartJson[0]
                        : cartJson

                    const parsedCartObj = updateJsonMappingWithApiFormat(cartDataObj, false)
                    const combinedJson = mergeCartAndApiJson(
                        parsedCartObj,
                        parsedObject,
                    )
                    extractDataAndBindValues(combinedJson)
                } else {
                    extractDataAndBindValues(parsedObject)
                }
            } else {
                extractDataAndBindValues(parsedObject)
            }
            setCartItem(itemCart)
        } catch (err) { }
    }

    const extractDataAndBindValues = (parsedObject: any) => {
        try {
            const defaultGuuid =
                parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue
                    ? uuidv4()
                    : undefined
            dispatch({
                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                payload: updateJsonToAddParentIdToFieldTypes(
                    parsedObject,
                    defaultGuuid,
                ),
            })
            setApiResponseJson(
                updateJsonToAddParentIdToFieldTypes(parsedObject, defaultGuuid),
            )
        } catch (err) { }
    }

    const checkIfChanged = () => {
        let isModified = false
        try {
            const modifiedData: any = roleId
                ? findChangedFieldTypesForAppRole(
                    apiResponseJson,
                    appRightsFieldTypes,
                    ['SourceValues', 'totalCount'],
                )
                : findChangedFieldTypesForAppRight(
                    apiResponseJson,
                    appRightsFieldTypes,
                    ['SourceValues', 'totalCount'],
                )

            if (roleId) {
                const appRights = modifiedData?.Rights || []
                appRights.forEach((right: any) => {
                    const rightFieldTypes = right?.FieldType || []
                    const changedFieldType = rightFieldTypes.find(
                        (x: any) => x.isModified,
                    )
                    if (changedFieldType) {
                        isModified = true
                    }
                })
            } else {
                const rightFieldTypes = modifiedData?.FieldType || []
                const changedFieldType = rightFieldTypes.find(
                    (x: any) => x.isModified,
                )
                if (changedFieldType) {
                    isModified = true
                }
            }
        } catch (err) { }
        return isModified
    }

    const bindUpdatedFieldTypeValues = () => {
        const originalData = JSON.parse(data)
        const parsedObject = Array.isArray(originalData)
            ? originalData[0]
            : originalData
        const parsedMappedWithApiFormat = updateJsonMappingWithApiFormat(parsedObject, false)

        if (roleId) {
            return updateJsonMappingWithApiFormat(findChangedFieldTypesForAppRole(
                parsedMappedWithApiFormat,
                appRightsFieldTypes,
                ['SourceValues'],
            ), true)
        } else {
            return updateJsonMappingWithApiFormat(findChangedFieldTypesForAppRight(
                parsedMappedWithApiFormat,
                appRightsFieldTypes,
                ['SourceValues'],
            ), true)
        }
    }

    const preAdd = () => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetPerson,
            assignmentType: 'Add',
            application: {
                ...resourceItem.resource,
                id: roleId || rightId,
                assignmentId: resourceItem.resource?.id,
            },
            assignmentStatus: resourceItem.assignment,
            fieldTypeValueJson: appRightsFieldTypes
                ? JSON.stringify([bindUpdatedFieldTypeValues()])
                : undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const preAddSplitted = (fieldType: any) => {
        const itemToAdd = cartHelpers.applicationsFieldTypeJsonCartItem({
            targetPerson,
            assignmentType: 'Add',
            application: {
                ...resourceItem.resource,
                id: roleId || rightId,
                assignmentId: resourceItem.resource?.id,
            },
            assignmentStatus: resourceItem.assignment,
            fieldTypeValueJson: fieldType
                ? JSON.stringify([fieldType])
                : undefined,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handleSave = () => {
        try {
            if (checkIfValidJson()) {
                if (checkIfAllRequiredFieldFilled()) {
                    if (checkIfValidFreeLablValues(appRightsFieldTypes)) {
                        if (
                            appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                        ) {
                            const originalData = JSON.parse(data)
                            const parsedObject = Array.isArray(originalData)
                                ? originalData[0]
                                : originalData
                            const parsedMappedWithApiFormat =
                                updateJsonMappingWithApiFormat(
                                    parsedObject,
                                    false,
                                )
                            let itemsToAdd: any = []
                            const fieldTypes =
                                appRightsFieldTypes?.FieldType || []
                            const orginalFieldTypeData =
                                parsedMappedWithApiFormat?.FieldType || []
                            fieldTypes.forEach((item: any) => {
                                const fieldType: any =
                                    getModifiedFieldTypeValue(
                                        orginalFieldTypeData,
                                        item,
                                    )
                                if (fieldType?.isModified) {
                                    const parentObject = {
                                        ...appRightsFieldTypes,
                                    }
                                    if (fieldType?.AzFieldTypeID) {
                                        const valueType =
                                            fieldType?.AzFieldTypeSelectionRuleTypeID ===
                                                FIELD_TYPES_SELECTION_RULE.MULTISELECT
                                                ? 'AssignedValues'
                                                : 'AssignedValue'
                                        let fieldValue = fieldType[valueType]

                                        if (fieldType?.AssignedAllValuesRange) {
                                            const itemToAdd: any =
                                                preAddSplitted({
                                                    ...parentObject,
                                                    FieldType: [
                                                        {
                                                            ...fieldType,
                                                        },
                                                    ],
                                                })
                                            itemsToAdd.push(itemToAdd)
                                        } else {
                                            if (Array.isArray(fieldValue)) {
                                                const fieldToAdd =
                                                    fieldValue.filter(
                                                        (x: any) =>
                                                            x.ActionType !==
                                                            ACTION_TYPES.NO_CHANGE,
                                                    )
                                                fieldToAdd.forEach(
                                                    (field: any) => {
                                                        const itemToAdd: any =
                                                            preAddSplitted({
                                                                ...parentObject,
                                                                FieldType: [
                                                                    {
                                                                        ...fieldType,
                                                                        [valueType]:
                                                                            [
                                                                                field,
                                                                            ],
                                                                    },
                                                                ],
                                                            })
                                                        itemsToAdd.push(
                                                            itemToAdd,
                                                        )
                                                    },
                                                )
                                            } else {
                                                if (
                                                    fieldType?.AzFieldTypeSelectionRuleTypeID !==
                                                        FIELD_TYPES_SELECTION_RULE.MULTISELECT &&
                                                    fieldValue
                                                ) {
                                                    fieldValue = [fieldValue]
                                                }
                                                const itemToAdd: any =
                                                    preAddSplitted({
                                                        ...parentObject,
                                                        FieldType: [
                                                            {
                                                                ...fieldType,
                                                                [valueType]:
                                                                    fieldValue,
                                                            },
                                                        ],
                                                    })
                                                itemsToAdd.push(itemToAdd)
                                            }
                                        }
                                    }
                                }
                            })

                            if (!itemsToAdd?.length) return
                            if (cartItem?.length) {
                                removeAndAddItemsToCart(cartItem, itemsToAdd)
                            } else {
                                addItemsHandle(itemsToAdd)
                            }
                        } else {
                            if (cartItem?.length) {
                                if (cartItem?.length) {
                                    const cartItemIds = cartItem.map(
                                        (cartItem: any) => cartItem.id,
                                    )
                                    removeItemsFromCart(cartItemIds).then(
                                        () => {
                                            handleAddToCart()
                                        },
                                    )
                                } else {
                                    handleAddToCart()
                                }
                            } else {
                                handleAddToCart()
                            }
                        }
                    } else {
                        showWarningMessage(t('Common_RequiredUniqueLabels'))
                    }
                } else {
                    showWarningMessage(
                        t('Common_RequiredFieldMissingValues'),
                    )
                    setIsFormValidated(true)
                }
            } else {
                showWarningMessage(t('Common_WronglyConfiguredJson'))
            }
        } catch (err) { }
    }

    const addItemsHandle = (itemsToAdd: any) => {
        try {
            addItemsToCart(itemsToAdd).then(() => {
                refetch()
                query.delete('selected')
                history.push(`${history.location.pathname}?${query.toString()}`)
            })
        } catch (err) { }
    }

    const removeAndAddItemsToCart = (
        itemsToRemove: any,
        itemsToAdd: any,
    ) => {
        try {
            const cartItemIds = itemsToRemove.map((cartItem: any) => cartItem.id)
            removeItemsFromCart(cartItemIds).then(() => {
                addItemsHandle(itemsToAdd)
            })

        } catch (err) { }
    }

    const handleAddToCart = () => {
        try {
            const itemToAdd: any = preAdd()
            if (itemToAdd === undefined) return

            addItemToCart(itemToAdd).then(() => {
                refetch()
                query.delete('selected')
                history.push(`${history.location.pathname}?${query.toString()}`)
            })
        } catch (err) { }
    }

    const checkIfDataAvailableToBind = () => {
        try {
            if (!apiResponseJson) {
                return false
            }
            if (roleId) {
                if (apiResponseJson?.Rights?.length) {
                    return true
                }
            } else {
                if (apiResponseJson?.FieldType?.length) {
                    return true
                }
            }
        } catch (err) { }
        return false
    }

    const checkIfAllRequiredFieldFilled = () => {
        const data = { ...appRightsFieldTypes }
        let isRequiredFilled = true
        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkAllRequiredFieldTypeFilled(item)) {
                                isRequiredFilled = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkAllRequiredFieldTypeFilled(item)) {
                            isRequiredFilled = false
                        }
                    })
                }
            }
        } catch (err) { }
        return isRequiredFilled
    }

    const checkIfRequiredFieldExist = (right: any) => {
        let isRequired = false
        if (right?.FieldType?.length) {
            for (let i = 0; i < right?.FieldType?.length; i++) {
                if (right?.FieldType[i].IsRequired) {
                    isRequired = true
                    break
                }
            }
        }
        return isRequired
    }

    const checkIfValidJson = () => {
        const data = { ...appRightsFieldTypes }
        let isValidJson = true
        if (!isFormValidated) {
            return isValidJson
        }

        try {
            if (data?.Rights?.length) {
                data?.Rights?.forEach((right: any) => {
                    if (right?.FieldType?.length) {
                        right?.FieldType?.forEach((item: any) => {
                            if (!checkFieldTypeWithValidSourceValues(item)) {
                                isValidJson = false
                            }
                        })
                    }
                })
            } else {
                if (data?.FieldType?.length) {
                    data?.FieldType?.forEach((item: any) => {
                        if (!checkFieldTypeWithValidSourceValues(item)) {
                            isValidJson = false
                        }
                    })
                }
            }
        } catch (err) { }
        return isValidJson
    }

    const itemAlreadyInCartForRemoval = () => {
        return (
            cartItem?.length &&
            cartItem.find((x: any) => x?.assignmentType === 'Remove')
        )
    }

    const postRevokeOrRenew = () => {
        if (toggleDrawer) {
            toggleDrawer()
        } else {
            query.delete('selected')
            history.push(`${history.location.pathname}?${query.toString()}`)
        }
    }

    const preRevoke = async () => {
        try {
            const itemToRevoke = cartHelpers.applicationsAccessToCartItem({
                targetPerson,
                assignmentType: 'Remove',
                application: {
                    ...resourceItem.resource,
                    id: roleId || rightId,
                },
                assignmentStatus: {
                    ...resourceItem.assignment,
                    assignmentId: resourceItem?.resource?.id,
                },
            })

            if (!itemToRevoke) return undefined


            if (cartItem?.length) {
                const cartItemIds = cartItem.map((cartItem: any) => cartItem.id)
                return removeItemsFromCart(cartItemIds).then(() => {
                    return itemToRevoke
                })

            }

            return itemToRevoke
        } catch (err) { }
        return undefined
    }

    return (
        <Fragment>
            {checkIfDataAvailableToBind() && !isLoading ? (
                <Fragment>
                    <GradientSpacer />
                    <Box className={classes.header}>
                        <Typography variant="h2">
                            {t('Common_SelectFieldTypeValues')}
                        </Typography>
                    </Box>
                    <Box className={classes.root}>
                        <Box>
                            {roleId ? (
                                // Section to bind multiple Rights under a role
                                <Fragment>
                                    {appRightsFieldTypes?.Rights?.length
                                        ? appRightsFieldTypes.Rights.map(
                                            (item: any, index: number) => (
                                                <AppRoleRight
                                                    rightInfo={item}
                                                    key={`app-role-right-${index}`}
                                                    isAppRole={true}
                                                    isFormValidated={
                                                        isFormValidated
                                                    }
                                                    defaultExpanded={
                                                        appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                                                    }
                                                    showRequiredFieldMessage={
                                                        checkIfRequiredFieldExist(
                                                            item,
                                                        ) &&
                                                        !checkIfAllRequiredFieldFilled()
                                                    }
                                                />
                                            ),
                                        )
                                        : null}
                                </Fragment>
                            ) : (
                                // Section to bind Rights
                                <Fragment>
                                    <AppRoleRight
                                        rightInfo={appRightsFieldTypes}
                                        isAppRole={false}
                                        isFormValidated={isFormValidated}
                                        defaultExpanded={
                                            appRightsFieldTypes?.SplitBusinessRequestApprovalPerFieldTypeValue
                                        }
                                        showRequiredFieldMessage={
                                            checkIfRequiredFieldExist(
                                                appRightsFieldTypes,
                                            ) &&
                                            !checkIfAllRequiredFieldFilled()
                                        }
                                    />
                                </Fragment>
                            )}
                        </Box>
                    </Box>
                </Fragment>
            ) : isLoading && (isManageAccess || accessLevel?.hasFieldTypes) ? (
                <Fragment>
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                </Fragment>
            ) : null}

            {isManageAccess && (
                <Box className={classes.footerButton}>
                    {/* Save Button in case of manage access(UI needs tobe updated, Waiting on UX) */}
                    {checkIfDataAvailableToBind() && !isLoading && (
                        <Box>
                            <Tooltip
                                title={
                                    itemAlreadyInCartForRemoval()
                                        ? t('Common_ItemAlreadyInCartForRevoke')
                                        : !checkIfChanged()
                                            ? t('Common_NoChangeFound')
                                            : ''
                                }
                            >
                                <Box
                                    padding="32px 30px 32px 30px"
                                    className={classes.saveBtnContainer}
                                >
                                    <Button
                                        disabled={
                                            itemAlreadyInCartForRemoval() ||
                                            !checkIfChanged()
                                        }
                                        onClick={handleSave}
                                        className={classes.saveButton}
                                        startIcon={
                                            <Icon
                                                name="AddToCart"
                                                style={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                }}
                                                color={
                                                    theme?.palette?.common
                                                        ?.white
                                                }
                                                fill={
                                                    theme?.palette?.common
                                                        ?.white
                                                }
                                            />
                                        }
                                    >
                                        {addToCartLoading ||
                                            addItemsToCartLoading ||
                                            isRemoving ? (
                                            <Loader
                                                color={
                                                    theme?.palette?.common
                                                        ?.white
                                                }
                                            />
                                        ) : (
                                            t('Common_AddToCart')
                                        )}
                                    </Button>
                                </Box>
                            </Tooltip>
                        </Box>
                    )}
                    {/* Revoke Button */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <Tooltip
                            title={
                                itemAlreadyInCartForRemoval()
                                    ? t('Common_ItemAlreadyInCart')
                                    : ''
                            }
                        >
                            <Box
                                style={{
                                    display: 'inline-flex',
                                    justifyContent: 'flex-end',
                                    minWidth: '18rem',
                                }}
                            >
                                <RenewRevoke
                                    resourceType={applicationType}
                                    resource={resourceItem.resource}
                                    assignment={resourceItem.assignment}
                                    postRenew={postRevokeOrRenew}
                                    preRevoke={preRevoke}
                                    postRevoke={postRevokeOrRenew}
                                    item={resourceItem}
                                    disabled={itemAlreadyInCartForRemoval()}
                                    warningMessageKey={
                                        Boolean(cartItem?.length)
                                            ? 'Common_ItemAlreadyInCartForEdit'
                                            : ''
                                    }
                                    isRevoking={isRemoving}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </Fragment>
    )
}

export default DynamicFieldTypesSelector
