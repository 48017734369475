import React from 'react'
import classNames from 'classnames'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
const useStyles = makeStyles(
    createStyles({
        showMaster: {
            position: 'relative',
        },
        lockbtn: {
            position: 'absolute',
            top: 0,
            bottom: '0',
            right: '0',
            left: '0',
            background: 'rgba(238, 240, 244, 0.82)',
            minHeight: '40px',
            zIndex: '999',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                background: 'rgba(238, 240, 244, 0.82) !important',
            },
            '& button': {
                height: 24,
                width: 90,
                background: '#262fa1',
                color: 'white',
                fontSize: 14,
                borderRadius: '10px',
                padding: 5,
                margin: 'auto',
                boxShadow: '0 4px 16px 0 rgba(38, 47, 161, 0.3)',
                padding: '2px 8px 2px 3px',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                    background: '#262fa1 !important',
                },
            },
        },
    }),
)
const MasterLockOverlay = ({
    showMasterLock,
    handleMasterUnlock,
    children,
    btnLabel,
    styles = {},
    buttonStyles = {},
}) => {
    const classes = useStyles()
    return (
        <div
            className={classNames({
                [classes.showMaster]: showMasterLock,
            })}
        >
            {showMasterLock && (
                <Box className={classes.lockbtn} style={{ ...styles }}>
                    <Button
                        style={{ ...buttonStyles }}
                        onClick={() => handleMasterUnlock()}
                    >
                        <Icon
                            style={{
                                height: 15,
                                width: 12,
                            }}
                            name={'LockRound'}
                        />
                        {btnLabel && (
                            <span
                                style={{
                                    fontSize: 12,
                                    marginLeft: '4px',
                                }}
                            >
                                {btnLabel}
                            </span>
                        )}
                    </Button>
                </Box>
            )}
            {children}
        </div>
    )
}

export default MasterLockOverlay
