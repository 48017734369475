import { Icon } from 'packages/eid-icons'

const ComputerTypeIcon = ({ data, attribute }) => {
    const dataValue = data.resource
    const operatingSystem = dataValue[attribute.name]
    let computerTypeName = ''
    if (operatingSystem === 'Windows') {
        computerTypeName = 'WindowBlue'
    } else if (operatingSystem === 'MacOS') {
        computerTypeName = 'MacOS'
    } else if (operatingSystem === 'Linux') {
        computerTypeName = 'Ubuntu'
    } else if (operatingSystem === 'Mainframe') {
        computerTypeName = 'Mainframe'
    } else if (operatingSystem === 'AS/400') {
        computerTypeName = 'AS400'
    } else if (operatingSystem === 'Android') {
        computerTypeName = 'Android'
    } else if (operatingSystem === 'iOS') {
        computerTypeName = 'iOS'
    } else if (operatingSystem === 'Unix') {
        computerTypeName = 'Unix'
    } else {
        computerTypeName = 'ComputerDefault'
    }
    return <Icon name={computerTypeName} />
}

export default ComputerTypeIcon
