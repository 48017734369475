import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import MailboxDetails from './MailboxDetails'
import { useMailbox } from 'hooks'

const MailboxDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()

    const { data: mailbox } = useMailbox(id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={mailbox?.friendlyName} />
            {!mailbox && <Loader />}
            {mailbox && (
                <MailboxDetails
                    mailbox={mailbox}
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={mailbox?.friendlyName} />}
        >
            {!mailbox && <Loader />}
            {mailbox && (
                <MailboxDetails mailbox={mailbox} toggleDrawer={toggleDrawer} />
            )}
        </Drawer>
    )
}

export default MailboxDrawer
