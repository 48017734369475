import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty } from 'packages/core'
import GenericButton from 'components/Button/GenericButton'
import { useTargetPerson, useAddItemToCart } from 'hooks'
import RequestableResourceDetails from 'components/RequestableResourceDetails'
import { WarningModal } from 'packages/eid-ui'
import ActivateNowButton from '../ListingPage/ActivateNowButton'
import cartHelpers from 'containers/Cart/cartHelpers'
import ComputerTypeIcon from 'components/DynamicComponents/ComputerTypeIcon'
import CustomCardHeader from 'containers/ListingPage/CardHeader'
import { FieldTypeJsonPreview } from './TableView'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px !important',
        position: 'relative',
        overflow: 'visible',
        boxShadow: 'none !important',
        border: 'none !important',
        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',

            '&:not(:hover)': {
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})

const styles = {
    card: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        position: 'relative',
        overflow: 'visible',

        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',

            zIndex: 4,

            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
}

const ItemCard = withStyles(styles)((props) => {
    const {
        item,
        cardStyles,
        attributes,
        color,
        showImage = false,
        resourceType,
        onItemClick,
    } = props
    const data = item.resource ? item.resource : item
    const [targetPerson] = useTargetPerson()
    const [showConfirm, setConfirm] = useState(false)

    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const isRevokable = item?.assignment?.assignmentDetails?.isRevokable
    const canActivateNow = item?.assignment?.canActivateNow

    const handleRevoke = () => {
        const itemToRevoke = cartHelpers.accessItemToCartItem(
            resourceType.name,
            targetPerson,
            item,
            'Remove',
        )
        if (!itemToRevoke) return

        addItemToCart(itemToRevoke).then(() => setConfirm(false))
    }

    const { t } = useTranslation()
    const cardClasses = useCardStyles({ cardStyles })
    const theme = useTheme()
    const headerAttribute = attributes.find((x) => x.isHeader)
    const imageAttribute = attributes.find(
        (i) => i.component?.name === 'ApplicationImage',
    )
    let imgUrl
    if (imageAttribute && showImage) {
        imgUrl = !isNilOrEmpty(data[imageAttribute.component.name])
            ? getFullImageUrl(data[imageAttribute.component.name])
            : getFullImageUrl('/webcdn/Images/AppLogos/Genric-1.png')
    }
    const iconAttribute = attributes.find((i) => i.component?.type === 'icon')
    const iconComponent = iconAttribute ? (
        <Box>
            {/* Below is just for computers */}
            <ComputerTypeIcon attribute={iconAttribute} data={item} />
        </Box>
    ) : (
        <></>
    )
    return (
        <Card classes={cardClasses} role="button">
            <CustomCardHeader
                title={data[headerAttribute?.name]}
                url={imgUrl}
                showIcon={!imgUrl && iconAttribute ? true : false}
                iconComponent={iconComponent}
            />

            <RequestableResourceDetails
                item={data}
                attributes={attributes}
                assignmentDetails={item?.assignment?.assignmentDetails}
                color={color}
                additionalData={item}
                isManageAccess={true}
                data={item}
                resourceType={resourceType}
            />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
            >
                <Box
                    style={{
                        gap: '10px',
                        padding: '20px',
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <FieldTypeJsonPreview ci={item} />
                    <GenericButton
                        onClick={onItemClick}
                        color={theme?.palette?.secondary?.main}
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '100%',
                            height: '32px',
                        }}
                        label={t('Details')}
                    />

                    {canActivateNow && (
                        <ActivateNowButton
                            resource={data}
                            resourceType={resourceType}
                            forCardView={true}
                            assignment={item?.assignment}
                        />
                    )}
                    {isRevokable && !canActivateNow && (
                        <>
                            <GenericButton
                                onClick={() => setConfirm(true)}
                                color="transparent"
                                rootStylesProp={{
                                    border: `1px solid ${theme?.palette?.error?.main}`,
                                    borderRadius: '4px',
                                    height: '32px',
                                    padding: '8px 15px 8px 16px',
                                    color: theme?.palette?.error?.main,
                                    fontSize: '14px',
                                }}
                                label={t('Common_Revoke')}
                            />

                            <WarningModal
                                title={t('Common_Confirm')}
                                description={t(
                                    'Common_ConfirmationBeforeRevocation',
                                )}
                                yesLabel={t('Common_Yes')}
                                noLabel={t('Common_No')}
                                open={showConfirm}
                                cancel={() => setConfirm(false)}
                                proceeding={isAdding}
                                proceed={handleRevoke}
                            />
                        </>
                    )}
                </Box>
            </Box>
            <div className={cardClasses.lineGlow}></div>
        </Card>
    )
})

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
