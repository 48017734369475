import { Fab, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loader, theme } from 'packages/eid-ui'

const drawerButtonStyles = makeStyles((theme) => ({
    root: ({ loading, color }) => ({
        minWidth: '80px',
        height: '31px',
        border: `solid 1px ${color}`,
        backgroundColor: loading ? color : theme?.palette?.common?.white,
        boxShadow: '0 0 0 0',
        color: loading ? theme?.palette?.common?.white : color,
        textTransform: 'capitalize !important',
        lineHeight: 1,

        '& path': {
            fill: color,
        },
        '&:hover': {
            backgroundColor: color,
            color: 'white',
            '& path': {
                fill: theme?.palette?.common?.white,
            },
            '& span': {
                color: theme?.palette?.common?.white,
            },
        },
    }),
}))
const drawerButtonStylesFilled = makeStyles((theme) => ({
    root: ({ color, fill, isDisabled }) => ({
        minWidth: '80px',
        height: '31px',
        border: `solid 1px ${color}`,
        backgroundColor: fill,
        boxShadow: '0 0 0 0',
        color: theme?.palette?.common?.white,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        textTransform: 'capitalize !important',
        lineHeight: 1,
        '& path': {
            fill: theme?.palette?.common?.white,
        },
        '& .MuiFab-label': {
            width: 'auto !important',
        },
        '&:hover': {
            backgroundColor: fill,
            color: theme?.palette?.common?.white,
            border: `solid 1px ${fill}`,
            '& path': {
                fill: theme?.palette?.common?.white,
            },
            '& span': {
                color: theme?.palette?.common?.white,
            },
        },
    }),
}))
const Button = ({
    onClick,
    loading,
    children,
    color,
    fill,
    isFilled,
    isDisabled,
}) => {
    const classes = isFilled
        ? drawerButtonStylesFilled({ color, fill, isDisabled })
        : drawerButtonStyles({ loading, color })
    const theme = useTheme()
    return (
        <Fab classes={classes} variant="extended" onClick={onClick}>
            {loading ? (
                <div
                    style={{
                        width: '12px',
                    }}
                >
                    <Loader
                        color={theme?.palette?.common?.white}
                        height="100%"
                        size={0.5}
                        display="inline"
                    />
                </div>
            ) : (
                children
            )}
        </Fab>
    )
}

Button.defaultProps = {
    color: theme?.palette?.secondary?.main,
}

export default Button
