import { styled, Box } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { defaultDataProtectedSubcomponent } from 'utils'

const AttributeLabel = styled(Box)({
    fontSize: '1.2rem',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '21.2rem',
    width: '21.2rem',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    fontWeight: 'bold',
    [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
        minWidth: '16rem',
        width: '16rem',
    },
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0.6rem',
    letterSpacing: '0.048rem',
})

const AttributeValue = styled(Box)(({ theme }) => ({
    fontSize: '1.4rem',
    verticalAlign: 'middle',
    lineHeight: 1.33,
    color: theme?.palette?.common?.black,
    width: '100%',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    paddingTop: '0.5rem',
    letterSpacing: '0',
}))

const AttributeWrapper = styled(Box)({
    padding: '0.224rem 0 0.8rem 0',

    // If an AttributeWrapper is the first-child in it's parent, and it also has a sibling that is also an AttributeWrapper, add this styling
    '&:first-child:has(+ &)': {
        paddingTop: '0.8rem',
    },
})

const Attribute = (props) => {
    const {
        label,
        value,
        orientation,
        labelProps,
        valueProps,
        protectSubcomponent = defaultDataProtectedSubcomponent,
    } = props
    return (
        <AttributeWrapper
            display={orientation === 'vertical' ? 'inherit' : 'flex'}
            paddingY="8.5px"
            data-protectedsubcomponent={protectSubcomponent}
        >
            <AttributeLabel {...labelProps}>{label}</AttributeLabel>
            <AttributeValue
                marginTop={orientation === 'vertical' ? '1.2rem' : '0'}
                {...valueProps}
            >
                {isNilOrEmpty(value) ? '-' : value}
            </AttributeValue>
        </AttributeWrapper>
    )
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute
