import React from 'react'
import { Layout } from 'components'
import { Box, Grid, Paper } from '@mui/material'
import SubHeader from 'components/SubHeader'
import { WorkflowIFrame } from 'packages/eid-ui'
import { useHistory, useParams } from 'react-router'
import { useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useWorkflows } from 'hooks/workflowsHooks'
import { Skeleton } from '@mui/material';
import WorkFlowsItems from './WorkflowItems.tsx'
import './workFlow.css'
import { bindResourceTypeNounAndVerbQueryParams } from 'utils'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useAppState } from 'appContext'
import useSubcomponents from 'useSubcomponents'
import Search from 'components/Layout/ITShopHeader/Search'
import NoAccessInfo from 'components/NoAccessInfo'

const WorkFlows: React.FC<any> = () => {
    const query = useQuery()

    const history = useHistory()
    //TODO: use resource type to detect the type and bind current resource type
    const { resourceType }: any = useParams()

    const { resourceTypes } = useConfiguration()
    const { t } = useTranslation()

    const resourceTypeSelected: any = resourceTypes.filter(
        (x) => x.name.toLowerCase() === resourceType.toLowerCase(),
    )

    const [state]: any = useResourceTypeContext()
    const currentResourceType =
        resourceTypeSelected && resourceTypeSelected.length > 0
            ? resourceTypeSelected[0]
            : {}

    const { hasAccessToTabs, canSeeSimpleTextSearch } = useSubcomponents()

    const { hasAccessToAtleastOneTab, hasAccessToWorkFlow } = hasAccessToTabs(
        currentResourceType.name,
    )
    const { data, isLoading } = useWorkflows(
        resourceType,
        state.searchTerm.length
            ? `${bindResourceTypeNounAndVerbQueryParams(
                resourceType,
            )}&searchTerm=${state.searchTerm}`
            : bindResourceTypeNounAndVerbQueryParams(resourceType),
        hasAccessToWorkFlow,
    )
    const totalCount = data ? data.length : 0

    const workflowName = query.get('workflowName')
    const resourceID = query.get('resourceID')
    const returnUrl = query.get('returnUrl')
    const selectedResourceGuid = query.get('SelectedResourceGuid')
    const wfParams: any = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }
    if (selectedResourceGuid) {
        wfParams.push(`SelectedResourceGuid=${selectedResourceGuid}`)
    }
    const [{ appConfig }]: any = useAppState()

    const searchConfig = appConfig.style?.search
    const searchProps = {
        styleData: searchConfig?.style,
    }
    return (
        <Layout
            currentResourceType={currentResourceType}
            subHeader={
                <SubHeader
                    totalCount={totalCount}
                    resourceTypes={resourceTypes}
                    showNavTabs={hasAccessToAtleastOneTab}
                    showWorkFlows={hasAccessToWorkFlow}
                    currentResourceType={currentResourceType}
                    showBackButton={workflowName}
                />
            }
        >
            {hasAccessToAtleastOneTab ? (
                <Paper
                    className={`root workflow ${appConfig.style.header.hide && 'noheader'
                        }`}
                >
                    {!workflowName &&
                        searchConfig?.show &&
                        canSeeSimpleTextSearch && (
                            <Grid
                                data-protectedsubcomponent={
                                    canSeeSimpleTextSearch
                                }
                                container
                                style={{
                                    justifyContent:
                                        data?.length <= 0 ? 'center' : 'start',
                                    gap: '24px',
                                }}
                            >
                                <Grid
                                    className="workflowsearchgrid hideonlowresolution"
                                    md={4}
                                    xs={12}
                                    spacing={2}
                                />
                                <Grid
                                    className="workflowsearchgrid"
                                    md={4}
                                    xs={12}
                                    spacing={2}
                                />
                                <Grid
                                    className="workflowsearchgrid"
                                    md={4}
                                    xs={12}
                                    spacing={2}
                                >
                                    <Search {...searchProps} />
                                </Grid>
                            </Grid>
                        )}
                    <Grid
                        container
                        style={{ justifyContent: 'start', gap: '24px' }}
                    >
                        {workflowName ? (
                            <WorkflowIFrame
                                baseEidUrl={config.BASE_EID_URL}
                                workflowName={workflowName}
                                onComplete={() => {
                                    query.delete('workflowName')
                                    history.push(returnUrl ? returnUrl :
                                        history.location.pathname,
                                        query.toString(),
                                    )
                                }}
                                workflowParams={wfParams}
                                loadingMessage={t('WorkflowLoadingMessage')}
                            />
                        ) : (
                            <>
                                {isLoading && !data ? (
                                    <Box width={'100%'}>
                                        <Skeleton height={100} />
                                    </Box>
                                ) : data?.length > 0 ? (
                                    data.map((item: any, index: number) => (
                                        <WorkFlowsItems
                                            key={`workflow-item${index}`}
                                            title={item.friendlyName}
                                            description={item.description}
                                            isEnabled={item.requestWorkflowName}
                                            onClick={() => {
                                                item.requestWorkflowName
                                                    ? history.push(
                                                        `${history.location.pathname}?workflowName=${item.requestWorkflowName}`,
                                                    )
                                                    : history.push(
                                                        `${history.location.pathname}`,
                                                    )
                                            }}
                                        />
                                    ))
                                ) : (
                                    <div>{t('Common_NoWorkflowsFound')}</div>
                                )}
                            </>
                        )}
                    </Grid>
                </Paper>
            ) : (
                <NoAccessInfo />
            )}
        </Layout>
    )
}
export default WorkFlows
