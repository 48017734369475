import * as React from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers/'
import { isNilOrEmpty } from 'packages/core'

export interface TimeDurationControlProps {
    disabled?: boolean
    selectedStartDate: any
    setSelectedStartDate: any
    minStartDate?: any
    selectedDuration: any
    setSelectedDuration: any
    maximumDuration: number
    hasInvalidDates?: boolean
    disabledTimeSlots?: any
    customTimeControl?:boolean
}

export const TimeDurationControl: React.FC<TimeDurationControlProps> = ({
    disabled = false,
    selectedStartDate,
    setSelectedStartDate,
    minStartDate,
    selectedDuration,
    setSelectedDuration,
    maximumDuration,
    disabledTimeSlots,
    customTimeControl = false,
}) => {
    const { t } = useTranslation()

    return (
        <Box display="flex">
            <Box flex={1.2}>
                <NativeDateTimePicker
                    minDate={
                        minStartDate ||
                        moment(new Date()).format('YYYY-MM-DDTHH:mm')
                    }
                    value={moment(selectedStartDate)
                        .clone()
                        .local()
                        .format('YYYY-MM-DDTHH:mm')}
                    handleChange={(e: any) => {
                        if (isNilOrEmpty(e)) {
                            setSelectedStartDate(null)
                        } else {
                            const date = moment(e)
                            const dateToSet = date.clone().utc().format()
                            setSelectedStartDate(dateToSet)
                        }
                    }}
                    pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
                    customTimeControl={customTimeControl}
                    disabledTimeSlots={disabledTimeSlots}
                    clearBtnLabel={t("Clear")}
                    todayBtnLabel={t("Today")}
                >
                    <TextField
                        type="text"
                        value={
                            selectedStartDate
                                ? moment(selectedStartDate)
                                      .local()
                                      .format('L LT')
                                : ''
                        }
                        label={t('StartDateTime')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </NativeDateTimePicker>
            </Box>
            <Box flex="1" className="textfield">
                <TextField
                    style={{ marginTop: '8px', minWidth: '120px' }}
                    type="number"
                    value={selectedDuration}
                    label={t('Duration(Minutes)')}
                    InputProps={{
                        inputProps: { min: 0, max: maximumDuration },
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        setSelectedDuration(event.target.value)
                    }}
                    disabled={disabled}
                />
            </Box>
        </Box>
    )
}
