import React from 'react'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CommonAttributes } from '../CommonAttributes'
import useSubcomponents from 'useSubcomponents'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
const PaddedDiv = styled('div')({
    padding: '0 31px',
})

export const OverviewSection = ({ azureLicense }: any) => {
    const { t } = useTranslation()
    const parsedHtml = ReactHtmlParser(DomPurify.sanitize(azureLicense?.instructions))

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        canSeeAzureLicenseTenantAttribute,
        canSeeAzureLicenseLicensePoolAttribute,
        canSeeAzureLicenseTenantSubscriptionAttribute,
        canSeeAzureLicenseLicensedAssigneeAttribute,
    } = useSubcomponents()


    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('AzureLicenses_LicensePoolSubBundle')}
                    value={azureLicense.name}
                    orientation={attributeOrientation}
                />

                {canSeeAzureLicenseTenantAttribute && azureLicense.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_Tenant')}
                        value={azureLicense.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                        protectSubcomponent={canSeeAzureLicenseTenantAttribute}
                    />
                )}

                {canSeeAzureLicenseLicensePoolAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_LicensePool')}
                        value={azureLicense.azureLicensePool_FriendlyName}
                        orientation={attributeOrientation}
                        protectSubcomponent={canSeeAzureLicenseLicensePoolAttribute}
                    />
                )}

                {canSeeAzureLicenseTenantSubscriptionAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_TenantSubscription')}
                        value={azureLicense.azureTenantSubscription_FriendlyName}
                        orientation={attributeOrientation}
                        protectSubcomponent={canSeeAzureLicenseTenantSubscriptionAttribute}
                    />
                )}

                {canSeeAzureLicenseLicensedAssigneeAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_LicenseGroup')}
                        value={azureLicense.licensedAssignee_FriendlyName}
                        orientation={attributeOrientation}
                        protectSubcomponent={canSeeAzureLicenseLicensedAssigneeAttribute}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureLicense.description}
                    orientation={attributeOrientation}
                />
                <CommonAttributes resource={azureLicense} />
                {parsedHtml && parsedHtml.length > 0 && (
                    <InfoBoxWithIcon
                        iconName={'InfoNew'}
                        message={parsedHtml}
                        rootStyles={{
                            width: '100%',
                            marginTop: '2.8rem',
                            marginBottom: '1.8rem',
                        }}
                    />
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
