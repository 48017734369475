import { useState } from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAccessRequestPolicy, useComputerOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import ComputerSessionRequest from 'components/ComputerSessionRequest'
import ActivateNowButton from '../../../ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'
import { Divider } from 'packages/eid-ui'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}
const hasAccessToOwnerControl =
    'ITShop-Computers-ManageAcces-MembershipBased-Details-Owners-Control'

const OverviewSection = ({ item }) => {
    const computer = item.resource
    const { assignmentDetails, resourceAssignment } = item.assignment
    const requestPolicy = useAccessRequestPolicy(computer?.requestPolicyId)
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computer?.friendlyName ? computer.friendlyName : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computer.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Infrastructure')}
                    value={computer.infrastructureName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Computers_Environment')}
                    value={computer.environmentTypeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={computer.computerTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={computer.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computer.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={
                        computer.isPsmEnabled === null ? (
                            '-'
                        ) : computer.isPsmEnabled === true ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={
                        computer.isJITEnabled === null ? (
                            '-'
                        ) : computer.isJITEnabled === true ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={computer.description ? computer.description : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AccessLevel')}
                    value={
                        resourceAssignment?.friendlyName
                            ? resourceAssignment.friendlyName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AssignedVia')}
                    value={
                        resourceAssignment?.assigneePreviewName
                            ? resourceAssignment.assigneePreviewName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={
                        assignmentDetails?.endDateUtc
                            ? moment
                                  .utc(assignmentDetails.endDateUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {requestPolicy && (
                    <>
                        <Divider />
                        <AccessRequestPolicyDetails
                            policy={requestPolicy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
const AdditionalInformationSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? computer.id : null,
    )
    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}

const ComputerDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const theme = useTheme()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('Computers')
    const [activeTab, setActiveTab] = useState('overview')
    let showSessionRequestTab = false
    const selectedTabForComputers = query.get('tab')
    if (selectedTabForComputers && selectedTabForComputers === 'loginsession') {
        showSessionRequestTab = true
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                {showSessionRequestTab && (
                    <ItemDetails.Tabs.Tab
                        value="sessionRequest"
                        label={t('Common_SessionRequest')}
                    />
                )}
                {showSessionRequestTab && (
                    <span
                        style={{
                            position: 'relative',
                            top: '12px',
                            right: '16px',
                            background: theme?.palette?.quaternary?.main,
                            height: '15px',
                            width: '15px',
                            borderRadius: '50%',
                            fontSize: '10px',
                            color: theme?.palette?.common?.white,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '1px',
                        }}
                    >
                        {item?.resource?.checkOutCount
                            ? item.resource.checkOutCount
                            : 0}
                    </span>
                )}
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {activeTab === 'sessionRequest' && (
                <ComputerSessionRequest item={item.resource} />
            )}

            {item?.assignment.canActivateNow && (
                <ActivateNowButton
                    resource={item?.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    assignment={item?.assignment}
                />
            )}
            {/* TODO: Uncomment below if we need renew functionality */}
            {/* <RenewRevoke
                resourceType={computerType}
                resource={item.resource}
                assignment={item.assignment}
                preRevoke={preRevoke}
                postRevoke={toggleDrawer}
                postRenew={toggleDrawer}
                item={item}
            /> */}
        </>
    )
}

export default ComputerDetails
