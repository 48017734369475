import { ArrowIcon, Icon } from 'packages/eid-icons'
import { Box, useTheme } from '@mui/material'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { useEffect, useRef, useState } from 'react'
import { AdvancePeopleFinder } from './advancePeopleFinder'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option) => option.friendlyName

function PeopleSearch({
    label,
    value,
    url = '/api/People/search',
    advanceSearch = false,
    onChange,
    ...rest
}) {
    const { t } = useTranslation()
    const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false)
    const theme = useTheme()
    const ref = useRef(null)

    return (
        <>
            <Box
                style={{
                    display: advanceSearch ? 'flex' : 'block',
                    alignItems: advanceSearch && 'end',
                }}
            >
                <EidAutocomplete
                    label={label ? label : t('Common_SomeoneElse')}
                    placeholder={t('Common_SelectPerson')}
                    url={url}
                    queryParams={queryParams}
                    dedupingInterval={60000}
                    value={value ? value : null}
                    getOptionLabel={getOptionLabel}
                    optionComponent={OptionComponent}
                    onChange={onChange}
                    inputWrapperStyles={{
                        width: advanceSearch ? '26.3rem' : '100%',
                        boxShadow: '0 2px 4px 0 rgba(48, 127, 193, 0.15)',
                    }}
                    {...rest}
                />
                {advanceSearch && (
                    <div
                        ref={ref}
                        style={{
                            height: '4rem',
                            width: '4rem',
                            zIndex: openAdvanceSearch && 1400,
                            borderRadius: '0.4rem',
                            border: `0.1rem solid ${theme?.palette?.primary?.main}`,
                            backgroundColor: theme?.palette?.primary?.main,
                            padding: '1.2rem',
                            marginLeft: '2rem',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setOpenAdvanceSearch((p) => !p)
                        }}
                    >
                        {openAdvanceSearch ? (
                            <Icon
                                name={'SimpleUncheck'}
                                color="white"
                                style={{ width: '1.4rem', height: '1.4rem' }}
                            />
                        ) : (
                            <Icon name={'AdvancedSearch'} color="white" />
                        )}
                    </div>
                )}
            </Box>
            <AdvancePeopleFinder
                onClose={() => setOpenAdvanceSearch(false)}
                open={openAdvanceSearch}
                onChange={onChange}
                url={url}
                topPosition={ref?.current?.getBoundingClientRect().top}
                rightPosition={ref?.current?.getBoundingClientRect().right}
            />
        </>
    )
}

export default PeopleSearch
