import React from 'react'
import { Box, useTheme } from '@mui/material'
import { EidAutocomplete } from 'packages/eid-controls'

const styles = (theme) => ({
    '& > div': {
        fontFamily: 'Rubik !important',
        '& > input': {
            fontSize: '14px',
            height: '36px',
            padding: '12px 10px',
        },

        '&:hover, &:focus': {
            borderColor: `${theme?.palette?.primary?.main} !important`,
        },
    },
})

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})

const getOptionLabel = (option) => option.friendlyName

const BusinessRequestTypes = ({
    selectedRequestType,
    setSelectedRequestType,
}) => {
    const theme = useTheme()

    return (
        <Box margin="10px 0px">
            <EidAutocomplete
                placeholder="Business Request Type"
                rootStyleProps={styles(theme)}
                url="/api/cart/businessRequestTypes"
                dedupingInterval={60000}
                value={selectedRequestType ? selectedRequestType : null}
                getOptionLabel={getOptionLabel}
                onChange={(_, selectedItem) =>
                    setSelectedRequestType(selectedItem)
                }
                optionComponent={OptionComponent}
                supportsServerSideSearch={false}
                filterOptions={undefined}
            />
        </Box>
    )
}

export default BusinessRequestTypes
