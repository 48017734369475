import {
    Box,
    FormControlLabel,
    styled,
    useTheme,
    Typography,
    Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import {
    Checkbox,
    TextField,
    WithCloseButton,
    Avatar,
    Tooltip,
    ToggleSwitch,
} from 'packages/eid-ui'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import PeopleSearch from 'components/PeopleSearch'
import { useIsSmallScreen } from 'packages/core'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'

const useStyles = makeStyles({
    label: {
        color: '#b4b4b4',
        fontSize: '10px',
        textTransform: 'uppercase',
        width: '65px',
        position: 'absolute',
        left: '10px',
        top: '4px',
        zIndex: 50,
    },
    dateMaincontainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3.2rem',
        '&  span': {
            fontSize: '14px',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '16px',
        },
    },

    dateMainwidthcontainer: {
        '& > div': {
            width: '90%',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            width: '100%',
            '& > div': {
                width: '100%',
            },
        },
    },
    dueDateInputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',

        '& svg': {
            marginTop: '-10px',
            marginRight: '4px',
        },
        '& input': {
            padding: '10px',
            paddingLeft: '80px',
            color: '#5d6870',
            fontSize: '16px',
        },
        '& fieldset': {
            border: 'none',
        },
        '& > div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
            },
        },
    },
    peoplesearchcontaner: {
        maxWidth: '250px',
        '& svg': {
            position: 'relative',
            left: '10px',
        },
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            maxWidth: '100%',
        },
    },
    personindicatorcontainer: {
        maxWidth: '250px',
        [`@media (max-width:${MOBILE_SCREEN_WIDTH}px)`]: {
            maxWidth: '100%',
        },
    },
    checkoutTypography: {
        paddingTop: '1rem',
        paddingRight: '1rem',
        fontSize: '1.4rem',
    },
    checkedOutMainContainer: {
        display: 'flex',
        marginTop: '1.8rem',
        marginBottom: '1.6rem',
    },
})
const PersonIndicator = styled(WithCloseButton)(({ theme }) => ({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: theme?.palette?.common?.white,
    display: 'flex',
    width: '250px',
    alignItems: 'center',
    height: '40px',
}))

const Label = styled('label')({
    padding: '0 0 0 0.8rem',
    lineHeight: 1.5,
    width: '250px',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
    marginBottom: '0.8rem',
})
const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '800px',
}
export interface ComputerSessionHistoryFiltersProps {
    isActiveConnectionOnly: boolean
    handleCheckedClick: (e: React.ChangeEvent<HTMLInputElement>) => void
    startDate: string
    endDate: string
    setStartDate: (e: any) => void
    setEndDate: (e: any) => void
    onPersonSelect: (e: any) => void
    selectedPerson: any
    dateContainerLabel?: string
}

export const ComputerSessionHistoryFilters: FC<
    ComputerSessionHistoryFiltersProps
> = (props) => {
    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        handleCheckedClick,
        isActiveConnectionOnly,
        onPersonSelect,
        selectedPerson,
        dateContainerLabel,
    } = props

    const classes = useStyles()
    const theme = useTheme()

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    return (
        <>
            <Box className={classes.checkedOutMainContainer}>
                {/* Checkbox for checkout */}
                <Box
                    style={{
                        width: isSmallScreen ? '125px' : '287px',
                        paddingLeft: '3px',
                    }}
                >
                    <Label>{t('CheckedOut')}</Label>
                    <Stack direction="row" style={{ paddingLeft: '0.8rem' }}>
                        <Typography className={classes.checkoutTypography}>
                            {t('Common_No')}
                        </Typography>
                        <ToggleSwitch
                            onChange={(e: any) => {
                                handleCheckedClick(e)
                            }}
                            value={isActiveConnectionOnly}
                            label={t('Common_Yes')}
                        />
                    </Stack>
                </Box>

                {/* Who Requested filter Start */}
                {selectedPerson ? (
                    <Box className={classes.personindicatorcontainer}>
                        <Label>{t('CheckedOutBy')}</Label>
                        <PersonIndicator
                            // @ts-ignore
                            onClose={() => {
                                onPersonSelect(null)
                            }}
                        >
                            <Avatar
                                size="small"
                                src={getFullImageUrl(
                                    selectedPerson.imageThumbUrl,
                                )}
                            />
                            <Tooltip
                                color="#5D6870"
                                fontColor={theme?.palette?.common?.white}
                                enterDelay={500}
                                enterNextDelay={500}
                                tooltipStyles={tooltipStyles}
                                title={selectedPerson.friendlyName}
                            >
                                <Typography
                                    style={{
                                        marginLeft: '21px',
                                        wordBreak: 'break-all',
                                    }}
                                    display="block"
                                    variant="body1"
                                    noWrap={true}
                                >
                                    {selectedPerson.friendlyName}
                                </Typography>
                            </Tooltip>
                        </PersonIndicator>
                    </Box>
                ) : (
                    <Box className={classes.peoplesearchcontaner}>
                        <PeopleSearch
                            label={t('CheckedOutBy')}
                            labelComponent={Label}
                            value={selectedPerson}
                            onChange={(_: any, value: any) => {
                                if (value) {
                                    onPersonSelect({
                                        id: value.id,
                                        friendlyName: value.friendlyName,
                                        imageThumbUrl: value.imageThumbUrl,
                                    })
                                }
                            }}
                        />
                    </Box>
                )}
                {/* Who Requested filter End */}
            </Box>

            {dateContainerLabel && <Label>{dateContainerLabel}</Label>}
            <Box className={classes.dateMaincontainer}>
                <Box className={classes.dateMainwidthcontainer}>
                    <NativeDatePicker
                        value={startDate}
                        handleChange={(date: string) => {
                            setStartDate(date)
                        }}
                        maxDate={endDate}
                    >
                        <Box className={classes.dueDateInputContainer}>
                            <Box component="p" className={classes.label}>
                                {t('Common_StartDate')}
                            </Box>
                            <TextField
                                type="text"
                                margin="none"
                                readOnly
                                value={
                                    startDate
                                        ? moment
                                              .utc(startDate)
                                              .local()
                                              .format('L')
                                        : ''
                                }
                                style={{
                                    backgroundColor:
                                        theme?.palette?.common?.white,
                                    width: '100%',
                                    boxShadow:
                                        '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                    borderRadius: '5px',
                                }}
                                placeholder={t('Common_SelectDate')}
                            />
                            <Box position="absolute" right="8px" top="16px">
                                <Icon
                                    name="Tasks"
                                    width={19}
                                    height={20}
                                    color="#aab0b4"
                                />
                            </Box>
                        </Box>
                    </NativeDatePicker>
                </Box>
                <Box className={classes.dateMainwidthcontainer}>
                    {/* End Date picker */}
                    <NativeDatePicker
                        value={endDate}
                        handleChange={(date: string) => {
                            setEndDate(date)
                        }}
                        minDate={startDate}
                    >
                        <Box className={classes.dueDateInputContainer}>
                            <Box component="p" className={classes.label}>
                                {t('Common_EndDate')}
                            </Box>
                            <TextField
                                type="text"
                                margin="none"
                                readOnly
                                value={
                                    endDate
                                        ? moment
                                              .utc(endDate)
                                              .local()
                                              .format('L')
                                        : ''
                                }
                                style={{
                                    backgroundColor:
                                        theme?.palette?.background?.paper,
                                    width: '100%',
                                    boxShadow:
                                        '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                    borderRadius: '5px',
                                    color: '#5d6870',
                                }}
                                placeholder={t('Common_SelectDate')}
                            />
                            <Box position="absolute" right="8px" top="16px">
                                <Icon
                                    name="Tasks"
                                    width={19}
                                    height={20}
                                    color="#aab0b4"
                                />
                            </Box>
                        </Box>
                    </NativeDatePicker>
                </Box>
            </Box>
        </>
    )
}

export default ComputerSessionHistoryFilters
