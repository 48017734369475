import GenericButton from 'components/Button/GenericButton'
import { useComputerPSMSessionSSHLogs } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

const DownloadSSHLogsButton = ({ sessionId }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const [downloadLogs, { data, isLoading }] = useComputerPSMSessionSSHLogs(
        sessionId,
    )

    if (data) {
        const link = document.createElement('a')
        link.download = `${sessionId}.txt`
        const blob = new Blob([data], { type: 'text/plain' })
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    }

    return (
        <GenericButton
            onClick={() => {
                !isLoading && downloadLogs()
            }}
            loading={isLoading}
            color={theme?.palette?.primary?.main}
            rootStylesProp={{
                borderRadius: '4px',
                minWidth: '180px',
                height: '40px',
                fontSize: '14px',
                padding: '8px 12px 8px 12px',
            }}
            startIcon={
                !isLoading && (
                    <Icon
                        name="Save"
                        color={theme?.palette?.common?.white}
                        width="15px"
                        height="15px"
                    />
                )
            }
            label={t('Computers_DownloadSSHLog')}
        />
    )
}

export default DownloadSSHLogsButton
