import { useCheckMasterPasswordExist } from 'hooks'
import { PasswordModal } from 'components/PasswordModal'
import { useState } from 'react'
import { isNilOrEmpty } from 'packages/core'

const requireMasterPassword = (ChildComponent, loader) => (props) => {
    const { data, isLoading } = useCheckMasterPasswordExist()
    const [open, setOpen] = useState('setPassword')

    if (isLoading) {
        return loader ? loader : <></>
    }

    if (isNilOrEmpty(open)) {
        return <ChildComponent {...props} />
    }

    if (data && data?.isCustomerPAMLicensed && !data?.isMasterPasswordExist) {
        return (
            <PasswordModal
                showDefaultContent={false}
                open={open}
                setOpen={setOpen}
            />
        )
    } else return <ChildComponent {...props} />
}

export default requireMasterPassword
