import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useComputerLoginSessionAccessDetail } from 'hooks'
import LoginSessionAccessDetails from './LoginSessionAccessDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const ComputerLoginSessionAccessDrawer = ({
    id,
    attributes,
    toggleDrawer,
    ...rest
}) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const { data: loginSessionDetails } = useComputerLoginSessionAccessDetail(
        id,
    )

    let item
    if (loginSessionDetails) {
        item = {
            resource: loginSessionDetails,
        }
    }

    const itemDetails = <LoginSessionAccessDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default ComputerLoginSessionAccessDrawer
