import { FC, useEffect } from 'react'
import { EidSelect } from 'packages/eid-controls'
import { isNilOrEmpty } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'

export interface SelectFilterProps {
    url?: any
    value?: any
    label?: any
    placeholder?: any
    options?: any
    isLoading?: boolean
    onChange?: any
    queryParams?: any
    shouldTriggerApiCall?: any
    excludeOptions?: any
    primitiveType?: any
    dedupingInterval?: any
    debounceInterval?: any
    getOptionLabel?: any
    optionConfig?: any
    clearable?: any
    basedOn?: any
    excludeOptionsConfig?: any
}

export const SelectFilter: FC<SelectFilterProps> = (props) => {
    const {
        url,
        value,
        onChange,
        queryParams = [],
        getOptionLabel,
        optionConfig,
        basedOn,
        excludeOptionsConfig,
        clearable = true,
        ...rest
    } = props

    const [appState]: any = useAppState()
    const [state, dispatch]: any = useResourceTypeContext()

    let urlToUse
    let queryParamsToUse = {
        ...queryParams,
    }
    const isInitialLoad : boolean = state["initialSavedValue"]

    let basedOnValue
    if (!isNilOrEmpty(basedOn)) {
        const stateToUse = basedOn.contextType === 'global' ? appState : state

        basedOnValue = stateToUse[basedOn.contextProp]

        switch (basedOn.type) {
            case 'required':
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                }
                break
            case 'optional':
            default:
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                } else {
                    urlToUse = url
                }
                break
        }
    } else {
        urlToUse = url
    }

    useEffect(() => {
        if (!isInitialLoad && basedOn && value) {
            onChange(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basedOnValue, isInitialLoad])

    const excludeOptions: any = []

    if (excludeOptionsConfig) {
        const stateToUse: any =
            excludeOptionsConfig.contextType === 'global' ? appState : state
        var optionToExcludeValue: any =
            stateToUse[excludeOptionsConfig.contextProp]
        if (optionToExcludeValue) {
            excludeOptions.push(
                optionToExcludeValue[excludeOptionsConfig.valueProp],
            )
        }
    }

    const clearIcon = (
        <Icon name="Close" color="#959598" onClick={() => onChange(null)} />
    )

    const handleOnChange = (value:any) => {
        if(isInitialLoad){
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: {initialSavedValue : false},
            })
        }
        onChange(value)
    }

    return (
        <EidSelect
            getOptionLabel={(option: any) => option[optionConfig.labelProp]}
            value={value}
            onChange={(value: any) => {
                const valueToDispatch = value ? value : ''
                handleOnChange(valueToDispatch)
            }}
            url={urlToUse}
            queryParams={queryParamsToUse}
            excludeOptions={excludeOptions}
            clearIcon={clearable && !isNilOrEmpty(value) && clearIcon}
            {...rest}
        />
    )
}
