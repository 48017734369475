import React from 'react'
import { Button as MuiButton, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        textTransform: 'capitalize !important',
        fontSize: '16px',
        color: theme?.palette?.common?.white,
        backgroundColor: props.color,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: props.color,
            '&::before': {
                borderRadius: 'inherit',
                content: '""',
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: '0.17',
                backgroundColor: theme?.palette?.common?.black,
                left: 0,
                top: 0,
            },
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.color,
            opacity: 0.7,
        },
        '&:focus': {
            boxShadow: 'none',
        },
        ...props.rootStylesProp,
    }),
    disabled: {
        color: `${theme?.palette?.common?.white} !important`,
    },
    label: {
        zIndex: 1,
    },
}))

const Button = (props) => {
    const {
        loading,
        color,
        disabled,
        onClick,
        children,
        rootStylesProp,
        ...rest
    } = props
    const classes = useStyles({ color, loading, rootStylesProp })

    const theme = useTheme()

    return (
        <MuiButton
            disabled={disabled}
            classes={classes}
            variant="contained"
            onClick={onClick}
            {...rest}
        >
            {loading ? (
                <Loader color={theme?.palette?.common?.white} />
            ) : (
                children
            )}
        </MuiButton>
    )
}
export default Button
