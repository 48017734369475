import { useEffect, useState } from 'react'
import { Box, Accordion } from '@mui/material'
import { Autocomplete, Card } from 'packages/eid-ui'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useDebounce, useIsSmallScreen, useSwrApiGet } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { pipe, uniqBy } from 'ramda'

const DEBOUNCE_INTERVAL = 300

const TCodeFilter = ({ resourceType }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const filter = currentResourceType.filters.find(
        (f) => f.name === 'TCodeFilter',
    )

    const [callRightsEndpoint, setCallRightsEndpoint] = useState(false)
    const [callAccountStoresEndpoint, setCallAccountStoresEndpoint] = useState(
        false,
    )
    const [selecetedAccountStore, setSelectedAccountStore] = useState('')
    const [selectedRight, setSelectedRight] = useState('')

    const [rightsOptions, setRightsOptions] = useState([])
    const [accountStoreOptions, setAccountStoreOptions] = useState([])

    const [state, dispatch] = useResourceTypeContext()
    const [searchKeyRights, setSearchKeyRights] = useState('')
    const [searchKeyStores, setSearchKeyStores] = useState('')
    const debouncedValueRights = useDebounce(searchKeyRights, DEBOUNCE_INTERVAL)
    const debouncedValueStores = useDebounce(searchKeyStores, DEBOUNCE_INTERVAL)

    const queryStringParts = []

    if (debouncedValueRights) {
        queryStringParts.push(`searchTerm=${debouncedValueRights}`)
    }

    const queryString = queryStringParts.join('&')
    const rightsUrl = '/api/globalRights/getGlobalRights'
    const urlWithQs = queryString ? `${rightsUrl}?${queryString}` : rightsUrl

    const { data: accountStores } = useSwrApiGet(
        () =>
            callAccountStoresEndpoint
                ? '/api/ApplicationRoles/targetSystemInfo?sortBy=accountStoreFriendlyName'
                : false,
        {
            dedupingInterval: 1800000,
        },
    )

    const { data: rights } = useSwrApiGet(
        () => (callRightsEndpoint ? urlWithQs : false),
        {
            dedupingInterval: 1800000,
        },
    )

    useEffect(() => {
        if (rights) {
            setRightsOptions(rights.data)
        }
    }, [rights])
    useEffect(() => {
        if (accountStores) {
            const accountStoresArray = accountStores.data?.map((s) => {
                return {
                    friendlyName: s.accountStoreFriendlyName,
                    id: s.accountStoreId,

                    ...s,
                }
            })

            if (Boolean(debouncedValueStores)) {
                const filteredItems = accountStoresArray.filter((item) =>
                    item['friendlyName']
                        .toLowerCase()
                        .includes(debouncedValueStores.toLowerCase()),
                )
                setAccountStoreOptions(filteredItems)
            } else {
                setAccountStoreOptions(accountStoresArray)
            }
        }
    }, [accountStores, debouncedValueStores])
    const getUniqueAccountStores = () => {
        return pipe(uniqBy((x) => x.accountStoreId))(accountStoreOptions)
    }
    const accountStore = state['accountStore'] ?? null
    const right = state[filter.contextProp] ?? null

    const content = () => (
        <>
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <Autocomplete
                        label={t('Common_TCodes')}
                        placeholder={t('Common_TCodes')}
                        options={rightsOptions}
                        loading={callRightsEndpoint && !rights}
                        loadingText=""
                        noOptionsText=""
                        multiple={false}
                        onInputChange={(_, value) => setSearchKeyRights(value)}
                        onChange={(_, selectedItem) => {
                            setSelectedRight(selectedItem)
                            setSearchKeyStores('')
                            dispatch({
                                type: 'SET_MULTIPLE_PROPS',
                                payload: {
                                    [filter.contextProp]: selectedItem,
                                },
                            })
                            if (!selectedItem) {
                                setSelectedAccountStore(null)
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'accountStore',
                                        value: null,
                                    },
                                })
                            }
                        }}
                        value={right}
                        getOptionLabel={(option) => option.friendlyName}
                        onOpen={() => {
                            setCallRightsEndpoint(true)
                        }}
                        onClose={() => {
                            setCallRightsEndpoint(false)
                        }}
                        filterOptions={undefined}
                        popperStyleProps={{ zIndex: 1000 }}
                    />
                </Box>
                {right && (
                    <Box padding="20px 20px 0px 20px">
                        <Autocomplete
                            label={
                                t('ApplicationRoles_SelectAccountStore') + '*'
                            }
                            placeholder={t(
                                'ApplicationRoles_SelectAccountStore',
                            )}
                            options={getUniqueAccountStores()}
                            loading={
                                callAccountStoresEndpoint && !accountStores
                            }
                            loadingText=""
                            noOptionsText=""
                            multiple={false}
                            onInputChange={(_, value) =>
                                setSearchKeyStores(value)
                            }
                            onChange={(_, selectedItem) => {
                                setSelectedAccountStore(selectedItem)
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'accountStore',
                                        value: selectedItem
                                            ? {
                                                  friendlyName:
                                                      selectedItem?.accountStoreFriendlyName,
                                                  id:
                                                      selectedItem?.accountStoreId,
                                              }
                                            : null,
                                    },
                                })
                            }}
                            value={accountStore}
                            getOptionLabel={(option) => option.friendlyName}
                            onOpen={() => {
                                setCallAccountStoresEndpoint(true)
                            }}
                            onClose={() => {
                                setCallAccountStoresEndpoint(false)
                            }}
                            filterOptions={undefined}
                            popperStyleProps={{ zIndex: 1000 }}
                            inputWrapperStyles={{
                                borderColor:
                                    !selecetedAccountStore &&
                                    !accountStore &&
                                    'red',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    )

    return isSmallScreen ? (
        <Accordion expanded={false} title={t('Common_TCodes')}>
            <Box paddingBottom="20px">{content()}</Box>
        </Accordion>
    ) : (
        <Card
            cardTitle={t('Common_TCodes')}
            colorCode={filter.colorCode.primary}
            collapsible
            expanded={false}
        >
            {content()}
        </Card>
    )
}

export default TCodeFilter
