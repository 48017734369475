import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const EndDateManageAccess = ({ attribute, data }) => {
    const { t } = useTranslation()
    const timeConstraintActive =
        data.assignment?.assignmentDetails?.timeConstraintActive
    return (
        <>
            {!timeConstraintActive
                ? t('Common_Never').toUpperCase()
                : moment
                      .utc(data?.assignment?.assignmentDetails.endDateUtc)
                      .clone()
                      .local()
                      .format('LLL')}
        </>
    )
}

export default EndDateManageAccess
