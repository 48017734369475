import React from 'react'
import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useApplicationRole } from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ApplicationRoleDetails from './ApplicationRoleDetails'
import { useTargetPerson } from 'hooks'

const ApplicationRoleDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()

    const { data } = useApplicationRole(id, targetPerson.id)
    const applicationRole = data?.resource
    const assignmentStatus = data?.assignment

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={applicationRole?.friendlyName} />
            {!applicationRole && <Loader />}
            {applicationRole && (
                <ApplicationRoleDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    applicationRole={applicationRole}
                    assignmentStatus={assignmentStatus}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={applicationRole?.friendlyName} />}
        >
            {!applicationRole && <Loader />}
            {applicationRole && (
                <ApplicationRoleDetails
                    toggleDrawer={toggleDrawer}
                    applicationRole={applicationRole}
                    assignmentStatus={assignmentStatus}
                />
            )}
        </Drawer>
    )
}

export default ApplicationRoleDrawer
