import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useApplicationAppRight, useTargetPerson } from 'hooks'
import AppRightDetails from './AppRightsDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const AppRightsDrawer = ({ id, attributes, toggleDrawer, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data: appRight } = useApplicationAppRight(id, targetPerson.id)

    let item
    if (appRight) {
        item = {
            resource: {
                ...appRight,
                description:
                    appRight?.description || appRight.assignmentPointName,
                requestPolicyId: appRight.accessRequestPolicyId,
            },
            assignment: {
                personId: targetPerson.id,
                isAssigned: appRight.isAssigned,
                canActivateNow: appRight.canActivateNow,
                isRenewable: appRight.isRenewable,
                resourceAssignment: {
                    id: appRight.applicationId,
                    friendlyName: appRight.assignmentPointName,
                },
                assignmentDetails: {
                    isRevokable: appRight.isRevocable,
                    timeConstraintActive: appRight.timeConstraintActive,
                    startDateUtc: appRight.startDateUtc,
                    endDateUtc: appRight.endDateUtc,
                },
            },
        }
    }

    const itemDetails = <AppRightDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default AppRightsDrawer
