import { useEffect, useState } from 'react'
import appConfig from 'config'
import './guacamole-player.min.css'
import { useComputerLiveSessionToken } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Loader } from 'packages/eid-ui'
import { useTheme } from '@mui/material'

const GuacamolePlayer = ({ session, url, header }) => {
    const { t } = useTranslation()
    const [apiCall, setApiCall] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const theme = useTheme()

    var pixel_density = window.devicePixelRatio || 1
    var optimal_dpi = pixel_density * 96
    var optimal_width = window.innerWidth * pixel_density
    var optimal_height = window.innerHeight * pixel_density

    const { data: tokenString } = useComputerLiveSessionToken(
        session.id,
        optimal_width,
        optimal_height,
        optimal_dpi,
        apiCall,
    )
    const isLive = session.canTerminateSession

    const guacamolePlaybackScript = document.createElement('script')
    guacamolePlaybackScript.src = `${appConfig.APP_SUBPATH}/Guacamole/guacamole-playback.js`
    guacamolePlaybackScript.async = false

    const guacamoleMainScript = document.createElement('script')
    guacamoleMainScript.src = `${appConfig.APP_SUBPATH}/Guacamole/guacamole.js`
    guacamoleMainScript.async = false

    const jQueryScript = document.createElement('script')
    jQueryScript.src = `${appConfig.APP_SUBPATH}/Guacamole/jquery-3.6.0.min.js`
    jQueryScript.async = false

    const guacamoleSnoopScript = document.createElement('script')
    guacamoleSnoopScript.src = `${appConfig.APP_SUBPATH}/Guacamole/guacamole-snoop.js`
    guacamoleSnoopScript.async = false

    function loadScript(script) {
        return new Promise(function (resolve, reject) {
            script.onload = function () {
                resolve(script)
            }
            script.onerror = function () {
                reject(script)
            }
            document.body.appendChild(script)
        })
    }

    function unloadScript(script) {
        document.body.removeChild(script)
    }

    let scripts = [guacamoleMainScript, guacamolePlaybackScript, jQueryScript]

    if (isLive) {
        scripts.push(guacamoleSnoopScript)
    }

    useEffect(() => {
        let promises = []
        scripts.forEach((script) => {
            promises.push(loadScript(script))
        })

        Promise.all(promises)
            .then(function () {
                window.get(url, header)
            })
            .catch(function (script) {
                console.log(script + ' failed to load')
            })

        return () => {
            scripts.forEach((script) => {
                unloadScript(script)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openLiveSession = () => {
        var win = window.open('')
        var scriptHeader =
            '<head>' +
            guacamoleMainScript.outerHTML +
            '\n' +
            jQueryScript.outerHTML +
            '\n' +
            guacamoleSnoopScript.outerHTML +
            `<link rel="stylesheet" href="${appConfig.APP_SUBPATH}/Guacamole/stylesheets/style.css">` +
            `<link rel="stylesheet" href="${appConfig.APP_SUBPATH}/Guacamole/stylesheets/error.css">` +
            '<title>Monitoring - ' +
            session.computerFriendlyName +
            '</title></head>'
        win.document.write(scriptHeader)
        win.document.write(
            "<body><p id='token' style='opacity: 0'>" +
                tokenString +
                "</p><div id='display'></div><p id='GatewayServerName' style='opacity: 0'>" +
                session.gatewayServerName +
                '</p></body>',
        )
    }

    useEffect(() => {
        if (apiCall && tokenString) {
            openLiveSession()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenString])

    const handleConnect = () => {
        setApiCall(true)
        if (tokenString) {
            openLiveSession()
        }
    }

    //Full Screen Logic starts here
    useEffect(() => {
        document.addEventListener('fullscreenchange', fullScreenCallBack)
        document.addEventListener('mozfullscreenchange', fullScreenCallBack)
        document.addEventListener('webkitfullscreenchange', fullScreenCallBack)
        return () => {
            document.removeEventListener('fullscreenchange', fullScreenCallBack)
            document.removeEventListener(
                'mozfullscreenchange',
                fullScreenCallBack,
            )
            document.removeEventListener(
                'webkitfullscreenchange',
                fullScreenCallBack,
            )
        }
    }, [])

    const fullScreenCallBack = () => {
        if (
            document.fullscreenElement /* Standard syntax */ ||
            document.webkitFullscreenElement /* Safari and Opera syntax */ ||
            document.msFullscreenElement /* IE11 syntax */
        ) {
            setIsFullScreen(true)
        } else setIsFullScreen(false)
    }

    const toggleFullScreen = () => {
        var elem = document.getElementById('player')
        if (isFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen()
            }
            setIsFullScreen(false)
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen()
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen()
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen()
            }
            setIsFullScreen(true)
        }
    }

    //Full Screen Logic ends here

    const LivePlayer = (
        <div
            id="session-active"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.25098039215686274)',
                padding: '20px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '4%',
            }}
        >
            <div id="card">
                <h2 style={{ textAlign: 'center' }}>
                    {t('Computers_LiveSession')}
                </h2>
                <p>{t('Computers_LiveSessionMessage')}</p>
                <br />
                <button
                    id="connect"
                    style={{
                        backgroundColor: theme?.palette?.primary?.main,
                        color: theme?.palette?.common?.white,
                        borderRadius: '5px',
                        border: 'none',
                        width: '100%',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleConnect()}
                >
                    {t('Connect')}
                </button>
            </div>
        </div>
    )

    return (
        <>
            {!isLive && (
                <>
                    <Loader id="loader" />
                    <div id="player">
                        <div id="display">
                            <div className="notification-container">
                                <div className="seek-notification">
                                    <p>
                                        {t('Computers_SeekInProgress')}
                                        <button id="cancel-seek">
                                            {t('Common_Cancel')}
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div
                                stlye={{
                                    position: 'relative',
                                    width: '1536px',
                                    height: '0px',
                                }}
                            >
                                <div
                                    id="session"
                                    style={{
                                        position: 'relative',
                                        width: '1536px',
                                        height: '518px',
                                        transformOrigin: '0px 0px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '1536px',
                                            height: '518px',
                                            position: 'absolute',
                                            left: '0px',
                                            top: '0px',
                                            overflow: 'hidden',
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div className="controls">
                            <button id="play-pause">{t('Play')}</button>
                            <img
                                id="replay"
                                style={{ height: '20px', cursor: 'pointer' }}
                                src={
                                    appConfig.BASE_EID_URL +
                                    '/webcdn/images/icons/32x32/reload.png'
                                }
                                alt="replay icon"
                            />
                            <input
                                id="position-slider"
                                type="range"
                                max="2922342"
                            />
                            <span id="position">00:00</span>
                            <span>/</span>
                            <span id="duration">00:00</span>
                            <Icon
                                id="fullscreen"
                                name={
                                    isFullScreen
                                        ? 'FullScreenExit'
                                        : 'FullScreen'
                                }
                                onClick={toggleFullScreen}
                            />
                        </div>
                    </div>
                    <div id="errorMessageBox" className="errorMessageBox">
                        {t('Computers_RecordingUnavailable')}
                    </div>
                </>
            )}
            {isLive && LivePlayer}
        </>
    )
}
export default GuacamolePlayer
