import React from 'react'
import Masonry from 'react-masonry-component'
import { Box, styled } from '@mui/material'
import { Loader } from 'packages/eid-ui'
import { mobileScreenWidth } from 'utils'
import { useApplicationSettings } from 'hooks'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import ItemCard from 'containers/ManageAccess/AccessItem'
const masonryOptions = {
    transitionDuration: 0,
    horizontalOrder: true,
}

const Container = styled(Box)({
    display: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const CardComponent = ({ item, attributes }) => {
    const { data: appConfig } = useApplicationSettings()
    const history = useHistory()
    const query = useQuery()

    return (
        <Container>
            <ItemCard
                key={item.key}
                cardStyles={{
                    margin: '14px',
                }}
                headerStyles={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                    padding: '20px',
                }}
                attributes={attributes}
                item={item}
                onItemClick={() => {
                    query.set('id', item.id)
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </Container>
    )
}

const CardView = ({
    data,
    isLoading,
    LoadingIndicatorComponent = Loader,
    isLoadingMore,
    noDataMessage,
    attributes,
    pagination,
    onClickDetailButton,
}) => {
    if (!data || isLoading)
        return <LoadingIndicatorComponent attributes={attributes} />
    if (data?.length === 0)
        return (
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                paddingY="20px"
            >
                {noDataMessage}
            </Box>
        )
    return (
        <>
            <Masonry options={masonryOptions} enableResizableChildren>
                {data.map((item, index) => (
                    <CardComponent
                        key={index}
                        item={item}
                        attributes={attributes}
                        onClickDetailButton={onClickDetailButton}
                    />
                ))}
            </Masonry>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px',
                }}
            >
                <Box>{pagination}</Box>
            </Box>
            {isLoadingMore && <Loader height="1in" width="100%" />}
        </>
    )
}

export default CardView
