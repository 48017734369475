import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemText,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Loader, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useCart,
    useCheckCredentialAvailableSlot,
    useTargetPerson,
} from 'hooks'
import { ItemDetails } from 'components'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { useAppState } from 'appContext'
import { MasterPasswordLock } from 'components'
import { PreApprpovedItems } from './PreApprovedItems'
import { CredentialCheckouts } from 'components/CredentialCheckouts'
import { AddToCart } from 'components/AddToCart'

const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const tooltipStyles = {
    top: '5px',
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: '208px',
        overflow: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '4px',
            '-webkit-border-radius': '3px',
            borderRadius: '3px',
            backgroundColor: '#f7f7f7',
        },

        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            borderRadius: '5px',
            border: '2px solid transparent',
            height: '20px',
            backgroundColor: '#d8d8d8',
        },
        backgroundColor: theme?.palette?.background?.paper,
        '& .MuiListItem-root': {
            cursor: 'pointer',
            width: '100%',
            height: '40px',
            wordBreak: 'break-word',
            paddingTop: '10px',
            fontSize: '14px',
            color: '#767676',
            '&.Mui-selected': {
                color: theme?.palette?.common?.black,
                backgroundColor: 'transparent',
            },
        },
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: `${theme?.palette?.common?.black} !important`,
        overflow: 'hidden',
        fontSize: '14px',
        fontWeight: 'bold',
        '& svg': {
            margin: '0px !important',
        },
    },
    taskbadge: {
        position: 'relative',
        top: '12px',
        right: '16px',
        background: theme?.palette?.quaternary?.main,
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        color: theme?.palette?.common?.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
}))

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const CredentialDetails = ({ credential, toggleDrawer }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [checkAvailability, { isLoading: isCheckingAvailability }] =
        useCheckCredentialAvailableSlot(credential?.id)

    const [activeTab, setActiveTab] = useState('overview')

    const [{ masterPassword }] = useAppState()

    const ONE_TIME_ACCESS = t('CredentialOneTimeAccess')
    const PRE_APPROVED_ACCESS = t('CredentialPreApprovedAccess')
    const ONE_TIME_ACCESS_DESCRIPTION = t('AllowsYouToRequestOneTime')
    const PRE_APPROVED_ACCESS_DESCRIPTION = t('AllowsYouToRequestPreApproved')

    const allAccessTypes = [
        {
            id: 1,
            friendlyName: ONE_TIME_ACCESS,
            fullPath: ONE_TIME_ACCESS_DESCRIPTION,
            isPreApproved: false,
        },
        {
            id: 2,
            friendlyName: PRE_APPROVED_ACCESS,
            fullPath: PRE_APPROVED_ACCESS_DESCRIPTION,
            isPreApproved: true,
        },
    ]

    const [accessType, setAccessType] = useState(allAccessTypes[0])

    const registry = useRegistry()

    const credentialType = registry.get(ResourceTypesNamespace, 'Credentials')

    const getPreValidationMessage = () => {
        if (!Boolean(accessType)) {
            return t('Credential_SelectAccessType')
        }
        return ''
    }
    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.sharedCredentialToCartItem({
            targetPerson,
            assignmentType: 'Add',
            credential,
            timeConstrained:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? baseObj?.timeConstrained
                    : false,
            isPreApproved: accessType?.isPreApproved,
            startDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? baseObj?.startDateUtc
                    : null,
            endDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? baseObj?.endDateUtc
                    : null,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const itemAlreadyInCart = credentialType.alreadyInCart(
        cart.cartItems,
        credential,
        accessType,
    )

    const accessTypeSelectorSection = (
        <PromptContainer>
            <Box width="172px" display="flex" flexDirection="column" zIndex={1}>
                <Box className={classes.sectionHeader}>
                    {t('Common_SelectAccessType')}
                </Box>
                <Icon
                    style={{ marginTop: '20px' }}
                    name="MonitorClock"
                    height="150px"
                    width="150px"
                    color="#D2D2D9"
                />
            </Box>
            <Box width="40%" paddingLeft="16px">
                <Tooltip
                    title={
                        itemAlreadyInCart ? t('Common_ItemAlreadyInCart') : ''
                    }
                >
                    <List classes={classes}>
                        {allAccessTypes.map((i, index) => (
                            <ListItem
                                selected={i.id === accessType?.id}
                                key={`${i.id}${index}`}
                                onClick={() => {
                                    !itemAlreadyInCart && setAccessType(i)
                                }}
                                disabled={itemAlreadyInCart}
                            >
                                <StyledRadioIcon
                                    icon={<Icon name="Radio" />}
                                    checkedIcon={
                                        <Icon
                                            name="RadioFilled"
                                            color={
                                                theme?.palette?.primary?.main
                                            }
                                        />
                                    }
                                    checked={i.id === accessType?.id}
                                />
                                <ListItemText
                                    disableTypography
                                    primary={i.friendlyName}
                                />
                                {
                                    <Tooltip
                                        tooltipStyles={tooltipStyles}
                                        placement="top"
                                        arrow={true}
                                        title={i.fullPath || ''}
                                    >
                                        <Box>
                                            <Icon name="InfoNew" />
                                        </Box>
                                    </Tooltip>
                                }
                            </ListItem>
                        ))}
                    </List>
                </Tooltip>
            </Box>
        </PromptContainer>
    )

    if (!cart) return <Loader />

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="checkouts"
                    label={t('Checkouts')}
                />
                <span className={classes.taskbadge}>
                    {credential?.checkOutCount ?? 0}
                </span>
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection credential={credential} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection credential={credential} />
            )}

            {activeTab === 'checkouts' && (
                <CredentialCheckouts item={credential} />
            )}

            {credential.isPreApproved ? (
                <PreApprpovedItems
                    item={credential}
                    handleViewRequest={() => setActiveTab('checkouts')}
                />
            ) : (
                accessTypeSelectorSection
            )}

            <div style={{ position: 'relative' }}>
                {!masterPassword && (
                    <MasterPasswordLock
                        style={{ height: '100%', width: '100%' }}
                    />
                )}
                <Box display="flex" flexDirection="row" flexWrap={'wrap'}>
                    <AddToCart
                        preAdd={preAdd}
                        resourceType={credentialType}
                        resource={credential}
                        postAdd={toggleDrawer}
                        preValidate={
                            !masterPassword ? '' : getPreValidationMessage
                        }
                        secondary={accessType}
                        showTimeConstraint={
                            masterPassword &&
                            accessType.friendlyName === ONE_TIME_ACCESS
                        }
                        hideSwitch={true}
                        useAccessLevelPolicy={false}
                        fetchAdditionalProperties={false}
                        checkAvailabilityFirst={
                            accessType.friendlyName === ONE_TIME_ACCESS &&
                            credential?.allowMultiCheckOut === false
                        }
                        checkAvailabilityFunction={checkAvailability}
                        isCheckingAvailability={isCheckingAvailability}
                    />
                </Box>
            </div>
        </Fragment>
    )
}

export default CredentialDetails
