import { usePaginatedQuery, useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'

const AZURE_ROLES_KEY_PREFIX = 'AZURE_ROLES'

export const useAzureRole = (
    id: string,
    targetPersonId: string,
    isAssignmentId: boolean = false,
) => {
    const callApi = useAxios()

    let url = `api/azureRoles/${id}/${targetPersonId}`

    if (isAssignmentId) {
        url = url + `?isAssignmentId=true`
    }

    return useQuery(
        [AZURE_ROLES_KEY_PREFIX, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useCheckAzureRbacRoleAccess = (id: string, scopeId: string) => {
    const callApi = useAxios()
    return useQuery(
        [`${AZURE_ROLES_KEY_PREFIX}_ACCESS`, id, scopeId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/azureRoles/checkAssigmentStatusActivateNow/${id}/${scopeId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(scopeId),
        },
    )
}
export const useCheckAzureAdminRoleAccess = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [`${AZURE_ROLES_KEY_PREFIX}_ACCESS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/azureRoles/checkAssigmentStatusActivateNow/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckAzureRoleAccessByAssignmentId = (
    assignmentId: string,
    targetPersonId: string,
) => {
    const callApi = useAxios()
    return useQuery(
        [`${AZURE_ROLES_KEY_PREFIX}_ACCESS`, assignmentId, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/azureRoles/checkAssigmentStatusByAssignment/${assignmentId}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(assignmentId) && Boolean(targetPersonId),
        },
    )
}

export const useAzureRoleGlobalSensitiveFunctions = (
    id: string,
    skip: number,
    take = 10,
    locationId?: string,
    search?: string,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${AZURE_ROLES_KEY_PREFIX}_GLOBAL_SENSITIVE_FUNCTIONS`,
            id,
            skip,
            take,
            search,
            locationId,
        ],
        () => {
            const queryParams = [`skip=${skip}`, `take=${take}`]
            if (locationId) {
                queryParams.push(`locationId=${locationId}`)
            }
            if (search) {
                queryParams.push(`searchTerm=${search}`)
            }

            return callApi({
                method: 'GET',
                url: `/api/businessFunctions/globalFunctions/${id}?${queryParams.join(
                    '&',
                )}`,
            })
        },
        {
            enabled: Boolean(id),
        },
    )
}

export const useAzureRoleTypes = (enabled: boolean) => {
    const callApi = useAxios()
    return useQuery(
        [AZURE_ROLES_KEY_PREFIX, 'ROLE_TYPES'],
        () =>
            callApi({
                method: 'GET',
                url: 'api/azureRoles/roleGroupTypes',
            }).then((data) => data.data),
        {
            enabled,
        },
    )
}

export const useAzureRoleBr = (
    targetPersonId: string,
    resourceId: string,
    resourceTypeId: string,
    locationId?: string,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            AZURE_ROLES_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            locationId,
        ],
        () => {
            const queryParams = [
                `targetPersonId=${targetPersonId}`,
                `resourceId=${resourceId}`,
                `resourceTypeId=${resourceTypeId}`,
            ]
            if (locationId) {
                queryParams.push(`locationId=${locationId}`)
            }
            return callApi({
                method: 'GET',
                url: `/api/azureRoles/businessRequestItemAssignmentDetails?${queryParams.join(
                    '&',
                )}`,
            }).then((data) => data.data)
        },
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId),
        },
    )
}
export const useLocalFunctionsByAzureRole = (
    azureRoleId: any,
    queryData: any,
    enabled: any,
) => {
    const callApi = useAxios()
    const endpoint = `api/BusinessFunctions/localFunctionsByAzureRole`
    return useApiWithInfiniteScroll(
        [azureRoleId, enabled, 'LOCAL_FUNCTIONS_BY_AZURE_ROLE', queryData],

        (skip: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    azureRoleId,
                    skip,
                    take: 15,
                    ...queryData,
                },
            })
        },
        undefined,
        15,
    )
}
