import { Box, Grid, Card, Link } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'appContext'
import { PasswordModal } from 'components/PasswordModal'
import { useAllApplications } from 'hooks'
import { getURLTarget, useUrlGenerator } from 'packages/core'

const useCardStyles = makeStyles((theme) => ({
    root: () => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
        border: '1px solid #00000036 !important',
        borderRadius: '8px !important',
        overflowWrap: 'break-word',
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
}))

const useStyles = makeStyles((theme) => ({
    requestmaincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.11)',
        background: theme?.palette?.common?.white,
        marginTop: '16px',
        padding: '0px 16px',
        minWidth: '200px',
    },
    requestitemtitle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#9b9b9b',
        height: '16px',
        marginBottom: '1px',
        textTransform: 'uppercase',
    },
    requestitemheading: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme?.palette?.primary?.main,
        margin: '0px',
    },
    requestitemtime: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme?.palette?.common?.black,
        margin: '0px',
    },
}))

export interface CredentialCheckoutTileProps {
    item: any
    parent: any
}

export const CredentialCheckoutTile: FC<CredentialCheckoutTileProps> = (
    props,
) => {
    const { item } = props
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState('')
    const classes = useStyles()
    const cardClasses = useCardStyles()
    const { t } = useTranslation()
    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const myTasksBusinessRequestUrl = getMyTasksUrl(
        `/myRequests/businessRequests?id=${item.businessRequestId}`,
    )
    const myTasksBusinessRequestItemUrl = getMyTasksUrl(
        `/myRequests/businessRequestItems?id=${item.businessRequestItemId}`,
    )

    return (
        <>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}

            <Grid item md={4}>
                <Card classes={cardClasses}>
                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_BusinessRequest')}
                    </Box>
                    <Box component="span">
                        {item.businessRequestName ? (
                            <Link
                                className={classes.requestitemheading}
                                href={myTasksBusinessRequestUrl}
                                target={getURLTarget()}
                            >
                                {item.businessRequestName}
                            </Link>
                        ) : (
                            '-'
                        )}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_BusinessRequestItem')}
                    </Box>
                    <Box component="span">
                        {item.businessRequestItemName ? (
                            <Link
                                className={classes.requestitemheading}
                                href={myTasksBusinessRequestItemUrl}
                                target={getURLTarget()}
                            >
                                {item.businessRequestItemName}
                            </Link>
                        ) : (
                            '-'
                        )}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_RequestedBy')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.requestorFriendlyName
                            ? item.requestorFriendlyName
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_StartTime')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.startTimeUtc
                            ? moment
                                  .utc(item.startTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_EndTime')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.endTimeUtc
                            ? moment.utc(item.endTimeUtc).local().format('L LT')
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_ActualCheckOutTime')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.actualCheckedOutTimeUtc
                            ? item.actualCheckedOutTimeUtc
                            : '-'}
                    </Box>
                </Card>
                <div className={cardClasses.lineGlow}></div>
            </Grid>
        </>
    )
}

export default CredentialCheckoutTile
