import React, { Fragment } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Box,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import RequestAccessButton from './RequestAccessButton'
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import TextFormatter from './TextFormatter'
import { useTranslation } from 'react-i18next'
import DynamicComponents from 'components/DynamicComponents'
import { ResourceListingInfoIcon } from 'components/DynamicComponents/ResourceListingInfoIcon'
import ActivateNowButton from './ActivateNowButton'
import { defaultDataProtectedSubcomponent } from 'utils'

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

var getValue = (ci, a) => {
    // QUICK Fix not to show unknown in computers
    if (a.name === 'infrastructureName' && ci[a.name] === 'Unknown') {
        return '-'
    }
    return ci[a.name] ?? ''
}

const useStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: theme?.palette?.background?.paper,
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '250px',
        width: 'auto',
        overflow: 'hidden',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    },
    tableHeaderCell: {
        maxWidth: '200px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${theme?.palette?.primary?.main} !important`,

            '& svg': {
                color: `${theme?.palette?.primary?.main} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${theme?.palette?.primary?.main} !important`,
        },
    },
    detailsBox: {
        '& button': {
            borderRadius: '4px',
        },
    },
}))

const renderComponent = (attribute, data) => {
    const DynamicComponentToRender = DynamicComponents[attribute.component.name]

    return <DynamicComponentToRender attribute={attribute} data={data} />
}

const TableView = ({
    fallbackMessage,
    data,
    attributes,
    onItemClick,
    loading,
    onSort,
    sortBy,
    sortOrder,
    currentResourceType,
}) => {
    const { t } = useTranslation()
    const toolTipValue = (items) => {
        const newItems = []
        items.map((item) => newItems.push(item.friendlyName))
        return newItems.join(`, `)
    }

    const filteredAttributes = attributes.filter(
        (a) => !a.manageAccessOnlyAttribute,
    )
    const headings = filteredAttributes.map((a) => ({
        ...a,
        label: t(a.label),
        sort: a.sortable ? a.sortable : false,
    }))

    const classes = useStyles({})
    const theme = useTheme()

    const handleSort = (data) => {
        const sortingProperty =
            data.columnName && data.columnName.length > 0
                ? data.columnName
                : data.name

        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    return (
        <>
            <Table id="identities_list_table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => {
                            const sortingProperty =
                                heading.columnName &&
                                heading.columnName.length > 0
                                    ? heading.columnName
                                    : heading.name

                            return (
                                <TableCell
                                    key={'pd-head-cell-' + index}
                                    className={clsx(
                                        classes.tableCell,
                                        classes.headGrey,
                                        classes.tableHeaderCell,
                                    )}
                                    align={heading.align}
                                    style={heading.headerCellStyles}
                                    data-protectedsubcomponent={
                                        heading?.requireAccess?.control ||
                                        defaultDataProtectedSubcomponent
                                    }
                                >
                                    {!heading.sort ? (
                                        <span style={{ display: 'flex' }}>
                                            {heading.label}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex' }}>
                                            <Tooltip
                                                title={
                                                    sortBy ===
                                                        sortingProperty &&
                                                    sortOrder === 'desc'
                                                        ? t(
                                                              'Common_SortAscending',
                                                          )
                                                        : t(
                                                              'Common_SortDescending',
                                                          )
                                                }
                                            >
                                                <TableSortLabel
                                                    active={heading.sort}
                                                    direction={
                                                        sortBy ===
                                                        sortingProperty
                                                            ? sortOrder
                                                            : 'asc'
                                                    }
                                                    className={
                                                        sortBy !==
                                                        (heading.columnName &&
                                                        heading.columnName
                                                            .length > 0
                                                            ? heading.columnName
                                                            : heading.name)
                                                            ? classes.tableSortLabel
                                                            : clsx(
                                                                  classes.tableSortLabel,
                                                                  classes.activeTableSortLabel,
                                                              )
                                                    }
                                                    style={{
                                                        color:
                                                            sortBy ===
                                                                sortingProperty &&
                                                            'yellow !important',
                                                    }}
                                                    onClick={() =>
                                                        handleSort(heading)
                                                    }
                                                >
                                                    {heading.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </span>
                                    )}
                                </TableCell>
                            )
                        })}
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    colSpan={headings.length + 1}
                                    style={{ textAlign: 'center' }}
                                >
                                    {fallbackMessage}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {data.map((item) => {
                                    const showActivateNow =
                                        item.canActivateNow &&
                                        currentResourceType.name !== 'Computers'
                                    return (
                                        <TableRow
                                            key={'pd-body-row' + item.id}
                                            className={classes.tableRow}
                                        >
                                            {filteredAttributes.map((a) => {
                                                let wrappedCellContent

                                                if (a.component) {
                                                    wrappedCellContent =
                                                        renderComponent(a, item)
                                                } else if (a.type !== 'array') {
                                                    const cellContent = (
                                                        <Box
                                                            maxHeight="200px"
                                                            display="flex"
                                                            overflow="auto"
                                                            minHeight="50px"
                                                            style={{
                                                                display: 'flex',
                                                                wordBreak:
                                                                    'break-word',
                                                                wordWrap:
                                                                    'break-word',
                                                                alignItems:
                                                                    'center',
                                                                ...a.style,
                                                            }}
                                                        >
                                                            <div>
                                                                {getValue(
                                                                    item,
                                                                    a,
                                                                ).length >
                                                                maxCharacters
                                                                    ? `${getValue(
                                                                          item,
                                                                          a,
                                                                      ).substring(
                                                                          0,
                                                                          maxCharacters,
                                                                      )}...`
                                                                    : getValue(
                                                                          item,
                                                                          a,
                                                                      )}{' '}
                                                            </div>
                                                        </Box>
                                                    )
                                                    wrappedCellContent =
                                                        getValue(item, a)
                                                            .length >
                                                        maxCharacters ? (
                                                            <Tooltip
                                                                title={getValue(
                                                                    item,
                                                                    a,
                                                                )}
                                                                color="#5D6870"
                                                                fontColor={
                                                                    theme
                                                                        .palette
                                                                        .common
                                                                        .white
                                                                }
                                                                enterDelay={500}
                                                                enterNextDelay={
                                                                    500
                                                                }
                                                                disableInteractive={
                                                                    false
                                                                }
                                                                tooltipStyles={
                                                                    tooltipStyles
                                                                }
                                                            >
                                                                {cellContent}
                                                            </Tooltip>
                                                        ) : (
                                                            cellContent
                                                        )
                                                } else {
                                                    wrappedCellContent = (
                                                        <TextFormatter
                                                            style={{
                                                                textDecoration:
                                                                    'underline',
                                                            }}
                                                            value={toolTipValue(
                                                                getValue(
                                                                    item,
                                                                    a,
                                                                ),
                                                            )}
                                                        />
                                                    )
                                                }
                                                return (
                                                    <TableCell
                                                        key={`${item.id}${a.name}`}
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {a.moreInfoIcon ? (
                                                            <Box display="flex">
                                                                {
                                                                    wrappedCellContent
                                                                }
                                                                <ResourceListingInfoIcon
                                                                    attribute={
                                                                        a
                                                                    }
                                                                    data={item}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            wrappedCellContent
                                                        )}
                                                    </TableCell>
                                                )
                                            })}
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-end"
                                                    width={'100%'}
                                                    className={
                                                        classes.detailsBox
                                                    }
                                                >
                                                    {showActivateNow ? (
                                                        <>
                                                            <RequestAccessButton
                                                                onClick={() =>
                                                                    onItemClick(
                                                                        item,
                                                                    )
                                                                }
                                                                label={t(
                                                                    'Common_Details',
                                                                )}
                                                            />

                                                            <ActivateNowButton
                                                                resource={item}
                                                                resourceType={
                                                                    currentResourceType
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <RequestAccessButton
                                                            onClick={() =>
                                                                onItemClick(
                                                                    item,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

export default TableView
