import { useTranslation } from 'react-i18next'
import LockedPasswordBox from './LockedPasswordBox'

const CredentialsLockedAttribute = ({ attribute, data }) => {
    const { t } = useTranslation()
    const item = data?.resource ? data?.resource : data
    const validationMessage =
        item.isExpired || !(item.canCheckOut || item.isCheckedOutBySomeoneElse)
            ? ''
            : attribute.component.validationMessage
    return (
        <LockedPasswordBox
            targetUrl={`${attribute.component.targetUrl}/${item.id}`}
            isClickable={attribute.component?.isClickable || item.canCheckIn}
            validationMessage={t(validationMessage)}
            targetProperty={attribute.component.targetProperty}
        />
    )
}

export default CredentialsLockedAttribute
