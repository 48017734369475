import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ForbiddenPage } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const ns = 'public'
const useStyles = makeStyles(() => ({
    noAccessInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: '9vw',
        },
    },
}))

const NoAccessInfo = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <Box className={classes.noAccessInfo}>
            <ForbiddenPage
                description={t(`${ns}:MsCommonAnonymous_ForbiddenPageMessage`)}
                linkText={t(`${ns}:MsCommonAnonymous_HomePage`)}
                errorCode={'403'}
                showLink={true}
            />
        </Box>
    )
}

export default NoAccessInfo
