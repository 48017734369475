import { Fragment, useState } from 'react'
import { ItemDetails } from 'components'
import { Box } from '@mui/material'
import { useCheckAzureAdminRoleAccess, useTargetPerson } from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import { Skeleton } from '@mui/material';
import { ResourceTypesNamespace, useRegistry } from 'core'
import { AddToCart } from 'components/AddToCart'
import { OverviewSection } from './OverviewSection'
import useConfiguration from 'useConfiguration'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import AdditionalInformationSection from './MoreInformation'

const AzureAdminRoleDetails = ({ azureAdminRole, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType('AzureRoles')
    const azureAdminRoleType = registry.get(
        ResourceTypesNamespace,
        'AzureAdminRoles',
    )

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()

    const {
        data: assignmentStatus,
        isLoading: isCheckingAccess,
    } = useCheckAzureAdminRoleAccess(azureAdminRole.id)

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.azAdminRoleToCartItem({
            targetPerson,
            assignmentType: 'Add',
            azureAdminRole,
            assignmentStatus,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const getPreValidationMessage = () => {
        if (assignmentStatus && assignmentStatus.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection azureAdminRole={azureAdminRole} />
            )}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection rbacRole={azureAdminRole} />
            )}

            {isCheckingAccess ? (
                <Box padding={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : assignmentStatus?.canActivateNow ? (
                <ActivateNowButton
                    resource={azureAdminRole}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                />
            ) : (
                <AddToCart
                    resourceType={azureAdminRoleType}
                    resource={azureAdminRole}
                    preAdd={preAdd}
                    postAdd={toggleDrawer}
                    preValidate={getPreValidationMessage}
                />
            )}
        </Fragment>
    )
}

export default AzureAdminRoleDetails
