import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import { Divider } from 'packages/eid-ui'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ computer, policy }: any) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const parsedHtml = ReactHtmlParser(
        DomPurify.sanitize(computer?.instructions),
    )

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computer?.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computer?.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={computer?.computerTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={computer?.accountStoreFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computer?.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={
                        computer?.isPsmEnabled ? (
                            <Icon name="CheckedRound" />
                        ) : (
                            <Icon name="UncheckedRound" />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={
                        computer?.isJITEnabled ? (
                            <Icon name="CheckedRound" />
                        ) : (
                            <Icon name="UncheckedRound" />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {policy && (
                    <>
                        <Divider />
                        <AccessRequestPolicyDetails
                            policy={policy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}
                {parsedHtml && parsedHtml.length > 0 && (
                    <InfoBoxWithIcon
                        iconName={'InfoNew'}
                        message={parsedHtml}
                        rootStyles={{
                            width: '100%',
                            marginTop: '2.8rem',
                            marginBottom: '1.8rem',
                        }}
                    />
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
