import { FC } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material'
import { useAzureApplicationOwnersAndDeputies } from 'hooks'
import { Skeleton } from '@mui/material';
import { ApplicationOwnerDeputies } from './ApplicationsOwnerDeputies'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

export type ApplicationAdditionalInformationProps = {
    className?: string
    buttonPosition?: 'right' | 'bottom'
    data: any
    applicationId: string
    isAzureApplication: boolean
}
const smallScreenWidth = 960

const hasAccessToOwnerControl = 'ITShop-Applications-Details-Owners-Control'
const hasAccessToADeputiesControl =
    'ITShop-Applications-Details-Deputies-Control'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',
            boxShadow: 'none !important',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        paddingdiv: {
            padding: '0 20px',
            width: '100%',
        },
        heading: {},
        result: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }),
)

export const ApplicationAdditionalInformation: FC<ApplicationAdditionalInformationProps> = (
    props,
) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const {
        data: ownersAndDeputies,
        isLoading,
    } = useAzureApplicationOwnersAndDeputies(
        hasAccessToControl(hasAccessToOwnerControl) ||
            hasAccessToControl(hasAccessToADeputiesControl)
            ? props.applicationId
            : null,
    )

    return (
        <Paper className={classes.root}>
            <Box className={classes.paddingdiv}>
                {isLoading ? (
                    <Box paddingRight={4}>
                        <Skeleton height={40} />
                    </Box>
                ) : (
                    <>
                        {hasAccessToControl(hasAccessToOwnerControl) && (
                            <Box
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ApplicationOwnerDeputies
                                    label={t('Common_Owners')}
                                    data={ownersAndDeputies.owners}
                                />
                            </Box>
                        )}
                        {props.isAzureApplication && (
                            <>
                                {hasAccessToControl(
                                    hasAccessToADeputiesControl,
                                ) && (
                                    <Box
                                        data-protectedsubcomponent={
                                            hasAccessToADeputiesControl
                                        }
                                    >
                                        <ApplicationOwnerDeputies
                                            label={t('Common_Deputies')}
                                            data={ownersAndDeputies.deputies}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    )
}
