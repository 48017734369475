export const preApprovedOnlyFilterColors = {
    primary: 'rgba(65,66,183,1)',
    secondary: 'rgba(65,66,183,0.1)',
}

export const suggestedOnlyFilterColors = {
    primary: 'rgba(241,86,40,1)',
    secondary: 'rgba(241,86,40,0.1)',
}

export const directAssignedOnlyFilterColors = {
    primary: 'rgba(10,172,0,1)',
    secondary: 'rgba(10,172,0,0.1)',
}

export const timeConstrainedOnlyFilterColors = {
    primary: 'rgba(35,73,144,1)',
    secondary: 'rgba(35,73,144,0.1)',
}

export const sharedWithCurrentUserOnlyFilterColors = {
    primary: 'rgba(107,0,123,1)',
    secondary: 'rgba(107,0,123,0.1)',
}

export const personalOnlyFilterColors = {
    primary: 'green',
    secondary: 'rgba(241,86,40,1)',
}

export const expringAccessOnlyFilterColors = {
    primary: 'green',
    secondary: 'rgba(241,86,40,1)',
}
