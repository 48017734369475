import {
    preApprovedOnlyFilterColors,
    directAssignedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forRequestAccess: false,
        forManageAccess: true,
        loginsession: false,
        membership: false,
        history: true,
        dynamic: true,
        name: 'Computers',
        title: 'Computer',
        requireAccess: {
            control: 'ITShop-ComputersComputer-Control',
        },
        order: 1,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        label: 'Computers_SelectComputer',
        placeholder: 'Computers_SelectComputer',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/Computers/GetAllForSearch',
        queryParams: {
            take: 25,
        },
        debounceInterval: 100,
        dedupingInterval: 1800000,
        contextProp: 'computer',
        queryParam: {
            name: 'ComputerId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: true,
        membership: true,
        history: true,
        name: 'ShoppingFor',
        advanceSearch: true,

        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 0,
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedComputers-Control',
        },
        order: 2,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forManageAccess: true,
        loginsession: true,
        membership: true,
        history: false,
        dynamic: true,
        name: 'AccessType',
        title: 'AccessType',
        order: 2,
        colorCode: {
            primary: 'rgba(107,0,123,1)',
            secondary: 'rgba(107,0,123,0.1)',
        },
        type: 'nullableBoolean',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'PreApproved',
            falseLabel: 'NotPreApproved',
            nullLabel: 'Common_All',
        },
        contextProp: 'accessType',
        queryParam: {
            name: 'ShowPreApproved',
        },
    },
    {
        forManageAccess: true,
        loginsession: true,
        membership: false,
        history: true,
        dynamic: true,
        name: 'ShowByActiveConnection',
        title: 'ShowByActiveSessions',
        requireAccess: {
            control: 'ITShop-ComputersActiveSessions-Control',
        },
        order: 3,
        colorCode: {
            primary: 'rgba(65,66,183,1)',
            secondary: 'rgba(65,66,183,0.1)',
        },
        label: 'ShowByActiveConnection',
        type: 'checkbox',
        contextProp: 'showActiveConnection',
        trueValueIndicator: 'ShowByActiveConnection',
        queryParam: {
            name: 'showActiveConnections',
        },
    },
    {
        forRequestAccess: false,
        forManageAccess: true,
        loginsession: false,
        membership: false,
        history: true,
        dynamic: true,
        name: 'WhoConnected',
        title: 'Common_WhoConnected',
        requireAccess: {
            // NOTE: In case the control name needs to be changed, please also update in useSubcomponents.js file for const canSeeWhoConnectedFilter
            control: 'ITShop-WhoConnected-Control',
        },
        order: 2,
        colorCode: {
            primary: 'rgb(65 154 183)',
            secondary: 'rgb(65 154 183 / 0.15)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'Common_WhoConnected',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'local',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'requestedByPerson',
        queryParam: {
            name: 'RequestedByPersonId',
            valueProp: 'id',
        },
    },
    {
        forManageAccess: false,
        loginsession: false,
        membership: false,
        history: false,
        dynamic: true,
        name: 'Type',
        title: 'CredentialsType',
        order: 4,
        colorCode: {
            primary: '#01745f',
            secondary: '#e6f1ef',
        },
        label: 'Computers_SelectCredentialsType',
        type: 'select',
        placeholder: 'Common_All',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Credentials/types',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'type',
        queryParam: {
            name: 'credentialTypeId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: true,
        membership: false,
        history: true,
        dynamic: true,
        name: 'Infrastructure',
        title: 'Computers_Infrastructure',
        requireAccess: {
            control: 'ITShop-ComputersInfrastructure-Control',
        },
        order: 9,
        colorCode: {
            primary: '#960067',
            secondary: '#f4e5ef',
        },
        type: 'autocomplete',
        label: 'Computers_SelectInfrastructure',
        placeholder: 'Computers_SelectInfrastructure',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Computers/infrustructureTypes',
        queryParams: {
            take: 25,
        },
        debounceInterval: 100,
        dedupingInterval: 1800000,
        contextProp: 'infrastructure',
        queryParam: {
            name: 'infrastructureId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: false,
        membership: false,
        history: true,
        dynamic: true,
        name: 'Environment',
        title: 'Computers_Environment',
        requireAccess: {
            control: 'ITShop-ComputersEnvironment-Control',
        },
        order: 10,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'autocomplete',
        label: 'Computers_Environment',
        placeholder: 'Computers_Environment',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/Computers/environmentTypes',
        queryParams: {
            take: 25,
        },
        debounceInterval: 100,
        dedupingInterval: 1800000,
        contextProp: 'environment',
        queryParam: {
            name: 'environmentTypeId',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: false,
        membership: false,
        history: true,
        dynamic: true,
        name: 'OperatingSystem',
        title: 'Common_OperatingSystem',
        requireAccess: {
            control: 'ITShop-ComputersOperatingSystem-Control',
        },
        order: 11,
        colorCode: {
            primary: 'rgba(10,172,0,1)',
            secondary: 'rgba(10,172,0,0.1)',
        },
        type: 'autocomplete',
        label: 'Common_OperatingSystem',
        placeholder: 'Common_OperatingSystem',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
        },
        url: '/api/Computers/operatingSystemTypes',
        queryParams: {
            take: 25,
        },
        contextProp: 'operatingSystem',
        queryParam: {
            name: 'operatingSystemId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: false,
        membership: true,
        history: false,
        dynamic: true,
        name: 'Capability',
        title: 'Computers_Capability',
        requireAccess: {
            control: 'ITShop-ComputersCapability-Control',
        },
        order: 12,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'nullableBoolean',
        label: 'Computers_SelectCapability',
        placeholder: 'Computers_SelectCapability',
        optionConfig: {
            type: 'label',
            labelProp: 'name',
            trueLabel: 'Common_PsmEnabled',
            falseLabel: 'Common_PsmNotEnabled',
            nullLabel: 'Common_All',
        },
        queryParam: {
            name: 'PSMEnabled',
        },
        contextProp: 'capability',
    },
    {
        forManageAccess: false,
        loginsession: false,
        membership: false,
        history: false,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-PreApprovedComputers-Control',
        },
        order: 5,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: {
            loginsession: false,
            history: false,
            membership: true,
        },
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forRequestAccess: true,
        name: 'TargetSystem',
        title: 'Common_TargetSystem',
        requireAccess: {
            control: 'ITShop-TargetSystem-Control',
        },
        order: 8,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        forManageAccess: false,
        loginsession: false,
        membership: false,
        history: false,
        dynamic: true,
        name: 'ReferencePerson',
        title: 'Common_ShopByReferencePerson',
        requireAccess: {
            control: 'ITShop-Computers-ShopByReferencePerson-Control',
        },
        order: 13,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'Common_ByReferencePerson',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'referencePerson',
        queryParam: {
            name: 'referencePersonId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        loginsession: true,
        membership: true,
        history: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ComputersAdvancedSearch-Control',
        },
        order: 13,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            showForRequestAccess: true,
            showForManageAccess: true,
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    showForRequestAccess: true,
                    showForManageAccess: true,
                    loginsession: true,
                    membership: true,
                    history: true,
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    showForRequestAccess: true,
                    showForManageAccess: true,
                    loginsession: true,
                    membership: true,
                    history: true,
                    name: 'dnsHostname',
                    type: 'text',
                    placeholder: 'Common_DnsHostName',
                    contextProp: 'advancedSearch.forms.dnsHostName',
                    queryParam: {
                        name: 'dnsHostName',
                        type: 'string',
                    },
                },
                {
                    showForRequestAccess: true,
                    showForManageAccess: true,
                    loginsession: true,
                    membership: true,
                    history: false,
                    name: 'computerTypeName',
                    type: 'text',
                    placeholder: 'Computers_ComputerType',
                    contextProp: 'advancedSearch.forms.computerTypeName',
                    queryParam: {
                        name: 'computerTypeName',
                        type: 'string',
                    },
                },
                {
                    showForRequestAccess: false,
                    showForManageAccess: false,
                    loginsession: true,
                    membership: false,
                    history: true,
                    name: 'StartDateAfter',
                    type: 'date',
                    placeholder: 'Common_StartDate',
                    contextProp: 'advancedSearch.forms.StartDateAfter',
                    queryParam: {
                        name: 'StartTimeUtc',
                        type: 'string',
                    },
                },
                {
                    showForRequestAccess: false,
                    showForManageAccess: false,
                    loginsession: true,
                    membership: false,
                    history: true,
                    name: 'EndDateBefore',
                    type: 'date',
                    placeholder: 'Common_EndDate',
                    contextProp: 'advancedSearch.forms.EndDateBefore',
                    queryParam: {
                        name: 'EndTimeUtc',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            showForRequestAccess: true,
            showForManageAccess: false,
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'operatingSystemTypeName',
        label: '',
        manageAccessOnlyAttribute: true,
        component: {
            name: 'ComputerTypeIcon',
            type: 'icon', //only one attribute can have this type=icon
        },
        loginsession: true,
        membership: false,
        history: false,
        showMasterLock: false,
        showInCard: false,
        requireAccess: {
            control: 'ITShop-Computers-OperatingSystemTypeName-Control',
        },
    },
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: true,
        loginsession: true,
        membership: true,
        history: false,
        showMasterLock: false,
        requestAccessOnly: true,
        requireAccess: {
            control: 'ITShop-Computers-FriendlyName-Control',
        },
    },
    {
        name: 'dnsHostName',
        label: 'Common_DNSName',
        sortable: true,
        loginsession: true,
        membership: true,
        history: false,
        showMasterLock: false,
        requestAccessOnly: true,
        requireAccess: {
            control: 'ITShop-Computers-DnsHostName-Control',
        },
    },
    {
        name: 'infrastructureName',
        label: 'Common_Infrastructure',
        sortable: true,
        loginsession: false,
        membership: false,
        history: false,
        showMasterLock: false,
        requestAccessOnly: true,
        requireAccess: {
            control: 'ITShop-Computers-InfrastructureName-Control',
        },
    },
    {
        name: 'environmentTypeFriendlyName',
        label: 'Computers_Environment',
        sortable: true,
        loginsession: true,
        membership: true,
        history: false,
        showMasterLock: false,
        requestAccessOnly: true,
        requireAccess: {
            control: 'ITShop-Computers-EnvironmentFriendlyName-Control',
        },
    },
    {
        name: 'computerTypeName',
        label: 'Common_Type',
        sortable: true,
        loginsession: false,
        membership: false,
        history: false,
        showMasterLock: false,
        requestAccessOnly: true,
        requireAccess: {
            control: 'ITShop-Computers-ComputerTypeName-Control',
        },
    },
    {
        name: 'statusButton',
        label: 'Common_CheckIn',
        hideValue: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '100px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
        component: {
            name: 'ComputersCheckInButton',
        },
        loginsession: false,
        membership: false,
        history: false,
        showMasterLock: true,
        requireAccess: {
            control: 'ITShop-Computers-CheckIn-Control',
        },
    },

    {
        name: 'statusButton',
        label: 'Status',
        hideValue: true,
        type: 'computerConnectBtn',
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '100px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
        component: {
            name: 'ComputersStatusButton',
        },
        loginsession: true,
        membership: false,
        history: false,
        showMasterLock: true,
        requireAccess: {
            control: 'ITShop-Computers-Status-Control',
        },
    },
    {
        name: 'startTimeUtc',
        label: 'CheckoutStartDate',
        type: 'datelocal',
        sortable: false,
        loginsession: true,
        membership: false,
        history: false,
        showMasterLock: false,
        component: {
            name: 'GenericDateTimeComponent',
        },
        requireAccess: {
            control: 'ITShop-Computers-CheckoutStartDate-Control',
        },
    },
    {
        name: 'endTimeUtc',
        label: 'CheckoutEndDate',
        sortable: false,
        type: 'datelocal',
        loginsession: true,
        membership: false,
        history: false,
        showMasterLock: false,
        component: {
            name: 'GenericDateTimeComponent',
        },
        requireAccess: {
            control: 'ITShop-Computers-CheckoutEndDate-Control',
        },
    },
    {
        name: 'operatingSystem',
        label: 'Common_OperatingSystem',
        sortable: true,
        hideInManageAccess: true,
        loginsession: false,
        membership: true,
        history: false,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-OperatingSystem-Control',
        },
    },
    {
        name: 'description',
        label: 'Description',
        sortable: true,
        loginsession: false,
        membership: true,
        history: false,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-Description-Control',
        },
    },

    {
        name: 'accessLevel',
        label: 'Common_AccessLevel',
        loginsession: false,
        membership: true,
        history: false,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-AccessLevel-Control',
        },
    },
    {
        name: 'assignee',
        label: 'Common_AssignedVia',
        loginsession: false,
        membership: true,
        history: false,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-Assignee-Control',
        },
    },
    {
        name: 'resourceEndDateUtc',
        label: 'Common_EndDate',
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
        loginsession: false,
        membership: true,
        history: false,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-ResourceEndDate-Control',
        },
    },
    {
        name: 'computerFriendlyName',
        label: 'Common_ComputerName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        loginsession: false,
        membership: false,
        history: true,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-ComputerFriendlyName-Control',
        },
    },
    {
        name: 'checkedOutByPersonId',
        label: 'Common_WhoConnected',
        hideValue: true,
        manageAccessOnlyAttribute: true,
        headerCellStyles: {
            width: '100px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
        component: {
            name: 'Person',
            config: {
                imgUrlProp: 'checkedOutByPersonThumbUrl',
                friendlyNameProp: 'checkedOutByPersonFriendlyName',
                personIdProp: 'checkedOutByPersonId',
                showOnlyIfIsPerson: true,
                isPersonFlagProp: 'ownerIsPerson',
            },
        },
        loginsession: false,
        membership: false,
        history: true,
        requireAccess: {
            control: 'ITShop-Computers-WhoConnected-Control',
        },
    },
    {
        name: 'externalCredentialFriendlyName',
        label: 'Common_Credential',
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        loginsession: false,
        membership: false,
        history: true,
        showMasterLock: false,
        requireAccess: {
            control: 'ITShop-Computers-Credential-Control',
        },
    },
    {
        name: 'when',
        manageAccessColumnName: 'startTimeUtc',
        label: 'Common_When',
        type: 'duration',
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        loginsession: false,
        membership: false,
        history: true,
        showMasterLock: false,
        component: {
            name: 'ComputersConnectedWhen',
        },
        requireAccess: {
            control: 'ITShop-Computers-When-Control',
        },
    },
    {
        name: 'duration',
        manageAccessColumnName: 'endTimeUtc',
        label: 'Common_Duration',
        type: 'duration',
        sortable: false,
        style: { fontWeight: 'bold', color: '#282828' },
        loginsession: false,
        membership: false,
        history: true,
        showMasterLock: false,
        component: {
            name: 'ComputersConnectedWhen',
        },
        requireAccess: {
            control: 'ITShop-Computers-Duration-Control',
        },
    },
    {
        name: 'canTerminateSession',
        label: 'Common_Terminate',
        sortable: false,
        style: { fontWeight: 'bold', color: '#282828' },
        loginsession: false,
        membership: false,
        history: true,
        showMasterLock: false,
        component: {
            name: 'ComputersTerminateButton',
        },
        requireAccess: {
            control: 'ITShop-Computers-Terminate-Control',
        },
    },
]
const manageAccessTabs = [
    {
        label: 'Computer_LoginSessionAccess',
        path: 'loginsession',
        icon: 'Keys',
        display: true,
        default: true,
        controller: 'loginSessionAccess',
        showMasterLock: true,
        contextProp: 'loginSessionCount',
        showCount: true,
        requireAccess: {
            control: 'Computers-LoginAccess',
        },
    },
    {
        label: 'LoginSessionHistory',
        path: 'history',
        icon: 'History',
        display: true,
        controller: 'loginSessionHistory',
        showMasterLock: false,
        contextProp: 'historyCount',
        showCount: true,
        requireAccess: {
            control: 'Computers-SessionHistory',
        },
    },
    {
        label: 'Computer_MembershipBasedAccess',
        path: 'membership',
        icon: 'Vip',
        display: true,
        controller: 'allAssignedResources',
        showMasterLock: false,
        contextProp: 'membershipCount',
        showCount: true,
        requireAccess: {
            control: 'Computers-Membership',
        },
    },
]
const computerConfig = {
    name: 'Computers',
    resourceTypeName: 'Computer',
    title: 'Common_Computers',
    route: '/computers',
    requireAccess: {
        page: 'ITShop-Computers-Page',
    },
    order: 10,
    icon: {
        small: 'ComputersSmall',
        large: 'ComputersLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/computers',
    },
    showTimeConstrainedFilter: false,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,
    attributes: attributes,
    manageAccessTabs,
}

export default computerConfig
