import { IResourceType } from '../types/IResourceType'
import { IResourceTypeIcon } from '../types/IResourceTypeIcon'
import { IResourceTypeComponents } from '../types/IResourceTypeComponents'
import { AccessLevel } from '../types/AccessLevel'
import { Sample } from './Sample'
import { AlreadyInCartType } from '../types/helperTypes'
import { ICartItem } from '../types/ICartItem'
import { IResource } from '../types/IResource'
import { RESOURCE_TYPE_NAMES } from 'utils'

export class ApplicationsType implements IResourceType {
    name = RESOURCE_TYPE_NAMES.APPLICATIONS

    route = '/applications'

    title = 'Common_Applications'

    access: AccessLevel = {
        page: 'ITShop-Applications-Page',
    }

    icon: IResourceTypeIcon = {
        small: 'ApplicationsSmall',
        large: 'ApplicationsLarge',
    }

    manageAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    requestAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    readonly cartComponent = null

    alreadyInCart: AlreadyInCartType = (
        cartItems: ICartItem[],
        resource: IResource,
        accessLevel: any,
    ) =>
        cartItems?.find(
            (c) =>
                ((c.resourceTypeRoleId === resource.id &&
                c.requestableResourceId === accessLevel?.resourceAssignment?.id) || (c?.assignmentId && (c.assignmentId === resource?.manageAccessId))),
        ) !== undefined

    filters = []

    attributes = []
}
