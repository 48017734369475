import React, { useState } from 'react'
import { useTCodes } from 'hooks'
import { useIsSmallScreen, useDebounce } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { StyledSearchInput, TableGrid } from 'components'
import { Pagination } from 'packages/eid-ui'

const take = 10

const TCodesList = ({ resourceId, differentiationValueId }) => {
    const isSmallScreen = useIsSmallScreen()

    const { t } = useTranslation()
    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useTCodes(
        resourceId,
        differentiationValueId,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    const attributes = [
        {
            label: t('Common_FriendlyName'),
            name: 'friendlyName',
        },
        {
            label: t('Common_Description'),
            name: 'description',
        },
        {
            label: t('Common_RightTypeName'),
            name: 'rightTypeName',
        },
        {
            label: t('Common_ResourceSystem'),
            name: 'resourceSystemName',
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
            />

            {!isLoading && (
                <Box
                    padding={!isSmallScreen ? '20px 0px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Pagination
                        count={numberOfPages}
                        page={page}
                        size={!isSmallScreen ? 'large' : 'small'}
                        onChange={handlePageChange}
                        totalCount={latestData?.totalCount}
                        onPageSelection={handlePageSelection}
                        itemsPerPage={perPageItems}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        showPageSelection={true}
                        showItemsPerPageSelection
                        showCountStat
                    />
                </Box>
            )}
        </>
    )
}

export default TCodesList
