import { styled } from '@mui/styles'
import { HorizontalTabs } from 'packages/eid-ui'

const Tabs = styled(HorizontalTabs)(({ theme }) => ({
    backgroundColor: '#fbfbfd',
    borderBottom: 'solid 1px #d8dadd',

    '& .MuiTab-root': {
        transition: 'color 0.15s ease-in-out',
        marginRight: '0.6rem',
        letterSpacing: '0.002em',

        '&:hover, &:focus': {
            color: theme?.palette?.primary?.main,
        },
    },

    '& .Mui-selected': {
        fontWeight: 'bold',
        letterSpacing: '0.02em',
    },

    '& .MuiTabs-flexContainer': {
        height: '6rem',
        paddingLeft: '1.3rem',
        paddingRight: '1.3rem',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme?.palette?.primary?.main,
    },
}))

export default Tabs
