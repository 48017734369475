import { Box, Collapse, styled, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Icon } from 'packages/eid-icons'
import { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import HeaderBgImage from '../../../../assets/images/header-bg.png'
import { FIELD_TYPE_COMPONENTS_TYPES, checkIfAnyFieldTypeMissingValue, updateFieldTypeJson } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useApplicationFieldTypeSourceValues } from 'hooks'
import { FixedList, FixedSingleValue, FreeTextMultiValue, FreeTextRange, ListCheckbox, MultiSelectAutocomplete, SingleSelectAutocomplete, SingleSelectDropdownList, SingleSelectRadioButton } from 'packages/eid-ui'
import SingleSelectLookupControl from 'components/ApplicationFieldTypes/FieldTypeValuesControl/SingleSelectLookupControl'
import MultiSelectLookupControl from 'components/ApplicationFieldTypes/FieldTypeValuesControl/MultiSelectLookupControl'
import config from 'config'

export type AppRoleRightProps = {
    rightInfo?: any
    isAppRole: boolean
    isFormValidated: boolean
    defaultExpanded?: boolean
    showRequiredFieldMessage?: boolean
}

const PaddedDiv = styled(Box)({
    margin: '0 20px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        rootwraper: {
            boxShadow: '0 4px 22px 0 rgba(4, 171, 147, 0.13)',
            borderRadius: '4px',
            marginBottom: '24px',
        },
        root: {
            background: '#fbfbfd',
            margin: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },

        approveaccordionleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordionright: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        approveaccordion: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '19px 16px',
            background: 'transparent',

            '& img': {
                display: 'none',
            },
        },
        approveheading: {
            fontSize: '1.8rem',
            color: '#1b1f1f',
            fontWeight: 600,

            '& label': {
                display: 'inline-block',
                marginRight: '0.8rem',
                fontWeight: 300,
            },
        },
        approvecounting: {
            padding: '0px 8px',
            marginLeft: '12px',
            fontSize: '12px',
            color: '#5d6870',
        },

        expandIcon: {
            width: '1.6rem',
            height: '1.6rem',
            marginLeft: '0.8rem',
            marginTop: '-0.1rem',
            color: theme?.palette?.primary?.main,
        },
        expandButton: {
            textTransform: 'uppercase',
            zIndex: 9999,
            marginTop: '0.2rem',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        headerBold: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        infoSubtext: {
            fontSize: '10px',
            marginTop: '5px',
        },
        coloredHeader: {
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
        },
        requiredFieldsHeader: {
            boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
            border: 'solid 1px rgba(208, 2, 27, 0.25)',
            backgroundColor: '#ffeff1',
        },
        approveaccordionHeader: {
            backgroundImage: 'linear-gradient(94deg, #682a94, #05afe0)',
            border: 'none !important',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            position: 'relative',
            overflow: 'hidden',
            '& div': {
                color: `${theme?.palette?.common?.white} !important`,
                borderColor: `${theme?.palette?.common?.white} !important`,
            },
            '& span': {
                color: theme?.palette?.common?.white,
            },
            '& img': {
                display: 'block',
            },
        },
        headingBG: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 0,
            right: 0,
            top: 0,
        },

        lineGlow: {
            '&:not(:empty)': {
                position: 'relative',

                '&::after': {
                    content: '""',
                    width: '95%',
                    height: '1px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: '-1px',
                    display: 'block',
                    opacity: '0.25',
                    position: 'absolute',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderImageSlice: '1',
                    borderImageSource:
                        'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
                },
            },
        },
    }),
)

const AppRoleRight: FC<AppRoleRightProps> = ({
    rightInfo,
    isAppRole,
    isFormValidated,
    defaultExpanded = false,
    showRequiredFieldMessage = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const [showExpanded, setShowExpanded] = useState<boolean>(defaultExpanded)
    const showExpand = rightInfo?.FieldType?.length || false
    const [{ appRightsFieldTypes }, dispatch]: any = useResourceTypeContext()

    useEffect(() => {
        setShowExpanded(defaultExpanded)
    }, [defaultExpanded])

    useEffect(() => {
        if (isFormValidated && !showExpanded) {
            setShowExpanded(!checkIfAnyFieldTypeMissingValue(rightInfo))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated])

    const bindUIElement = (item: any) => {
        const valueType =
            item?.AzFieldTypeSelectionRuleTypeID === 3 ||
                item?.AzFieldTypeSelectionRuleTypeID === 4
                ? 'AssignedValues'
                : 'AssignedValue'
        const isApiSupportedSource = Boolean(item?.DataType && item?.Name && item?.FieldMethodName && item?.FieldParameters)
        switch (item?.SelectionRule) {
            // Multi || AzFieldTypeSelectionRuleTypeID = 3 Multiple Key Value Pairs
            case FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE:
            case FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES:
                return (
                    <FreeTextMultiValue
                        fieldType={item}
                        AzLocalRightID={rightInfo?.AzLocalRightID}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )

            // Single || AzFieldTypeSelectionRuleTypeID = 1
            case FIELD_TYPE_COMPONENTS_TYPES.SINGLE_SELECT_RADIO_BUTTON:
                return (
                    <SingleSelectRadioButton
                        fieldType={item}
                        AzLocalRightID={rightInfo?.AzLocalRightID}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // Single || AzFieldTypeSelectionRuleTypeID = 1
            case FIELD_TYPE_COMPONENTS_TYPES.SINGLE_SELECT_DROPDOWN_LIST:
                return (
                    <SingleSelectDropdownList
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // Single || AzFieldTypeSelectionRuleTypeID = 1
            case FIELD_TYPE_COMPONENTS_TYPES.SINGLE_SELECT_LOOKUP_CONTROL:
                return (
                    <SingleSelectLookupControl
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // Single || AzFieldTypeSelectionRuleTypeID = 1
            // case FIELD_TYPE_COMPONENTS_TYPES.PERSON_RELATIVE:
            //     return (
            //         <PersonRelative
            //             fieldType={item}
            //             isFormValidated={isFormValidated}
            //             valueType={valueType}
            //             isApiSupportedSource={isApiSupportedSource}
            //             useDataHook={useApplicationFieldTypeSourceValues}
            //             handleUpdate={(itemToUpdate: any) => {
            //                 dispatch({
            //                     type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
            //                     payload: updateFieldTypeJson(
            //                         appRightsFieldTypes,
            //                         'AzFieldTypeID',
            //                         item.AzFieldTypeID,
            //                         itemToUpdate,
            //                         rightInfo?.AzLocalRightID,
            //                     ),
            //                 })
            //             }}
            //         />
            //     )
            // Range || AzFieldTypeSelectionRuleTypeID = 2
            case FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE:
            case FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE:
                return (
                    <FreeTextRange
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // Range || AzFieldTypeSelectionRuleTypeID = 2
            case FIELD_TYPE_COMPONENTS_TYPES.FIXED_SINGLE_VALUE:
            case FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_SINGLE_VALUE:
                return (
                    <FixedSingleValue
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // MultiSelect || AzFieldTypeSelectionRuleTypeID = 3
            case FIELD_TYPE_COMPONENTS_TYPES.MULTI_SELECT_CHECKBOX_LIST:
                return (
                    <ListCheckbox
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // MultiSelect || AzFieldTypeSelectionRuleTypeID = 3
            case FIELD_TYPE_COMPONENTS_TYPES.MULTI_SELECT_LOOKUP_CONTROL:
                return (
                    <MultiSelectLookupControl
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(itemToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    itemToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // MultiSelect || AzFieldTypeSelectionRuleTypeID = 3
            case FIELD_TYPE_COMPONENTS_TYPES.MULTI_SELECT_AUTOCOMPLETE:
                return (
                    <MultiSelectAutocomplete
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(valueToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    valueToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            // MultiSelect || AzFieldTypeSelectionRuleTypeID = 3
            case FIELD_TYPE_COMPONENTS_TYPES.FIXED_LIST:
            case FIELD_TYPE_COMPONENTS_TYPES.PERSON_RELATIVE:
                return (
                    <FixedList
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                    />
                )
            // Single || AzFieldTypeSelectionRuleTypeID = 3
            case FIELD_TYPE_COMPONENTS_TYPES.SINGLE_SELECT_AUTO_COMPLETE:
                return (
                    <SingleSelectAutocomplete
                        fieldType={item}
                        isFormValidated={isFormValidated}
                        valueType={valueType}
                        isApiSupportedSource={isApiSupportedSource}
                        useDataHook={useApplicationFieldTypeSourceValues}
                        handleUpdate={(valueToUpdate: any) => {
                            dispatch({
                                type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                                payload: updateFieldTypeJson(
                                    appRightsFieldTypes,
                                    'AzFieldTypeID',
                                    item.AzFieldTypeID,
                                    valueToUpdate,
                                    rightInfo?.AzLocalRightID,
                                ),
                            })
                        }}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Box
            className={classNames(classes.rootwraper, {
                [classes.coloredHeader]: showExpanded,
                [classes.requiredFieldsHeader]:
                    showRequiredFieldMessage && !showExpanded,
                [classes.lineGlow]: !showRequiredFieldMessage && !showExpanded,
            })}
        >
            {rightInfo?.DisplayName && (
                <Box
                    className={classNames(classes.approveaccordion, {
                        [classes.approveaccordionHeader]: showExpanded,
                    })}
                >
                    {showExpanded && (
                        <Box className={classes.headingBG}>
                            <img
                                src={`${config.APP_SUBPATH}${HeaderBgImage}`}
                                alt="background"
                            />
                        </Box>
                    )}

                    <Box className={classes.approveaccordionleft}>
                        <Box className={classes.approveheading}>
                            {isAppRole && (
                                <label
                                    style={{
                                        color: !showExpanded
                                            ? '#8b909a'
                                            : '#fff',
                                    }}
                                >{`${t('Common_AppRight')}:  `}</label>
                            )}
                            {`${rightInfo?.DisplayName || '-'}`}
                        </Box>
                        {showRequiredFieldMessage && (
                            <Box
                                style={{
                                    marginLeft: '1.9rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.7rem',
                                    color: showExpanded ? '#fff' : '#d0021b',
                                }}
                            >
                                <Icon
                                    style={{
                                        color: showExpanded
                                            ? '#fff'
                                            : '#d0021b',
                                        height: '1.4rem',
                                        width: '1.4rem',
                                    }}
                                    name={'CriticalRisk'}
                                />
                                {t('CompleteMandatoryFields')}
                            </Box>
                        )}
                        <Box
                            className={classNames(
                                classes.approvecounting,
                                classes.infoSubtext,
                            )}
                        >
                            {rightInfo?.FieldType?.length
                                ? ''
                                : t('Common_NoFieldTypeValueFound')}
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        fontSize="12px"
                        fontWeight="bold"
                        color={theme?.palette?.primary?.main}
                    >
                        {showExpand && (
                            <Box
                                component="span"
                                display="flex"
                                className={classes.expandButton}
                                onClick={() => setShowExpanded(!showExpanded)}
                            >
                                {showExpanded
                                    ? t('HideFieldTypes')
                                    : t('ShowFieldTypes')}
                                <Box className={classes.expandIcon}>
                                    {showExpanded ? (
                                        <Icon
                                            name="MinusCircle"
                                            width="1.6rem"
                                            height="1.6rem"
                                        />
                                    ) : (
                                        <Icon
                                            name="PlusCircle"
                                            width="1.6rem"
                                            height="1.6rem"
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            <Collapse
                style={{
                    borderRadius: '8px',
                    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderImageSource:
                        'linear-gradient(to right, #682a94 0%, #05afe0 100%)',
                    borderImageSlice: '1',
                    // backgroundImage:
                    //     'linear-gradient(to bottom, #fcfcfd, #fcfcfd), linear-gradient(to right, #682a94 0%, #05afe0 100%)',
                }}
                in={showExpanded}
                timeout={400}
                unmountOnExit
            >
                <PaddedDiv className={classes.root}>
                    {rightInfo?.FieldType?.length
                        ? rightInfo?.FieldType.map(
                            (item: any, index: number) => (
                                <Fragment key={`right-fieldtype-${index}`}>
                                    {bindUIElement(item)}
                                </Fragment>
                            ),
                        )
                        : null}
                </PaddedDiv>
            </Collapse>
        </Box>
    )
}

export default AppRoleRight
