import React from 'react'
import { Box, Typography } from '@mui/material'
import { SelectionBox, ChipsContainer } from 'components'
import { SubProcessIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useBusinessDomains } from 'hooks'

function BusinessSubDomainSelection({
    onSelect,
    onSelectAll,
    domain,
    onBackClick,
    onSkipClick,
}) {
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: businessDomains } = useBusinessDomains(targetPerson.id)

    return (
        <SelectionBox
            icon={<SubProcessIcon />}
            onBackClick={onBackClick}
            onSkipClick={onSkipClick}
            style={{
                contentStyles: { minHeight: '55vh', marginBottom: '40px' },
                hedaerStyles: { padding: '175px 10px 0px 10px' },
            }}
            headingText={
                <>
                    <Box component="span">
                        {t('OnBoarding_SubDomainSelection')}
                        <Typography
                            style={{
                                textDecoration: 'underline',
                                fontWeight: 500,
                                paddingLeft: '5px',
                            }}
                        >
                            {domain.friendlyName}
                        </Typography>
                    </Box>
                </>
            }
            footerText={t('OnBoarding_FooterTextMessage')}
        >
            {() => (
                <ChipsContainer
                    loading={!businessDomains}
                    data={
                        businessDomains
                            ? businessDomains.filter(
                                  (d) => d.parentId === domain.id,
                              )
                            : []
                    }
                    onSelect={onSelect}
                    selectAllButton
                    onSelectAll={onSelectAll}
                />
            )}
        </SelectionBox>
    )
}

export default BusinessSubDomainSelection
