import React from 'react'
import { Box, styled } from '@mui/material'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import { Divider } from 'packages/eid-ui'
import { Skeleton } from '@mui/material'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ businessRole, policy, loading }: any) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const parsedHtml = ReactHtmlParser(
        DomPurify.sanitize(businessRole?.instructions),
    )
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={businessRole.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {businessRole.businessDomain_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('BusinessRoles_Domain')}
                        value={businessRole.businessDomain_FriendlyName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('BusinessRoles_HighLevelClassification')}
                    value={businessRole.highLevelClassification}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={businessRole.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {loading && (
                    <>
                        <Divider />

                        <Skeleton height={'30px'}></Skeleton>
                        <Skeleton height={'30px'}></Skeleton>
                    </>
                )}
                {policy && (
                    <>
                        {' '}
                        <Divider />
                        <AccessRequestPolicyDetails
                            policy={policy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}

                {parsedHtml && parsedHtml.length > 0 && (
                    <InfoBoxWithIcon
                        iconName={'InfoNew'}
                        message={parsedHtml}
                        rootStyles={{
                            width: '100%',
                            marginTop: '2.8rem',
                            marginBottom: '1.8rem',
                        }}
                    />
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
