import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import {
    useCheckOutCredential,
    useCurrentPerson,
    useAccessRequestPolicy,
    useCheckIfMasterPasswordExist,
} from 'hooks'
import { EidButton, useNotification } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import { CheckOutAdvanced } from 'components/CheckOutAdvanced'

const CheckOut = ({
    data,
    drawerView,
    parentStyle = {},
    checkedOutBySomeoneElse,
}) => {
    const item = data.resource

    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()

    const [checkOutCredential, { isLoading: isCheckingOut }] =
        useCheckOutCredential(item.id)
    const { masterPasswordExists } = useCheckIfMasterPasswordExist()

    const accessRequestPolicy = useAccessRequestPolicy(item.requestPolicyId)

    const [showAdvancedCheckout, setShowAdvancedCheckout] = useState(false)
    const [startDateTime, setStartDateTime] = useState(
        (checkedOutBySomeoneElse && item.currentCheckOutEndTimeUtc) ||
            moment(new Date()).utc().format(),
    )
    const [duration, setDuration] = useState(0)
    const [justification, setJustification] = useState('')
    const { showWarningMessage } = useNotification()

    useEffect(() => {
        if (accessRequestPolicy) {
            setDuration(accessRequestPolicy.defaultValueInMinutes)
        }
    }, [accessRequestPolicy])

    const violatesPolicy =
        accessRequestPolicy?.maximumValueInMinutes &&
        duration > accessRequestPolicy?.maximumValueInMinutes
    const hasInvalidDates =
        isNilOrEmpty(startDateTime) || duration < 1 || violatesPolicy

    let invalidDatesMessage = ''

    if (duration < 1) {
        invalidDatesMessage = t('InvalidDuration')
    }
    if (isNilOrEmpty(startDateTime)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy?.maximumValueInMinutes,
        })
    }

    const handleCheckOut = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        if (duration < 1) {
            showWarningMessage(t('InvalidDuration'))
            return
        }

        const checkOutData = {
            id: item.id,
            targetPersonId: currentPerson.id,
            startDateTime,
            endDateTime: moment(startDateTime)
                .clone()
                .add(duration, 'm')
                .utc()
                .format(),
            justification,
        }

        checkOutCredential(checkOutData)
        setShowAdvancedCheckout(false)
    }

    let checkOutOptions = [
        {
            name: 'Now',
            onClick: handleCheckOut,
            label: t('Now'),
        },
        {
            name: 'AdvancedMode',
            onClick: () => {
                setStartDateTime(
                    (checkedOutBySomeoneElse &&
                        item.currentCheckOutEndTimeUtc) ||
                        moment().utc().format(),
                )
                setShowAdvancedCheckout(true)
            },
            label: t('AdvancedMode'),
        },
    ]

    if (checkedOutBySomeoneElse)
        checkOutOptions = checkOutOptions.filter((x) => x.name !== 'Now')

    const handleChangeStartDate = (startDate) => {
        setStartDateTime(startDate)
    }

    const handleChangeDuration = (duration) => {
        setDuration(duration)
    }

    return (
        <>
            {!drawerView ? (
                <Box>
                    <Box style={{ height: '24px' }}>
                        <EidButton.CheckOutListing
                            loading={isCheckingOut}
                            checkedOutBySomeoneElse={checkedOutBySomeoneElse}
                            label={
                                checkedOutBySomeoneElse
                                    ? t('CheckedOut')
                                    : t('CheckOut')
                            }
                            onButtonClick={
                                !checkedOutBySomeoneElse && handleCheckOut
                            }
                            checkoutOptions={checkOutOptions}
                            parentStyle={parentStyle}
                        />
                    </Box>
                    {checkedOutBySomeoneElse && (
                        <Typography
                            style={{
                                fontSize: '12px',
                                marginTop: '8px',
                                lineHeight: '1.0',
                            }}
                        >
                            {t('Common_By') +
                                ' ' +
                                item.checkedOutByFriendlyName}
                        </Typography>
                    )}
                </Box>
            ) : (
                <Box minWidth="170px" height="48px">
                    <EidButton.CheckOutDetailButton
                        loading={isCheckingOut}
                        disabled={!item.canCheckOut}
                        label={
                            item.canCheckOut ? t('CheckOut') : t('CheckedOut')
                        }
                        onButtonClick={handleCheckOut}
                        checkoutOptions={checkOutOptions}
                    />
                </Box>
            )}
            {showAdvancedCheckout && (
                <CheckOutAdvanced
                    onSucces={handleCheckOut}
                    loading={isCheckingOut}
                    open={showAdvancedCheckout}
                    onClose={() => setShowAdvancedCheckout(false)}
                    requestPolicy={accessRequestPolicy}
                    selectedStartDate={startDateTime}
                    setSelectedStartDate={handleChangeStartDate}
                    selectedDuration={duration}
                    minStartDate={
                        checkedOutBySomeoneElse &&
                        item.currentCheckOutEndTimeUtc
                    }
                    setSelectedDuration={handleChangeDuration}
                    justification={justification}
                    setJustification={setJustification}
                    hasInvalidDates={hasInvalidDates}
                    invalidDatesMessage={invalidDatesMessage}
                    item={item}
                />
            )}
        </>
    )
}

CheckOut.defaultProps = {
    drawerView: false,
    checkedOutBySomeoneElse: false,
}

export default CheckOut
