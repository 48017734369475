import { useEffect, useState } from 'react'
import {
    useGetControlsAccess,
    useIsSmallScreen,
    useQuery,
    useUrlGenerator,
} from 'packages/core'
import Layout from 'components/Layout'
import DesktopVersion from './DesktopVersion'
import MobileVersion from './MobileVersion'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useAllApplications, useDefaultFilters } from 'hooks'
import useManageAccessResources from './useManageAccessResources'
import useAppliedFilterValues from 'components/AppliedFilters/useAppliedFilterValues'
import ViewPendingAccess from './ViewPendingAccess'
import { useResourceTypeContext } from 'resourceTypeContext'
import SubHeader from 'components/SubHeader'
import { Box } from '@mui/material'
import { useHistory } from 'react-router'
import { useViewIndicator } from 'packages/eid-ui'
import NoAccessInfo from 'components/NoAccessInfo'
import useSubcomponents from 'useSubcomponents'
import config from 'config'

const createStyles = () => {
    return {
        rootStyles: {
            position: 'relative',
            paddingBottom: '30px',

            overflowY: 'overlay !important',
        },
    }
}

const ManageAccess = ({ resourceType, ...rest }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const styles = createStyles()
    const [state, dispatch] = useResourceTypeContext()
    const { sorting: sortQuery } = state

    const setSortQuery = (newSortQuery) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const query = useQuery()

    const [targetPerson] = useTargetPerson()

    const {
        resourceTypes,
        manageAccess,
        getResourceType,
        getResourceTypeAttributes,
    } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)
    const resourceTypeAttributes = getResourceTypeAttributes(
        currentResourceType.name,
        true,
    )

    const { hasAccessToTabs } = useSubcomponents()
    const accessControlsNames = useGetControlsAccess().map((c) => c.name)

    const { hasAccessToAtleastOneTab, hasAccessToManageAccess } =
        hasAccessToTabs(currentResourceType.name)

    const selectedTab = query.get('tab')
    const id = query.get('selected')

    const [isEnabled, setIsEnabled] = useState(false)

    useEffect(() => {
        if (currentResourceType?.manageAccessTabs && !selectedTab) {
            const defaultTab = currentResourceType.manageAccessTabs.filter(
                (t) => t.default,
            )[0]

            history.replace(
                `${history.location.pathname}?tab=${defaultTab.path}${
                    query.get('view') ? '&view=card' : ''
                }${id ? '&selected=' + id : ''}`,
            )
        }
    }, [currentResourceType, selectedTab, id])

    useEffect(() => {
        if (currentResourceType) {
            let selectedTabInfo = null
            if (currentResourceType?.manageAccessTabs) {
                if (selectedTab) {
                    selectedTabInfo = currentResourceType.manageAccessTabs.find(
                        (x) => x.path === selectedTab,
                    )
                    dispatch({
                        type: 'SET_MANAGE_ACCESS_FILTERS',
                        payload: {
                            selectedTab: selectedTabInfo,
                        },
                    })
                    setFiltersForResource(selectedTabInfo)
                }
            } else {
                setFiltersForResource(selectedTabInfo)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentResourceType, selectedTab])

    const setFiltersForResource = (selectedTabInfo) => {
        let filtersToChange = []
        const payloadToDispatch = {}
        if (
            currentResourceType.filters &&
            config.DISABLE_DEFAULT_FILTERS?.toLowerCase() !== 'true'
        ) {
            const accessibleFilters = currentResourceType.filters.filter((f) =>
                f.requireAccess
                    ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
                    : true,
            )
            if (!currentResourceType.manageAccessTabs) {
                filtersToChange = accessibleFilters.filter((f) => {
                    return (
                        f.forManageAccess &&
                        f.hasOwnProperty('default') &&
                        state[f.contextProp] !== f['default'] &&
                        (config.DISABLE_DEFAULT_PREAPPROVED_FILTER?.toLowerCase() !==
                            'true' ||
                            f.name !== 'ShowOnlyPreApproved') &&
                        (config.DISABLE_DEFAULT_DIRECTASSIGNED_FILTER?.toLowerCase() !==
                            'true' ||
                            f.name !== 'ShowOnlyDirectAssigned')
                    )
                })
                if (filtersToChange && filtersToChange.length > 0) {
                    filtersToChange.forEach((el) => {
                        if (el['default'] !== undefined) {
                            payloadToDispatch[el.contextProp] = el['default']
                        }
                    })
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: payloadToDispatch,
                    })
                }
                setIsEnabled(hasAccessToManageAccess)
            } else {
                filtersToChange = accessibleFilters.filter((f) => {
                    return (
                        f.forManageAccess &&
                        f.hasOwnProperty('default') &&
                        state[f.contextProp] !==
                            f.default[selectedTabInfo?.path] &&
                        (config.DISABLE_DEFAULT_PREAPPROVED_FILTER?.toLowerCase() !==
                            'true' ||
                            f.name !== 'ShowOnlyPreApproved') &&
                        (config.DISABLE_DEFAULT_DIRECTASSIGNED_FILTER?.toLowerCase() !==
                            'true' ||
                            f.name !== 'ShowOnlyDirectAssigned')
                    )
                })
                if (filtersToChange && filtersToChange.length > 0) {
                    filtersToChange.forEach((el) => {
                        if (el.default[selectedTabInfo?.path] !== undefined) {
                            payloadToDispatch[el.contextProp] =
                                el.default[selectedTabInfo?.path]
                        }
                    })
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: payloadToDispatch,
                    })
                }
                setIsEnabled(hasAccessToManageAccess)
            }
        } else {
            setIsEnabled(hasAccessToManageAccess)
        }
    }

    const appliedFiltersValues = useAppliedFilterValues(currentResourceType)

    let attributes = resourceTypeAttributes
    if (appliedFiltersValues.some((x) => x.name === 'ShowOnlyDirectAssigned')) {
        attributes = resourceTypeAttributes.filter((x) => x.name !== 'assignee')
    }

    // Logic to refine attributes in case of tabs(With respect to selected Tab)
    if (selectedTab) {
        attributes = resourceTypeAttributes.filter((x) => x[selectedTab])
    }

    const title = `${t(manageAccess.title)} | ${t(currentResourceType.title)}`

    useEffect(() => {
        document.title = title
    }, [title])

    const {
        totalCount,
        loading,
        list,
        loadingMore,
        infiniteScrollObervableDiv,
    } = useManageAccessResources(currentResourceType, isEnabled)

    useEffect(() => {
        if (currentResourceType && currentResourceType.manageAccessTabs) {
            let selectedTabInfo = currentResourceType.manageAccessTabs.find(
                (x) => x.path === selectedTab,
            )
            if (!selectedTabInfo) {
                selectedTabInfo = currentResourceType.manageAccessTabs.find(
                    (x) => x.default,
                )
            }
            dispatch({
                type: 'LEFT_PANE_TAB_COUNT',
                payload: {
                    key: selectedTabInfo.contextProp,
                    value: totalCount,
                },
            })
        } else {
            dispatch({
                type: 'LEFT_PANE_TAB_COUNT',
                payload: {
                    key: 'membershipCount',
                    value: totalCount,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, selectedTab])
    const history = useHistory()

    const { viewIndicator } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        cardViewLabel: t('Card_View_Label'),
        listViewLabel: t('List_View_Label'),
    })

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const myTasksUrl = getMyTasksUrl()

    const { hasAccessToViewPendingAccess } = useSubcomponents()

    const viewPendingAccess = myTasksUrl && hasAccessToViewPendingAccess && (
        <Box
            data-protectedsubcomponent={hasAccessToViewPendingAccess}
            marginRight="10px"
        >
            <ViewPendingAccess
                label={t('ManageAccess_ViewPendingAccess')}
                url={`${myTasksUrl}/myRequests/businessRequests`}
            />
        </Box>
    )

    const getFilteredDataList = (items) => {
        try {
            let selectedTabInfo = currentResourceType.manageAccessTabs?.find(
                (x) => x.path === selectedTab,
            )
            if (items && selectedTabInfo) {
                if (selectedTabInfo?.controller === 'LocalRights') {
                    const updatedList = items.map((item) => {
                        return {
                            resource: {
                                ...item,
                                description:
                                    item?.description ||
                                    item.assignmentPointName,
                                requestPolicyId: item.accessRequestPolicyId,
                            },
                            assignment: {
                                personId: targetPerson.id,
                                isAssigned: item.isAssigned,
                                canActivateNow: item.canActivateNow,
                                isRenewable: item.isRenewable,
                                resourceAssignment: {
                                    id: item.applicationId,
                                    friendlyName: item.applicationFriendlyName,
                                    resourceAssignmentId: item.id,
                                },
                                assignmentDetails: {
                                    isRevokable: item.isRevocable,
                                    timeConstraintActive:
                                        item.timeConstraintActive,
                                    startDateUtc: item.startDateUtc,
                                    endDateUtc: item.endDateUtc,
                                },
                            },
                        }
                    })
                    return updatedList
                } else if (selectedTabInfo?.controller === 'azureroles') {
                    const updatedList = items.map((item) => {
                        return {
                            resource: {
                                ...item,
                                description:
                                    item?.description ||
                                    item.assignmentPointName,
                            },
                            assignment: {
                                personId: targetPerson.id,
                                isAssigned: item.isAssigned,
                                canActivateNow: item.canActivateNow,
                                isRenewable: item.isRenewable,
                                resourceAssignment: {
                                    id: item.applicationId,
                                    friendlyName: item.applicationFriendlyName,
                                    resourceAssignmentId: item.id,
                                },
                                assignmentDetails: {
                                    timeConstraintActive:
                                        item.timeConstraintActive,
                                    startDateUtc: item.startDateUtc,
                                    endDateUtc: item.endDateUtc,
                                    isRevokable: item.isRevocable,
                                },
                            },
                        }
                    })
                    return updatedList
                }
            }
            return items
        } catch (err) {
            console.error(err)
        }
        return items
    }

    return (
        <Layout
            currentResourceType={currentResourceType}
            rootStyles={styles.rootStyles}
            subHeader={
                !isSmallScreen && (
                    <SubHeader
                        totalCount={totalCount}
                        viewSwitcher={viewIndicator}
                        viewPendingAccess={viewPendingAccess}
                        resourceTypes={resourceTypes}
                        currentResourceType={currentResourceType}
                        showWorkFlows={true}
                        showNavTabs={hasAccessToAtleastOneTab}
                    />
                )
            }
        >
            {hasAccessToAtleastOneTab ? (
                <>
                    {isSmallScreen ? (
                        <MobileVersion
                            id={id}
                            list={getFilteredDataList(list)}
                            loading={loading}
                            totalCount={totalCount}
                            loadingMore={loadingMore}
                            resourceTypes={resourceTypes}
                            targetPerson={targetPerson}
                            currentResourceType={currentResourceType}
                            resourceTypeAttributes={attributes}
                            infiniteScrollObervableDiv={
                                infiniteScrollObervableDiv
                            }
                            {...rest}
                        />
                    ) : (
                        <DesktopVersion
                            id={id}
                            list={getFilteredDataList(list)}
                            loading={loading}
                            totalCount={totalCount}
                            loadingMore={loadingMore}
                            resourceTypes={resourceTypes}
                            targetPerson={targetPerson}
                            currentResourceType={currentResourceType}
                            resourceTypeAttributes={attributes}
                            infiniteScrollObervableDiv={
                                infiniteScrollObervableDiv
                            }
                            onSort={(sortBy, sortOrder) => {
                                setSortQuery({
                                    sortBy:
                                        sortBy.manageAccessColumnName &&
                                        sortBy.manageAccessColumnName.length > 0
                                            ? sortBy.manageAccessColumnName
                                            : sortBy.name,
                                    sortOrder,
                                })
                            }}
                            sortOrder={sortQuery.sortOrder}
                            sortBy={sortQuery.sortBy}
                            {...rest}
                        />
                    )}
                </>
            ) : (
                <NoAccessInfo />
            )}
        </Layout>
    )
}

const ensureFilters = (ChildComponent) => (props) => {
    const { data: onBoardingFilters } = useDefaultFilters(props.resourceType)

    if (onBoardingFilters === undefined) return <></>
    return <ChildComponent {...props} />
}

export default ensureFilters(ManageAccess)
