import { Box, styled } from '@mui/material'
import { useEffect } from 'react'
import { useAppState } from 'appContext'
import { useEidGrid } from './EidTableGrid'

const TableOverView = styled(Box)({
    padding: '16px 0px',
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const EidTableGrid = ({
    rowSelection = false,
    selected,
    handleSelectAllClick,
    handleClick,
    isLoading,
    list,
    isLoadingMore,
    noDataMessage,
    pagination,
    attributes,
    defaultView,
    onClickDetailButton,
    showSelectAll = true,
    selectionIdentifier = 'id',
    highLightSelectedRow = false,
    errorMessage = null,
    onSort
}) => {
    const [state] = useAppState()

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage,
        pagination,
        attributes,
        rowSelection,
        selected,
        handleSelectAllClick,
        handleClick,
        onClickDetailButton,
        defaultView,
        selectionIdentifier,
        showSelectAll,
        highLightSelectedRow,
        errorMessage,
        onSort
    })

    useEffect(() => {
        setCurrentView(state?.itemsView || 'table')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.itemsView])

    return <TableOverView>{viewToRender}</TableOverView>
}

export default EidTableGrid
