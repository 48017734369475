import { useGetControlsAccess } from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToControl = (controlName) => {
        return !controlName
            ? true
            : controls.includes(controlName)
            ? controlName
            : ''
    }

    const canChangeOverridenFilters = hasAccessToControl(
        'ITShop-Change-Overridden-Filters-Control',
    )

    const canSeeSimpleTextSearch = hasAccessToControl(
        'ITShop-SimpleTextSearch-Control',
    )

    const hasAccessToTCodesGrid = hasAccessToControl(
        'ITShop-TCodesGrid-Control',
    )

    const hasAccessToLocalSensitiveFunctionsGrid = hasAccessToControl(
        'ITShop-LocalSensitiveFunctionsGrid-Control',
    )

    const hasAccessToHelpMenu = hasAccessToControl(
        'ITShop-Header-Help-Menu',
    )

    const canSeeManagementRolesGranted = hasAccessToControl(
        'ITShop-ManagementRolesGrantedGrid-Control',
    )

    const canShopForOthers = hasAccessToControl(
        'ITShop-ShopForTargetPerson-Control',
    )

    const canSeeApplicationRolesGranted = hasAccessToControl(
        'ITShop-ApplicationRolesGrantedGrid-Control',
    )

    const canSeeApplications = hasAccessToControl(
        'ITShop-ApplicationsGrid-Control',
    )

    const hasAccessToSeeApproverControl = hasAccessToControl(
        'ITShop-ShowCartApprover-Control',
    )

    const hasAccessToSeeBusinessRequestTypesControl = hasAccessToControl(
        'ITShop-ShowCartBusinessRequestTypes-Control',
    )

    const hasAccessToCartDueDate = hasAccessToControl(
        'ITShop-CartDueDate-Control',
    )

    const canSeeBusinessRequest = hasAccessToControl(
        'ITShop-ManageAccessBusinessRequestAttribute-Control',
    )

    const canSeeAccessRequestPolicy = hasAccessToControl(
        'ITShop-ResourceAccessRequestPolicy-Control',
    )

    const canSeeShopByApplications = hasAccessToControl(
        'ITShop-ShopByApplications-Control',
    )

    const canSeeManagementRolesSuggestedGrid = hasAccessToControl(
        'ITShop-ManagementRolesSuggestedGrid-Control',
    )

    // Not in use
    const canSeeShowOnlyAzureApplications = hasAccessToControl(
        'ITShop-ShowOnlyAzureApplications-Control',
    )
    // Not in use
    const canSeeManageAccessFiltersBar = hasAccessToControl(
        'ITShop-ShowManageAccessFiltersBar-Control',
    )

    const canSeeAzureLicenseTenantAttribute = hasAccessToControl(
        'ITShop-AzureLicensesResourceSystemAttribute-Control',
    )

    const canSeeAzureLicenseLicensePoolAttribute = hasAccessToControl(
        'ITShop-AzureLicensesLicensePoolAttribute-Control',
    )

    const canSeeAzureLicenseTenantSubscriptionAttribute = hasAccessToControl(
        'ITShop-AzureLicensesTenantSubscriptionAttribute-Control',
    )

    const canSeeAzureLicenseLicensedAssigneeAttribute = hasAccessToControl(
        'ITShop-AzureLicensesLicensedAssigneeAttribute-Control',
    )

    const canSeeAzureLicenseServicePlansSection = hasAccessToControl(
        'ITShop-AzureLicensesTenantSubscriptionServicesSection-Control',
    )

    const canSeeComputersPSMSessionsRecordings = hasAccessToControl(
        'ITShop-ComputerPSMSessionRecordings-Control',
    )
    const canSeeWhoConnectedFilter = hasAccessToControl(
        'ITShop-WhoConnected-Control',
    )

    const hasAccessToTargetSystemControl = hasAccessToControl(
        'ITShop-TargetSystem-Control',
    )

    const hasAccessToApplicationProcesses = hasAccessToControl(
        'ITShop-ApplicationProcesses-Control',
    )

    const hasAccessToBusinessDomains = hasAccessToControl(
        'ITShop-BusinessDomains-Control',
    )

    const hasAccessToViewPendingAccess = hasAccessToControl(
        'ITShop-ViewPendingAccess-Control',
    )

    const hasAccessToTargetPersonSelection = hasAccessToControl(
        'ITShop-ShopForTargetPerson-Control',
    )

    const canReassignApprover = hasAccessToControl(
        'ITShop-ReassignCartApprover-Control',
    )

    const canSeeAtLeastOneFilter = (resourceType, screen) => {
        let filters = []
        if (screen === 'RequestAccess') {
            filters = resourceType.filters.filter(
                (f) => f.forRequestAccess === true,
            )
        } else if (screen === 'ManageAccess') {
            filters = resourceType.filters.filter(
                (f) => f.forManageAccess === true,
            )
        }
        filters = filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        )

        return filters.length > 0
    }

    const hasAccessToResourceTabs = (subComponentName) => {
        return hasAccessToControl(`${subComponentName}`)
    }

    const hasAccessToTabs = (currentResourceType) => {
        const hasAccessToRequestAccess = currentResourceType
            ? hasAccessToResourceTabs(
                  `ITShop-${currentResourceType}-RequestAccess-Control`,
              )
            : true
        const hasAccessToManageAccess = currentResourceType
            ? hasAccessToResourceTabs(
                  `ITShop-${currentResourceType}-ManageAccess-Control`,
              )
            : true
        const hasAccessToWorkFlow = currentResourceType
            ? hasAccessToResourceTabs(
                  `ITShop-${currentResourceType}-Workflow-Control`,
              )
            : true
        const hasAccessToAtleastOneTab =
            hasAccessToRequestAccess ||
            hasAccessToManageAccess ||
            hasAccessToWorkFlow
        return {
            hasAccessToRequestAccess,
            hasAccessToManageAccess,
            hasAccessToAtleastOneTab,
            hasAccessToWorkFlow,
        }
    }

    return {
        canSeeSimpleTextSearch,
        hasAccessToTCodesGrid,
        hasAccessToHelpMenu,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeManagementRolesGranted,
        canSeeApplicationRolesGranted,
        hasAccessToSeeApproverControl,
        hasAccessToSeeBusinessRequestTypesControl,
        hasAccessToCartDueDate,
        canSeeBusinessRequest,
        canSeeAccessRequestPolicy,
        canSeeApplications,
        canSeeShopByApplications,
        canSeeAtLeastOneFilter,
        canShopForOthers,
        canSeeManagementRolesSuggestedGrid,
        canSeeShowOnlyAzureApplications,
        canSeeManageAccessFiltersBar,
        canSeeAzureLicenseTenantAttribute,
        canSeeAzureLicenseLicensePoolAttribute,
        canSeeAzureLicenseTenantSubscriptionAttribute,
        canSeeAzureLicenseLicensedAssigneeAttribute,
        canSeeAzureLicenseServicePlansSection,
        canSeeComputersPSMSessionsRecordings,
        canSeeWhoConnectedFilter,
        canChangeOverridenFilters,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
        hasAccessToViewPendingAccess,
        hasAccessToTargetPersonSelection,
        canReassignApprover,
        hasAccessToTabs,
        hasAccessToControl,
    }
}
export default useSubcomponents
