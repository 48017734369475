import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PreApprovedItemsAccessTile } from '../AccessTile'
import { PreApprovedItemWrapper } from '../WrapperForPreApprovedAccessItem'

export type PreApprpovedLoginAccessProps = {
    resource: any
    resourceType: any
    manageBtnUrl: string
    handleViewRequest: () => void
}

export const PreApprpovedLoginAccess: FC<PreApprpovedLoginAccessProps> = (
    props,
) => {
    const { resource, resourceType, handleViewRequest, manageBtnUrl } = props
    const { t } = useTranslation()

    return (
        <PreApprovedItemWrapper
            title={t('Common_LoginSessionExistingAccess')}
            itemCount={resource?.checkOutCount}
        >
            <PreApprovedItemsAccessTile
                handleViewRequest={handleViewRequest}
                showArrows={false}
                resource={resource}
                resourceType={resourceType}
                type="preapprovedConnect"
                accessTypeLabel={t('Common_PreApproved')}
                manageBtnUrl={manageBtnUrl}
            />
            <PreApprovedItemsAccessTile
                showCount={true}
                handleViewRequest={handleViewRequest}
                resource={resource}
                resourceType={resourceType}
                type="request"
                accessTypeLabel={t('Common_Requests')}
                accessName={t('Common_LoginSession')}
                manageBtnUrl={manageBtnUrl}
            />
        </PreApprovedItemWrapper>
    )
}
