import React from 'react'
import { Skeleton } from '@mui/material';
import { Box, styled, useTheme } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
    padding: '8px',
    height: 'max-content',
    width: '50%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}))

const ItemCardSkeleton = () => {
    const theme = useTheme()

    return (
        <Container>
            <div
                style={{
                    backgroundColor: theme?.palette?.background?.paper,
                }}
            >
                <Skeleton animation="wave" variant="rectangular" height={80} />
                <Box marginTop={2} padding={2} width="100%">
                    <Skeleton variant="rectangular" animation="wave" height={8} />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ height: 96, marginTop: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={48}
                        style={{ marginTop: 16 }}
                    />
                </Box>
            </div>
        </Container>
    );
}

export default ItemCardSkeleton
