import React from 'react'
import Fab from '@mui/material/Fab'
import { useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        textTransform: 'capitalize !important',
        backgroundColor: theme?.palette?.background?.paper,
        color: theme?.palette?.primary?.main,
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        boxShadow: 'none',
        overflow: 'hidden',
        ...props.style,
        width: props.style.width && `${props.style.width}!important`,
    }),
    label: { lineHeight: 1, wordBreak: 'break-all' },
}))

const ExtendedFab = ({ style, label, onClick, ...rest }) => {
    const classes = useStyles({ style })

    return (
        <Fab variant="extended" classes={classes} onClick={onClick} {...rest}>
            {label}
        </Fab>
    )
}

export default ExtendedFab
