import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateRemainingTime } from 'utils'

const RemainingTime = ({ endTime, expiredLabelLocaleKey = 'Expired' }) => {
    const { t } = useTranslation()
    const currentTime = new Date().toISOString()

    const getRemainingTime = (currentTime, endTime) => {
        if (!endTime) {
            return '-'
        }
        if (currentTime > endTime) {
            return t(expiredLabelLocaleKey)
        }
        const duration = calculateRemainingTime(currentTime, endTime)

        const time = `${duration?.hours} ${t('hours')} ${duration?.minutes} ${t(
            'mins',
        )}`
        const daysLabel = duration?.days > 1 ? t('days') : t('day')

        const durationInDays = duration?.days + ' ' + daysLabel + ' ' + time

        return duration?.days > 0 ? durationInDays : time
    }
    return <Fragment>{getRemainingTime(currentTime, endTime)}</Fragment>
}

export default RemainingTime
