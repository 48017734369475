import React from 'react'
import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import BusinessRoleDetails from './BusinessRoleDetails'
import { useBusinessRole } from 'hooks'

const BusinessRoleDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()

    const { data: businessRole } = useBusinessRole(id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={businessRole?.friendlyName} />
            {!businessRole && <Loader />}
            {businessRole && (
                <BusinessRoleDetails
                    businessRole={businessRole}
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={businessRole?.friendlyName} />}
        >
            {!businessRole && <Loader />}
            {businessRole && (
                <BusinessRoleDetails
                    businessRole={businessRole}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </Drawer>
    )
}

export default BusinessRoleDrawer
