import { useEffect, useState } from 'react'
import { useComputerLoginSessionEvents } from 'hooks'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TableGrid } from 'components'
import { Pagination } from 'packages/eid-ui'
import Search from 'components/Layout/ITShopHeader/Search'
import moment from 'moment'
import { useResourceTypeContext } from 'resourceTypeContext'
import DownloadSSHLogsButton from './DownloadSSHLogsButton'

const useTableStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: theme?.palette?.background?.paper,
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '250px',
        width: 'auto',
        overflow: 'hidden',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    },
    tableHeaderCell: {
        maxWidth: '200px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${theme?.palette?.primary?.main} !important`,

            '& svg': {
                color: `${theme?.palette?.primary?.main} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${theme?.palette?.primary?.main} !important`,
        },
    },
    detailsBox: {
        '& button': {
            borderRadius: '4px',
        },
    },
}))

const ConvertTimeSpanObjectToString = (timeSpan) => {
    let connectedWhen = '0 min'
    if (timeSpan?.days > 0) {
        connectedWhen = `${timeSpan.days} ${timeSpan.days > 1 ? 'days' : 'day'}`
    }
    if (timeSpan?.hours > 0) {
        connectedWhen =
            connectedWhen === '0 min'
                ? `${timeSpan.hours} ${timeSpan.hours > 1 ? 'hours' : 'hour'}`
                : connectedWhen +
                  ` ${timeSpan.hours} ${timeSpan.hours > 1 ? 'hours' : 'hour'}`
    }
    if (timeSpan?.minutes > 0) {
        connectedWhen =
            connectedWhen === '0 min'
                ? `${timeSpan.minutes} ${timeSpan.minutes > 1 ? 'mins' : 'min'}`
                : connectedWhen +
                  ` ${timeSpan.minutes} ${
                      timeSpan.minutes > 1 ? 'mins' : 'min'
                  }`
    }
    return <>{connectedWhen}</>
}

const LoginSessionEvents = ({ sessionId, showDownloadButton }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const tableClasses = useTableStyles()

    const [
        { sorting: sortQuery, searchTerm },
        dispatch,
    ] = useResourceTypeContext()

    const setSortQuery = (newSortQuery) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const [pagination, setPagination] = useState({ page: 1, take: 10 })

    useEffect(() => {
        setPagination({ ...pagination, page: 1 })
    }, [searchTerm])

    const handlePageChange = (_, value) => {
        setPagination({ ...pagination, page: value })
    }

    const handlePageSelection = (value) => {
        setPagination({ ...pagination, page: value })
    }

    const handleItemsPerPageChange = (value) => {
        setPagination({ page: 1, take: value })
    }

    const { latestData } = useComputerLoginSessionEvents(
        sessionId,
        (pagination.page - 1) * pagination.take,
        pagination.take,
    )

    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / pagination.take)
        : 0

    const attributes = [
        {
            label: t('Common_ConnectedWhen'),
            name: 'when',
            resolve: (item) =>
                item.when ? ConvertTimeSpanObjectToString(item.when) : '-',
        },
        {
            label: t('Common_StartedAt'),
            name: 'startTimeUtc',
            sortable: true,
            resolve: (item) =>
                item.startTimeUtc
                    ? moment.utc(item.startTimeUtc).local().format('L LT')
                    : '-',
        },
        {
            label: t('Common_Duration'),
            name: 'duration',
            resolve: (item) =>
                item.duration
                    ? ConvertTimeSpanObjectToString(item.duration)
                    : '-',
        },
        {
            label: t('Computers_SessionKeystrokes'),
            sortable: true,
            name: 'keyStrokeBlock',
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="32px 32px 16px 32px"
                bgcolor="white"
                borderTop={`1px solid {theme?.palette?.background?.default}`}
            >
                <Box>
                    {showDownloadButton && (
                        <DownloadSSHLogsButton sessionId={sessionId} />
                    )}
                </Box>

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Search outlined />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                tableClasses={tableClasses}
                onSort={(sortBy, sortOrder) => {
                    setSortQuery({
                        sortBy:
                            sortBy.columnName && sortBy.columnName.length > 0
                                ? sortBy.columnName
                                : sortBy.name,
                        sortOrder,
                    })
                }}
                sortOrder={sortQuery.sortOrder}
                sortBy={sortQuery.sortBy}
            />

            {!isLoading && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={pagination.page}
                        onChange={handlePageChange}
                        showPageSelection={true}
                        onPageSelection={handlePageSelection}
                        itemsPerPage={pagination.take}
                        showItemsPerPageSelection={true}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />
                </Box>
            )}
        </>
    )
}

export default LoginSessionEvents
