import React, { useState } from 'react'
import TableView from './TableView'
import { useViewIndicator } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'
import CardView from './CardView'

const useEidGrid = ({
    isLoading,
    data,
    isLoadingMore,
    noDataMessage = '',
    pagination,
    attributes,
    handleSelectAllClick,
    defaultView = 'table',
    rowSelection = false,
    selected = [],
    handleClick,
    onClickDetailButton,
    selectionIdentifier,
    showSelectAll,
    highLightSelectedRow,
    errorMessage,
    onSort
}) => {
    const [state, dispatch] = useAppState()
    const [currentView, setCurrentView] = useState(
        state && state.itemsView ? state.itemsView : defaultView,
    )

    const { t } = useTranslation()

    const { viewIndicator: viewSwitcher } = useViewIndicator({
        view: currentView,
        onCardViewClick: () => {
            setCurrentView('card')

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'card',
            })
        },
        onListViewClick: () => {
            setCurrentView('table')

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'table',
            })
        },
        cardViewLabel: t('Card_View_Label'),
        listViewLabel: t('List_View_Label'),
    })

    const viewToRender =
        currentView === 'card' ? (
            <CardView
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                onClickDetailButton={onClickDetailButton}
                showSelectAll={showSelectAll}
                errorMessage={errorMessage}
            />
        ) : (
            <TableView
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                selectionIdentifier={selectionIdentifier}
                showSelectAll={showSelectAll}
                highLightSelectedRow={highLightSelectedRow}
                errorMessage={errorMessage}
                onSort={onSort}
            />
        )

    return { viewSwitcher, currentView, setCurrentView, viewToRender }
}

export { useEidGrid }
