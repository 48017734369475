import { FC, useState } from 'react'
import { Box, Button, useTheme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import classNames from 'classnames'

import useConfiguration from 'useConfiguration'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import { useHistory } from 'react-router'
import appConfig from 'config'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'
import RevokeButton from 'containers/ManageAccess/RevokeButton'

export type PreApprovedItemsAccessTileProps = {
    resource?: any
    assignment?: any
    type: string
    resourceType: any
    showArrows?: boolean
    accessName?: string
    accessTypeLabel?: string
    handleViewRequest: () => void
    showCount?: boolean
    canActivateNow?: boolean
    manageBtnUrl: string
}

const useStyles = makeStyles((theme) =>
    createStyles({
        preapprovecard: {
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
            padding: '16px 16px 9px',
            background: theme?.palette?.background?.paper,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab936e 7%, #2c83bd47 91%, rgba(48, 127, 193, 0))',
            borderImageSlice: '1',
            borderBottomWidth: '1px',
            borderStyle: 'solid',
            borderTopWidth: '0',
        },
        preapprovecardleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        preapprovecardright: {
            display: 'flex',
        },
        preapprovedConnect: {
            display: 'flex',
            '& button': {
                '&:nth-child(1)': {
                    paddingLeft: '6px',
                    borderRadius: '5px',
                    fontSize: '11px',
                    '& span': {
                        fontSize: '10px',
                        '& p': {
                            paddingRight: '5px',
                        },
                    },
                },
            },
        },
        manageAccessButton: {
            background: theme?.palette?.background?.paper,
            padding: '7px 8px 7px 13px',
            border: `solid 1px ${theme?.palette?.primary?.main}`,
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '1',
            color: theme?.palette?.primary?.main,
            marginLeft: '15px',
            textTransform: 'capitalize',
            '& span': {
                fontSize: '14px',
                marginLeft: '0px',
            },
        },
        preapprovedActivatebtn: {
            display: 'flex',
            '& button': {
                '&:nth-child(1)': {
                    background: `${theme?.palette?.secondary?.main} !important`,
                    padding: '6px 8px 5px',
                    borderRadius: '5px',
                    border: `solid 1px ${theme?.palette?.secondary?.main}`,
                    fontSize: '11px',
                    lineHeight: '1',
                    color: theme?.palette?.common?.white,
                    '& span': {
                        fontSize: '10px',
                    },
                },
            },
        },
        viewrequestbtn: {
            '& button': {
                background: theme?.palette?.common?.white,
                padding: '7px 15px 7px 13px',
                border: `solid 1px ${theme?.palette?.primary?.main}`,
                borderRadius: '16px',
                fontSize: '14px',
                lineHeight: '1',
                color: theme?.palette?.primary?.main,
                marginLeft: '15px',
                '& span': {
                    fontSize: '12px',
                },
            },
        },
        preapprovebtn: {
            border: 'solid 1px #aab0b4',
            fontSize: '12px',
            lineHeight: '1',
            padding: '4px 16px',
            minWidth: '120px',
            textAlign: 'center',
        },
        membershipbtn: {
            background: theme?.palette?.common?.white,
            borderRadius: '5px',
            color: theme?.palette?.tertiary?.main,
        },
        preApprovedbtn: {
            background: theme?.palette?.common?.white,
            borderRadius: '5px',
            color: theme?.palette?.secondary?.main,
        },
        requestbtn: {
            background: theme?.palette?.common?.white,
            borderRadius: '5px',
            color: '#1b1f1f',
        },
        preapproveadmin: {
            marginLeft: '15px',
            fontSize: '14px',
            color: theme?.palette?.common?.black,
        },
        preapprovedsection: {
            display: 'flex',
            alignItems: 'center',
        },
        taskbadgesection: {
            position: 'relative',
        },
        taskbadge: {
            position: 'absolute',
            top: '-15px',
            right: '-10px',
            background: theme?.palette?.quaternary?.main,
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            fontSize: '10px',
            color: theme?.palette?.common?.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '1px',
        },
        custommasterlock: {
            '& div div:nth-child(1)': {
                height: '60px',
                top: '-20px',
            },
        },
    }),
)

export const PreApprovedItemsAccessTile: FC<PreApprovedItemsAccessTileProps> = (
    props: PreApprovedItemsAccessTileProps,
) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const history = useHistory()
    const {
        resource,
        assignment,
        type,
        resourceType,
        showArrows = true,
        accessTypeLabel,
        accessName,
        handleViewRequest,
        showCount = false,
        canActivateNow,
        manageBtnUrl,
    } = props
    const classes = useStyles()
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState('')
    const { getResourceType } = useConfiguration()

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }

    const getManageButton = () => {
        return (
            <Button
                className={classes.manageAccessButton}
                onClick={() =>
                    history.push(`${appConfig.APP_SUBPATH}/${manageBtnUrl}`)
                }
                endIcon={
                    <Icon
                        name="ArrowSmall"
                        width="20px"
                        fill={theme?.palette?.primary?.main}
                        direction="right"
                    />
                }
                size={'medium'}
            >
                {t('Common_Manage')}
            </Button>
        )
    }

    const getActivateNowButton = () => {
        const resourceTypeConfig = getResourceType(resourceType?.name)
        if (canActivateNow && assignment?.resourceAssignment?.resourceId) {
            return (
                <ActivateNowButton
                    resource={{
                        id: assignment?.resourceAssignment?.resourceId,
                        resourceAssignmentId:
                            assignment?.resourceAssignment
                                ?.resourceAssignmentId,
                    }}
                    resourceType={resourceTypeConfig}
                    forCardView={true}
                    width="100px"
                />
            )
        }
    }

    const getConnectButton = () => {
        return (
            <>
                {!masterPassword && (
                    <PasswordModal
                        showDefaultContent={false}
                        open={open}
                        setOpen={setOpen}
                    />
                )}
                {!masterPassword ? (
                    <Box className={classes.custommasterlock}>
                        <MasterLockOverlay
                            btnLabel={t('Common_Unlock')}
                            showMasterLock={!masterPassword}
                            handleMasterUnlock={() => handleMasterUnlock()}
                        >
                            <ComputerConnectBtn
                                parentStyle={{}}
                                showPrefixIcon={false}
                                data={resource}
                                drawerView={false}
                                showCalenderIcon={false}
                            />
                        </MasterLockOverlay>
                    </Box>
                ) : (
                    <ComputerConnectBtn
                        parentStyle={{}}
                        showPrefixIcon={false}
                        data={resource}
                        drawerView={false}
                        showCalenderIcon={false}
                    />
                )}
            </>
        )
    }

    const renderButtons = () => {
        if (type === 'membership') {
            return (
                <>
                    {getActivateNowButton()}
                    {assignment?.assignmentDetails?.isRevokable && (
                        <RevokeButton
                            resourceType={resourceType}
                            item={{
                                resource: resource,
                                assignment: assignment,
                            }}
                            buttonStyles={{ minWidth: '72px' }}
                            postRevoke={() => {}}
                        />
                    )}
                    {getManageButton()}
                </>
            )
        } else if (type === 'preapprovedActivate') {
            return (
                <>
                    <Box className={classes.preapprovedActivatebtn}>
                        {getActivateNowButton()}
                    </Box>
                    {getManageButton()}
                </>
            )
        } else if (type === 'preapprovedConnect') {
            return (
                <>
                    {resource?.isPsmEnabled && (
                        <Box className={classes.preapprovedConnect}>
                            {getConnectButton()}
                        </Box>
                    )}
                    {getManageButton()}
                </>
            )
        } else if (type === 'request') {
            return (
                <Box className={classes.viewrequestbtn}>
                    <Button
                        onClick={() => handleViewRequest()}
                        endIcon={
                            <Icon
                                name="ArrowSmall"
                                color={theme?.palette?.primary?.main}
                                width="20px"
                                fill={theme?.palette?.primary?.main}
                                direction="right"
                            />
                        }
                        size={'medium'}
                    >
                        {t('Common_ViewRequest')}
                    </Button>
                </Box>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <Box className={classes.preapprovecard}>
                <Box className={classes.preapprovecardleft}>
                    <Box className={classes.preapprovedsection}>
                        <Box
                            className={classNames(classes.preapprovebtn, {
                                [classes.membershipbtn]: type === 'membership',
                                [classes.preApprovedbtn]:
                                    type === 'preapprovedConnect' ||
                                    type === 'preapprovedActivate',
                                [classes.requestbtn]: type === 'request',
                            })}
                        >
                            {accessTypeLabel}
                        </Box>
                        {showArrows ? (
                            <Box>
                                <Icon name="ArrowIconGreen" />
                            </Box>
                        ) : null}
                    </Box>
                    <Box className={classes.taskbadgesection}>
                        <Box className={classes.preapproveadmin}>
                            {accessName}
                        </Box>
                        {showCount && (
                            <span className={classes.taskbadge}>
                                {resource?.checkOutCount}
                            </span>
                        )}
                    </Box>
                </Box>
                <Box className={classes.preapprovecardright}>
                    {renderButtons()}
                </Box>
            </Box>
        </>
    )
}
