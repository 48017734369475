import React from 'react'
import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useAzureRole, useTargetPerson } from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import AzureAdminRoleDetails from './AzureAdminRoleDetails'

const AzureAdminRoleDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()

    const { data: azureAdminRole } = useAzureRole(id, targetPerson?.id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={azureAdminRole?.friendlyName} />
            {!azureAdminRole && <Loader />}
            {azureAdminRole && (
                <AzureAdminRoleDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    azureAdminRole={azureAdminRole}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            header={<ItemHeader title={azureAdminRole?.friendlyName} />}
            closeOnOutsideClick
        >
            {!azureAdminRole && <Loader />}
            {azureAdminRole && (
                <AzureAdminRoleDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    azureAdminRole={azureAdminRole}
                />
            )}
        </Drawer>
    )
}

export default AzureAdminRoleDrawer
