import React, { useEffect, useReducer, useState } from 'react'
import { useAccessRequestPolicies, useAddItemsToCart, useCart } from 'hooks'
import {
    Box,
    Checkbox,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { ItemDetails } from 'components'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Loader, Pagination, Tooltip } from 'packages/eid-ui'
import { isNilOrEmpty, useDebounce, useIsSmallScreen } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import { reducers } from './reducers'
import { StyledInput } from './StyledInput'
import { SuggestedRole } from './SuggestedRole'

const DesktopContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '32px',
    '& >button': {
        '& >div': { width: '185px !important' },
    },
})
const MobileContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '32px',
    '& >button': {
        '& >div': { width: '327px !important' },
    },
})

const useStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: theme?.palette?.background?.paper,
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        textTransform: 'uppercase',
        color: `${theme?.palette?.primary?.main} !important`,
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
}))

const take = 10

const SuggestedRoles = ({
    resource,
    differentiationValue,
    targetPerson,
    onDataLoaded,
    useDataHook,
    title,
}) => {
    const [{ referencePerson }] = useResourceTypeContext()

    const [selectedItems, dispatch] = useReducer(reducers, [])

    const classes = useStyles()
    const theme = useTheme()

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const { data: cart } = useCart()

    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const { latestData } = useDataHook(
        resource.id,
        targetPerson.id,
        referencePerson?.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
        differentiationValue?.id,
    )

    useEffect(() => {
        if (latestData && onDataLoaded) {
            onDataLoaded(latestData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestData])

    const isLoading = !Boolean(latestData)

    const data = latestData ? latestData.data : undefined

    const [addItemsToCart, { isLoading: isAddingItems }] = useAddItemsToCart()

    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    if (!cart || !requestAccessPolicies) {
        return <Loader />
    }

    const isItemAlreadyInCart = (id) =>
        Boolean(
            cart.cartItems.find((item) => item.requestableResourceId === id),
        )

    const error = selectedItems.some(
        (r) =>
            r.timeConstraintActive &&
            (isNilOrEmpty(r.endDateUtc) ||
                r.startDateUtc.isAfter(r.endDateUtc) ||
                (r.policy?.maximumValueInMinutes &&
                    r.endDateUtc?.isAfter(
                        r.startDateUtc
                            .clone()
                            .add(r.policy?.maximumValueInMinutes, 'minutes'),
                    ))),
    )

    const attributes = [
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <>{item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('Common_ResourceSystem'),
        },
    ]

    const proceedToCartButton = (
        <Tooltip
            title={
                selectedItems.length === 0
                    ? t('Common_NoItemSelected')
                    : error
                    ? t('Common_InvalidDatesForItems')
                    : ''
            }
        >
            <Box maxWidth="300px" minWidth="200px">
                <ItemDetails.ActionButton
                    width="fit-content"
                    minWidth="100%"
                    height="48px"
                    fontColor={theme?.palette?.common?.white}
                    bgColor={theme?.palette?.primary?.main}
                    disabled={selectedItems.length === 0 || error}
                    loading={isAddingItems}
                    onClick={() => {
                        addItemsToCart(selectedItems).then(() =>
                            dispatch({
                                type: 'REMOVE_ALL_ITEMS',
                                payload: filterItems(data, cart.cartItems).map(
                                    (d) => d.id,
                                ),
                            }),
                        )
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Icon
                            name="AddToCart"
                            color={theme?.palette?.common?.white}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="10px">
                        <Typography
                            style={{ color: theme?.palette?.common?.white }}
                        >
                            {t('Common_AddAllToCart', {
                                items: selectedItems.length,
                                count: selectedItems.length,
                            })}
                        </Typography>
                    </Box>
                </ItemDetails.ActionButton>
            </Box>
        </Tooltip>
    )

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    const rowSelection = true
    const selection = selectedItems.map((i) => i.requestableResourceId)
    const headings = attributes.map((a) => `${a.label}`)

    const isSelected = (id) => selection.indexOf(id) !== -1

    const isTimeConstrained = (item) =>
        selectedItems.find((i) => i.requestableResourceId === item.id)
            ?.timeConstraintActive ?? false

    const filterItems = (data, cartItems) => {
        const newItems = []
        for (let i = 0; i < data.length; i++) {
            const currentItem = data[i]
            const index = cartItems.findIndex(
                (item) => item.requestableResourceId === currentItem.id,
            )

            if (index === -1 && !currentItem.isAssigned) {
                newItems.push({
                    ...currentItem,
                    policy: requestAccessPolicies[currentItem.requestPolicyId],
                })
            }
        }
        return newItems
    }

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const getSelectedItem = (itemId) =>
        selectedItems.find((i) => i.requestableResourceId === itemId)

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="32px 32px 16px 32px"
            >
                <Typography
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginRight: '10px',
                        wordBreak: 'break-word',
                    }}
                >
                    {title}
                    {latestData ? (
                        <Box
                            component="span"
                            style={{
                                fontSize: '14px',
                                color: '#aab0b4',
                            }}
                            marginLeft="8px"
                        >
                            {latestData.totalCount}
                        </Box>
                    ) : (
                        ''
                    )}
                </Typography>

                <Box width="308px" display="flex" alignItems="center">
                    <StyledInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {data && rowSelection && (
                            <TableCell
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                padding="checkbox"
                            >
                                {filterItems(data, cart.cartItems).length >
                                    0 && (
                                    <Checkbox
                                        icon={<Icon name="CheckBox" />}
                                        checkedIcon={
                                            <Icon
                                                name="CheckedBox"
                                                color={
                                                    theme?.palette?.primary
                                                        ?.main
                                                }
                                            />
                                        }
                                        checked={
                                            data.length > 0 &&
                                            filterItems(
                                                data,
                                                cart.cartItems,
                                            ).every(
                                                (el) =>
                                                    selectedItems.findIndex(
                                                        (s) =>
                                                            s.requestableResourceId ===
                                                            el.id,
                                                    ) > -1,
                                            )
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                dispatch({
                                                    type: 'ADD_ALL_ITEMS',
                                                    payload: {
                                                        items: filterItems(
                                                            data,
                                                            cart.cartItems,
                                                        ),
                                                        targetPersonId:
                                                            targetPerson.id,
                                                    },
                                                })
                                            } else {
                                                dispatch({
                                                    type: 'REMOVE_ALL_ITEMS',
                                                    payload: filterItems(
                                                        data,
                                                        cart.cartItems,
                                                    ).map((d) => d.id),
                                                })
                                            }
                                        }}
                                    />
                                )}
                            </TableCell>
                        )}

                        {headings.map((heading, index) => (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={heading.align}
                                data-protectedsubcomponent={
                                    heading?.requireAccess?.control || ''
                                }
                            >
                                {heading}
                            </TableCell>
                        ))}
                        <TableCell
                            key="setduration"
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 2}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    className={classes.tableCell}
                                    colSpan={headings.length + 2}
                                    style={{ textAlign: 'center' }}
                                >
                                    <Trans i18nKey="Common_NoDataFound" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {data.map((item, index) => (
                                    <SuggestedRole
                                        key={`${item.id}${index}`}
                                        headings={headings}
                                        attributes={attributes}
                                        item={item}
                                        isTimeConstrained={isTimeConstrained}
                                        getSelectedItem={getSelectedItem}
                                        dispatch={dispatch}
                                        isItemAlreadyInCart={
                                            isItemAlreadyInCart
                                        }
                                        isSelected={isSelected}
                                        targetPerson={targetPerson}
                                    />
                                ))}
                            </>
                        ))}
                </TableBody>
            </Table>

            {isSmallScreen ? (
                <>
                    <Box
                        width="100%"
                        padding="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!isLoading && (
                            <Pagination
                                count={numberOfPages}
                                size="small"
                                page={page}
                                onChange={handlePageChange}
                                totalCount={latestData?.totalCount}
                                onPageSelection={handlePageSelection}
                                itemsPerPage={perPageItems}
                                onItemsPerPageChange={handleItemsPerPageChange}
                                showPageSelection={true}
                                showItemsPerPageSelection
                                showCountStat
                            />
                        )}
                    </Box>

                    <MobileContainer>{proceedToCartButton}</MobileContainer>
                </>
            ) : (
                <DesktopContainer
                    justifyContent={
                        data && numberOfPages > 1 ? 'space-between' : 'flex-end'
                    }
                >
                    {data && !isLoading && (
                        <Pagination
                            count={numberOfPages}
                            size="large"
                            page={page}
                            onChange={handlePageChange}
                            totalCount={latestData?.totalCount}
                            onPageSelection={handlePageSelection}
                            itemsPerPage={perPageItems}
                            onItemsPerPageChange={handleItemsPerPageChange}
                            showPageSelection={true}
                            showItemsPerPageSelection
                            showCountStat
                        />
                    )}

                    {proceedToCartButton}
                </DesktopContainer>
            )}
        </>
    )
}

export default SuggestedRoles
