import PropTypes from 'prop-types'
import { useTheme, Box, Typography, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { WithCloseButton, Divider } from 'packages/eid-ui'
import { Icon, InfoIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useAllApplications } from 'hooks'
import { getURLTarget, useUrlGenerator } from 'packages/core'
import { useHistory } from 'react-router-dom'
import ItemMessageDetailContainer from './ItemMessageContainer'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    titleMessage: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#1b1f1f',
    },
    infoMessageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '48px',
        paddingLeft: '48px',

        backgroundColor: theme?.palette?.common?.white,
        color: '#5d6870',
        display: 'flex',
        alignItems: 'center',

        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',

        borderRadius: '5px',
        border: 'solid 1px #ebebed',
    },
    listItemIndicator: {
        color: '#d8d8d8',
        marginRight: '20px',
    },
    iconContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '48px',

        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme?.palette?.grey?.[900],

        borderRadius: '5px 0px 0px 5px',
    },
}))

const statusColors = {
    Completed: 'green',
    WentForApproval: 'green',
    Error: 'red',
    Unknown: 'blue',
}

const Icons = {
    Completed: <Icon name="FilledCheck" color={statusColors['Completed']} />,
    WentForApproval: (
        <Icon name="FilledCheck" color={statusColors['WentForApproval']} />
    ),
    Error: <Icon name="Attention" color={statusColors['Error']} />,
    Unknown: (
        <Icon name="GeneralNotification" color={statusColors['Unknown']} />
    ),
}

const CartSubmissionMessage = React.forwardRef(
    ({ data, error, onClose, errorMessage }, ref) => {
        const { t } = useTranslation()
        const theme = useTheme()

        const classes = useStyles()

        const { getMyTasksUrl } = useUrlGenerator(useAllApplications)
        const history = useHistory()

        const CartItemStatusMessages = {
            Completed: t('HasBeenSubmittedSuccessfully'),
            WentForApproval: t('HasBeenSubmittedForApproval'),
            Error: t('HasNotBeenSubmittedSuccessfully'),
            Unknown: t('HasBeenSubmittedSuccessfully'),
        }
        const isValidBusinessRequestId = (id) =>
            id !== '00000000-0000-0000-0000-000000000000'
        if (error) {
            return (
                <WithCloseButton
                    ref={ref}
                    style={{
                        backgroundColor: theme?.palette?.background?.paper,
                        borderRadius: '5px',
                        boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
                        padding: theme.spacing(2, 4),
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                    onClose={onClose}
                    iconColor="#65686C"
                >
                    <Box display="flex">
                        <Box display="flex" marginX="8px">
                            {Icons['Error']}
                        </Box>
                        <Box display="flex" marginX="8px">
                            <Typography>{errorMessage}</Typography>
                        </Box>
                    </Box>
                </WithCloseButton>
            )
        }

        const myTasksBusinessRequestUrl = getMyTasksUrl(
            `/myRequests/businessRequests?id=${data.businessRequestId}`,
        )

        const myTasksUrl = getMyTasksUrl()
        const area = history.location.pathname

        return (
            <WithCloseButton
                ref={ref}
                style={{
                    backgroundColor: theme?.palette?.background?.paper,
                    borderRadius: '5px',
                    boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
                    padding: theme.spacing(3, 4),
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                onClose={onClose}
                iconColor="#65686C"
                iconPosition={{
                    right: 0,
                    top: '20px',
                }}
            >
                <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex">
                        <Typography className={classes.titleMessage}>
                            {t('Common_YourCartSubmissionStatus')}
                        </Typography>
                    </Box>
                    {data.businessRequestId &&
                        myTasksBusinessRequestUrl &&
                        isValidBusinessRequestId(data?.businessRequestId) && (
                            <Box marginTop="12px" display="flex">
                                <Box component="span">
                                    <Box component="span">
                                        {`${t('Common_CartTrackStatusHere')}: `}
                                    </Box>

                                    <Box component="span">
                                        <Link
                                            href={myTasksBusinessRequestUrl}
                                            target={getURLTarget()}
                                        >
                                            {data.businessRequestName}
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                    <Box width="100%" paddingY="13px">
                        <Divider />
                    </Box>

                    {data.itemResults.map((result) => {
                        return (
                            <ItemMessageDetailContainer
                                friendlyName={result.friendlyName}
                                titleMessage={
                                    CartItemStatusMessages[result.status]
                                }
                                icon={Icons[result.status]}
                                detailsMessage={result.message}
                            />
                        )
                    })}

                    {isValidBusinessRequestId(data?.businessRequestId) && (
                        <Box width="100%" paddingY="24px">
                            <Divider />
                        </Box>
                    )}

                    {isValidBusinessRequestId(data?.businessRequestId) && (
                        <Box className={classes.infoMessageContainer}>
                            <Box className={classes.iconContainer}>
                                <InfoIcon
                                    width="24"
                                    height="23"
                                    color={theme?.palette?.common?.white}
                                />
                            </Box>

                            {myTasksUrl && (
                                <Box mx="18px">
                                    {t('Common_CartTrackStatusOfAllRequests')}

                                    <Box component="span" marginX="3px">
                                        <Link
                                            href={myTasksUrl}
                                            target={getURLTarget()}
                                        >
                                            {t('Common_MyTasks')}
                                        </Link>
                                    </Box>

                                    <Box
                                        component="span"
                                        marginRight="3px"
                                        style={{ textTransform: 'lowercase' }}
                                    >
                                        {t('Common_CartFindApprovedRequests', {
                                            text: `${t('Common_And')}`,
                                        })}
                                    </Box>

                                    <Box component="span">
                                        <Link
                                            href={`${area}/manageAccess`}
                                            target={getURLTarget()}
                                        >
                                            {t('Common_ManageAccess')}.
                                        </Link>
                                    </Box>
                                </Box>
                            )}

                            {!myTasksUrl && (
                                <Box mx="18px">
                                    {t('Common_CartFindApprovedRequests')}

                                    <Box component="span" marginLeft="3px">
                                        <Link
                                            href={`${area}/manageAccess`}
                                            target={getURLTarget()}
                                        >
                                            {t('Common_ManageAccess')}.
                                        </Link>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </WithCloseButton>
        )
    },
)

CartSubmissionMessage.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default CartSubmissionMessage
