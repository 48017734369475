import React, { useState } from 'react'
import { Link } from '@mui/material'

const ArrayFormatter = ({
    items,
    showMax = items.length,
    style,
    label,
    keyProp,
    labelProp,
}) => {
    const [showPrompt, setShowPrompt] = useState(true)

    const shouldTruncate = items.length > showMax

    const isTruncated = shouldTruncate && showPrompt

    if (items === undefined || items === null || !items.length) {
        return (
            <div>
                <span
                    style={{
                        textTransform: 'capitalize',
                        color: '#b4b4b4',
                        paddingRight: '4px',
                        fontSize: '16px',
                    }}
                >
                    {label}:
                </span>
                <span style={{ color: '#b4b4b4' }}>-</span>
            </div>
        )
    }

    const renderEntries = (length) => {
        let content = []
        for (let i = 0; i <= length - 1; i++) {
            content.push(
                <React.Fragment key={items[i][keyProp]}>
                    {i !== 0 && (
                        <span style={{ ...style, textDecoration: 'none' }}>
                            ,{' '}
                        </span>
                    )}
                    <span
                        style={{
                            ...style,
                        }}
                    >
                        {items[i][labelProp]}
                    </span>
                </React.Fragment>,
            )
        }
        return content
    }

    return (
        <div style={{ height: 'fit-content' }}>
            <span
                style={{
                    color: '#b4b4b4',
                    textTransform: 'capitalize',
                    paddingRight: '4px',
                    fontSize: '16px',
                }}
            >
                {label}:
            </span>
            {renderEntries(isTruncated ? showMax : items.length)}

            {isTruncated && (
                <Link
                    component="button"
                    underline="none"
                    style={{ padding: '0px 4px' }}
                    onClick={() => setShowPrompt(false)}
                >
                    +{items.length - showMax} more
                </Link>
            )}
        </div>
    )
}

export default ArrayFormatter
