import React from 'react'
import { CardHeader, Typography, Box, useTheme } from '@mui/material'
import HeaderBgImage from './header-bg.png'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import config from 'config'
const useCardContentStyles = makeStyles((theme) => ({
    headingBG: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 0,
        right: 0,
        top: 0,
        borderRadius: '0.8rem',
    },
    Oval: {
        position: 'absolute',
        zIndex: 1,
        left: 5,
        width: '8.8rem',
        height: '8.8rem',
        boxShadow: '0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 0.1rem rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',
        backgroundColor: theme?.palette?.common?.white,
        padding: '2.4rem',
    },
    headingContentBox: (props) => ({
        position: 'relative',
        padding: '2rem',
        paddingTop: '1.8rem',
        paddingLeft: props.mobile ? '2rem' : '3.2rem',
        paddingRight: '1.6rem',
        minHeight: '9.5rem',
        width: '100%',
    }),
    heading: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        maxWidth: '69rem',
        letterSpacing: '0.0648rem',
    },
    image: {
        height: '3.7rem',
        width: '3.7rem',
    },
}))

const MyCardHeader = withStyles(() => ({
    root: {
        padding: 0,
        width: '100%',
        overflow: 'hidden',
        borderTopRightRadius: '0.8rem',
        borderTopLeftRadius: '0.8rem',
    },
    content: {
        width: '100%',
    },
    title: {
        fontSize: '2.4rem',
        fontWeight: '600',
    },
}))(CardHeader)

const tooltipStyles = {
    boxShadow: '0 0.2rem 1.6rem 0 rgba(0, 0, 0, 0.11)',
    padding: '0.8rem',
    maxWidth: '50rem',
}

const CustomCardHeader = ({ title, url, iconComponent, showIcon }) => {
    const isSmallScreen = useIsSmallScreen()
    const theme = useTheme()
    const contentClasses = useCardContentStyles()
    const maxCharacters = isSmallScreen ? 40 : 120
    let titleText = isNilOrEmpty(title) ? '' : title
    titleText =
        titleText.length > maxCharacters
            ? `${titleText.substring(0, maxCharacters)}...`
            : titleText

    const titleJsx = (
        <Typography
            className={contentClasses.heading}
            style={{
                fontWeight: 'bold',
                fontSize: '1.8rem',
                lineHeight: '3.8rem',
                color: theme?.palette?.common?.white,
                display: 'block',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                marginLeft: url || showIcon ? '8rem' : '0',
            }}
        >
            {titleText}
        </Typography>
    )

    let wrappedTitle

    if (titleText.length > maxCharacters) {
        wrappedTitle = (
            <Tooltip
                title={title}
                color="#5D6870"
                fontColor={theme?.palette?.common?.white}
                enterDelay={500}
                enterNextDelay={500}
                disableInteractive={false}
                tooltipStyles={tooltipStyles}
            >
                {titleJsx}
            </Tooltip>
        )
    } else {
        wrappedTitle = titleJsx
    }

    return (
        <MyCardHeader
            className={contentClasses.cardHeader}
            isSmallScreen={isSmallScreen}
            title={
                <Box
                    className={contentClasses.headingContentBox}
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    style={{
                        background:
                            'linear-gradient(104deg, #682a94, #05afe0 100%)',
                    }}
                >
                    {wrappedTitle}
                    {url && (
                        <Box className={contentClasses.Oval}>
                            <img
                                className={contentClasses.image}
                                src={url}
                                alt="header icon"
                            />
                        </Box>
                    )}
                    {showIcon && (
                        <Box
                            className={contentClasses.Oval}
                            style={{ padding: '3rem' }}
                        >
                            {iconComponent}
                        </Box>
                    )}

                    <img
                        className={contentClasses.headingBG}
                        src={`${config.APP_SUBPATH}${HeaderBgImage}`}
                        alt="background"
                    />
                </Box>
            }
        />
    )
}

export default CustomCardHeader
