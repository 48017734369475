import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreen } from 'packages/core'
import { Pagination } from 'packages/eid-ui'
import { FC } from 'react'

const useStyles = makeStyles({
    sessionpagination: {
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
        marginBottom: '30px',
        borderRadius: '5px',
        '& ul': {
            height: '40px',
            maxWidth: '420px',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            border: 'solid 1px #ebebed',
            overflow: 'hidden',
            borderRadius: '5px',
            '& li': {
                height: '40px',
                '& button': {
                    height: '40px',
                    minWidth: 'auto',
                    width: '40px',
                    borderRadius: '0',
                    margin: '0  !important',
                    borderRight: 'solid 1px #ebebed !important',
                    fontSize: '14px !important',
                },
            },
        },
    },
})

export interface ComputersPaginationProps {
    page: number
    pageCount: number
    handlePageChange: (e: any, value: any) => void
    handlePageSelection: (value: any) => void
    totalCount: number
    perPageItems: number
    handleItemsPerPageChange: (value: any) => void
}

export const ComputersPagination: FC<ComputersPaginationProps> = (props) => {
    const {
        page,
        handlePageChange,
        pageCount,
        handlePageSelection,
        totalCount,
        perPageItems,
        handleItemsPerPageChange,
    } = props
    const isSmallScreen = useIsSmallScreen()
    let minimumRowsPerPage
    if(perPageItems < 10 ){
        minimumRowsPerPage = perPageItems
    }
    const classes = useStyles()
    return (
        <Box className={classes.sessionpagination}>
            <Pagination
                count={pageCount}
                size={!isSmallScreen ? 'large' : 'small'}
                page={page}
                onChange={handlePageChange}
                onPageSelection={handlePageSelection}
                showPageSelection={true}
                minimumRowsPerPage={minimumRowsPerPage}
                showItemsPerPageSelection
                showCountStat
                totalCount={totalCount}
                itemsPerPage={perPageItems}
                onItemsPerPageChange={handleItemsPerPageChange}
            />
        </Box>
    )
}

export default ComputersPagination
