import { useState } from 'react'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { SubPathLevel } from 'appConfiguration'
import DropdownMenuItem from './MenuItem'

const DropdownMenu = ({ currentResourceType, totalCount }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const { resourceTypes } = useConfiguration()

    const [open, setOpen] = useState(false)

    const {
        location: { pathname },
    } = history

    const query = useQuery()
    query.delete('selected')

    let subPaths = pathname.split('/')

    const areaName =
        subPaths.length >= SubPathLevel + 2 ? subPaths[2 + SubPathLevel] : ''

    const applicationId = query.get('applicationId')
    const workflowName = query.get('workflowName')

    const resourceTypesToShow = resourceTypes

    const selectedRoute =
        areaName !== 'applications'
            ? resourceTypesToShow.filter(
                  (r) => r.name === currentResourceType.name,
              )[0]
            : { name: 'applications', title: 'Common_Applications' }

    return (
        <>
            <DropdownFilter
                label={t(
                    selectedRoute ? selectedRoute.title : 'Common_Applications',
                )}
                setOpen={setOpen}
                open={open}
                style={{
                    height: '60px',
                }}
                anchorPosition={{ top: '60px' }}
                totalCount={totalCount}
                showTotalCount={!applicationId && !workflowName}
            >
                {resourceTypesToShow.map((r, index) => (
                    <DropdownMenuItem key={`resource-${index}`} currentResourceType={currentResourceType} areaName={areaName} resource={r} setOpen={() => setOpen((prev) => !prev)} />
                ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }
