export const checkIfPasswordConditionsSatisfied = (
    condition,
    password,
    currentPerson,
) => {
    switch (condition.name) {
        case 'MinimumLength':
            return checkIfMinimumCharacters(password, condition.matchCount)
        case 'MaximumLength':
            return checkIfMaximumCharacters(password, condition.matchCount)
        case 'PreventDictionaryWords':
            return checkIfUnauthorizedWords(password, condition.arguments)
        case 'EnglishUppercaseLetters':
            return checkIfEnglishUpperCaseLetters(password)
        case 'EnglishLowercaseLetters':
            return checkIfEnglishLowerCaseLetters(password)
        case 'EnglishArabicNumerals':
            return checkIfEnglishArabicNumerals(password)
        case 'NonAlphanumericCharacters':
            return checkIfNonAlphanumericCharacters(password)
        case 'WindowsComplexity':
            return checkWindowsComplexityConditions(
                password,
                condition.conditions,
                currentPerson,
            )
        case 'RequiresMixedCase':
            return checkMixedCase(password)
        case 'LeadingLetter':
            return checkBeginsWithLetter(password)
        case 'PasswordMaximumPairsOfRepeatingCharacters':
            return checkMaxPairsOfRepeatingChars(password, condition.arguments)
        case 'RequireMainframeCompatibility':
            return checkCustomRegex(password, condition.pattern)
        case 'FirstXCharactersOfLogin':
            return checkFirstXCharactersOfLogin(
                password,
                condition.arguments,
                currentPerson,
            )
        case 'UserName':
            return checkUsernameExcluded(password, currentPerson)
        case 'CustomRegex':
            return checkCustomRegex(password, condition.pattern)
        default:
            // Log or handle unexpected condition name
            console.warn('Unexpected condition name:', condition.name)
            return true
    }
}

const checkCustomRegex = (password, pattern) => {
    let regex
    try {
        regex = new RegExp(pattern)
    } catch (e) {
        console.error('Invalid regex pattern:', e.message)
        return true
    }
    return regex.test(password)
}

const checkUsernameExcluded = (password, currentPerson) => {
    const userName = currentPerson?.login
    return password.length > 0 && !password.includes(userName)
}

const checkFirstXCharactersOfLogin = (
    password,
    noOfCharacters,
    currentPerson,
) => {
    const login = currentPerson?.login
    // Determine the effective number of characters to check, which is the smaller of 'noOfCharacters' or login.length
    const effectiveLength = Math.min(noOfCharacters, login.length)

    // Extract the characters to check against the password
    const charsToExclude = login.substring(0, effectiveLength).split('')

    // Check if the password contains any of these characters
    for (let char of charsToExclude) {
        if (password.includes(char)) {
            return false // If any character is found in the password, return false.
        }
    }

    return password.length > 0 // If none of the characters are found, the password is valid if its lenght is greater than 0.
}

const checkWindowsComplexityConditions = (
    password,
    subConditions,
    currentPerson,
) => {
    const subConditionsArray = []

    subConditions.map((condition) => {
        subConditionsArray.push(
            checkIfPasswordConditionsSatisfied(
                condition,
                password,
                currentPerson,
            ),
        )
    })

    const trueValuesCount = subConditionsArray.filter(
        (value) => value === true,
    ).length
    return trueValuesCount >= 3
}

const checkIfMinimumCharacters = (value, limit) => {
    return value.length >= limit
}

const checkIfMaximumCharacters = (value, limit) => {
    return value.length > 0 && value.length <= limit
}

const checkIfEnglishUpperCaseLetters = (str) => {
    if (str === null) {
        return false
    }
    const pattern = /[A-Z]/
    return pattern.test(str)
}

const checkIfEnglishLowerCaseLetters = (str) => {
    if (str === null) {
        return false
    }
    const pattern = /[a-z]/
    return pattern.test(str)
}

const checkIfEnglishArabicNumerals = (str) => {
    if (str === null) {
        return false
    }
    const pattern = /[0-9]/
    return pattern.test(str)
}

const checkIfNonAlphanumericCharacters = (str) => {
    if (str === null) {
        return false
    }
    // This pattern matches any character that is not a letter or a number.
    const pattern = /[^A-Za-z0-9]/
    return pattern.test(str)
}

const checkIfUnauthorizedWords = (
    str,
    unauthorizedWords = 'goodbye,hello,password',
) => {
    const unauthorizedWordsArray = unauthorizedWords.split(',')
    //TODO: Fetch unauthorized words from backend once ready
    if (str.length < 1) return false
    const regex = new RegExp(unauthorizedWordsArray.join('|'), 'i') // 'i' for case-insensitive matching
    return !regex.test(str)
}

const checkMixedCase = (str) => {
    const containsUppercase = /[A-Z]/.test(str)
    const containsLowercase = /[a-z]/.test(str)
    return containsUppercase && containsLowercase
}

const checkBeginsWithLetter = (str) => {
    return /^[A-Za-z]/.test(str)
}

const checkMaxPairsOfRepeatingChars = (str, value) => {
    if (str.length < 1) {
        //Return false if no password
        return false
    }
    const matches = str.match(/(.)\1/g) // Find repeating characters
    if (!matches) {
        return true // No repeating pairs found
    }
    return matches.length <= value // True if value or fewer pairs, false otherwise
}

export function countPasswordConditions(
    password,
    policyConditions,
    currentPerson,
) {
    let satisfiedCount = 0
    let totalCount = 0

    // Evaluate 'WindowsComplexity' with its special logic
    const checkWindowsComplexity = (subConditions) => {
        let subSatisfied = 0
        subConditions.forEach((condition) => {
            if (
                checkIfPasswordConditionsSatisfied(
                    condition,
                    password,
                    currentPerson,
                )
            ) {
                subSatisfied++
            }
        })
        // At least 3 of the 4 conditions must be satisfied
        return subSatisfied >= 3
    }

    // Main loop to evaluate conditions
    policyConditions.forEach((condition) => {
        if (condition.name === 'WindowsComplexity') {
            totalCount++ // Correctly increment totalCount here for the whole 'WindowsComplexity'
            if (checkWindowsComplexity(condition.conditions)) {
                satisfiedCount++
            }
        } else {
            totalCount++ // Increment total count for regular conditions
            if (
                checkIfPasswordConditionsSatisfied(
                    condition,
                    password,
                    currentPerson,
                )
            ) {
                satisfiedCount++
            }
        }
    })
    return { satisfiedCount, totalCount }
}
