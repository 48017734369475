import { usePaginatedQuery, useQuery } from 'react-query'
import {
    useApiGetWithInfiniteScroll,
    useAxios,
    useQuery as useBrowserQuery,
} from 'packages/core'
import { useCurrentPerson, useTargetPerson } from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import appConfig from 'config'

export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/${id}/`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplications = (enabled: boolean) => {
    const [targetPerson] = useTargetPerson()
    let queryParams = []

    const [state]: any = useResourceTypeContext()

    if (targetPerson) {
        queryParams.push(`targetPersonId=${targetPerson.id}`)
    }
    if (state.searchTerm) {
        queryParams.push(`searchTerm=${encodeURIComponent(state.searchTerm)}`)
    }

    let queryString = queryParams.join('&')
    if (state.sorting.sortOrder) {
        queryString = `${queryString}&sortBy=${state.sorting.sortBy}&desc=${state.sorting.sortOrder === 'desc'
            }`
    }

    return useApiGetWithInfiniteScroll(
        '/api/ProtectedAppResources',
        queryString,
        enabled,
    )
}

export const useResourceTypes = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'RESOURCE_TYPES', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ProtectedAppResources/resourceTypes/${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useHandleSelectApplication = () => {
    const query = useBrowserQuery()
    const history = useHistory()

    const [selectedApplication, setSelectedApplication] = useState<any>(null)

    const selectApplication = (application: any) => {
        setSelectedApplication(application)
    }

    useEffect(() => {
        if (selectedApplication) {
            let route
            query.set('selected', selectedApplication.id)
            route = '/applications'
            history.push(`${appConfig.APP_SUBPATH}${route}?${query.toString()}`)
            setSelectedApplication(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApplication])

    return {
        selectApplication,
    }
}

export const useApplicationOwners = (applicationId: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS', applicationId],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/resourceOwners?resourceId=${applicationId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(applicationId),
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/ProtectedAppResources/ownersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckApplicationsAccess = (
    id: string | null,
    referencePersonId: string | null,
    options: any,
) => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()

    const data = {
        targetPersonId: targetPerson.id,
        id: id,
        allowAppManagementRoles: Boolean(
            options?.allowAppManagementRoleDirectAssignment,
        ),
        allowAppRoles: Boolean(options?.allowAppRoleDirectAssignment),
        allowRoleDefinitions: Boolean(
            options?.allowRoleDefinitionDirectAssignment,
        ),
        referencePersonId: referencePersonId,
    }

    return useQuery(
        [`${APPLICATIONS_KEY}_ACCESS`, id, targetPerson.id],
        () =>
            callApi({
                method: 'POST',
                url: `/api/ProtectedAppResources/GetSingleRequestDetails`,
                data,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(options),
        },
    )
}

export const useApplicationAppRights = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_APP_RIGHTS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/LocalRights/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` +
                    (applicationId ? `&applicationId=${applicationId}` : ''),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}

export const useApplicationAppRight = (id: string, targetPersonId: string) => {
    const callApi = useAxios()

    return useQuery(
        [`${APPLICATIONS_KEY}_APP_RIGHT`, id],
        () =>
            callApi({
                method: 'GET',
                url: `api/LocalRights/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationAppRightByAssigneeId = (assigneeId: string) => {
    const callApi = useAxios()

    return useQuery(
        [`${APPLICATIONS_KEY}_APP_RIGHT`, assigneeId],
        () =>
            callApi({
                method: 'GET',
                url: `api/LocalRights/localRightsByAssigneeId/${assigneeId}/`,
            }).then((data) => data.data),
        {
            enabled: Boolean(assigneeId),
        },
    )
}

export const useApplicationRoleDefinitions = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_ROLE_DEFINITIONS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/azureroles/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` +
                    (applicationId ? `&applicationId=${applicationId}` : ''),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}

export const useApplicationManagementRoleAssignments = (
    applicationId: string | null,
    targetPersonId: string,
    skip: number,
    take: number = 3,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${APPLICATIONS_KEY}_MANAGEMENT_ROLE_ASSIGNMENTS_LIST`,
            applicationId,
            targetPersonId,
            skip,
            take,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/ManagementRoles/application/assigned?targetPersonId=${targetPersonId}&skip=${skip}&take=${take}` +
                    (applicationId ? `&applicationId=${applicationId}` : ''),
            }),
        {
            enabled: Boolean(targetPersonId),
        },
    )
}
export const useApplicationLocalFunctionsByPermissionLevel = (
    id: any,
    isAzureRole: boolean,
    skip: number,
    take = 10,
    search: any,
) => {
    const callApi = useAxios()

    const url = isAzureRole
        ? 'api/BusinessFunctions/localFunctionsByAzureRole'
        : 'api/BusinessFunctions/localFunctions'
    return usePaginatedQuery(
        [`${APPLICATIONS_KEY}_LOCAL_FUNCTIONS`, id, skip, take, search],
        () =>
            callApi({
                method: 'GET',
                url:
                    `${url}?${isAzureRole ? 'azureRoleId' : 'roleId'
                    }=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationFieldTypes = (
    scopeId: any,
    roleId: any,
    rightId: any,
    isEnabled = true,
) => {
    const callApi = useAxios()
    const [targetPerson] = useTargetPerson()
    const urlRole = `api/azureroles/fieldtypevaluesjsontemplate`
    const urlRight = `api/LocalRights/apprights/fieldtypevaluesjsontemplate`
    let dataObject: any = {
        AssigneeId: targetPerson.id,
    }
    if (roleId) {
        dataObject.AssigneeLocalRoleScopeId = scopeId
        dataObject.LocalRoleId = roleId
    }
    if (rightId) {
        dataObject.AssigneeLocalRightScopeId = scopeId
        dataObject.LocalRightId = rightId
    }
    return useQuery(
        [
            APPLICATIONS_KEY,
            'DYNAMIC_FIELD_TYPES',
            roleId,
            rightId,
            targetPerson.id,
        ],
        () =>
            callApi({
                method: 'POST',
                url: roleId ? urlRole : urlRight,
                data: dataObject,
            }).then((data) => data.data),
        {
            enabled: (Boolean(roleId) || Boolean(rightId)) && isEnabled,
            cacheTime: 0
        },
    )
}

export const useApplicationFieldTypeSourceValues = (
    fieldType: any,
    skip: any,
    take: any,
    searchTerm: any,
    sorting?: any
) => {
    const [targetPerson] = useTargetPerson()
    const queryData: any = {
        skip: skip || 0,
        take: take || 1000,
        searchTerm,
        FieldTypeName: fieldType?.Name,
        DataType: fieldType?.DataType,
        FieldMethodName: fieldType?.FieldMethodName,
        FieldParametersJson: fieldType?.FieldParameters,
        AssigneeId: targetPerson.id,
    }

    if (sorting?.sortOrder) {
        queryData.sortBy = sorting.sortBy
        queryData.desc = sorting.sortOrder === 'desc'
    }

    const callApi = useAxios()
    return usePaginatedQuery(
        [APPLICATIONS_KEY, 'FIELD_TYPE_SOURCE_VALUES', queryData],
        () =>
            callApi({
                method: 'POST',
                url: `api/fieldType/getfieldTypeValueDynamicJson`,
                data: queryData,
            }),
        {
            enabled: Boolean(
                fieldType?.DataType &&
                fieldType?.Name &&
                fieldType?.FieldMethodName &&
                fieldType?.FieldParameters &&
                targetPerson.id,
            ),
        },
    )
}

export const useApplicatiponAzureRole = (
    id: string,
    targetPersonId: string,
) => {
    const callApi = useAxios()

    let url = `api/azureroles/byLocalRoleScopeId/${id}/${targetPersonId}`

    return useQuery(
        [APPLICATIONS_KEY, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}
