import { Fragment } from 'react'
import { Box, styled } from '@mui/material'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails, PeopleList } from 'components'
import { useCredentialOwners } from 'hooks'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const hasAccessToOwnerControl = 'ITShop-Credentials-Details-Owners-Control';

export const MoreInformationSection = ({ credential }: any) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useCredentialOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? credential.id : null,
    )

    return (
        <Fragment>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && <ItemDetails.AttributesContainer bottomPadding="0">
                        <PaddedDiv data-protectedsubcomponent={hasAccessToOwnerControl}>
                            <ItemDetails.Attribute
                                label={t('Common_Owners')}
                                value={<PeopleList list={owners} />}
                                orientation={attributeOrientation}
                            />
                        </PaddedDiv>
                    </ItemDetails.AttributesContainer>}
                </>
            )}
        </Fragment>
    )
}
