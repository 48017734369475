import React from 'react'
import { Box, Typography } from '@mui/material'
import { Avatar } from 'packages/eid-ui'

const nameStyle = {
    fontSize: '1.4rem',
    color: '#5d6870',
    padding: '1rem 0rem'
}

const Person = ({ imgSrc, name }) => (
    <Box display="flex" alignItems="center" overflow='hidden' >
        <Box component="span" padding="1rem 1rem" >
            <Avatar src={imgSrc} /> 
        </Box>
        <Typography style={nameStyle}>{name}</Typography>
    </Box>
)

export default Person
