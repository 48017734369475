import React from 'react'
import { Box, styled, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useIsSmallScreen } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import makeStyles from '@mui/styles/makeStyles';

const BaseContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    borderRadius: '5px',
    backgroundColor: theme?.palette?.background?.paper,
    position: 'relative',
}))

const DesktopContainer = styled(BaseContainer)(({ styles }) => ({
    flexWrap: 'wrap',
    padding: '0px 20px 8px 20px',
    ...styles,
}))

const MobileContainer = styled(BaseContainer)({
    flexDirection: 'column',
})

const flexCenterStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const useStyles = makeStyles((theme) =>
    createStyles({
        policyViolationMessage: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '12px',
            color: theme?.palette?.error?.main,
            marginRight: '12px',
        },
    }),
)

const TimeDurationSelector = (props) => {
    const classes = useStyles()
    const theme = useTheme()

    const {
        startDateTimeSelector,
        endDateTimeSelector,
        rootStyles,
        validationMessage,
    } = props

    const isSmallScreen = useIsSmallScreen()

    return isSmallScreen ? (
        <MobileContainer>
            <Box display="flex" alignItems="flex-end">
                <Icon
                    style={{ margin: '12px 8px' }}
                    name="StartTime"
                    color={theme?.palette?.primary?.main}
                />
                {startDateTimeSelector}
            </Box>

            <Box display="flex" alignItems="flex-end">
                <Icon
                    style={{ margin: '12px 8px' }}
                    name="EndTime"
                    color={theme?.palette?.primary?.main}
                />
                {endDateTimeSelector}
            </Box>
        </MobileContainer>
    ) : (
        <DesktopContainer styles={rootStyles}>
            <Box display="flex" alignItems="flex-end">
                <Icon
                    style={{ margin: '12px 8px' }}
                    name="StartTime"
                    color={theme?.palette?.primary?.main}
                />
                {startDateTimeSelector}
            </Box>

            <Box
                width="80px"
                height="100%"
                style={{ ...flexCenterStyles, margin: '0px 30px' }}
            >
                <Icon name="ArrowSeperator" color="#5D6870" />
            </Box>

            <Box display="flex" alignItems="flex-end">
                <Icon
                    style={{ margin: '12px 8px' }}
                    name="EndTime"
                    color={theme?.palette?.primary?.main}
                />
                {endDateTimeSelector}
            </Box>
            <Box className={classes.policyViolationMessage}>
                {validationMessage}
            </Box>
        </DesktopContainer>
    )
}

export default TimeDurationSelector
