import React, { useEffect } from 'react'
import {
    isNilOrEmpty,
    useAuthState,
    useQuery,
    useSwrApiGet,
} from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useAccessSubcomponents,
    useAllApplications,
    useCurrentPerson,
    useTargetPerson,
    useTranslations,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18next'

const useLoadCurrentPerson = () => {
    const { data } = useCurrentPerson()

    return !isNilOrEmpty(data)
}

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data.data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadAllApplications = () => {
    const { data: applications } = useAllApplications()

    return !isNilOrEmpty(applications)
}

const useLoadTranslations = () => {
    const RESOURCE_SET_NAME = 'ITShop'

    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()

    const locale =
        currentPerson && currentPerson.language?.languageCode
            ? currentPerson.language?.languageCode
            : 'en'
    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                let keyToUse = r.key
                const parts = r.key.split('_')
                if (parts[0] === RESOURCE_SET_NAME) {
                    keyToUse = r.key.substring(RESOURCE_SET_NAME.length + 1)
                }

                if (debugLocalization) {
                    current[keyToUse] = r.key
                } else {
                    current[keyToUse] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(data)
}

const useSetTargetPerson = () => {
    const [{ currentUserId }] = useAuthState()

    const [targetPerson, setTargetPerson, { getSavedTargetPersonId }] =
        useTargetPerson()

    const savedTargetPersonId = getSavedTargetPersonId()

    const targetPersonId = savedTargetPersonId
        ? savedTargetPersonId
        : currentUserId

    const { data } = useSwrApiGet(() => {
        return `/api/people?personId=${targetPersonId}`
    })

    useEffect(() => {
        if (data) {
            setTargetPerson(data.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return targetPerson !== null
}

const withRequiredData = (ChildComponent) => (props) => {
    const currentPersonLoaded = useLoadCurrentPerson()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const allApplicationsLoaded = useLoadAllApplications()
    const translationsLoaded = useLoadTranslations()
    const setTargetPerson = useSetTargetPerson()

    if (
        !currentPersonLoaded ||
        !accessSubcomponentsLoaded ||
        !allApplicationsLoaded ||
        !translationsLoaded ||
        !setTargetPerson
    ) {
        return <Loader />
    }

    return <ChildComponent {...props} />
}

export default withRequiredData
