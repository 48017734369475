import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useCheckComputerAccessByAssignmentId,
    useComputer,
    useTargetPerson,
} from 'hooks'
import ComputerDetails from './MembershipBasesAccessDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const ComputerMembershipBasedAccessDrawer = ({
    id,
    attributes,
    toggleDrawer,
    ...rest
}) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data: assignmentStatus } = useCheckComputerAccessByAssignmentId(
        id,
        targetPerson.id,
    )

    const { data: computer } = useComputer(id, targetPerson.id, true)

    let item
    if (computer && assignmentStatus) {
        item = {
            resource: computer,
            assignment: assignmentStatus,
        }
    }

    const itemDetails = <ComputerDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default ComputerMembershipBasedAccessDrawer
