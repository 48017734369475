import { useState } from 'react'
import { Box } from '@mui/material';
import { makeStyles, createStyles, styled } from '@mui/styles' 
import { useTranslation } from 'react-i18next'
import { useCredentialOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen, useQuery } from 'packages/core'
import WorkflowActions from 'components/WorkflowActions'
import LockedPasswordBox from 'components/DynamicComponents/LockedPasswordBox'
import { Icon } from 'packages/eid-icons'
import CheckOutButton from 'components/DynamicComponents/CheckOutButton'
import CheckInButton from 'components/DynamicComponents/CheckInButton'
import { RemainingTime } from 'components/DynamicComponents/CredentialRemainingTime'
import { Person } from 'components/ApplicationBanner/PersonDetails'
import moment from 'moment'
import { MasterPasswordLock } from 'components'
import { useAppState } from 'appContext'
import { CommonAttributes } from '../../CommonAttributes'
import CredentialCheckouts from 'components/CredentialCheckouts'
import useSubcomponents from 'useSubcomponents'

const hasAccessToOwnerControl =
    'ITShop-Credentials-ManageAccess-ManageCredentials-Details-Owners-Control'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingTop: '25px',
            padding: '20px 24px',
        },
        sectionHeader: {
            overflowWrap: 'break-word',
            wordBreak: 'normal',
            textTransform: 'uppercase',
            color: `${theme?.palette?.background?.paper} !important`,
            overflow: 'hidden',
            lineHeight: '15px',
            fontSize: '16px',
            padding: '0px 10px 20px 0px',
            fontWeight: 'bold',
            backgroundImage:
                'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
            backgroundSize: '8.00px 8.00px',
            '& svg': {
                margin: '0px !important',
            },
        },
    }),
)

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    maxWidth: 'fit-content',
}

const OverviewSection = ({ item }) => {
    const [{ masterPassword }] = useAppState()
    const credential = item.resource

    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const hiddenPropsBaseUrl = '/api/credentials/hiddenProperties'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={credential.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('CredentialsType')}
                    value={credential.typeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <div style={{ position: 'relative' }}>
                    {!masterPassword && (
                        <MasterPasswordLock
                            style={{
                                height: '100%',
                                width: '110%',
                                marginLeft: '-35px',
                            }}
                        />
                    )}
                    <div>
                        <ItemDetails.Attribute
                            label={t('Credentials_Username')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={credential.canCheckIn}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="userName"
                                    showIcon={true}
                                    isDetailed={true}
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />

                        <ItemDetails.Attribute
                            label={t('Password')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={credential.canCheckIn}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="password"
                                    showIcon={true}
                                    isDetailed={true}
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />

                        <ItemDetails.Attribute
                            label={t('Notes')}
                            value={
                                <LockedPasswordBox
                                    targetUrl={`${hiddenPropsBaseUrl}/${credential.id}`}
                                    isClickable={credential.canCheckIn}
                                    validationMessage={t(
                                        'CredentialCheckoutValidationMessage',
                                    )}
                                    targetProperty="notes"
                                    showIcon={true}
                                    isDetailed={true}
                                />
                            }
                            orientation={attributeOrientation}
                            valueProps={overflowStyleProps}
                        />
                    </div>
                </div>
                <ItemDetails.Attribute
                    label={t('Common_LogonName')}
                    value={credential.checkedOutByLogin}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={credential.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckedOutBy')}
                    value={
                        credential.currentCheckOutPersonId && (
                            <Person
                                personId={credential.currentCheckOutPersonId}
                            />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AllowMultipleCheckout')}
                    value={
                        credential.allowMultiCheckOut
                            ? t('Common_True')
                            : t('Common_False')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckoutTime')}
                    value={
                        credential?.checkOutStartTimeUtc &&
                        moment
                            .utc(credential?.checkOutStartTimeUtc)
                            .clone()
                            .local()
                            .format('L LT')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('RemainingTime')}
                    value={
                        <RemainingTime
                            endTime={credential?.checkOutEndTimeUtc}
                        />
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AccessType')}
                    value={
                        credential.isPreApproved
                            ? t('CredentialPreApprovedAccess')
                            : t('CredentialOneTimeAccess')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialsType')}
                    value={credential.typeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialsCategory')}
                    value={credential.categoryName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={credential.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <CommonAttributes item={item} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const credential = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useCredentialOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? credential.id : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}

export const ActionsSection = (props) => {
    const classes = useStyles()

    return (
        <>
            <PromptContainer>
                {props.iconName ? (
                    <Box width="172px" display="flex" flexDirection="column">
                        <Box className={classes.sectionHeader}>
                            {props.sectionLabel}
                        </Box>
                        <Icon
                            name={props.iconName}
                            height="150px"
                            width="150px"
                            color="#D2D2D9"
                        />
                    </Box>
                ) : (
                    <></>
                )}
                <Box width="auto" paddingLeft="16px">
                    {props.children}
                </Box>
            </PromptContainer>
        </>
    )
}

const ManageCredentialDetails = ({ item }) => {
    const { t } = useTranslation()
    const [{ masterPassword }] = useAppState()
    const query = useQuery()
    const showCheckoutsDetails = query.get('showCheckouts')
    const [activeTab, setActiveTab] = useState(
        showCheckoutsDetails ? 'checkouts' : 'overview',
    )

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="checkouts"
                    label={t('Checkouts')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            {activeTab === 'checkouts' && (
                <CredentialCheckouts item={item.resource} />
            )}

            <div style={{ position: 'relative' }}>
                {!item?.resource?.isExpired && (
                    <>
                        {!masterPassword && (
                            <MasterPasswordLock
                                style={{ height: '100%', width: '100%' }}
                            />
                        )}
                        <ActionsSection
                            sectionLabel={t('AvailableActions')}
                            iconName="SettingsDouble"
                        >
                            <WorkflowActions
                                resource={item.resource}
                                route={'credentials'}
                            />
                        </ActionsSection>
                    </>
                )}
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent="flex-start"
                    style={{ padding: '20px 20px 10px 20px' }}
                >
                    {item?.resource?.canCheckOut && (
                        <CheckOutButton data={item} drawerView={true} />
                    )}
                    {item?.resource?.canCheckIn && (
                        <CheckInButton data={item} drawerView={true} />
                    )}
                </Box>
            </div>
        </>
    )
}

export default ManageCredentialDetails
