import { FC, useState } from 'react'
import { Box, Collapse } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { useAzureApplicationOwnersAndDeputies } from 'hooks'
import { Skeleton } from '@mui/material';
import { PersonDetails } from './PersonDetails'
import { Pagination } from 'packages/eid-ui'
import classNames from 'classnames'
import { paginate } from 'utils'

const pageSize = 4

export type AzureApplicationDeputiesProps = {
    className?: any
    buttonPosition?: 'right' | 'bottom'
    data: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: { position: 'relative' },

        buttonPositionRightTop: {},

        showMoreButton: {
            paddingTop: theme.spacing(1.5),
            '& > button': {
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
                color: theme?.palette?.primary?.main,
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },

        content: {
            display: 'flex',
        },

        defaultPerson: {},
        disableDefaultPerson: {
            opacity: 0.4,
        },

        ownersContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '150px',
            overflow: 'auto',
            padding: '5px 4px',
        },
        collapsibleContent: {
            position: 'relative',
            backgroundColor: theme?.palette?.grey?.[100],
            borderRadius: '8px',
            border: 'solid 1px #f9f9f9',
            backdropFilter: 'blur(2px)',
        },

        personContainer: {
            padding: '0px 12px',
            borderRadius: '8px',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme?.palette?.background?.paper,
                '& p': {
                    color: theme?.palette?.common?.black,
                    fontWeight: 'bold',
                },
            },
        },
    }),
)

export const AzureApplicationDeputies: FC<AzureApplicationDeputiesProps> = (
    props,
) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const { data: ownersAndDeputies, isLoading } =
        useAzureApplicationOwnersAndDeputies(props.data.id)

    const data = ownersAndDeputies && ownersAndDeputies.deputies
    const [page, setPage] = useState(1)

    const paginatedData = data && paginate(data, page, pageSize)

    const numberOfPages = data ? Math.ceil((data.length - 1) / pageSize) : 0

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    if (isLoading) {
        return (
            <Box paddingRight={4}>
                <Skeleton height={40} />
            </Box>
        )
    } else {
        if (data.length === 0) {
            return <Box paddingLeft={'4px'}>-</Box>
        }

        const deputy = data.length > 0 && data[0]

        const showMoreCount = data.length - 1

        return (
            <Box className={classNames(classes.root, props.className)}>
                {data && deputy && (
                    <Box
                        className={classNames(classes.defaultPerson, {
                            [classes.disableDefaultPerson]: checked,
                        })}
                    >
                        <PersonDetails size="medium" person={deputy} />
                    </Box>
                )}

                {checked && (
                    <Collapse in={checked} collapsedSize={0}>
                        <Box className={classes.collapsibleContent}>
                            <Box className={classes.ownersContainer}>
                                {data &&
                                    paginatedData.map((o: any, index: any) => (
                                        <Box
                                            className={classes.personContainer}
                                        >
                                            <PersonDetails
                                                key={o.id + index}
                                                size="small"
                                                person={o}
                                            />
                                        </Box>
                                    ))}
                            </Box>
                            {checked && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width="100%"
                                    justifyContent="center"
                                    padding="5px 11px"
                                >
                                    <Pagination
                                        count={numberOfPages}
                                        size="small"
                                        siblingCount={0}
                                        page={page}
                                        onChange={handlePageChange}
                                        variant={'rounded'}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Collapse>
                )}

                {data && data.length > 1 && (
                    <Box
                        className={classNames(classes.showMoreButton, {
                            [classes.buttonPositionRightTop]:
                                props.buttonPosition === 'right',
                        })}
                    >
                        <button onClick={handleChange}>
                            {checked
                                ? `- ${t('Common_ShowLess')}`
                                : `+ ${showMoreCount} ${t('Common_More')}`}
                        </button>
                    </Box>
                )}
            </Box>
        );
    }
}
