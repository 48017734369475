import applicationRoles from './applicationRoles'
import azureLicenses from './azureLicenses'
import azureRoles from './azureRoles'
import businessRoles from './businessRoles'
import mailboxes from './mailboxes'
import manageAccess from './manageAccess'
import managementRoles from './managementRoles'
import sharedFolders from './sharedFolders'
import applications from './applications'
import computers from './computers'
import credentials from './credentials'
import config from 'config'
import sharepoint from './sharepoint'

export const SubPathLevel = config.APP_SUBPATH
    ? config.APP_SUBPATH.split('/').length - 1
    : 0

export const AREANAME_URL_INDEX = 2
export const RESOURCE_URL_INDEX = 1

const appConfiguration = {
    manageAccess: manageAccess,
    resourceTypes: [
        applicationRoles,
        azureLicenses,
        azureRoles,
        businessRoles,
        mailboxes,
        managementRoles,
        sharedFolders,
        applications,
        computers,
        credentials,
        sharepoint,
    ],
}

export default appConfiguration
