import React from 'react'
import { ItemDetails, PeopleList } from 'components'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CommonAttributes } from '../CommonAttributes'
import useSubcomponents from 'useSubcomponents'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const hasAccessToOwnerControl =
    'ITShop-ManagementRoles-Details-Overview-Owners-Control'

export const OverviewSection = ({ managementRole }: any) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const parsedHtml = ReactHtmlParser(
        DomPurify.sanitize(managementRole?.instructions),
    )

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={managementRole.name}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_Type')}
                    value={managementRole.typeFriendlyName}
                    orientation={attributeOrientation}
                />

                {managementRole.owners && (
                    <>
                        {hasAccessToControl(hasAccessToOwnerControl) && (
                            <ItemDetails.Attribute
                                label={t('ManagementRoles_Owners')}
                                value={
                                    <PeopleList list={managementRole.owners} />
                                }
                                orientation={attributeOrientation}
                            />
                        )}
                    </>
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={managementRole.localizedDescription}
                    orientation={attributeOrientation}
                />

                <ItemDetails.Attribute
                    label={t('Common_IsHighSecurity')}
                    value={
                        isNilOrEmpty(managementRole.isHighSecurity)
                            ? '-'
                            : managementRole.isHighSecurity
                            ? t('Common_Yes')
                            : t('Common_No')
                    }
                    orientation={attributeOrientation}
                />

                <CommonAttributes resource={managementRole} />
                {parsedHtml && parsedHtml.length > 0 && (
                    <InfoBoxWithIcon
                        iconName={'InfoNew'}
                        message={parsedHtml}
                        rootStyles={{
                            width: '100%',
                            marginTop: '2.8rem',
                            marginBottom: '1.8rem',
                        }}
                    />
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
