import React from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    useCurrentPerson,
    useTargetPerson,
    useUpdateDefaultFilters,
} from 'hooks'
import useConfiguration from 'useConfiguration'
import { Icon } from 'packages/eid-icons'
import { useAppState } from 'appContext'
import GenericButton from 'components/Button/GenericButton'

const DeleteFilters = ({ resourceType, showIcon }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { data: currentPerson } = useCurrentPerson()
    // eslint-disable-next-line
    const [_, dispatchAppState] = useAppState()
    const [{ savedFilters }] = useAppState()
    const [targetPerson] = useTargetPerson()

    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const [saveFilters, { isLoading }] = useUpdateDefaultFilters()

    const deleteAllFilters = () => {
        saveFilters({ resourceType: currentResourceType.name })
        try {
            if (savedFilters) {
                const selectedFilter = {
                    ...savedFilters,
                    [currentResourceType.name]: {
                        ...savedFilters[currentResourceType.name],
                        filters: {},
                    },
                }
                dispatchAppState({
                    type: 'SAVE_FILETRS',
                    payload: selectedFilter,
                })
            }
        } catch (err) {}
    }

    const tooltipTitle = shoppingForSomeoneElse
        ? t('Common_DeleteFiltersDisabledForSomeoneElseHelpText')
        : t('Common_DeleteSavedFiltersHelpText')

    return (
        <Box title={tooltipTitle}>
            <GenericButton
                onClick={shoppingForSomeoneElse ? undefined : deleteAllFilters}
                hoverColor={'rgba(255, 255, 255, 0.5)'}
                label={t('Common_DeleteFilters')}
                loading={isLoading}
                color={
                    shoppingForSomeoneElse
                        ? '#b4b4b4'
                        : theme?.palette?.common?.black
                }
                loaderColor={theme?.palette?.secondary?.main}
                rootStylesProp={{
                    color: shoppingForSomeoneElse
                        ? '#b4b4b4'
                        : theme?.palette?.common?.black,
                    cursor: shoppingForSomeoneElse ? 'default' : 'pointer',
                    opacity: shoppingForSomeoneElse ? 0.7 : 1,
                    height: '40px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    minWidth: '110px',
                    padding: '6px 4px',
                    maxWidth: '30%',
                }}
                startIcon={showIcon && <Icon color="#3e3e3e" name="Delete" />}
            />
        </Box>
    )
}

export default DeleteFilters
