import ApplicationRoleDrawer from './Details/ApplicationRoles'
import BusinessRoleDrawer from './Details/BusinessRoles'
import AzureLicenseDrawer from './Details/AzureLicenses'
import AzureRolesDrawer from './Details/AzureRoles'
import ManagementRoleDrawer from './Details/ManagementRoles'
import SharedFolderDrawer from './Details/SharedFolders'
import MailBoxesDrawer from './Details/Mailboxes'
import ComputerLoginHistoryAccessDrawer from './Details/Computers/LoginHistoryAccessDrawer'
import ComputerMembershipBasedAccessDrawer from './Details/Computers/MembershipBasedAccessDrawer'
import { useQuery } from 'packages/core'
import CredentialHistoryDrawer from './Details/Credentials/CredentialHistoryDrawer'
import ManageCredentialDrawer from './Details/Credentials/ManageCredentialDrawer'
import AppManagementRolesDrawer from './Details/Applications/AppManagementRolesDrawer'
import AppRoleDefinitionsDrawer from './Details/Applications/AppRoleDefinitionsDrawer'
import AppRightsDrawer from './Details/Applications/AppRightsDrawer'
import ComputerLoginSessionAccessDrawer from './Details/Computers/LoginSessionAccessDrawer'

const DetailsDrawer = ({ type, ...rest }) => {
    const query = useQuery()
    const selectedTab = query.get('tab')

    const getDetailedDrawer = () => {
        switch (type) {
            case 'ApplicationRoles':
                return ApplicationRoleDrawer
            case 'BusinessRoles':
                return BusinessRoleDrawer
            case 'AzureLicenses':
                return AzureLicenseDrawer
            case 'AzureRoles':
                return AzureRolesDrawer
            case 'ManagementRoles':
                return ManagementRoleDrawer
            case 'SharedFolders':
                return SharedFolderDrawer
            case 'Mailboxes':
                return MailBoxesDrawer
            case 'Computers':
                if (selectedTab === 'history') {
                    return ComputerLoginHistoryAccessDrawer
                } else if (selectedTab === 'loginsession') {
                    return ComputerLoginSessionAccessDrawer
                } else {
                    return ComputerMembershipBasedAccessDrawer
                }
            case 'Applications':
                if (selectedTab === 'appRights') {
                    return AppRightsDrawer
                } else if (selectedTab === 'roleDefinitions') {
                    return AppRoleDefinitionsDrawer
                } else {
                    return AppManagementRolesDrawer
                }
            case 'Credentials':
                if (selectedTab === 'credentials') {
                    return ManageCredentialDrawer
                } else {
                    return CredentialHistoryDrawer
                }
            default:
                return <></>
        }
    }

    let DetailComponent = getDetailedDrawer()
    return <DetailComponent {...rest} />
}
export default DetailsDrawer
