import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tooltip, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment'
import {
    useCurrentPerson,
    useAccessRequestPolicy,
    useComputerBookNow,
    useComputerCheckIn,
    refreshComputerList,
    useCheckIfMasterPasswordExist,
    useCheckAccessToCheckInOperation,
} from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { ConnectAdvanced } from 'components/ConnectAdvanced'
import appConfig from 'config'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { useNotification } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    connectbtndiv: {
        display: 'flex',
        alignItems: 'center',
    },
    taskicon: {
        width: '24px',
        height: '24px',
        background: theme?.palette?.primary?.main,
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        borderRadius: '5px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px',
    },
    connectbtn: {
        width: '100%',
    },
    taskbadgesection: {
        position: 'relative',
        cursor: 'pointer',
    },
    taskbadge: {
        position: 'absolute',
        top: '-8px',
        right: '-6px',
        background: theme?.palette?.quaternary?.main,
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        fontWeight: '500',
        color: theme?.palette?.common?.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
}))

const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme?.palette?.common?.black,
        color: theme?.palette?.common?.white,
        fontSize: '10px',
        borderRadius: '4px',
    },
    arrow: {
        color: theme?.palette?.common?.black,
    },
}))

const ComputerConnectBtn = ({
    data,
    drawerView,
    parentStyle,
    showCalenderIcon = true,
    showPrefixIcon,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const tooltipClasses = useTooltipStyles()
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const { showSuccessMessage, showWarningMessage } = useNotification()

    const itemData = data.resource ? data.resource : data

    const [item, setItem] = useState(itemData)
    const [connecting, setConnecting] = useState(false)
    const { data: currentPerson } = useCurrentPerson()
    const accessRequestPolicy = useAccessRequestPolicy(itemData.requestPolicyId)
    const [showAdvancedCheckout, setShowAdvancedCheckout] = useState(false)
    const { masterPasswordExists } = useCheckIfMasterPasswordExist()
    const [startDateTime, setStartDateTime] = useState(
        moment(new Date()).utc().format(),
    )
    const [duration, setDuration] = useState('')
    const [credentialsType, setCredentialsType] = useState(
        'personalCredentials',
    )
    const [selectedCredentials, setSelectedCredentials] = useState(null)

    useEffect(() => {
        if (itemData && itemData.canCheckOut !== item.canCheckOut) {
            setItem(itemData)
        }
        if (itemData && itemData.checkOutCount !== item.checkOutCount) {
            setItem(itemData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemData, item.canCheckOut])

    useEffect(() => {
        if (connecting) {
            var refreshList = setInterval(function () {
                refreshComputerList()
            }, 20000)

            var connectingTimeout = setTimeout(function () {
                setConnecting(false)
                clearInterval(refreshList)
            }, 120000)

            if (itemData?.checkOutId) {
                setConnecting(false)
                clearInterval(refreshList)
                clearTimeout(connectingTimeout)
            }
        } else {
            if (refreshList) {
                clearInterval(refreshList)
            }
            if (connectingTimeout) {
                clearTimeout(connectingTimeout)
            }
        }
        return () => {
            if (refreshList) {
                clearInterval(refreshList)
            }
            if (connectingTimeout) {
                clearTimeout(connectingTimeout)
            }
        }
    }, [itemData, connecting])

    const [checkInComputer] = useComputerCheckIn()

    const violatesPolicy =
        accessRequestPolicy?.maximumValueInMinutes &&
        duration > accessRequestPolicy?.maximumValueInMinutes
    const hasInvalidDates =
        isNilOrEmpty(startDateTime) || duration < 1 || violatesPolicy

    let invalidDatesMessage = ''
    if (duration < 1) {
        invalidDatesMessage = t('InvalidDuration')
    }
    if (isNilOrEmpty(startDateTime)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy?.maximumValueInMinutes,
        })
    }

    const [
        bookComputer,
        { data: bookComputerData, isLoading: isLoadingBookComputer, isError },
    ] = useComputerBookNow()

    useEffect(() => {
        if (bookComputerData && !isError) {
            showSuccessMessage(t('Common_ComputerBookedSccessfully'))
            setShowAdvancedCheckout(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookComputerData, isError, isLoadingBookComputer])

    const updateDataObjectOnChange = () => {
        try {
            let tItem = {
                ...item,
                canCheckOut: !item.canCheckOut,
            }

            setItem(tItem)
        } catch (err) {}
    }

    const isRequestedBySelf = item.requestedByPersonId
        ? item.requestedByPersonId === currentPerson.id
            ? true
            : false
        : undefined

    const { data: hasAccessToCheckIn } = useCheckAccessToCheckInOperation(
        item.credentialId,
        isRequestedBySelf === false,
    )

    const handleConnectBtnClick = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        if (item.disableConnectButton) {
            return
        }

        handleCheckOut()
    }

    const handleCheckOut = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        setShowAdvancedCheckout(false)
        const wfParams = []
        wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
        wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
        wfParams.push(`TargetComputerGuid=${item.id}`)
        wfParams.push(`IsAdvanceMode=${false}`)
        const fullWorkflowUrl = constructApprovalWorkflowUrl(
            appConfig.BASE_EID_URL,
            'OpenPSMSessionWF',
            wfParams,
        )

        window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
        query.delete('selected')
        history.push(`${history.location.pathname}?${query.toString()}`)
        setConnecting(true)
    }

    const handleAdvancedCheckOut = (type) => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        if (type === 'Connect') {
            setShowAdvancedCheckout(false)
            const wfParams = []
            wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
            wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
            wfParams.push(`TargetComputerGuid=${item.id}`)
            wfParams.push(`IsAdvanceMode=${true}`)
            wfParams.push(`CheckOutStartDate=${startDateTime}`)
            wfParams.push(
                `CheckOutEndDate=${moment(startDateTime)
                    .clone()
                    .add(duration, 'm')
                    .utc()
                    .format()}`,
            )
            if (selectedCredentials?.id)
                wfParams.push(
                    `ExternalCredentialGuid=${selectedCredentials?.id}`,
                )
            if (credentialsType === 'sharedCredentials') {
                wfParams.push(`IsSharedCred=true`)
            }
            const fullWorkflowUrl = constructApprovalWorkflowUrl(
                appConfig.BASE_EID_URL,
                'OpenPSMSessionWF',
                wfParams,
            )
            window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
            query.delete('selected')
            history.push(`${history.location.pathname}?${query.toString()}`)
            setConnecting(true)
        } else {
            const dataObject = {
                computerId: item.id,
                targetPersonId: currentPerson.id,
                credentialId: selectedCredentials?.id
                    ? selectedCredentials.id
                    : undefined,
                checkOutStartDate: startDateTime,
                checkOutEndDate: moment(startDateTime)
                    .clone()
                    .add(duration, 'm')
                    .utc()
                    .format(),
            }
            bookComputer(dataObject)
        }
    }

    const handleReconnect = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        try {
            const wfParams = []
            wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
            wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
            wfParams.push(`TargetComputerGuid=${item.id}`)
            wfParams.push(`IsAdvanceMode=${true}`)
            if (item?.checkedOutCredentialId)
                wfParams.push(
                    `ExternalCredentialGuid=${item?.checkedOutCredentialId}`,
                )
            const fullWorkflowUrl = constructApprovalWorkflowUrl(
                appConfig.BASE_EID_URL,
                'OpenPSMSessionWF',
                wfParams,
            )
            window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
        } catch (err) {}
    }

    const RECONNECT_OPTION = {
        name: 'reconnect',
        onClick: handleReconnect,
        label: t('Common_Reconnect'),
    }
    const DISCONNECT_OPTION = {
        name: 'disconnect',
        onClick: () => {
            if (!masterPasswordExists()) {
                showWarningMessage(t('MasterPasswordDoesNotExist'))
                return
            }
            updateDataObjectOnChange()
            checkInComputer(item.checkOutId)
        },
        label: t('Common_Disconnect'),
    }

    const CONNECT_NOW_OPTION = {
        name: 'now',
        onClick: handleCheckOut,
        label: t('Now'),
    }

    const CONNECT_ADVANCED_OPTION = {
        name: 'advanced',
        onClick: () => {
            setStartDateTime(moment(new Date()).utc().format())
            setShowAdvancedCheckout(true)
        },
        label: t('AdvancedMode'),
    }

    const getButtonLabel = () => {
        if (connecting) {
            return t('Connecting')
        }
        return t(item?.connectButtonLabel)
    }

    const getButtonOptions = () => {
        if (connecting) {
            return []
        }

        if (!isRequestedBySelf && hasAccessToCheckIn?.hasAccess) {
            return [DISCONNECT_OPTION]
        }
        if (item?.canCheckIn) {
            return [RECONNECT_OPTION, DISCONNECT_OPTION]
        }
        if (!item.disableAdvConnectButton && !item.disableConnectButton) {
            return [CONNECT_NOW_OPTION, CONNECT_ADVANCED_OPTION]
        }

        if (!item.disableConnectButton) {
            return [CONNECT_NOW_OPTION]
        }

        if (!item.disableAdvConnectButton) {
            return [CONNECT_ADVANCED_OPTION]
        }

        return []
    }

    const getButtonTooltipMessage = () => {
        if (item?.canCheckIn) {
            return ''
        }
        if (item.disableAdvConnectButton && item.disableConnectButton) {
            return t('Computers_CannotConnect')
        }
        if (item.disableConnectButton) {
            return t('Computers_UseAdvancedMode')
        }
        return ''
    }

    const handleChangeStartDate = (startDate) => {
        setStartDateTime(startDate)
    }
    const handleChangeDuration = (duration) => {
        setDuration(duration)
    }

    const disabledButtonStyles = {
        backgroundColor: '#aab0b4',
        color: `${theme?.palette?.common?.white} !important`,
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#aab0b4',
        },
    }
    const connectedStyles = item.disableConnectButton
        ? disabledButtonStyles
        : {}

    const checkIfSomeOneElseSession = () => {
        if (item.isConnectedBySomeoneElse) {
            return true
        }
        return false
    }

    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <>
            <Box className={classes.connectbtndiv}>
                <Box style={{ height: '24px' }} className={classes.connectbtn}>
                    <Tooltip
                        arrow={true}
                        title={getButtonTooltipMessage()}
                        classes={tooltipClasses}
                        open={showTooltip}
                    >
                        <span
                            style={{
                                display: drawerView ? 'inline-block' : 'inline',
                            }}
                        >
                            <EidButton.CheckOutListing
                                loading={false}
                                label={getButtonLabel()}
                                onButtonClick={handleConnectBtnClick}
                                checkoutOptions={getButtonOptions()}
                                drawerView={drawerView}
                                parentStyle={{
                                    ...parentStyle,
                                    ...connectedStyles,
                                }}
                                showPrefixIcon={showPrefixIcon}
                                onMouseOver={() => {
                                    setShowTooltip(true)
                                }}
                                onMouseLeave={() => {
                                    setShowTooltip(false)
                                }}
                                onClick={() => setShowTooltip(false)}
                                showBackdrop={false}
                            />
                        </span>
                    </Tooltip>
                </Box>

                {item?.checkOutCount > 0 && showCalenderIcon && (
                    <>
                        <Box
                            onClick={() => {
                                query.set('selected', item.id)
                                query.set('showSession', true)
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                            className={classes.taskbadgesection}
                        >
                            <Box className={classes.taskicon}>
                                <Icon name="ActiveCalender" />
                            </Box>
                            <span className={classes.taskbadge}>
                                {item.checkOutCount}
                            </span>
                        </Box>
                    </>
                )}
            </Box>
            {showAdvancedCheckout && (
                <ConnectAdvanced
                    onSucces={(type) => handleAdvancedCheckOut(type)}
                    loading={isLoadingBookComputer}
                    open={showAdvancedCheckout}
                    onClose={() => setShowAdvancedCheckout(false)}
                    requestPolicy={accessRequestPolicy}
                    selectedStartDate={startDateTime}
                    setSelectedStartDate={handleChangeStartDate}
                    selectedDuration={duration}
                    setSelectedDuration={handleChangeDuration}
                    hasInvalidDates={hasInvalidDates}
                    invalidDatesMessage={invalidDatesMessage}
                    setCredentialsType={(e) => setCredentialsType(e)}
                    credentialsType={credentialsType}
                    item={item}
                    selectedCredentials={selectedCredentials}
                    setSelectedCredentials={(e) => setSelectedCredentials(e)}
                    isConnectedBySomeOneElse={checkIfSomeOneElseSession()}
                />
            )}
        </>
    )
}

ComputerConnectBtn.defaultProps = {
    drawerView: false,
}

export default ComputerConnectBtn

const constructApprovalWorkflowUrl = (
    baseEidUrl,
    workflowName,
    workflowParams,
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')
    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
