import React, { useState, useRef } from 'react'
import { Typography, Box, styled, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { Icon } from 'packages/eid-icons'
import { Header, Dropdown } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useQuery, useAuthState } from 'packages/core'
import { useAllCarts, useTargetPerson } from 'hooks'
import CartMenuItem from './CartMenuItem'
import { getFullImageUrl } from 'utils'
import moment from 'moment'
import { useAppState } from 'appContext'

const ShowHideButton = styled('div')({
    backgroundColor: '#f9f9fb',
    opacity: 0.7,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    height: '29px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
        opacity: 1,
    },
})

const AttributeSkeleton = styled(Skeleton)({
    width: '94%',
    margin: '10px',
    padding: '10px',
    height: 16,
})

const Loader = () => (
    <Box>
        <AttributeSkeleton animation="wave" variant="rect" />
        <AttributeSkeleton animation="wave" variant="rect" />
    </Box>
)

const MenuTitle = styled(Typography)({
    fontSize: '10px',
    color: '#a7a6a6',
    textTransform: 'uppercase',
    marginLeft: '22px',
})

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '52px',
        right: 0,
        ...anchorPosition,
    }),
    container: {
        '& button': {
            width: '24px',
            height: '100%',
            justifyContent: 'center',
        },
    },
}))

const AllCartsDropdown = React.forwardRef(
    ({ open, setOpen, showMax = 3 }, ref) => {
        const history = useHistory()
        const query = useQuery()
        const [{ currentUserId }] = useAuthState()
        const { data, isFetching } = useAllCarts()
        const [targetPerson, setTargetPerson] = useTargetPerson()
        const [{ disableSwitchTargetPerson }] = useAppState()

        const { t } = useTranslation()
        const theme = useTheme()

        const [itemsToShow, setItemsToShow] = useState(showMax)

        const handleClose = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }

        const expandButton =
            data && showMax < data.length && itemsToShow !== data.length ? (
                <ShowHideButton
                    onClick={() =>
                        setItemsToShow((prev) =>
                            prev === data.length ? showMax : data.length,
                        )
                    }
                >
                    <Typography
                        style={{
                            color: theme?.palette?.primary?.main,
                            fontSize: '11px',
                        }}
                    >
                        {t('Common_ViewAll')}
                    </Typography>
                </ShowHideButton>
            ) : (
                <span></span>
            )

        const content = isFetching ? (
            <Loader />
        ) : (
            data.slice(0, itemsToShow).map((item, index) => (
                <CartMenuItem
                    key={index}
                    avatarSrc={getFullImageUrl(item.targetPersonImageThumbUrl)}
                    targetPersonId={item.targetPersonId}
                    selected={item.targetPersonId === targetPerson.id}
                    displayName={item.targetPersonFriendlyName}
                    itemCount={item.cartItems.length}
                    isCurrentUserTarget={item.targetPersonId === currentUserId}
                    checkoutCart={() => {
                        if (!disableSwitchTargetPerson) {
                            setTargetPerson({
                                id: item.targetPersonId,
                                friendlyName: item.targetPersonFriendlyName,
                                imageThumbUrl: item.targetPersonImageThumbUrl,
                            })
                        }
                        query.set('cart', 'visible')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                        setOpen(false)
                    }}
                    lastEditTime={moment
                        .utc(item.modifiedDateUtc)
                        .local()
                        .format('LLL')}
                />
            ))
        )

        return (
            <Dropdown
                open={open}
                rootStyles={{
                    paddingRight: '0px',
                }}
                width="320px"
                listStyles={{
                    paddingBottom: '0px',
                    '& > ul': {
                        paddingBottom: '0px',
                        paddingTop: '6px',
                    },
                }}
                anchorEl={ref.current}
                handleClose={handleClose}
                showCone={true}
                menuHeight="45vh"
                expandButton={expandButton}
            >
                {data && (
                    <MenuTitle>
                        {t('Common_ShoppingCartFor', { count: data.length })}
                    </MenuTitle>
                )}

                {content}
            </Dropdown>
        )
    },
)

const CartDropdown = ({ anchorPosition, showMax = 3 }) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ anchorPosition })
    const anchorRef = useRef(null)
    const theme = useTheme()

    const displayIcon = (
        <Header.IconLink
            logo
            component="button"
            style={{ minWidth: '0px', width: '20px', height: '100%' }}
            onClick={() => {
                setOpen((prev) => !prev)
            }}
        >
            <Icon
                name="Arrow"
                color={theme?.palette?.common?.white}
                width="12px"
                height="12px"
            />
            <span ref={anchorRef} className={classes.dropdownAnchor} />
        </Header.IconLink>
    )

    return (
        <Box classes={{ root: classes.container }}>
            {displayIcon}
            {open && (
                <AllCartsDropdown
                    ref={anchorRef}
                    showMax={showMax}
                    open={open}
                    setOpen={setOpen}
                />
            )}
        </Box>
    )
}

export default CartDropdown
