import { Box, Typography, Grid, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Loader, useViewIndicator } from 'packages/eid-ui'
import TableView from './TableView'
import { useQuery } from 'packages/core'
import AccessItem from './AccessItem'
import { CardList } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import AccessItemDrawer from './AccessItemDrawer'
import { getUniqueId } from './resourceTypeHelpers'
import { subHeaderHeight } from 'utils'
import Selectors from 'containers/Selectors'
import useSubcomponents from 'useSubcomponents'
import ComputerCardView from 'components/ComputerCardView'
import { useAppState } from 'appContext'
import Search from 'components/Layout/ITShopHeader/Search'
import { useResourceTypeContext } from 'resourceTypeContext'
import NoAccessInfo from 'components/NoAccessInfo'
import { Fragment } from 'react'
const useStyles = makeStyles((theme) =>
    createStyles({
        cardItem: {
            height: 'inline-block',
            overflow: 'hidden',
            [theme.breakpoints.between('0', '1000')]: {
                width: '100%',
            },
            [theme.breakpoints.between('1000', '1400')]: {
                width: '50%',
            },
            [theme.breakpoints.up('1400')]: {
                width: '33.33%',
            },
        },
    }),
)
const DesktopVersion = ({
    id,
    list,
    loading,
    onSort,
    sortBy,
    sortOrder,
    loadingMore,
    currentResourceType,
    resourceTypeAttributes,
    infiniteScrollObervableDiv,
    history,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles()
    const { canSeeSimpleTextSearch, hasAccessToTabs } = useSubcomponents()
    const [
        { manageAccess },
        dispatch,
    ] = useResourceTypeContext()
    const { hasAccessToManageAccess } = hasAccessToTabs(
        manageAccess?.selectedTab?.requireAccess?.control,
    )

    const [{ appConfig }] = useAppState()
    const searchConfig = appConfig.style?.search

    const CURRENT_SCREEN = 'ManageAccess'
    const query = useQuery()
    const selectedTab = query.get('tab')

    const { view } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    return (
        <>
            <div
                style={{
                    flexGrow: 1,
                    marginTop: `${subHeaderHeight + 20}px`,
                }}
            >
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                    }}
                >
                    {/* Filter based on configurations */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex="27%"
                        maxWidth="370px"
                    >
                        <Selectors
                            resourceType={currentResourceType.name}
                            screen={CURRENT_SCREEN}
                            showDeleteFiltersButton={false}
                            showSaveFiltersButton={false}
                            showFilters={hasAccessToManageAccess}
                        />
                    </Box>
                    {hasAccessToManageAccess ? (
                            <Box
                                flex="73%"
                                display="flex"
                                flexWrap="wrap"
                                height="100%"
                                data-protectedsubcomponent={hasAccessToManageAccess}
                            >
                                <Grid
                                    container
                                    spacing={0}
                                    style={
                                        view === 'card'
                                            ? {
                                                  margin: '0 14px',
                                              }
                                            : {}
                                    }
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        width="100%"
                                        padding="8px"
                                    >
                                        {searchConfig?.show &&
                                            canSeeSimpleTextSearch && (
                                                <div
                                                    data-protectedsubcomponent={
                                                        canSeeSimpleTextSearch
                                                    }
                                                    style={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        flex: 1,
                                                        minWidth: '140px',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Search
                                                        styleData={
                                                            searchConfig?.style
                                                        }
                                                    />
                                                </div>
                                            )}{' '}
                                    </Box>

                                    {view === 'card' ? (
                                        <CardList
                                            // TODO: Enable this when we have new UI
                                            // cardType={currentResourceType?.name}
                                            fetcher={() => ({
                                                loading,
                                                loadingMore,
                                                list,
                                            })}
                                            infiniteScrollObervableDiv={
                                                infiniteScrollObervableDiv
                                            }
                                            noItemMessage={
                                                <Typography
                                                    variant="h5"
                                                    style={{ color: '#b4b4b4' }}
                                                >
                                                    {t('Common_NoItemsFound', {
                                                        itemType: t(
                                                            manageAccess?.selectedTab
                                                                ? manageAccess
                                                                      .selectedTab
                                                                      .label
                                                                : currentResourceType.title,
                                                        ),
                                                    })}
                                                </Typography>
                                            }
                                            renderItem={(item, index) => (
                                                <>
                                                    {/* Keepin this as discussed on call as we need thsi in future */}
                                                    {currentResourceType?.name ===
                                                    'NewUi' ? (
                                                        <Box
                                                            key={`${getUniqueId(
                                                                item,
                                                                selectedTab,
                                                            )}-${index} `}
                                                        >
                                                            <ComputerCardView
                                                                btnLabel={t(
                                                                    'Common_Details',
                                                                )}
                                                                item={item}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <div
                                                            key={`${getUniqueId(
                                                                item,
                                                                selectedTab,
                                                            )}-${index} `}
                                                            className={
                                                                classes.cardItem
                                                            }
                                                        >
                                                            <AccessItem
                                                                resourceType={
                                                                    currentResourceType
                                                                }
                                                                cardStyles={{
                                                                    margin:
                                                                        '8px',
                                                                }}
                                                                buttonStyles={{
                                                                    '&:hover': {
                                                                        backgroundColor:
                                                                            '#02997E',
                                                                    },
                                                                }}
                                                                attributes={
                                                                    resourceTypeAttributes
                                                                }
                                                                cardHeaderStyles={{}}
                                                                item={item}
                                                                onItemClick={() => {
                                                                    query.set(
                                                                        'selected',
                                                                        getUniqueId(
                                                                            item,
                                                                            selectedTab,
                                                                        ),
                                                                    )
                                                                    history.push(
                                                                        `${
                                                                            history
                                                                                .location
                                                                                .pathname
                                                                        }?${query.toString()}`,
                                                                    )
                                                                }}
                                                                color={{
                                                                    primary:
                                                                        theme
                                                                            .palette
                                                                            .secondary
                                                                            .main,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <Box
                                            padding="8px"
                                            marginRight="8px"
                                            width="100%"
                                        >
                                            <TableView
                                                onSort={onSort}
                                                sortOrder={sortOrder}
                                                sortBy={sortBy}
                                                data={list}
                                                attributes={
                                                    resourceTypeAttributes
                                                }
                                                loading={loading}
                                                fallbackMessage={t(
                                                    'Common_NoItemsFound',
                                                    {
                                                        itemType: t(
                                                            manageAccess?.selectedTab
                                                                ? manageAccess
                                                                      .selectedTab
                                                                      .label
                                                                : currentResourceType.title,
                                                        ),
                                                    },
                                                )}
                                                onItemClick={(item) => {
                                                    query.set(
                                                        'selected',
                                                        getUniqueId(
                                                            item,
                                                            selectedTab,
                                                        ),
                                                    )
                                                    history.push(
                                                        `${
                                                            history.location
                                                                .pathname
                                                        }?${query.toString()}`,
                                                    )
                                                }}
                                                currentResourceType={
                                                    currentResourceType
                                                }
                                            />
                                            {loadingMore && <Loader />}
                                            {infiniteScrollObervableDiv}
                                        </Box>
                                    )}
                                </Grid>
                            </Box>
                    ) : (
                        <NoAccessInfo />
                    )}
                </div>
            </div>
            {id && hasAccessToManageAccess && (
                <AccessItemDrawer
                    type={currentResourceType.name}
                    id={id}
                    attributes={resourceTypeAttributes}
                    toggleDrawer={() => {
                        query.delete('selected')
                        if (query.get('showSession')) {
                            query.delete('showSession')
                        }
                        if (query.get('showCheckouts')) {
                            query.delete('showCheckouts')
                        }
                        dispatch({
                            type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                            payload: null,
                        })
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                    color={{
                        primary: theme?.palette?.secondary?.main,
                    }}
                    currentResourceType={currentResourceType}
                />
            )}
        </>
    )
}

export default DesktopVersion
