import {
    preApprovedOnlyFilterColors,
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
    suggestedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        advanceSearch: true,

        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedManagementRoles-Control',
        },
        order: 2,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        default: true,
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedManagementRoles-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: true,
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedManagementRoles-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ShowSuggestedRoles',
        title: 'Common_SuggestAdditionalResources',
        requireAccess: {
            control: 'ITShop-SuggestedManagementRoles-Control',
        },
        order: 3,
        colorCode: suggestedOnlyFilterColors,
        type: 'checkbox',
        label: 'Common_SuggestManagementRolesLabel',
        contextProp: 'showSuggested',
        trueValueIndicator: 'Common_SuggestManagementRolesLabel',
        queryParam: {
            name: 'showSuggested',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        requireAccess: {
            control: 'ITShop-ManagementRolesApplications-Control',
        },
        order: 4,
        colorCode: {
            primary: '#9230c5',
            secondary: '#ebeaf7',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'BusinessDomains',
        title: 'Common_BusinessDomains',
        requireAccess: {
            control: 'ITShop-BusinessDomains-Control',
        },
        order: 5,
        colorCode: {
            primary: '#01745f',
            secondary: '#e6f1ef',
        },
        icon: {
            name: 'ApplicationProcess',
            color: '#959598',
        },
        type: 'tree',
        searchable: true,
        searchPlaceholder: 'Common_Search',
        url: '/api/managementRoles/businessDomains',
        dedupingInterval: 1800000,
        contextProp: 'businessDomain',
        selectedValueIndicatorProp: 'friendlyName',
        queryParam: {
            name: 'businessDomainId',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'BusinessFunctions',
        title: 'Common_BusinessFunctions',
        requireAccess: {
            control: 'ITShop-ManagementRolesBusinessFunctions-Control',
        },
        order: 6,
        colorCode: {
            primary: '#960067',
            secondary: '#f4e5ef',
        },
        icon: {
            name: 'BusinessFunction',
            color: '#959598',
        },
        type: 'checklist',
        searchable: true,
        searchPlaceholder: 'Common_Search',
        url: '/api/businessFunctions/businessFunctions',
        basedOn: {
            type: 'optional',
            contextProp: 'businessDomain',
            valueProp: 'id',
            queryParamName: 'locationId',
        },
        dedupingInterval: 1800000,
        debounceInterval: 500,
        contextProp: 'businessFunctions',
        selectedValueIndicatorProp: 'friendlyName',
        queryParam: {
            name: 'businessFunctionIds',
            valueProp: 'id',
        },
        showPagination: true,
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'TCode',
        title: 'Common_TCodes',
        requireAccess: {
            control: 'ITShop-ManagementRolesTCode-Control',
        },
        order: 7,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        placeholder: 'Common_TCodes',
        optionConfig: {
            type: 'labelWithSubLabel',
            labelProp: 'friendlyName',
            subLabelProp: 'description',
        },
        url: '/api/globalRights/getGlobalRights',
        queryParams: {
            take: 25,
        },
        contextProp: 'tCode',
        debounceInterval: 500,
        dedupingInterval: 60000,

        queryParam: {
            name: 'tCode',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ReferencePerson',
        title: 'Common_ShopByReferencePerson',
        requireAccess: {
            control: 'ITShop-ManagementRoles-ShopByReferencePerson-Control',
        },
        order: 8,
        type: 'autocomplete',
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        label: 'Common_ByReferencePerson',
        placeholder: 'Common_SelectPerson',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        excludeOptionsConfig: {
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'referencePerson',
        queryParam: {
            name: 'referencePersonId',
            valueProp: 'id',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ManagementRolesAdvancedSearch-Control',
        },
        order: 9,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'TechnicalName',
                    type: 'text',
                    placeholder: 'Common_TechnicalName',
                    contextProp: 'advancedSearch.forms.technicalName',
                    queryParam: {
                        name: 'name',
                        type: 'string',
                    },
                },

                {
                    name: 'Owner',
                    type: 'autocomplete',
                    label: 'ManagementRoles_ByOwner',
                    placeholder: 'Common_SelectPerson',
                    optionConfig: {
                        type: 'person',
                        labelProp: 'friendlyName',
                        imageUrlProp: 'imageUrl',
                    },
                    url: '/api/people/search',
                    queryParams: {
                        take: 25,
                    },
                    debounceInterval: 500,
                    dedupingInterval: 60000,
                    contextProp: 'advancedSearch.forms.owner',
                    queryParam: {
                        name: 'OwnerPersonId',
                        valueProp: 'id',
                    },
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'description',
                        type: 'string',
                    },
                },
            ],
        },

        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property

    {
        name: 'localizedFriendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: true,
    },
    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
        requireAccess: {
            control: 'ITShop-ManagementRolesNameAttribute-Control',
        },
    },
    {
        name: 'typeFriendlyName',
        label: 'Common_Type',
        sortable: true,
        requireAccess: {
            control: 'ITShop-ManagementRolesTypeFriendlyNameAttribute-Control',
        },
    },
    {
        name: 'localizedDescription',
        label: 'Common_Description',
        sortable: true,
    },
    {
        label: 'Common_CurrentAccessEnds',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const managementRolesConfig = {
    name: 'ManagementRoles',
    resourceTypeName: 'ManagementRole',
    title: 'Common_ManagementRoles',
    route: '/managementRoles',
    requireAccess: {
        page: 'ITShop-ManagementRoles-Page',
    },
    order: 6,
    icon: {
        small: 'ManagementRolesSmall',
        large: 'ManagementRolesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/managementRoles',
    },
    workflows: [],
    filters: filters,
    attributes: attributes,
}

export default managementRolesConfig
