import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Icon } from 'packages/eid-icons'
import './workFlowItem.css'
import { useAppState } from 'appContext'
type props = {
    title: string
    description: string
    onClick: () => void
    isEnabled: any
}

const useStyles = makeStyles((theme) => ({
    workflowHeader: (themeColors: any) => ({
        background: themeColors?.gradient,
        color: theme?.palette?.common?.white,
        '&:hover': {
            background: themeColors?.secondaryGradient,
            cursor: 'pointer',
        },
    }),
}))

const WorkFlowsItems: React.FC<props> = ({
    title,
    description,
    onClick,
    isEnabled,
}) => {
    const [{ appConfig }]: any = useAppState()
    const themeColors = appConfig.style?.colors
    const classes = useStyles(themeColors)

    return (
        <Grid
            onClick={onClick}
            item
            md={4}
            xs={12}
            spacing={2}
            className={`workflowinnergrid ${
                isEnabled ? 'show-workflow' : 'disable-workflow'
            }`}
        >
            <div
                className={
                    themeColors?.gradient && themeColors?.secondaryGradient
                        ? classes.workflowHeader
                        : 'gridtitle'
                }
            >
                <h2>{title}</h2>
            </div>
            <div className="workflowdescription inActive">
                <Icon name="WorkFlowChart" /> <p>{description}</p>
            </div>
            <div className="workflowdescription active">
                <Icon name="WorkFlowChartSelected" /> <p>{description}</p>
            </div>
        </Grid>
    )
}

export default WorkFlowsItems
