import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails, PeopleList } from 'components'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Spinner } from 'packages/eid-ui'
import {
    useAccessRequestPolicy,
    useComputerLoginSessionHistoryDetail,
    useComputerOwners,
    useCurrentPerson,
    useHasAccessToViewPSMRecordings,
    useHasAccessToViewSessionEvents,
} from 'hooks'
import moment from 'moment'
import RecordingsTab from './RecordingTab'
import useSubcomponents from 'useSubcomponents'
import LoginSessionEvents from './LoginSessionEvents'
import { ResourceTypeContextProvider } from 'resourceTypeContext'
const hasAccessToOwnerControl =
    'ITShop-Computers-ManageAcces-LoginHistory-Details-Owners-Control'
const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const LoadingContainer = styled('div')({
    position: 'absolute',
    top: '33%',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
})

const ComputersConnectedWhenAndDuration = (name, data) => {
    const { t } = useTranslation()
    const whenData = data[name]
    let connectedWhen = `0 ${t('min')}`
    if (whenData?.days > 0) {
        connectedWhen = `${whenData.days} ${
            whenData.days > 1 ? t('days') : t('day')
        }`
    }
    if (whenData?.hours > 0) {
        connectedWhen =
            connectedWhen === `0 ${t('min')}`
                ? `${whenData.hours} ${
                      whenData.hours > 1 ? t('hours') : t('hour')
                  }`
                : connectedWhen +
                  ` ${whenData.hours} ${
                      whenData.hours > 1 ? t('hours') : t('hour')
                  }`
    }
    if (whenData?.minutes > 0) {
        connectedWhen =
            connectedWhen === `0 ${t('min')}`
                ? `${whenData.minutes} ${
                      whenData.minutes > 1 ? t('mins') : t('min')
                  }`
                : connectedWhen +
                  ` ${whenData.minutes} ${
                      whenData.minutes > 1 ? t('mins') : t('min')
                  }`
    }
    return <>{connectedWhen}</>
}

const OverviewSection = ({ item }) => {
    const computer = item
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_Computer')}
                    value={computer.computerFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ComputerId')}
                    value={computer.computerId}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Credential')}
                    value={computer.externalCredentialFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Computers_PersonDisplayName')}
                    value={computer.checkedOutByPersonFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PersonLogin')}
                    value={computer.externalCredentialName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PersonId')}
                    value={computer.checkedOutByPersonId}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_StartTime')}
                    value={
                        computer.startTimeUtc
                            ? moment
                                  .utc(computer.startTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={
                        computer.endTimeUtc
                            ? moment
                                  .utc(computer.endTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_When')}
                    value={ComputersConnectedWhenAndDuration('when', computer)}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Duration')}
                    value={ComputersConnectedWhenAndDuration(
                        'duration',
                        computer,
                    )}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_RecordingStatus')}
                    value={
                        isNilOrEmpty(computer.recordingStatus)
                            ? t('Not_Picked')
                            : computer.recordingStatus
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_SharedCredentialsId')}
                    value={computer.externalCredentialId}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_RDPSession')}
                    value={computer.isRdp ? t('Common_Yes') : t('Common_No')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_TerminateSession')}
                    value={
                        computer.canTerminateSession
                            ? t('Common_Yes')
                            : t('Common_No')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const MoreInformation = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        hasAccessToControl(hasAccessToOwnerControl)
            ? computer.computerId
            : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}

const LoginHistoryAccessDetails = ({ item }) => {
    const session = item?.resource
    const { t } = useTranslation()

    const {
        data: loginHistorySessionDetails,
        isLoading,
    } = useComputerLoginSessionHistoryDetail(session?.id)

    const accessRequestPolicy = useAccessRequestPolicy(session?.requestPolicyId)
    const { data: currentPerson } = useCurrentPerson()
    const isSelf = currentPerson.id === session.checkedOutByPersonId

    const { canSeeComputersPSMSessionsRecordings } = useSubcomponents()

    const canSeeRecordedSession =
        canSeeComputersPSMSessionsRecordings &&
        !session?.canTerminateSession &&
        session?.recordingUploaded

    const canSeeLiveSession =
        canSeeComputersPSMSessionsRecordings &&
        session?.canTerminateSession &&
        accessRequestPolicy?.allowLiveSessionSnooping

    const canSeeRecordingsTab = canSeeRecordedSession || canSeeLiveSession

    const defaultTabs = [
        {
            value: 'sessiondetails',
            label: t('Common_SessionDetails'),
            display: true,
        },
        {
            value: 'recordings',
            label: t('Common_Recordings'),
            display: isSelf && canSeeRecordingsTab,
            protectedSubcomponent: canSeeComputersPSMSessionsRecordings,
        },
        {
            value: 'sessionevents',
            label: t('Computers_SessionEvents'),
            display: false, //NOTE: Will be set to true after checking access from BE
        },
        {
            value: 'moreInformation',
            label: t('Common_MoreInformation'),
            display: true,
        },
    ]

    const [tabs, setTabs] = useState(defaultTabs)
    const [activeTab, setActiveTab] = useState('sessiondetails')

    const { data: accessToViewSomeoneElseRecordings } =
        useHasAccessToViewPSMRecordings(
            session?.checkedOutByPersonId,
            !isSelf && canSeeRecordingsTab,
        )

    const { data: accessToViewSessionEvents } = useHasAccessToViewSessionEvents(
        session?.checkedOutByPersonId,
        true,
    )

    useEffect(() => {
        const newTabs = tabs.map((tab) => {
            if (
                tab.value === 'recordings' &&
                accessToViewSomeoneElseRecordings?.hasAccess &&
                canSeeRecordingsTab
            ) {
                return { ...tab, display: true }
            }
            if (
                tab.value === 'sessionevents' &&
                accessToViewSessionEvents?.hasAccess
            ) {
                return { ...tab, display: true }
            }
            return tab
        })

        setTabs(newTabs)
    }, [accessToViewSomeoneElseRecordings, accessToViewSessionEvents])

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                {tabs
                    .filter((x) => x.display)
                    .map((tab) => (
                        <ItemDetails.Tabs.Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                            data-protectedsubcomponent={
                                tab.protectedSubcomponent
                                    ? tab.protectedSubcomponent
                                    : ''
                            }
                        />
                    ))}
            </ItemDetails.Tabs>
            {isLoading && (
                <LoadingContainer>
                    <Spinner />
                </LoadingContainer>
            )}

            {activeTab === 'sessiondetails' && !isLoading && (
                <OverviewSection item={loginHistorySessionDetails} />
            )}
            {activeTab === 'recordings' && !isLoading && (
                <RecordingsTab item={loginHistorySessionDetails} />
            )}
            {activeTab === 'sessionevents' && !isLoading && (
                <ResourceTypeContextProvider key="ViewComputersLoginSessionEvents">
                    <LoginSessionEvents
                        sessionId={session?.id}
                        showDownloadButton={session?.isSshLogsAvailable}
                    />
                </ResourceTypeContextProvider>
            )}
            {activeTab === 'moreInformation' && !isLoading && (
                <MoreInformation item={item} />
            )}
        </>
    )
}

export default LoginHistoryAccessDetails
