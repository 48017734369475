import { useAuthState, useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import GuidedShopOrApp from './GuidedShopOrApp'
import GuidedShop from 'containers/GuidedShop'
import ManageAccess from 'containers/ManageAccess'
import ListingPage from 'containers/ListingPage'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ResourceTypeContextProvider } from 'resourceTypeContext'
import withRequiredData from './withRequiredData'
import WorkFlows from 'containers/Workflows'
import { Fragment, useEffect } from 'react'
import appConfig from 'config'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import ComputerConnectWorkFlow from 'containers/ComputerWorkflow'
import { useAppState } from 'appContext'
import { getWorkflowWaitTimeInSeconds } from 'packages/core'
import { IdleTimer } from 'packages/eid-ui'
import { useSessionTimeout } from 'hooks'
import requireMasterPassword from './requireMasterPassword'

const App = () => {
    const query = useQuery()
    const { manageAccess, resourceTypes } = useConfiguration()
    const [{ isWorkflowIRenderable }, authDispatch]: any = useAuthState()
    // eslint-disable-next-line
    const [, dispatch]: any = useAppState()
    const workflowWaitTime = getWorkflowWaitTimeInSeconds() * 1000
    const { data: loginSessionTimeout } = useSessionTimeout()

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, workflowWaitTime)
        }
        if (appConfig.ALLOW_FILTERS_OVERRIDE?.toLowerCase() === 'true') {
            const queryParamsObj = Object.fromEntries(query)

            dispatch({
                type: 'SET_URL_FILTERS',
                payload: queryParamsObj,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (appConfig.ENABLE_DEV_FEATURES?.toLowerCase() === 'true') {
            dispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
        if (query.get('enableDevFeatures')) {
            query.delete('enableDevFeatures')
            dispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${appConfig.BASE_EID_URL}/UI/Common/SAML2/HiddenBlankPage`}
                    />
                </div>
            )}
            <Switch>
                <Route exact path="/" component={GuidedShopOrApp} />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/`}
                    component={GuidedShopOrApp}
                />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/guidedShop`}
                    component={GuidedShop}
                />

                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/:resourceType/workflows`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <WorkFlows {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/computers/manageAccess/workflow/`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ComputerConnectWorkFlow {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />

                {manageAccess &&
                    resourceTypes.map((r) => (
                        <Route
                            key={`${manageAccess.name}_${r.name}`}
                            exact
                            path={`${appConfig.APP_SUBPATH}${r.route}${manageAccess.route}/:tab?`}
                            render={(props) => (
                                <ResourceTypeContextProvider>
                                    <ManageAccess
                                        resourceType={r.name}
                                        {...props}
                                    />
                                </ResourceTypeContextProvider>
                            )}
                        />
                    ))}

                {resourceTypes.map((r) => (
                    <Route
                        key={r.name}
                        exact
                        path={`${appConfig.APP_SUBPATH}${r.route}`}
                        render={(props) => (
                            <ResourceTypeContextProvider>
                                <ListingPage resourceType={r.name} {...props} />
                            </ResourceTypeContextProvider>
                        )}
                    />
                ))}
                <Redirect from="" to={`${appConfig.APP_SUBPATH}/notfound`} />
            </Switch>
            {loginSessionTimeout && (
                <IdleTimer loginSessionTimeout={loginSessionTimeout} />
            )}
        </Fragment>
    )
}

//@ts-ignore
export default withRequiredData(requireMasterPassword(App))
