import { Box } from '@mui/material'
import {
    useAccessRequestPolicy,
    useAddItemToCart,
    useCheckIfMasterPasswordExist,
    useTargetPerson,
} from 'hooks'
import { EidButton, Tooltip, useNotification } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import moment from 'moment'
import { isItemAlreadyInCart } from 'utils'
import { useAllCarts } from 'hooks'

const ExtendButton = ({ data }) => {
    const { data: allCarts } = useAllCarts()

    const item = data?.resource
    const assignment = data?.assignment
    const { t } = useTranslation()
    const [addItemToCart, { isLoading: isAddingToCart }] = useAddItemToCart()
    const [targetPerson] = useTargetPerson()
    const accessRequestPolicy = useAccessRequestPolicy(item?.requestPolicyId)
    const { masterPasswordExists } = useCheckIfMasterPasswordExist()
    const { showWarningMessage } = useNotification()

    const currentPersonCart = allCarts.filter(
        (el) => el.targetPersonId === assignment?.personId,
    )

    const itemAlreadyInCart = isItemAlreadyInCart(
        currentPersonCart,
        item?.id,
        assignment?.resourceAssignment?.locationId,
    )

    const handleExtension = (item) => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        const itemToAdd = cartHelpers.sharedCredentialToCartItem({
            targetPerson,
            assignmentType: 'Add',
            credential: item,
            timeConstrained: true,
            startDateUtc: assignment?.assignmentDetails?.endDateUtc,
            endDateUtc: moment(assignment?.assignmentDetails?.endDateUtc).add(
                accessRequestPolicy?.defaultValueInMinutes,
                'm',
            ),
            ...item,
        })

        addItemToCart(itemToAdd)
    }

    return (
        <Box>
            <Tooltip
                title={
                    itemAlreadyInCart
                        ? t('Common_ItemAlreadyInCart')
                        : t('Credentials_ExtendMessage')
                }
            >
                <span>
                    <EidButton.ExtendListing
                        loading={isAddingToCart}
                        onClick={() => handleExtension(item)}
                        disabled={itemAlreadyInCart}
                    >
                        <span style={{ fontSize: '11px', lineHeight: '1.0' }}>
                            {t('Credentials_Extend')}
                        </span>

                        <Icon
                            name="ArrowSmall"
                            fill="white"
                            color="white"
                            direction="right"
                            style={{ marginLeft: '5px' }}
                        />
                    </EidButton.ExtendListing>
                </span>
            </Tooltip>
        </Box>
    )
}

export default ExtendButton
