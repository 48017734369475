import { FC, Fragment } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import appConfig from 'config'
import RevokeButton from 'containers/ManageAccess/RevokeButton'
import { PreApprovedItemWrapper } from 'components/PreApprovedItems/WrapperForPreApprovedAccessItem'
import { Pagination } from 'packages/eid-ui'
import {
    hasValidJson,
    updateJsonMappingWithApiFormat,
    useIsSmallScreen,
} from 'packages/core'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import { FieldTypeValuesAssignmentPopup } from 'packages/eid-ui'
import { useResourceTypeContext } from 'resourceTypeContext'

export type ProtectedApplicationAssignmentInfoProps = {
    title: string
    resourceAssignment?: any
    resourceType: any
    totalCount?: number
    pageCount?: number
    setPage?: (value: any) => void
    page?: any
    manageAccessRedirectTab: string
    applicationId: string
    applicationTypeRegistry: any
    mappingKeyForType: string
}

export const ProtectedApplicationAssignmentInfo: FC<
    ProtectedApplicationAssignmentInfoProps
> = (props: ProtectedApplicationAssignmentInfoProps) => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        resourceAssignment,
        title,
        resourceType,
        totalCount = 0,
        pageCount = 1,
        setPage,
        page,
        manageAccessRedirectTab,
        applicationId,
        applicationTypeRegistry,
        mappingKeyForType,
    } = props

    const isSmallScreen = useIsSmallScreen()
    const theme = useTheme()
    const [{ selectedAppRoleOrRightToHighLight }]: any =
        useResourceTypeContext()

    const getParsedObject = (apiDataObject: any) => {
        try {
            const parsedObject = Array.isArray(apiDataObject)
                ? apiDataObject[0]
                : apiDataObject
            return parsedObject
        } catch (err) {}
        return apiDataObject
    }

    const checkIfSelectedAccessAlreadyAssigned = (resource: any) => {
        try {
            return Boolean(
                selectedAppRoleOrRightToHighLight ===
                    resource?.[mappingKeyForType],
            )
        } catch (err) {}
        return false
    }

    return (
        <>
            <PreApprovedItemWrapper
                defaultExpand={page > 1}
                showExpand={resourceAssignment?.length ? true : false}
                title={title}
                itemCount={totalCount}
                accessKeyType={mappingKeyForType}
            >
                <Fragment>
                    {resourceAssignment?.length &&
                        resourceAssignment.map((item: any, index: number) => {
                            const resource = item?.resource
                            const isSelectedMatchedWithAssigned =
                                checkIfSelectedAccessAlreadyAssigned(resource)
                            return (
                                <>
                                    <Box
                                        key={`application-assignment-tile-${title}-${index}`}
                                        sx={{
                                            boxShadow:
                                                '0 4px 16px 0 rgba(0, 0, 0, 0.09)',
                                            borderRadius: '8px',
                                            padding: '2rem',
                                            background:
                                                theme.palette.common.white,
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            margin: '16px 0',
                                            borderImageSource:
                                                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab936e 7%, #2c83bd47 91%, rgba(48, 127, 193, 0))',
                                            borderImageSlice: '1',
                                            borderBottomWidth: '1px',
                                            borderStyle: 'solid',
                                            borderTopWidth: '0',
                                            ...(isSelectedMatchedWithAssigned && {
                                                background:
                                                    'linear-gradient(white, white) padding-box, linear-gradient(to right, #682a94, #05afe0) border-box',
                                                borderRadius: '.8rem',
                                                border: '1px solid transparent',
                                                position: 'relative',
                                            }),
                                        }}
                                    >
                                        {isSelectedMatchedWithAssigned && (
                                            <Box
                                                sx={{
                                                    background:
                                                        'linear-gradient(107deg, #5a61f0, #e2568d)',
                                                    fontSize: '1.2rem',
                                                    position: 'absolute',
                                                    top: '-1.4rem',
                                                    padding: '0.4rem 0.8rem',
                                                    color: theme?.palette
                                                        ?.common?.white,
                                                    borderRadius: '.5rem',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {t('Assigned')}
                                            </Box>
                                        )}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: 'solid 1px #aab0b4',
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        lineHeight: '1',
                                                        padding: '4px 16px',
                                                        textAlign: 'center',
                                                        textTransform:
                                                            'uppercase',
                                                        color: '#262fa1',
                                                        ...(resource?.isAssigned && {
                                                            background:
                                                                theme.palette
                                                                    .common
                                                                    .white,
                                                            borderRadius: '5px',
                                                            color: theme.palette
                                                                .tertiary.main,
                                                        }),
                                                        ...(!resource?.isAssigned && {
                                                            background:
                                                                theme.palette
                                                                    .common
                                                                    .white,
                                                            borderRadius: '5px',
                                                            color: theme.palette
                                                                .secondary.main,
                                                        }),
                                                    }}
                                                >
                                                    {resource?.isAssigned
                                                        ? t('Assigned')
                                                        : t(
                                                              'Common_PreApproved',
                                                          )}
                                                </Box>
                                                <Box>
                                                    <Icon name="ArrowIconGreen" />
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        marginLeft: '15px',
                                                        fontSize: '14px',
                                                        color: theme?.palette
                                                            ?.common?.black,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {resource?.friendlyName ??
                                                        '-'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '1rem',
                                                '& button': {
                                                    textTransform: 'capitalize',
                                                    '& p': {
                                                        textTransform:
                                                            'capitalize',
                                                    },
                                                },
                                            }}
                                        >
                                            {item?.resource
                                                ?.assignedFieldTypeValuePreviewJson &&
                                                hasValidJson(
                                                    updateJsonMappingWithApiFormat(
                                                        getParsedObject(
                                                            JSON.parse(
                                                                item.resource
                                                                    ?.assignedFieldTypeValuePreviewJson,
                                                            ),
                                                        ),
                                                        false,
                                                    ),
                                                ) && (
                                                    <Box>
                                                        <FieldTypeValuesAssignmentPopup
                                                            fieldTypeValueJson={updateJsonMappingWithApiFormat(
                                                                getParsedObject(
                                                                    JSON.parse(
                                                                        item
                                                                            .resource
                                                                            ?.assignedFieldTypeValuePreviewJson,
                                                                    ),
                                                                ),
                                                                false,
                                                            )}
                                                            withBorder={true}
                                                            showLabel={false}
                                                        />
                                                    </Box>
                                                )}

                                            {item?.assignment
                                                ?.canActivateNow && (
                                                <ActivateNowButton
                                                    resource={item?.resource}
                                                    resourceType={resourceType}
                                                    width="90px"
                                                    assignment={
                                                        item?.assignment
                                                    }
                                                    forCardView={true}
                                                    resourceId={applicationId}
                                                    resourceAssignmentId={
                                                        //For applications, we need to pass resourceId from resourceAssignment Object as resourceAssignmentId
                                                        item?.assignment
                                                            ?.resourceAssignment
                                                            ?.resourceId
                                                    }
                                                />
                                            )}
                                            {item.assignment?.assignmentDetails
                                                ?.isRevokable && (
                                                <RevokeButton
                                                    resourceType={
                                                        applicationTypeRegistry
                                                    }
                                                    item={{
                                                        resource: resource,
                                                        assignment:
                                                            item.assignment,
                                                    }}
                                                    buttonStyles={{
                                                        minWidth: '72px',
                                                    }}
                                                    postRevoke={() => {}}
                                                />
                                            )}
                                            <Button
                                                sx={{
                                                    background:
                                                        theme?.palette?.common
                                                            ?.white,
                                                    padding: '7px 8px 7px 13px',
                                                    border: `solid 1px ${theme?.palette?.primary?.main}`,
                                                    borderRadius: '4px',
                                                    fontSize: '14px',
                                                    lineHeight: '1',
                                                    color: theme?.palette
                                                        ?.primary?.main,
                                                    textTransform: 'capitalize',
                                                    '& span': {
                                                        fontSize: '14px',
                                                        marginLeft: '0px',
                                                    },
                                                }}
                                                onClick={() =>
                                                    history.push(
                                                        `${
                                                            appConfig.APP_SUBPATH
                                                        }/applications/manageAccess?tab=${manageAccessRedirectTab}&selected=${
                                                            resource?.manageAccessId ||
                                                            resource.id
                                                        }`,
                                                    )
                                                }
                                                endIcon={
                                                    <Icon
                                                        name="ArrowSmall"
                                                        width="20px"
                                                        fill={
                                                            theme?.palette
                                                                ?.primary?.main
                                                        }
                                                        direction="right"
                                                    />
                                                }
                                                size={'medium'}
                                            >
                                                {t('Common_Manage')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    {pageCount > 1 && (
                        <Box
                            padding={!isSmallScreen ? '20px 32px' : '16px'}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ position: 'relative', zIndex: '999' }}
                        >
                            <Pagination
                                count={pageCount}
                                size={!isSmallScreen ? 'large' : 'small'}
                                page={page}
                                onChange={(_: any, value: any) =>
                                    typeof setPage === 'function' &&
                                    setPage(value)
                                }
                            />
                        </Box>
                    )}
                </Fragment>
            </PreApprovedItemWrapper>
        </>
    )
}
