import React, { useEffect } from 'react'
import { Loader } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import { useGetControlsAccess } from 'packages/core'
import { useDefaultFilters } from 'hooks'
import appConfig from 'config'

const GuidedShopOrApp = (props) => {
    const { history } = props

    const { resourceTypes } = useConfiguration()
    const controls = useGetControlsAccess()

    const shouldShowGuidedShopForFirstTimeLogin =
        controls.findIndex(
            (c) => c.name === 'ITShop-ShowGuidedShopForFirstTimeLogin-Control',
        ) >= 0

    const firstResourceTypeRoute =
        resourceTypes.length > 0 ? resourceTypes[0].route : undefined

    const { data: filtersData } = useDefaultFilters('')

    useEffect(() => {
        if (filtersData) {
            if (
                shouldShowGuidedShopForFirstTimeLogin &&
                filtersData.showGuidedShop
            ) {
                history.push(`${appConfig.APP_SUBPATH}/guidedShop`)
            } else {
                history.push(
                    `${appConfig.APP_SUBPATH}${firstResourceTypeRoute}` ??
                        `${appConfig.APP_SUBPATH}/forbidden`,
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, filtersData, firstResourceTypeRoute])

    return <Loader />
}

export default GuidedShopOrApp
