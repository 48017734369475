import React from 'react'
import Button from './index'
import { Box, Typography } from '@mui/material'
import useAppliedFilterValues from 'components/AppliedFilters/useAppliedFilterValues'

const styles = (theme) => ({
    normal: {
        minWidth: '160px',
        width: '100%',
        backgroundColor: theme?.palette?.background?.paper,
        color: '#363636',
        border: 'solid 1px #d2d2d9',

        '& >span': { display: 'flex', flexDirection: 'column' },
        '&:hover': {
            backgroundColor: theme?.palette?.background?.paper,
            opacity: 0.95,
        },
        '&:active': {
            backgroundColor: theme?.palette?.background?.paper,
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: `${theme?.palette?.common?.white} !important`,
    },

    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.15,
    },
    subLabel: {
        fontSize: '11px',
        color: theme?.palette?.grey?.[900],
        lineHeight: 1.15,
    },
    count: {
        position: 'absolute',
        top: '10px',
        right: '12px',
        fontSize: '12px',
        borderRadius: '50%',
        backgroundColor: 'rgb(48,127,193)',
        height: '14px',
        width: '18px',
        color: theme?.palette?.common?.white,
        paddingBottom: '19px',
    },
})

const FiltersButton = ({
    children,
    label,
    subLabel,
    currentResourceType,
    ...rest
}) => {
    const allAppliedFilterValues = useAppliedFilterValues(currentResourceType)

    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Typography style={styles.label}>{label}</Typography>

                    <Typography style={styles.subLabel}>{subLabel}</Typography>

                    {allAppliedFilterValues &&
                        allAppliedFilterValues.length > 0 && (
                            <Box style={styles.count}>
                                <span>{allAppliedFilterValues.length}</span>
                            </Box>
                        )}
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default FiltersButton
