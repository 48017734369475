
import { Select } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const getOptionLabel = (option) => option.friendlyName

const CredentialsSearch = (props) => {
    const { selectedCredentials, credentialsOptions, isLoading, setSelectedCredentials } = props
    const { t } = useTranslation()
    return (
        <Select
            placeholder={t('Common_SelectCredentials')}
            options={credentialsOptions}
            loading={isLoading}
            onChange={(selectedItem) => {
                setSelectedCredentials(selectedItem)
            }}
            value={selectedCredentials}
            getOptionLabel={getOptionLabel}
        />
    )
}

export default CredentialsSearch
