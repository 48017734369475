import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import moment from 'moment'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import { PasswordModal } from 'components/PasswordModal'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import ComputersConnectedWhen from 'components/DynamicComponents/ComputersConnectedWhen'
import CredentialsLockedAttribute from 'components/DynamicComponents/CredentialsLockedAttribute'
import CredentialStatusButton from 'components/DynamicComponents/CredentialStatusButton'
import CredentialRemainingTime from 'components/DynamicComponents/CredentialRemainingTime'
import DynamicComponents from 'components/DynamicComponents'

const useStyles = makeStyles((theme) =>
    createStyles({
        cardroot: {
            display: 'block',
            alignItems: 'center',
            marginBottom: '5px',
            '& h6': {
                display: 'block',
            },
            '& p': {
                padding: '0 !important',
                '& button': {
                    width: '115px',
                    textAlign: 'center',
                },
            },
            '& p p': {
                padding: '0 !important',
            },
        },
        masteLockCustom: {
            '& >div div:nth-last-child(2)': {
                top: '-25px',
            },
        },
        cardinfo: {
            fontSize: '13px',
            color: theme?.palette?.common?.black,
            fontWeight: 400,
            marginBottom: '16px',
        },
        requestconnectbtn: {
            ' & > div >div': {
                maxWidth: '110px !important',
            },

            ' & span svg:nth-last-child(1)': {
                marginTop: '10px',
            },
            ' & button': {
                padding: '0 0 0 10px',

                ' & div': {
                    maxWidth: 'auto !important',
                },
            },
        },
        preApproved: {
            ' & > div div': {
                maxWidth: '90px !important',
            },
            ' & button': {
                padding: '0 !important',
                ' & p': {
                    width: '100%',
                    textAlign: 'center',
                },
            },
        },
        lockedInfo: {},
    }),
)

const Attribute = ({
    maxWidth,
    label,
    value,
    fallbackValue,
    orientation,
    labelStyles,
    valueStyles,
    type = '',
    item,
    attribute,
    data,
}) => {
    const [{ masterPassword }] = useAppState()
    const [open, setOpen] = useState('')
    const { t } = useTranslation()
    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }
    const display = orientation === 'vertical' ? 'block' : 'inline'
    const labelText = orientation === 'horizontal' ? `${label}: ` : label

    const classes = useStyles()
    const theme = useTheme()

    const bindValue = (item, attribute) => {
        if (value) {
            if (type && type === 'datelocal') {
                return moment.utc(value).local().format('L LT')
            } else if (type && type === 'duration') {
                return (
                    <span>
                        <ComputersConnectedWhen
                            attribute={attribute}
                            data={data}
                        />
                    </span>
                )
            } else if (type && type === 'credentialLockedAttribute') {
                return (
                    <Box className={classes.lockedInfo}>
                        <CredentialsLockedAttribute
                            attribute={attribute}
                            data={data}
                        />
                    </Box>
                )
            } else if (type && type === 'credentialStatusBtn') {
                return <CredentialStatusButton data={data} />
            } else if (type && type === 'CredentialRemainingTime') {
                return <CredentialRemainingTime data={data} />
            } else if (attribute?.component) {
                const DynamicComponentToRender =
                    DynamicComponents[attribute.component.name]

                return (
                    <div>
                        <DynamicComponentToRender
                            attribute={attribute}
                            data={data}
                        />
                    </div>
                )
            } else {
                return value
            }
        } else {
            if (type && type === 'computerConnectBtn') {
                return (
                    <Box className={classes.masteLockCustom}>
                        <MasterLockOverlay
                            btnLabel={t('Common_Unlock')}
                            showMasterLock={!masterPassword}
                            handleMasterUnlock={handleMasterUnlock}
                            styles={{
                                minHeight: '65px',
                                marginLeft: '-32px',
                                marginRight: '-20px',
                                maxWidth: '200px',
                                width: '100%',
                            }}
                        >
                            <Box className={classes.cardinfo}>
                                <Box
                                    className={classNames(
                                        classes.requestconnectbtn,
                                        {
                                            [classes.preApproved]:
                                                item.isPreApproved,
                                        },
                                    )}
                                >
                                    <ComputerConnectBtn
                                        parentStyle={{}}
                                        showPrefixIcon={false}
                                        data={item}
                                        drawerView={false}
                                        showCalenderIcon={true}
                                    />
                                </Box>
                            </Box>
                        </MasterLockOverlay>
                    </Box>
                )
            } else if (type && type === 'credentialLockedAttribute') {
                return (
                    <Box className={classes.lockedInfo}>
                        <CredentialsLockedAttribute
                            attribute={attribute}
                            data={data}
                        />
                    </Box>
                )
            } else if (type && type === 'credentialStatusBtn') {
                return <CredentialStatusButton data={data} />
            } else if (attribute?.component) {
                const DynamicComponentToRender =
                    DynamicComponents[attribute.component.name]

                return (
                    <div>
                        <DynamicComponentToRender
                            attribute={attribute}
                            data={data}
                        />
                    </div>
                )
            } else {
                return <span>{fallbackValue}</span>
            }
        }
    }

    return (
        <Box style={{ maxWidth }}>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}
            <Box className={classes.cardroot}>
                <Typography
                    variant="subtitle1"
                    display={display}
                    style={{ color: '#b4b4b4', ...labelStyles }}
                >
                    {labelText}
                </Typography>
                <Typography
                    display={display}
                    style={{
                        color: theme?.palette?.common?.black,
                        wordWrap: 'break-word',
                        fontSize: '14px',

                        ...valueStyles,
                    }}
                >
                    {bindValue(item, attribute)}
                </Typography>
            </Box>
        </Box>
    )
}

Attribute.propTypes = {
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
}

Attribute.defaultProps = {
    maxWidth: 'fit-content',
    label: '',
    orientation: 'horizontal',
    fallbackValue: '-',
    labelStyles: {},
    valueStyles: {},
}

export default Attribute
