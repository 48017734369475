import { Box, styled } from '@mui/material'
import { ItemDetails } from 'components'
import ComputersPagination from 'components/ComputerSessionRequest/Pagination'
import {
    useComputerLoginSessionHistoryDetails,
    useCurrentPerson,
    useTargetPerson,
} from 'hooks'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionHistoryFilters from './Filters'
import ComputerSessionHistoryTile from './SessionHistoryTile'
import moment from 'moment'
import { useIsSmallScreen } from 'packages/core'

interface PaddedDivProps {
    isSmallScreen: boolean
}

const PaddedDiv = styled(Box)<PaddedDivProps>(({ isSmallScreen }) => ({
    padding: isSmallScreen ? '0 16px' : '0 31px',
}))

export interface ComputerSessionHistoryProps {
    item: any
}
const defaultTake = 10
export const ComputerSessionHistory: FC<ComputerSessionHistoryProps> = (
    props,
) => {
    const { item } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const { data: currentPerson } = useCurrentPerson()
    const [isActiveConnectionOnly, setIsActiveConnectionOnly] =
        useState<boolean>(false)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [queryParms, setQueryParams] = useState<string>('')
    const [page, setPage] = useState(1)
    const [selectedPerson, setSelectedPerson] = useState<any>(currentPerson)
    const [perPageItems, setPerPageItems] = useState(defaultTake) //take
    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const handlePageSelection = (value: any) => {
        setPage(value)
    }

    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }
    const skip = (page - 1) * perPageItems
    const { data: loginHistorySessionDetails, isLoading } =
        useComputerLoginSessionHistoryDetails(queryParms)
    const numberOfPages = loginHistorySessionDetails
        ? Math.ceil(loginHistorySessionDetails.totalCount / perPageItems)
        : 0
    const isSmallScreen = useIsSmallScreen()

    const handleFilterChange = (change: any) => {
        switch (change.prop) {
            case 'startDate':
                setStartDate(change.value)
                break
            case 'endDate':
                setEndDate(change.value)
                break
            case 'isActiveConnectionOnly':
                setIsActiveConnectionOnly(change.value)
                break
            case 'selectedPerson':
                setSelectedPerson(change.value)
                break
        }
        setPage(1)
    }
    useEffect(() => {
        let queryParams = `?ShowActiveConnections=${
            isActiveConnectionOnly ? isActiveConnectionOnly : ''
        }&skip=${skip}&take=${perPageItems}`
        if (startDate) {
            queryParams =
                queryParams +
                `&StartDateAfter=${moment(startDate)
                    .startOf('day')
                    .set('millisecond', 0)
                    .utc()
                    .format()}`
        }
        if (endDate) {
            queryParams =
                queryParams +
                `&EndDateBefore=${moment(endDate)
                    .endOf('day')
                    .set('millisecond', 0)
                    .utc()
                    .format()}`
        }
        if (item.id) {
            queryParams = queryParams + `&ComputerId=${item.id}`
        }
        if (selectedPerson && selectedPerson.id) {
            queryParams =
                queryParams +
                `&TargetPersonId=${targetPerson.id}&RequestedByPersonId=${selectedPerson.id}`
        } else if (targetPerson.id) {
            queryParams = queryParams + `&TargetPersonId=${targetPerson.id}`
        }

        setQueryParams(queryParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isActiveConnectionOnly,
        startDate,
        endDate,
        page,
        selectedPerson,
        perPageItems,
    ])

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv isSmallScreen={isSmallScreen}>
                <ComputerSessionHistoryFilters
                    setEndDate={(e: string) =>
                        handleFilterChange({ prop: 'endDate', value: e })
                    }
                    setStartDate={(e: string) =>
                        handleFilterChange({ prop: 'startDate', value: e })
                    }
                    endDate={endDate}
                    startDate={startDate}
                    isActiveConnectionOnly={isActiveConnectionOnly}
                    handleCheckedClick={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) =>
                        handleFilterChange({
                            prop: 'isActiveConnectionOnly',
                            value: e.target.checked,
                        })
                    }
                    onPersonSelect={(e: any) =>
                        handleFilterChange({ prop: 'selectedPerson', value: e })
                    }
                    selectedPerson={selectedPerson}
                    dateContainerLabel={t('SessionHistoryDatesBetween')}
                />
            </PaddedDiv>
            {isLoading && (
                <PaddedDiv isSmallScreen={isSmallScreen}>
                    <ItemDetails.AttributesLoader />
                </PaddedDiv>
            )}
            {loginHistorySessionDetails?.data?.length > 0 && (
                <>
                    {loginHistorySessionDetails.data.map(
                        (sesReq: any, index: number) => (
                            <ComputerSessionHistoryTile
                                key={`session-request-${index}`}
                                item={sesReq}
                            ></ComputerSessionHistoryTile>
                        ),
                    )}
                    <ComputersPagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pageCount={numberOfPages}
                        handlePageSelection={handlePageSelection}
                        totalCount={loginHistorySessionDetails?.totalCount}
                        perPageItems={perPageItems}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                    />
                </>
            )}
            {!isLoading &&
                loginHistorySessionDetails &&
                loginHistorySessionDetails.data &&
                loginHistorySessionDetails.data.length <= 0 && (
                    <PaddedDiv isSmallScreen={isSmallScreen}>
                        <p>
                            {t('Common_NoItemsFound', {
                                itemType: t('Common_SessionHistory'),
                            })}
                        </p>
                    </PaddedDiv>
                )}
        </ItemDetails.AttributesContainer>
    )
}

export default ComputerSessionHistory
