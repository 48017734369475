import { Box } from '@mui/material'
import { useApplicationManagementRoleAssignments, useTargetPerson } from 'hooks'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { ProtectedApplicationAssignmentInfo } from '../AssignmentTile'
import { useResourceTypeContext } from 'resourceTypeContext'

const take = 3

export type ApplicationManagementRolesAssignmentsProps = {
    applicationId: string
    applicationsType: any
    applicationTypeRegistry: any
    setShowSpacer: any
}

export const ApplicationManagementRolesAssignments: FC<
    ApplicationManagementRolesAssignmentsProps
> = (props: ApplicationManagementRolesAssignmentsProps) => {
    const [page, setPage] = useState(1)
    const managementRoleRef = useRef<HTMLDivElement>(null);

    const scrollToDiv = () => {
        if (managementRoleRef.current) {
            managementRoleRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const {
        applicationId,
        applicationsType,
        applicationTypeRegistry,
        setShowSpacer,
    } = props

    const [targetPerson] = useTargetPerson()
    const { t } = useTranslation()
    const [{ selectedAppRoleOrRightToHighLight }, dispatch]: any = useResourceTypeContext()
    const { data, isLoading } = useApplicationManagementRoleAssignments(
        applicationId,
        targetPerson.id,
        (page - 1) * take,
        take,
    )
    const pageCount = data?.totalCount ? Math.ceil(data.totalCount / take) : 0

    useEffect(() => {
        if (data?.data?.length) {
            setShowSpacer(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (data?.data?.length && selectedAppRoleOrRightToHighLight) {
            const isSelectedExist = data.data.find((x: any) => (x?.id && x.id === selectedAppRoleOrRightToHighLight))
            if (isSelectedExist) {
                dispatch({
                    type: 'UPDATE_SELECTED_ACCESS_LEVEL_ALREADY_ASSIGNED',
                    payload: {
                        selectedAppRoleOrRightAlreadyAssigned: true,
                        accessType: 'id'
                    },
                })
                scrollToDiv()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAppRoleOrRightToHighLight])

    return (
        <Box>
            {isLoading ? (
                <Skeleton height={40} />
            ) : data?.data?.length ? (
                <Box ref={managementRoleRef}>
                    <ProtectedApplicationAssignmentInfo
                        totalCount={data?.totalCount}
                        setPage={(value: any) => setPage(value)}
                        pageCount={pageCount}
                        resourceAssignment={data?.data || []}
                        title={t('Common_AppManagementRoles')}
                        resourceType={applicationsType}
                        manageAccessRedirectTab="appManagementRoles"
                        applicationId={applicationId}
                        applicationTypeRegistry={applicationTypeRegistry}
                        mappingKeyForType='id'
                    />
                </Box>
            ) : null}
        </Box>
    )
}
