import React, { Fragment } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useApplicationRoleOwnersAndApprovers } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import useSubcomponents from 'useSubcomponents'

const hasAccessToOwnerControl = 'ITShop-Groups-Details-Owners-Control';
const hasAccessToApproversControl = 'ITShop-Groups-Details-Approvers-Control';

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const MoreInformationSection = ({ applicationRole }: any) => {
    const { t } = useTranslation()
    const { hasAccessToLocalSensitiveFunctionsGrid, hasAccessToControl } = useSubcomponents()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        data: ownersAndApprovers,
        isLoading: ownersAndApproversLoading,
    } = useApplicationRoleOwnersAndApprovers((hasAccessToControl(hasAccessToOwnerControl) || hasAccessToControl(hasAccessToApproversControl)) ? applicationRole.id : null)

    return (
        <Fragment>
            {ownersAndApproversLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        {hasAccessToControl(hasAccessToOwnerControl) &&
                            <Box data-protectedsubcomponent={hasAccessToOwnerControl}>
                                <ItemDetails.Attribute
                                    label={t('ApplicationRoles_Owners')}
                                    value={
                                        <PeopleList list={ownersAndApprovers.owners} />
                                    }
                                    orientation={attributeOrientation}
                                />
                            </Box>}
                        {hasAccessToControl(hasAccessToApproversControl) &&
                            <Box data-protectedsubcomponent={hasAccessToApproversControl}>
                                <ItemDetails.Attribute
                                    label={t('Common_Approvers')}
                                    value={
                                        <PeopleList
                                            list={ownersAndApprovers.approvers}
                                        />
                                    }
                                    orientation={attributeOrientation}
                                />
                            </Box>}
                    </PaddedDiv>

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <LocalSensitiveFunctions
                            applicationRole={applicationRole}
                            protectSubcomponent={hasAccessToLocalSensitiveFunctionsGrid}
                        />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </Fragment>
    )
}
