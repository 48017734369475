import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import {
    useAccessRequestPolicy,
    useComputerOwners,
    useTargetPerson,
} from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import moment from 'moment'
import { Divider } from 'packages/eid-ui'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import { RenewRevoke } from 'components/RenewRevoke'
import { ResourceTypesNamespace, useRegistry } from 'core'
import useConfiguration from 'useConfiguration'
import cartHelpers from 'containers/Cart/cartHelpers'
import useSubcomponents from 'useSubcomponents'
import DynamicFieldTypesSelector from 'containers/ListingPage/Applications/DynamicFieldTypesSelector'

const hasAccessToOwnerControl =
    'ITShop-Applications-ManageAccess-RoleDefinitions-Details-Owners-Control'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const role = item.resource
    const { t } = useTranslation()
    const requestPolicy = useAccessRequestPolicy(role?.requestPolicyId)

    const attributeOrientation = 'horizontal'
    const DateComponentForManageAccess = (attributeName, data) => {
        const timeConstraintActive =
            data.assignment?.assignmentDetails?.timeConstraintActive

        return (
            <>
                {!timeConstraintActive
                    ? '-'
                    : moment
                          .utc(
                              data?.assignment?.assignmentDetails[
                                  attributeName
                              ],
                          )
                          .clone()
                          .local()
                          .format('LLL')}
            </>
        )
    }
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_FriendlyName')}
                    value={role?.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={role?.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={role?.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Type')}
                    value={role?.azRoleTypeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_StartDate')}
                    value={DateComponentForManageAccess('startDateUtc', item)}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_EndDate')}
                    value={DateComponentForManageAccess('endDateUtc', item)}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <Typography
                    style={{
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        margin: '16px 0px',
                    }}
                >
                    {role?.isAzureApplication
                        ? t('AzureApplicationDetails')
                        : t('ApplicationDetails')}
                </Typography>
                <ItemDetails.Attribute
                    label={t('Common_FriendlyName')}
                    value={role?.applicationFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={role?.applicationName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {role?.azureApplicationId && (
                    <ItemDetails.Attribute
                        label={t('AzureApplicationId')}
                        value={role?.azureApplicationId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}

                {role?.azureApplicationObjectId && (
                    <ItemDetails.Attribute
                        label={t('AzureApplicationObjectId')}
                        value={role?.azureApplicationObjectId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {role?.servicePrincipalObjectId && (
                    <ItemDetails.Attribute
                        label={t('ServicePrincipalObjectId')}
                        value={role?.servicePrincipalObjectId}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {requestPolicy && (
                    <>
                        <Divider style={{ margin: '3.6rem 0 1.2rem' }} />
                        <AccessRequestPolicyDetails
                            policy={requestPolicy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const role = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        hasAccessToControl(hasAccessToOwnerControl)
            ? role?.azLocalRoleId
            : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '-0.6rem',
                                    marginTop: '-1.2rem',
                                }}
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}
const AppRoleDefinitionDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const registry = useRegistry()
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('Applications')
    const [activeTab, setActiveTab] = useState('overview')
    const applicationType = registry.get(ResourceTypesNamespace, 'Applications')

    const fieldTypeSelector = () => {
        if (item?.assignment?.canActivateNow || !item?.resource?.assignedFieldTypeValuePreviewJson) {
            return null
        }
        return (
            <DynamicFieldTypesSelector
                resourceItem={item}
                accessLevel={item.resource}
                toggleDrawer={toggleDrawer}
                applicationType={applicationType}
            />
        )
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
                style={{ marginBottom: '2rem' }}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />

                {/* <ItemDetails.Tabs.Tab
                    value="actions"
                    label={t('Common_Actions')}
                /> */}
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            {fieldTypeSelector()}
            {item?.assignment.canActivateNow && (
                <ActivateNowButton
                    resource={item.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                    assignment={item?.assignment}
                />
            )}
        </>
    )
}

export default AppRoleDefinitionDetails
