import { withRouter } from 'react-router'
import { EidLogoIcon, Icon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { Box, styled, Typography, useTheme } from '@mui/material'
import { useEnvironment } from 'hooks'
import LanguageMenu from './LanguageMenu'
import HelpMenu from './HelpMenu'
import AccountDropdown from './AccountDropdown'
import appWindowConfig from 'config'
import ShoppingCartMenuIcon from './ShoppingCartMenuIcon'
import { useAppState } from 'appContext'
import { useQuery } from 'packages/core'
import { headerHeight } from 'utils'
import useSubcomponents from 'useSubcomponents'
import { useEffect, useRef } from 'react'

const StyledBox = styled(Box)({
    '@media (max-width:1159px)': {
        display: 'none',
    },
})

const StyledChip = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: theme?.palette?.common?.white,
    height: '13px',
    maxWidth: '48px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:1159px)': {
        left: '2px',
    },
}))

const OpenCartmenu = styled(Box)(({ theme }) => ({
    display: 'flex',
    background: theme?.palette?.background?.paper,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    padding: '1.8rem 3rem',
    position: 'absolute',
    top: '0',
    right: '0.5rem',
    zIndex: '9999',
    animationDelay: '-0.6s',
    borderRadius: '4px',
    '& hr': {
        display: 'none',
    },
    '& div': {
        minWidth: 'auto !important',
        marginRight: 'auto !important',
    },
}))

const ITShopHeader = () => {
    const { data: env } = useEnvironment()
    const [{ appConfig: config, devFeaturesEnabled }] = useAppState()
    const query = useQuery()
    const cartOpen = query.get('cart') === 'visible'
    const headerStyleConfig = config.style.header
    const theme = useTheme()
    const ref = useRef(null)
    const { hasAccessToHelpMenu } = useSubcomponents()
    const environmentName = env?.environmentName
    const [_, dispatchAppState] = useAppState()

    useEffect(() => {
        dispatchAppState({
            type: 'SET_HEADER_HEIGHT',
            payload: ref?.current?.firstChild.offsetHeight,
        })
    }, [])
    return (
        <>
            <div ref={ref}>
                <Header
                    bgColor={headerStyleConfig.bgColor}
                    height={headerHeight}
                    borderBottom={
                        config?.style?.header?.bottomColor
                            ? {
                                  borderBottom: `2px solid ${config.style.header.bottomColor}`,
                              }
                            : {}
                    }
                >
                    <Header.IconLink
                        logo
                        href={
                            headerStyleConfig.eidLogo?.url
                                ? headerStyleConfig.eidLogo?.url
                                : `${appWindowConfig.APP_SUBPATH}/`
                        }
                        rightDivider={headerStyleConfig.showEidLogo}
                    >
                        {headerStyleConfig.showEidLogo && (
                            <>
                                {headerStyleConfig.eidLogo?.type ? (
                                    <>
                                        {headerStyleConfig.eidLogo?.type ===
                                        'icon' ? (
                                            <Icon
                                                style={
                                                    headerStyleConfig.eidLogo
                                                        ?.style
                                                }
                                                name={
                                                    headerStyleConfig.eidLogo
                                                        ?.value
                                                }
                                                color={
                                                    theme?.palette?.primary
                                                        ?.main
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={
                                                    headerStyleConfig.eidLogo
                                                        ?.style
                                                }
                                                src={
                                                    headerStyleConfig.eidLogo
                                                        ?.value
                                                }
                                                alt={
                                                    headerStyleConfig.eidLogo
                                                        ?.name
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    <EidLogoIcon />
                                )}
                            </>
                        )}
                    </Header.IconLink>
                    <Header.IconLink
                        logo
                        href={`${appWindowConfig.APP_SUBPATH}/`}
                    >
                        {headerStyleConfig?.appLogo?.type === 'icon' ? (
                            <Icon
                                style={headerStyleConfig?.appLogo?.style}
                                name={headerStyleConfig?.appLogo?.value}
                                color={theme?.palette?.primary?.main}
                            />
                        ) : (
                            <img
                                style={headerStyleConfig?.appLogo?.style}
                                src={headerStyleConfig?.appLogo?.value}
                                alt={headerStyleConfig?.appLogo?.name}
                            />
                        )}
                    </Header.IconLink>
                    <StyledBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {headerStyleConfig?.appTitle?.type === 'text' ? (
                            <Header.Title
                                style={headerStyleConfig?.appTitle?.style}
                            >
                                {headerStyleConfig?.appTitle?.value}
                            </Header.Title>
                        ) : (
                            <Header.IconLink
                                logo
                                href={`${appWindowConfig.APP_SUBPATH}/`}
                            >
                                <img
                                    style={headerStyleConfig?.appTitle?.style}
                                    src={headerStyleConfig?.appTitle?.value}
                                    alt={headerStyleConfig?.appTitle?.name}
                                />
                            </Header.IconLink>
                        )}
                    </StyledBox>
                    {headerStyleConfig.showEnvironment && environmentName && (
                        <Box position="relative" width="32px">
                            <Tooltip title={environmentName}>
                                <StyledChip>
                                    <Typography
                                        noWrap
                                        style={{
                                            fontSize: '8px',
                                            padding: '0px 1.5px',
                                        }}
                                    >
                                        {environmentName}
                                    </Typography>
                                </StyledChip>
                            </Tooltip>
                        </Box>
                    )}
                    {devFeaturesEnabled && (
                        <Box position="relative">
                            <StyledChip>
                                <Typography
                                    noWrap
                                    style={{
                                        fontSize: '8px',
                                        padding: '0px 1.5px',
                                    }}
                                >
                                    - WIP
                                </Typography>
                            </StyledChip>
                        </Box>
                    )}

                    <Header.RightSection>
                        <Header.Item
                            style={{ minWidth: '32px', padding: '0px 8px' }}
                        >
                            <LanguageMenu
                                anchorPosition={{
                                    right: '-32px',
                                    top: '54px',
                                }}
                            />
                        </Header.Item>
                        {hasAccessToHelpMenu && (
                            <Box
                                data-protectedsubcomponent={hasAccessToHelpMenu}
                                style={{
                                    padding: '2.4rem 0',
                                }}
                            >
                                <Header.Item
                                    style={{
                                        minWidth: '32px',
                                        padding: '0px 8px',
                                    }}
                                >
                                    <HelpMenu />
                                </Header.Item>
                            </Box>
                        )}

                        <Header.Item
                            style={{ minWidth: '32px', padding: '0px 8px' }}
                        >
                            <AccountDropdown />
                        </Header.Item>
                        <ShoppingCartMenuIcon />
                    </Header.RightSection>
                </Header>
            </div>

            {cartOpen && (
                <Box
                    sx={{
                        position: 'fixed',
                        left: '50%',
                        width: '100%',
                        maxWidth: '100%',
                        height: '100%',
                        zIndex: '1201',
                        pointerEvents: 'none',
                        transform: 'translateX(-50%)',
                        top: '0px',
                    }}
                >
                    <OpenCartmenu>
                        <ShoppingCartMenuIcon
                            hideOnOpenDrawer={false}
                            showDrawer={false}
                        />
                    </OpenCartmenu>
                </Box>
            )}
        </>
    )
}
export default withRouter(ITShopHeader)
