import { FC, useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { Modal } from 'components/Modal'
import { PasswordInput } from 'components/PasswordInput'
import { ItemDetails } from 'components'
import { useTranslation } from 'react-i18next'
import {
    useCurrentPerson,
    usePasswordPolicy,
    useSetMasterPassword,
} from 'hooks'
import PasswordStrengthMeter from './PasswordStrengthMeter'
import PasswordValidation from './PasswordValidation'
import { Loader, ValidationMessage } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import { countPasswordConditions } from './PasswordValidationUtils'
import { useAppState } from 'appContext'

export interface SetPasswordProps {
    open?: any
    onClose?: any
    onCancel?: any
}

export const SetPassword: FC<SetPasswordProps> = ({ open, onClose }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { data: currentPerson } = useCurrentPerson()

    const [password, setPassword] = useState('')
    const [confirmationPassword, setConfirmationPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [, dispatch]: any = useAppState()
    const [setMasterPassword, { isLoading }]: any = useSetMasterPassword()

    const [totalPasswordConditions, setTotalPasswordConditions] = useState(4)
    const [satisfiedPasswordConditions, setSatisfiedPasswordConditions] =
        useState(0)

    const { data: passwordPolicy, isLoading: loadingPasswordPolicy } =
        usePasswordPolicy()

    useEffect(() => {
        if (passwordPolicy) {
            const { satisfiedCount, totalCount } = countPasswordConditions(
                password,
                passwordPolicy.conditions,
                currentPerson,
            )
            setTotalPasswordConditions(totalCount)
            setSatisfiedPasswordConditions(satisfiedCount)
        }
    }, [password, passwordPolicy])

    const handleSubmit = () => {
        if (password !== confirmationPassword) {
            setErrorMessage('Passwords do not match')
            return
        }
        setMasterPassword(password).then(
            (data: { data: { isSucess: any; message: any } }) => {
                if (data?.data.isSucess) {
                    dispatch({
                        type: 'SET_MASTER_PASSWORD',
                        payload: password,
                    })
                    setPassword('')
                    setConfirmationPassword('')
                    onClose()
                }
                if (!data?.data.isSucess && data?.data.message) {
                    setErrorMessage(t(data.data.message))
                }
            },
        )
    }

    const footerContent = (
        <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            padding="24px 32px"
        >
            <Box minWidth="88px">
                <ItemDetails.ActionButton
                    fontColor={theme?.palette?.common?.white}
                    bgColor={theme?.palette?.primary?.main}
                    width="fit-content"
                    minWidth="100%"
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={
                        satisfiedPasswordConditions !== totalPasswordConditions
                    }
                    disabledColor={theme?.palette?.disabled.main}
                >
                    {t('Common_Ok')}
                </ItemDetails.ActionButton>
            </Box>

            <Box minWidth="130px">
                <ItemDetails.ActionButton
                    fontColor={theme?.palette?.common?.white}
                    bgColor={'#aab0b4'}
                    width="fit-content"
                    disabled={true} //Cancel button is disabled to force user to set master password
                >
                    {t('Common_Cancel')}
                </ItemDetails.ActionButton>
            </Box>
        </Box>
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t(
                'Common_EnterTheNewMasterPasswordToEncryptDecryptYourSecrets',
            )}
            footerContent={footerContent}
            disableClose
        >
            <Box>{t('Common_MasterPasswordRequirementMessage')}</Box>

            <Box marginY="24px">
                <PasswordInput
                    value={password}
                    placeholder={t('Common_NewMasterPassword')}
                    onChange={(e) => {
                        setErrorMessage('')
                        setPassword(e.target.value)
                    }}
                    error={!isNilOrEmpty(errorMessage)}
                />
            </Box>

            <Box marginY="24px">
                <PasswordInput
                    value={confirmationPassword}
                    placeholder={t('Common_ConfirmPassword')}
                    onChange={(e) => {
                        setErrorMessage('')
                        setConfirmationPassword(e.target.value)
                    }}
                    error={!isNilOrEmpty(errorMessage)}
                />
            </Box>
            {errorMessage && (
                <Box marginY="24px">
                    <ValidationMessage message={errorMessage} />
                </Box>
            )}
            <PasswordStrengthMeter
                value={satisfiedPasswordConditions}
                maxValue={totalPasswordConditions}
            />
            {loadingPasswordPolicy ? (
                <Loader />
            ) : (
                <PasswordValidation
                    password={password}
                    passwordPolicyConditions={passwordPolicy?.conditions}
                    currentPerson={currentPerson}
                />
            )}
        </Modal>
    )
}
