import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { Box, styled } from '@mui/material'
import ApplicationAccessLevel from './ApplicationAccessLevel'
import { ApplicationOverView } from './ApplicationOverView'
import { ApplicationAdditionalInformation } from './AdditionalDetails'
import { ApplicationActions } from './ApplicationActions'
import { ProtectedApplicationAssignments } from './ProtectedApplicationAssignments/ProtectedApplicationAssignments'
import useSubcomponents from 'useSubcomponents'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import useConfiguration from 'useConfiguration'
import { useAccessRequestPolicy } from 'hooks'
import { ResourceTypesNamespace, useRegistry } from 'core'
import DynamicFieldTypesSelector from './DynamicFieldTypesSelector'

const SUBTABS = [
    {
        value: 'localSensitiveFunctions',
        label: 'Common_LocalFunctionalAccess',
        display: true,
        requireAccess: {
            control: 'ITShop-ApplicationDetails-SensitiveFunctions',
        },
    },
]

const ApplicationDetails = ({
    application,
    accessLevel,
    accessLevels,
    setAccessLevelValue,
    targetPerson,
    isCheckingAccess,
    applicationId,
    item,
}) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const [isFormValidated, setIsFormValidated] = useState(false)
    const registry = useRegistry()
    const applicationsType = registry.get(
        ResourceTypesNamespace,
        'Applications',
    )
    const subTabsAccess = SUBTABS.filter(
        (x) => x.display && hasAccessToControl(x.requireAccess?.control),
    )
    const [subActiveTab, setSubActiveTab] = useState(
        subTabsAccess && subTabsAccess.length > 0 ? subTabsAccess[0].value : '',
    )
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType('Applications')
    const [activeTab, setActiveTab] = useState('overview')

    const requestPolicy = useAccessRequestPolicy(
        accessLevel?.resourceAssignment?.requestPolicyId,
    )

    const fieldTypeSelector = () => {
        return (
            <DynamicFieldTypesSelector
                accessLevel={accessLevel?.resourceAssignment}
                isFormValidatedFromAccessLevel={isFormValidated}
            />
        )
    }
    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
                style={{ marginBottom: '2rem' }}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="action"
                    label={t('Common_Actions')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <ApplicationOverView
                    data={application}
                    policy={requestPolicy}
                />
            )}
            {activeTab === 'additionalInformation' && (
                <ApplicationAdditionalInformation
                    isAzureApplication={application.isAzureApplication}
                    applicationId={applicationId}
                />
            )}
            {activeTab === 'action' && (
                <ApplicationActions data={application} />
            )}

            {applicationId && (
                <ProtectedApplicationAssignments
                    application={application}
                    applicationsType={currentResourceType}
                    applicationId={applicationId}
                    applicationTypeRegistry={applicationsType}
                />
            )}

            {application && (
                <Box minWidth={'100%'}>
                    <ApplicationAccessLevel
                        accessLevel={accessLevel}
                        accessLevels={accessLevels?.filter(
                            (a) => !a.isAssigned,
                        )}
                        setAccessLevelValue={(val) => setAccessLevelValue(val)}
                        isCheckingAccess={isCheckingAccess}
                        application={application}
                        targetPerson={targetPerson}
                        fieldTypeSelector={fieldTypeSelector()}
                        setIsFormValidated={(val) => setIsFormValidated(val)}
                        isFormValidated={isFormValidated}
                    />
                </Box>
            )}
            {accessLevel && (
                <Fragment>
                    <Box paddingRight="12px">
                        <ItemDetails.Tabs
                            variant={'scrollable'}
                            value={subActiveTab}
                            onChange={(_, value) => setSubActiveTab(value)}
                        >
                            {subTabsAccess.map((subTab, i) => {
                                return (
                                    <ItemDetails.Tabs.Tab
                                        value={subTab.value}
                                        label={t(subTab.label)}
                                        data-protectedsubcomponent={
                                            subTab.requireAccess?.control
                                        }
                                    />
                                )
                            })}
                        </ItemDetails.Tabs>
                    </Box>

                    <Box overflow="auto">
                        {subActiveTab === 'localSensitiveFunctions' && (
                            <LocalSensitiveFunctions
                                resourceAssignment={
                                    accessLevel?.resourceAssignment
                                }
                            />
                        )}
                    </Box>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ApplicationDetails
