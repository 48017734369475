import React, { useEffect } from 'react'
import ListingPageDesktop from './ListingPageDesktop'
import { isNilOrEmpty, useIsSmallScreen, useQuery } from 'packages/core'
import ListingPageMobile from './ListingPageMobile'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useHistory } from 'react-router'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import useRequestAccessResources from './useRequestAccessResources'
import { Layout } from 'components'
import {
    useDefaultFilters,
    useGuidedShopFilters,
    useUpdateDefaultFilters,
} from 'hooks'
import SubHeader from 'components/SubHeader'
import { useViewIndicator } from 'packages/eid-ui'
import appConfig from 'config'
import { useAppState } from 'appContext'
import NoAccessInfo from 'components/NoAccessInfo'
import useSubcomponents from 'useSubcomponents'
import { RESOURCE_TYPE_NAMES } from 'utils'

const ListingPage = ({ resourceType }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()
    // eslint-disable-next-line
    const [_, dispatchAppState] = useAppState()
    const [{ savedFilters }] = useAppState()
    const [
        { sorting: sortQuery, shouldLoadTags },
        dispatch,
    ] = useResourceTypeContext()

    const setSortQuery = (newSortQuery) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const {
        resourceTypes,
        getResourceType,
        getResourceTypeAttributes,
    } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)
    let resourceTypeAttributes = getResourceTypeAttributes(
        currentResourceType.name,
    )

    const { hasAccessToTabs } = useSubcomponents()

    const {
        hasAccessToRequestAccess,
        hasAccessToAtleastOneTab,
    } = hasAccessToTabs(currentResourceType.name)
    // Filter for computers only
    if (currentResourceType && currentResourceType.name === 'Computers') {
        resourceTypeAttributes = resourceTypeAttributes.filter(
            (x) => x.requestAccessOnly,
        )
    }

    const title = t(currentResourceType.title)
    useEffect(() => {
        document.title = title
    }, [title])

    const {
        totalCount,
        loading,
        list,
        rest,
        loadingMore,
        infiniteScrollObervableDiv,
    } = useRequestAccessResources(currentResourceType, hasAccessToRequestAccess)

    useEffect(() => {
        if (rest && shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const id = query.get('selected')

    const isSmallScreen = useIsSmallScreen()

    const {
        clearGuidedShopFilters,
        getGuidedShopFilters,
    } = useGuidedShopFilters()

    useEffect(() => {
        const guidedShopFilters = getGuidedShopFilters()
        if (guidedShopFilters != null) {
            if (
                guidedShopFilters.accountStoreUsageType &&
                guidedShopFilters.accountStore
            ) {
                dispatch({
                    type: 'SET_MULTIPLE_PROPS',
                    payload: {
                        accountStoreUsageType: {
                            friendlyName:
                                guidedShopFilters.accountStoreUsageType
                                    .friendlyName,
                            id: guidedShopFilters.accountStoreUsageType.id,
                        },
                        accountStore: {
                            friendlyName:
                                guidedShopFilters.accountStore.friendlyName,
                            id: guidedShopFilters.accountStore.id,
                        },
                    },
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBoardingFilters =
        savedFilters && savedFilters?.[currentResourceType.name]
            ? savedFilters[currentResourceType.name]?.filters
            : {}

    const [
        saveFilters,
        { isLoading: savingFilters },
    ] = useUpdateDefaultFilters()

    const [{ tags, ...restFilters }] = useResourceTypeContext()

    const formsApplied =
        Object.keys(restFilters).findIndex(
            (k) =>
                k.startsWith('advancedSearch') &&
                k !== 'advancedSearch.tags' &&
                !isNilOrEmpty(restFilters[k]),
        ) >= 0
    const tagsApplied =
        Object.keys(restFilters).findIndex(
            (k) =>
                k.startsWith('advancedSearch.tags') &&
                Array.isArray(restFilters[k]) &&
                restFilters[k].length > 0,
        ) >= 0

    const advancedSearchApplied = formsApplied || tagsApplied
    const handleSaveFilters = () => {
        const filtersToSave = {
            ...onBoardingFilters,
        }

        if (currentResourceType.name === 'AzureRoles') {
            if (restFilters.hasOwnProperty('roleGroupType')) {
                filtersToSave.roleGroupType = restFilters.roleGroupType
            } else {
                filtersToSave.roleGroupType = null
            }

            if (restFilters.hasOwnProperty('roleType')) {
                filtersToSave.roleType = restFilters.roleType
            } else {
                filtersToSave.roleType = null
            }

            if (restFilters.hasOwnProperty('globalFunction')) {
                filtersToSave.globalFunction = restFilters.globalFunction
            } else {
                filtersToSave.globalFunction = null
            }
        }
        if (currentResourceType.name === 'Computers') {
            if (restFilters.hasOwnProperty('infrastructure')) {
                filtersToSave.infrastructure = restFilters.infrastructure
            } else {
                filtersToSave.infrastructure = null
            }

            if (restFilters.hasOwnProperty('operatingSystem')) {
                filtersToSave.operatingSystem = restFilters.operatingSystem
            } else {
                filtersToSave.operatingSystem = null
            }

            if (restFilters.hasOwnProperty('capability')) {
                filtersToSave.capability = restFilters.capability
            } else {
                filtersToSave.capability = null
            }

            if (restFilters.hasOwnProperty('environment')) {
                filtersToSave.environment = restFilters.environment
            } else {
                filtersToSave.environment = null
            }
        }

        if (currentResourceType.name === 'Credentials') {
            if (restFilters.hasOwnProperty('category')) {
                filtersToSave.category = restFilters.category
            }
        }

        if (currentResourceType.name === 'SharedFolders') {
            if (restFilters.hasOwnProperty('uncPath')) {
                filtersToSave.uncPath = restFilters.uncPath
            } else {
                filtersToSave.applicauncPathtionProcess = null
            }
        }

        if (currentResourceType.name === 'Mailboxes') {
            if (restFilters.hasOwnProperty('mailboxTypeId')) {
                filtersToSave.mailboxTypeId = restFilters.mailboxTypeId
            } else {
                filtersToSave.mailboxTypeId = null
            }
        }

        if (restFilters.hasOwnProperty('application')) {
            filtersToSave.application = restFilters.application
        } else {
            filtersToSave.application = null
        }

        filtersToSave.selectedResource = currentResourceType.name

        filtersToSave.showPreApproved = restFilters.showPreApproved

        if (restFilters.hasOwnProperty('applicationProcess')) {
            filtersToSave.applicationProcess = restFilters.applicationProcess
        } else {
            filtersToSave.applicationProcess = null
        }

        if (restFilters.hasOwnProperty('azureLicensePool')) {
            filtersToSave.azureLicensePool = restFilters.azureLicensePool
        } else {
            filtersToSave.azureLicensePool = null
        }

        if (restFilters.hasOwnProperty('azureTenant')) {
            filtersToSave.azureTenant = restFilters.azureTenant
        } else {
            filtersToSave.azureTenant = null
        }

        if (restFilters.hasOwnProperty('azureTenantSubscription')) {
            filtersToSave.azureTenantSubscription =
                restFilters.azureTenantSubscription
        } else {
            filtersToSave.azureTenantSubscription = null
        }

        if (restFilters.hasOwnProperty('businessFunctions')) {
            filtersToSave.businessFunctions = restFilters.businessFunctions
        } else {
            filtersToSave.businessFunctions = null
        }

        if (restFilters.hasOwnProperty('showSuggested')) {
            filtersToSave.showSuggestedApplicationRoles =
                restFilters.showSuggested
        } else {
            filtersToSave.showSuggestedApplicationRoles = false
        }

        if (
            restFilters.hasOwnProperty('tCode') &&
            restFilters.hasOwnProperty('accountStore')
        ) {
            filtersToSave.tCode = restFilters.tCode
            filtersToSave.accountStore = restFilters.accountStore
        } else {
            filtersToSave.tCode = ''
            filtersToSave.accountStore = ''
        }

        if (restFilters.hasOwnProperty('capability')) {
            filtersToSave.capability = restFilters.capability
        } else {
            filtersToSave.capability = null
        }

        if (restFilters.hasOwnProperty('environment')) {
            filtersToSave.environment = restFilters.environment
        } else {
            filtersToSave.environment = null
        }

        if (restFilters.hasOwnProperty('infrastructure')) {
            filtersToSave.infrastructure = restFilters.infrastructure
        } else {
            filtersToSave.infrastructure = null
        }

        if (restFilters.hasOwnProperty('operatingSystem')) {
            filtersToSave.operatingSystem = restFilters.operatingSystem
        } else {
            filtersToSave.operatingSystem = null
        }

        if (restFilters.accountStoreUsageType) {
            filtersToSave.accountStoreUsageType =
                restFilters.accountStoreUsageType
        } else {
            filtersToSave.accountStoreUsageType = null
        }

        if (restFilters.accountStore) {
            if (
                currentResourceType.name !== RESOURCE_TYPE_NAMES.BUSINESS_ROLES
            ) {
                filtersToSave.accountStore = restFilters.accountStore
            }
        } else {
            filtersToSave.accountStore = null
        }
        if (restFilters.applicationProcess || restFilters.businessDomain) {
            const idToUse = restFilters.applicationProcess
                ? restFilters.applicationProcess.id
                : restFilters.businessDomain
                ? restFilters.businessDomain.id
                : ''
            filtersToSave.selectedResourceLocationId = idToUse
        } else {
            filtersToSave.selectedResourceLocationId = ''
        }

        if (restFilters.businessDomain) {
            filtersToSave.businessDomain = restFilters.businessDomain
        } else {
            filtersToSave.businessDomain = null
        }

        if (restFilters.referencePerson) {
            filtersToSave.referencePerson = restFilters.referencePerson
        } else {
            filtersToSave.referencePerson = null
        }

        if (advancedSearchApplied) {
            filtersToSave.advanced = {}

            if (restFilters['advancedSearch.forms.friendlyName']) {
                filtersToSave.advanced['advancedSearch@forms@friendlyName'] =
                    restFilters['advancedSearch.forms.friendlyName']
            } else {
                filtersToSave.advanced['advancedSearch@forms@friendlyName'] = ''
            }

            if (restFilters['advancedSearch.forms.alias']) {
                filtersToSave.advanced['advancedSearch@forms@alias'] =
                    restFilters['advancedSearch.forms.alias']
            } else {
                filtersToSave.advanced['advancedSearch@forms@alias'] = ''
            }

            if (restFilters['advancedSearch.forms.primaryEmailAddress']) {
                filtersToSave.advanced[
                    'advancedSearch@forms@primaryEmailAddress'
                ] = restFilters['advancedSearch.forms.primaryEmailAddress']
            } else {
                filtersToSave.advanced[
                    'advancedSearch@forms@primaryEmailAddress'
                ] = ''
            }

            if (restFilters['advancedSearch.forms.technicalName']) {
                filtersToSave.advanced['advancedSearch@forms@technicalName'] =
                    restFilters['advancedSearch.forms.technicalName']
            } else {
                filtersToSave.advanced['advancedSearch@forms@technicalName'] =
                    ''
            }

            if (restFilters['advancedSearch.forms.owner']) {
                filtersToSave.advanced['advancedSearch@forms@owner'] =
                    restFilters['advancedSearch.forms.owner']
            } else {
                filtersToSave.advanced['advancedSearch@forms@owner'] = ''
            }

            if (restFilters['advancedSearch.forms.highLevelClassification']) {
                filtersToSave.advanced[
                    'advancedSearch@forms@highLevelClassification'
                ] = restFilters['advancedSearch.forms.highLevelClassification']
            } else {
                filtersToSave.advanced[
                    'advancedSearch@forms@highLevelClassification'
                ] = ''
            }

            if (restFilters['advancedSearch.forms.description']) {
                filtersToSave.advanced['advancedSearch@forms@description'] =
                    restFilters['advancedSearch.forms.description']
            } else {
                filtersToSave.advanced['advancedSearch@forms@description'] = ''
            }

            if (
                restFilters['advancedSearch.tags'] &&
                restFilters['advancedSearch.tags'].length > 0
            ) {
                filtersToSave.advanced['advancedSearch@tags'] =
                    restFilters['advancedSearch.tags']
            } else {
                filtersToSave.advanced['advancedSearch@tags'] = null
            }
        } else {
            filtersToSave.advanced = {
                'advancedSearch@forms@friendlyName': '',
                'advancedSearch@forms@technicalName': '',
                'advancedSearch@forms@owner': null,
                'advancedSearch@forms@highLevelClassification': '',
                'advancedSearch@forms@description': '',
                'advancedSearch@tags': [],
                alias: '',
                primaryEmailAddress: '',
            }
        }

        saveFilters({
            resourceType: currentResourceType.name,
            ...filtersToSave,
        }).then(() => {
            // Update local state
            try {
                if (savedFilters) {
                    const selectedFilter = {
                        ...savedFilters,
                        [currentResourceType.name]: {
                            ...savedFilters[currentResourceType.name],
                            filters: filtersToSave,
                        },
                    }
                    dispatchAppState({
                        type: 'SAVE_FILETRS',
                        payload: selectedFilter,
                    })
                }
            } catch (err) {}

            clearGuidedShopFilters()
        })
    }

    const { viewIndicator } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        cardViewLabel: t('Card_View_Label'),
        listViewLabel: t('List_View_Label'),
    })

    const applicationId = query.get('applicationId')
    const showBackButton = query.get('workflow')

    return (
        <Layout
            currentResourceType={currentResourceType}
            subHeader={
                !isSmallScreen && (
                    <SubHeader
                        totalCount={totalCount}
                        viewSwitcher={viewIndicator}
                        resourceTypes={resourceTypes}
                        currentResourceType={currentResourceType}
                        showNavTabs={!applicationId && hasAccessToAtleastOneTab}
                        showWorkFlows={true}
                        showBackButton={showBackButton}
                    />
                )
            }
        >
            {hasAccessToAtleastOneTab ? (
                <>
                    {isSmallScreen ? (
                        <ListingPageMobile
                            handleSaveFilters={handleSaveFilters}
                            savingFilters={savingFilters}
                            totalCount={totalCount}
                            loading={loading}
                            loadingMore={loadingMore}
                            data={list}
                            id={id}
                            currentResourceType={currentResourceType}
                            resourceTypeAttributes={resourceTypeAttributes}
                            infiniteScrollObervableDiv={
                                infiniteScrollObervableDiv
                            }
                            onItemClick={(item) => {
                                query.set('selected', item.id)
                                history.push(
                                    `${appConfig.APP_SUBPATH}/${
                                        currentResourceType.name
                                    }?${query.toString()}`,
                                )
                            }}
                        />
                    ) : (
                        <ListingPageDesktop
                            handleSaveFilters={handleSaveFilters}
                            savingFilters={savingFilters}
                            totalCount={totalCount}
                            loading={loading}
                            loadingMore={loadingMore}
                            data={list}
                            id={id}
                            currentResourceType={currentResourceType}
                            resourceTypeAttributes={resourceTypeAttributes}
                            infiniteScrollObervableDiv={
                                infiniteScrollObervableDiv
                            }
                            onSort={(sortBy, sortOrder) => {
                                setSortQuery({
                                    sortBy:
                                        sortBy.columnName &&
                                        sortBy.columnName.length > 0
                                            ? sortBy.columnName
                                            : sortBy.name,
                                    sortOrder,
                                })
                            }}
                            sortOrder={sortQuery.sortOrder}
                            sortBy={sortQuery.sortBy}
                            onItemClick={(item) => {
                                query.set('selected', item.id)
                                history.push(
                                    `${appConfig.APP_SUBPATH}/${
                                        currentResourceType.name
                                    }?${query.toString()}`,
                                )
                            }}
                        />
                    )}
                </>
            ) : (
                <NoAccessInfo />
            )}
        </Layout>
    )
}

const ensureFilters = (ChildComponent) => (props) => {
    const { data: onBoardingFilters } = useDefaultFilters(props.resourceType)
    if (onBoardingFilters === undefined) return <></>
    return <ChildComponent {...props} />
}

export default ensureFilters(ListingPage)
