import React from 'react'
import ITShopDesktopHeader from './ITShopDesktopHeader'
import { useIsSmallScreen } from 'packages/core'
import ITShopMobileHeader from './ITShopMobileHeader'

const ITShopHeader = () => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? <ITShopMobileHeader /> : <ITShopDesktopHeader />
}

export default ITShopHeader
