import { Box, Typography } from '@mui/material'
import { DynamicForm } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'

const DynamicFormDrawerComp = ({ additionalFormFields, handleDataChange }) => {
    const { t } = useTranslation()
    return (
        <Box>
            <Box
                padding="16px 31px"
                borderTop="1px solid rgb(232, 232, 232)"
                borderBottom="1px solid rgb(232, 232, 232)"
                boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.07)"
            >
                <Typography
                    style={{
                        fontSize: '14px',
                        lineHeight: 1.43,
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        wordBreak: 'break-word',
                    }}
                >
                    {t('Common_DynamicRequestAttributes')}
                </Typography>
            </Box>
            <DynamicForm
                formFieldJson={additionalFormFields}
                onFieldChange={(data) => handleDataChange(data)}
                showErrorMessages={true}
                t={t}
            />
        </Box>
    )
}

export default DynamicFormDrawerComp
