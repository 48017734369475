import React from 'react'
import './PasswordStrengthMeter.css'
import { useTranslation } from 'react-i18next'

const PasswordStrengthMeter = ({ value, maxValue }) => {
    const { t } = useTranslation()
    let strengthScore = value

    if (value === 0) {
        strengthScore = 0.0375 * maxValue
        //To meet the design, this requires a minimum score of 3.75% to show a circle. 3.75% was found after hit and trial
    }

    const createPasswordLabel = (score, maxScore) => {
        let percentageSatisfied

        if (maxValue === 0) {
            // Check to avoid division by zero
            percentageSatisfied = 0
        } else percentageSatisfied = Math.round((score / maxScore) * 100)

        if (percentageSatisfied <= 25) {
            return 'Low'
        }
        if (percentageSatisfied > 25 && percentageSatisfied <= 50) {
            return 'Fair'
        }
        if (percentageSatisfied > 50 && percentageSatisfied <= 75) {
            return 'Good'
        }
        if (percentageSatisfied >= 75) {
            return 'High'
        } else {
            return 'Low'
        }
    }

    return (
        <div className="password-strength-meter">
            <label className="password-strength-meter-label">
                Password strength:{' '}
                {t(createPasswordLabel(strengthScore, maxValue))}
            </label>
            <br />
            <progress
                className={`password-strength-meter-progress strength-${createPasswordLabel(
                    strengthScore,
                    maxValue,
                )}`}
                value={strengthScore}
                max={maxValue}
            />
        </div>
    )
}

export default PasswordStrengthMeter
