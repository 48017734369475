import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    root: (props) => ({
        border: 'solid 1px #ebebed',
        boxShadow: ' 0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
        borderRadius: '0.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '4.8rem',
        background: '#fff',
        overflow: 'hidden',
        ...props?.rootStyles,
    }),
    iconArea: (props) => ({
        background: '#8b909a',
        width: '4.8rem',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        alignSelf: 'stretch',
        ...props?.iconAreaStyles,

        '& svg': {
            width: '2.4rem !important',
            height: '2.4rem !important',
            color: 'white',
            ...props?.iconStyles,
            '& path': {
                fill: props?.iconStyles.color || '#fff',
            },
        },
    }),
    message: (props) => ({
        padding: '1.6rem',
        color: '#5d6870',
        ...props?.messageStyles,
    }),
})

const InfoBoxWithIcon = ({
    message,
    iconName,
    iconStyles = {},
    messageStyles = {},
    iconAreaStyles = {},
    rootStyles = {},
}) => {
    const classes = useStyles({
        iconStyles,
        messageStyles,
        iconAreaStyles,
        rootStyles,
    })

    return (
        <Box className={classes.root}>
            <Box className={classes.iconArea}>
                <Icon name={iconName} />
            </Box>
            <Box className={classes.message}>{message}</Box>
        </Box>
    )
}

export default InfoBoxWithIcon
