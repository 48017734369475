import React from 'react'
import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useAzureLicense } from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import AzureLicenseDetails from './AzureLicenseDetails'

const AzureLicenses = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()

    const { data: azureLicense } = useAzureLicense(id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={azureLicense?.friendlyName} />
            {!azureLicense && <Loader />}
            {azureLicense && (
                <AzureLicenseDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    azureLicense={azureLicense}
                    id={id}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            header={<ItemHeader title={azureLicense?.friendlyName} />}
            closeOnOutsideClick
        >
            {!azureLicense && <Loader />}
            {azureLicense && (
                <AzureLicenseDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    azureLicense={azureLicense}
                    id={id}
                />
            )}
        </Drawer>
    )
}

export default AzureLicenses
