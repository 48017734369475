import {
    directAssignedOnlyFilterColors,
    preApprovedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        advanceSearch: true,

        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedAzureRoles-Control',
        },
        order: 2,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        default: true,
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedAzureAdminRoles-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: true,
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedAzureAdminRoles-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'RoleGroupType',
        title: 'Common_AzureRoleType',
        order: 2,
        colorCode: {
            primary: 'rgb(178, 62, 62)',
            secondary: 'rgba(178, 62, 62, 0.09)',
        },
        type: 'select',
        placeholder: 'Common_AzureRoleType',
        optionConfig: {
            type: 'label',
            labelProp: 'value',
        },
        url: '/api/AzureRoles/roleGroupTypes',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'roleGroupType',
        queryParam: {
            name: 'roleGroupType',
            valueProp: 'id',
        },
        primitiveType: true,
        clearable: true,
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'RoleType',
        title: 'Common_RoleType',
        requireAccess: {
            control: 'ITShop-AzureAdminRolesRoleTypes-Control',
        },
        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'select',
        placeholder: 'Common_RoleType',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/AzureRoles/roleTypes',
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'roleType',
        queryParam: {
            name: 'roleTypeId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'optional',
            contextProp: 'roleGroupType',
            valueProp: 'id',
            queryParamName: 'roleGroupType',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'GlobalFunctionsSearch',
        title: 'Common_GlobalFunctions',
        requireAccess: {
            control: 'ITShop-AzureAdminRolesGlobalFunctions-Control',
        },
        order: 4,
        colorCode: {
            primary: 'rgb(50,205,50)',
            secondary: 'rgb(50,205,50,0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectGlobalFunction',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/businessFunctions/globalFunctions',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'globalFunction',
        queryParam: {
            name: 'globalFunctionId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AzureTenant',
        title: 'AzureAdminRoles_AzureTenant',
        requireAccess: {
            control: 'ITShop-AzureAdminRolesTenants-Control',
        },
        order: 5,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        placeholder: 'AzureAdminRoles_SelectAzureTenant',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/azureLicenses/tenants',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'azureTenant',
        queryParam: {
            name: 'tenantId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        requireAccess: {
            control: 'ITShop-AzureRolesApplications-Control',
        },
        order: 6,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ReferencePerson',
        title: 'Common_ShopByReferencePerson',
        requireAccess: {
            control: 'ITShop-AzureRoles-ShopByReferencePerson-Control',
        },
        order: 7,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'Common_ByReferencePerson',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'referencePerson',
        queryParam: {
            name: 'referencePersonId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-AzureAdminRolesAdvancedSearch-Control',
        },
        order: 7,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'SystemType',
                    type: 'text',
                    placeholder: 'Common_SystemType',
                    contextProp: 'advancedSearch.forms.systemType',
                    queryParam: {
                        name: 'resourceSystemTypeFriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property

    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: true,
    },
    {
        name: 'resourceSystemTypeFriendlyName',
        label: 'Common_SystemType',
        sortable: true,
    },
    {
        name: 'roleTypeFriendlyName',
        label: 'Common_RoleType',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureAdminRolesRoleTypeAttribute-Control',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        label: 'Common_Tenant',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureAdminRolesResourceSystemAttribute-Control',
        },
    },
    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
    {
        label: 'Common_CurrentAccessEnds',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const azureRolesConfig = {
    name: 'AzureRoles',
    resourceTypeName: 'AzureRole',
    title: 'Common_AzureRoles',
    route: '/azureRoles',
    requireAccess: {
        page: 'ITShop-AzureAdminRoles-Page',
    },
    order: 5,
    icon: {
        small: 'AzureRolesSmall',
        large: 'AzureRolesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/azureRoles',
    },
    // showTimeConstrainedFilter: true,
    // showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,
    attributes: attributes,
}

export default azureRolesConfig
