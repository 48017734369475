import { Button } from 'components'
import { Typography, useTheme } from '@mui/material'
import { useCheckAccessToCheckInOperation, useTerminateSession } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNotification } from 'packages/eid-ui'

const styles = (theme) => ({
    normal: {
        backgroundColor: theme?.palette?.primary?.main,
        color: theme?.palette?.common?.white,
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        borderRadius: '5px',
        padding: '5px 8px 4px',
    },
    disabled: {
        backgroundColor: '#c5cbda !important',
        border: 'solid 1px #c5cbda',
        color: `${theme?.palette?.common?.white} !important`,
        opacity: '0.8',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
})

const ComputersTerminateButton = ({ data }) => {
    const theme = useTheme()
    const [enableApi, setEnableApi] = useState(false)
    const { showWarningMessage } = useNotification()
    const { t } = useTranslation()
    const item = data?.resource
    const { data: hasAccessToTerminate } = useCheckAccessToCheckInOperation(
        item.externalCredentialGuid,
        enableApi,
    )
    const [terminateSession] = useTerminateSession()

    useEffect(() => {
        if (hasAccessToTerminate?.hasAccess) {
            handleTerminateSession()
        }
        if (hasAccessToTerminate?.hasAccess === false) {
            showWarningMessage(t('AccessDeniedTerminateMessage'))
        }
        setEnableApi(false)
    }, [hasAccessToTerminate])

    const handleTerminateSession = () => {
        terminateSession(item.id)
    }
    return (
        <>
            {item?.canTerminateSession ? (
                <Button
                    onClick={() => setEnableApi(true)}
                    style={styles(theme).normal}
                    disabledStyles={styles(theme).disabled}
                >
                    <Typography style={styles(theme).label}>
                        {t('Common_Terminate')}
                    </Typography>
                </Button>
            ) : (
                <></>
            )}
        </>
    )
}

export default ComputersTerminateButton
