import { FC } from 'react'
import { Box, useTheme } from '@mui/material'
import { Modal } from 'components/Modal'
import { ItemDetails } from 'components'
import { useTranslation } from 'react-i18next'

export interface ResetConfrimationProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
}

export const ResetConfrimation: FC<ResetConfrimationProps> = (props) => {
    const { open, onClose, onReset, onCancel } = props
    const { t } = useTranslation()
    const theme = useTheme()

    const footerContent = (
        <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            padding="24px 32px"
        >
            <Box minWidth="88px">
                <ItemDetails.ActionButton
                    fontColor={theme?.palette?.common?.white}
                    bgColor={theme?.palette?.primary?.main}
                    width="fit-content"
                    minWidth="100%"
                    onClick={onReset}
                >
                    {t('Common_YesResetMyMasterPassword')}
                </ItemDetails.ActionButton>
            </Box>

            <Box minWidth="130px">
                <ItemDetails.ActionButton
                    fontColor={theme?.palette?.common?.white}
                    bgColor={'#aab0b4'}
                    width="fit-content"
                    minWidth="100%"
                    onClick={onCancel}
                >
                    {t('Common_Cancel')}
                </ItemDetails.ActionButton>
            </Box>
        </Box>
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('Common_ResetMasterPasswordConfirmation')}
            footerContent={footerContent}
        >
            <Box>
                Warning! Are you sure you have forgotten your master password?
                All of your saved passwords will be deleted if you proceed.
            </Box>
        </Modal>
    )
}
