import React from 'react'
import { Collapse, Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowIcon } from 'packages/eid-icons'
import { Divider } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': { backgroundColor: 'transparent !important' },
    },
}))

const LicenseDetailsCard = ({
    title,
    subTitle,
    collapsible,
    expanded,
    children,
}) => {
    const classes = useStyles()

    const [_expanded, setExpanded] = React.useState(expanded)

    const handleExpandClick = () => {
        setExpanded(!_expanded)
    }

    const header = (
        <Box display="flex" justifyContent="space-between">
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                flexWrap="wrap"
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        wordBreak: 'break-all',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    style={{
                        fontSize: '16px',
                        color: '#b4b4b4',
                        wordBreak: 'break-all',
                    }}
                >
                    {subTitle}
                </Typography>
            </Box>
            {collapsible && (
                <IconButton
                    classes={classes}
                    onClick={handleExpandClick}
                    aria-expanded={_expanded}
                    aria-label="show more"
                    disableFocusRipple
                    disableRipple
                    size="large">
                    <ArrowIcon direction={_expanded ? 'up' : 'down'} />
                </IconButton>
            )}
        </Box>
    )

    const contentList = (
        <>
            {collapsible ? (
                <Collapse in={_expanded} timeout={400} unmountOnExit>
                    <Box paddingTop="20px" paddingBottom="24px">
                        <Divider></Divider>
                    </Box>
                    {children}
                </Collapse>
            ) : (
                { children }
            )}
        </>
    )

    return (
        <div>
            {header}
            {contentList}
        </div>
    )
}

export default LicenseDetailsCard
