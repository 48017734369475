import { Box, styled, useTheme, Typography, Stack } from '@mui/material'
import PeopleSearch from 'components/PeopleSearch'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import {
    TextField,
    WithCloseButton,
    Avatar,
    Tooltip,
    ToggleSwitch,
} from 'packages/eid-ui'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'

const CheckOutGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: '3.2rem',
    rowGap: '1.6rem',
    marginTop: '1.8rem',
    marginBottom: '3.2rem',
    maxWidth: '53.5rem',

    [theme.breakpoints.down('lg')]: {
        maxWidth: 'none',
    },

    '& .dateInputLabel': {
        color: '#b4b4b4',
        fontSize: '10px',
        textTransform: 'uppercase',
        width: '65px',
        position: 'absolute',
        left: '10px',
        top: '4px',
        zIndex: 50,
    },
    '& .dateMainWidthContainer': {
        gridColumn: 'span 3',

        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 6',
        },
    },
    '& .dateContainerLabel': {
        gridColumn: 'span 6',
    },
    '& .dueDateInputContainer': {
        gridColumn: 'span 3',
        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 6',
        },
        display: 'flex',
        alignItems: 'center',
        position: 'relative',

        '& svg': {
            marginTop: '-10px',
            marginRight: '4px',
        },
        '& input': {
            padding: '10px',
            paddingLeft: '80px',
            color: '#5d6870',
            fontSize: '16px',
            textAlign: 'center',
        },
        '& fieldset': {
            border: 'none',
        },
        '& > div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
            },
        },
        '@supports (-moz-appearance:none)': {
            '& input': {
                paddingLeft: '5.7rem',
            },
        },
    },
    '& .peopleSearchContainer': {
        gridColumn: 'span 3',
        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 5',
        },

        '& svg': {
            position: 'relative',
            left: '10px',
        },
    },
    '& .personIndicatorContainer': {
        gridColumn: 'span 3',
        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 5',
        },
    },
    '& .checkoutTypography': {
        paddingTop: '1rem',
        paddingRight: '1rem',
        fontSize: '1.4rem',
    },
    '& .checkOutToggle': {
        gridColumn: 'span 3',
        [theme.breakpoints.down('lg')]: {
            gridColumn: 'span 1',
        },
    },
}))

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '800px', // some arbitrary width
}

const PersonIndicator = styled(WithCloseButton)(({ theme }) => ({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: theme?.palette?.common?.white,
    display: 'flex',
    alignItems: 'center',
    width: '25rem',
    height: '40px',
}))

const MainLabel = styled('label')({
    lineHeight: 1.5,
    width: '25rem',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
    marginBottom: '0.8rem',
})

export interface ComputerSessionRequestFiltersProps {
    isCheckedOut: boolean
    checkboxLabel: string
    dateContainerLabel?: string
    handleCheckedClick: (e: React.ChangeEvent<HTMLInputElement>) => void
    startDate: string
    endDate: string
    setStartDate: (e: any) => void
    setEndDate: (e: any) => void
    onPersonSelect: (e: any) => void
    selectedPerson: any
}

export const ComputerSessionRequestFilters: FC<
    ComputerSessionRequestFiltersProps
> = (props) => {
    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        handleCheckedClick,
        isCheckedOut,
        checkboxLabel,
        dateContainerLabel,
        onPersonSelect,
        selectedPerson,
    } = props

    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <CheckOutGrid>
            {/* Checkbox for checkout */}
            <Box className="checkOutToggle">
                <MainLabel>{t('CheckedOut')}</MainLabel>
                <Stack direction="row">
                    <Typography className="checkoutTypography">
                        {t('Common_No')}
                    </Typography>
                    <ToggleSwitch
                        onChange={(e: any) => {
                            handleCheckedClick(e)
                        }}
                        value={isCheckedOut}
                        label={checkboxLabel}
                    />
                </Stack>
            </Box>

            {/* Who Requested filter Start */}
            {selectedPerson ? (
                <Box className="personIndicatorContainer">
                    <MainLabel>{t('CheckedOutBy')}</MainLabel>
                    <PersonIndicator
                        // @ts-ignore
                        onClose={() => {
                            onPersonSelect(null)
                        }}
                    >
                        <Avatar
                            size="small"
                            src={getFullImageUrl(selectedPerson.imageThumbUrl)}
                        />
                        <Tooltip
                            color="#5D6870"
                            fontColor={theme?.palette?.common?.white}
                            enterDelay={500}
                            enterNextDelay={500}
                            tooltipStyles={tooltipStyles}
                            title={selectedPerson.friendlyName}
                        >
                            <Typography
                                style={{
                                    marginLeft: '21px',
                                    wordBreak: 'break-all',
                                }}
                                display="block"
                                variant="body1"
                                noWrap={true}
                            >
                                {selectedPerson.friendlyName}
                            </Typography>
                        </Tooltip>
                    </PersonIndicator>
                </Box>
            ) : (
                <Box className="peopleSearchContainer">
                    <PeopleSearch
                        labelComponent={MainLabel}
                        label={t('CheckedOutBy')}
                        value={selectedPerson}
                        onChange={(_: any, value: any) => {
                            if (value) {
                                onPersonSelect({
                                    id: value.id,
                                    friendlyName: value.friendlyName,
                                    imageThumbUrl: value.imageThumbUrl,
                                })
                            }
                        }}
                    />
                </Box>
            )}

            {dateContainerLabel && (
                <MainLabel
                    className="dateContainerLabel"
                    sx={{ marginBottom: '-0.8rem' }}
                >
                    {dateContainerLabel}
                </MainLabel>
            )}

            <Box className="dateMainWidthContainer">
                <NativeDatePicker
                    value={startDate}
                    handleChange={(date: string) => {
                        setStartDate(date)
                    }}
                    maxDate={endDate}
                >
                    <Box className="dueDateInputContainer">
                        <Box component="p" className="dateInputLabel">
                            {t('Common_StartDate')}
                        </Box>
                        <TextField
                            type="text"
                            margin="none"
                            name="filterStartDate"
                            readOnly
                            value={
                                startDate
                                    ? moment.utc(startDate).local().format('L')
                                    : ''
                            }
                            style={{
                                backgroundColor: theme?.palette?.common?.white,
                                width: '100%',
                                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                borderRadius: '5px',
                            }}
                            placeholder={t('Common_SelectDate')}
                        />
                        <Box position="absolute" right="8px" top="16px">
                            <Icon
                                name="Tasks"
                                width={19}
                                height={20}
                                color="#aab0b4"
                            />
                        </Box>
                    </Box>
                </NativeDatePicker>
            </Box>
            <Box className="dateMainWidthContainer">
                {/* End Date picker */}
                <NativeDatePicker
                    value={null}
                    handleChange={(date: string) => {
                        setEndDate(date)
                    }}
                    minDate={startDate}
                >
                    <Box className="dueDateInputContainer">
                        <Box component="p" className="dateInputLabel">
                            {t('Common_EndDate')}
                        </Box>
                        <TextField
                            type="text"
                            margin="none"
                            name="filterEndDate"
                            readOnly
                            value={
                                endDate
                                    ? moment.utc(endDate).local().format('L')
                                    : ''
                            }
                            style={{
                                backgroundColor: theme?.palette?.common?.white,
                                width: '100%',
                                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                borderRadius: '5px',
                                color: '#5d6870',
                            }}
                            placeholder={t('Common_SelectDate')}
                        />
                        <Box position="absolute" right="8px" top="16px">
                            <Icon
                                name="Tasks"
                                width={19}
                                height={20}
                                color="#aab0b4"
                            />
                        </Box>
                    </Box>
                </NativeDatePicker>
            </Box>
        </CheckOutGrid>
    )
}

export default ComputerSessionRequestFilters
