import React, { useState } from 'react'
import { styled } from '@mui/material'
import { useAzureLicenseBr, useTargetPerson } from 'hooks'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../../CommonAttributes'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const azureLicense = item.resource

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useAzureLicenseBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('AzureLicenses_LicensePoolSubBundle')}
                    value={azureLicense.name}
                    orientation={attributeOrientation}
                />
                {azureLicense.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_Tenant')}
                        value={azureLicense.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('AzureLicenses_LicensePool')}
                    value={azureLicense.azureLicensePool_FriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('AzureLicenses_TenantSubscription')}
                    value={azureLicense.azureTenantSubscription_FriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('AzureLicenses_LicenseGroup')}
                    value={azureLicense.licensedAssignee_FriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureLicense.description}
                    orientation={attributeOrientation}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AzureLicenseDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const history = useHistory()
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    const registry = useRegistry()

    const azureLicenseType = registry.get(
        ResourceTypesNamespace,
        'AzureLicenses',
    )

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.azLicenseToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            azureLicense: item.resource,
            assignmentStatus: item.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && <OverviewSection item={item} />}

            {isManageAccess && (
                <RenewRevoke
                    resourceType={azureLicenseType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </>
    )
}

export default AzureLicenseDetails
