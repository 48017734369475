import React from 'react'
import { useTranslation } from 'react-i18next'
import RiskIndicator from '../RiskIndicator'
import TableGrid from '../TableGrid'

const FunctionsList = ({ loading = false, data }) => {
    const { t } = useTranslation()
    const attributes = [
        {
            name: 'friendlyName',
            label: t('Common_FriendlyName'),
            style: {
                width: '35%',
            },
        },
        {
            name: 'functionType',
            label: t('Common_Type'),
            style: {
                width: '20%',
            },
        },
        {
            name: 'description',
            label: t('Common_Description'),
            style: {
                width: '35%',
            },
        },
        {
            label: t('Common_RiskLevel'),
            resolve: (item) => <RiskIndicator variant={item.riskLevel} />,
        },
    ]

    return <TableGrid loading={loading} data={data} attributes={attributes} />
}

export default FunctionsList
