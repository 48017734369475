import React from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    useTheme,
    TableSortLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: theme?.palette?.background?.paper,
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '220px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
}))

const TableGrid = (props) => {
    const {
        data,
        attributes,
        loading,
        rowSelection,
        fallbackMessage,
        tableClasses,
        onSort,
        sortBy,
        sortOrder,
    } = props

    const defaultClasses = useStyles({})
    const classes = tableClasses ?? defaultClasses
    const theme = useTheme()
    const { t } = useTranslation()

    const [selected, setSelected] = React.useState([])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (item) => {
        const selectedIndex = selected.indexOf(item.id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item.id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const handleSort = (data) => {
        const sortingProperty =
            data.columnName && data.columnName.length > 0
                ? data.columnName
                : data.name

        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const getValue = (attribute, item) => {
        if (attribute.resolve) {
            return attribute.resolve(item)
        }
        return item[attribute.name] ?? '-'
    }

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.tableRow}>
                    {rowSelection && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={
                                    <Icon
                                        name="CheckedBox"
                                        color={theme?.palette?.primary?.main}
                                    />
                                }
                                checked={
                                    data.length > 0 &&
                                    selected.length === data.length
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                    )}

                    {attributes.map((a, index) => {
                        const sortingProperty =
                            a.columnName && a.columnName.length > 0
                                ? a.columnName
                                : a.name
                        return (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={a.align}
                                data-protectedsubcomponent={
                                    a?.requireAccess?.control || ''
                                }
                            >
                                {!a.sortable ? (
                                    a.label
                                ) : (
                                    <span style={{ display: 'flex' }}>
                                        <Tooltip
                                            title={
                                                sortBy === sortingProperty &&
                                                sortOrder === 'desc'
                                                    ? t('Common_SortAscending')
                                                    : t('Common_SortDescending')
                                            }
                                        >
                                            <TableSortLabel
                                                active={a.sortable}
                                                direction={
                                                    sortBy === sortingProperty
                                                        ? sortOrder
                                                        : 'asc'
                                                }
                                                className={
                                                    sortBy !==
                                                    (a.columnName &&
                                                    a.columnName.length > 0
                                                        ? a.columnName
                                                        : a.name)
                                                        ? classes.tableSortLabel
                                                        : clsx(
                                                              classes.tableSortLabel,
                                                              classes.activeTableSortLabel,
                                                          )
                                                }
                                                style={{
                                                    color:
                                                        sortBy ===
                                                            sortingProperty &&
                                                        'yellow !important',
                                                }}
                                                onClick={() => handleSort(a)}
                                            >
                                                {a.label}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </span>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading && (
                    <TableRow>
                        <TableCell
                            colSpan={attributes.length}
                            style={{ textAlign: 'center' }}
                        >
                            <Loader />
                        </TableCell>
                    </TableRow>
                )}
                {data &&
                    (data.length === 0 ? (
                        <TableRow className={classes.tableRow}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={attributes.length}
                                style={{ textAlign: 'center' }}
                            >
                                {fallbackMessage}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((item) => (
                                <TableRow
                                    key={item.id}
                                    className={classes.tableRow}
                                >
                                    {rowSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                icon={<Icon name="CheckBox" />}
                                                checkedIcon={
                                                    <Icon
                                                        name="CheckedBox"
                                                        color={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    />
                                                }
                                                checked={isSelected(item.id)}
                                                onClick={() =>
                                                    handleClick(item)
                                                }
                                            />
                                        </TableCell>
                                    )}

                                    {attributes.map((a, index) => (
                                        <TableCell
                                            key={`${item.id}${index}`}
                                            className={classes.tableCell}
                                            style={a.style}
                                        >
                                            {getValue(a, item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    ))}
            </TableBody>
        </Table>
    )
}

TableGrid.defaultProps = {
    fallbackMessage: <Trans i18nKey="Common_NoDataFound" />,
}
export default TableGrid
