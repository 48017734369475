import { Box, Typography, styled } from '@mui/material'
import { FunctionsList, StyledSearchInput } from 'components'
import { useLocalFunctionsByAzureRole } from 'hooks'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
const PaddedDiv = styled('div')({
    padding: '0 31px',
})
const LocalSensitiveFunctions = ({ rbacRole, protectSubcomponent = '' }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const [searchKey, setSearchKey] = useState('')
    const debouncedSearchValue = useDebounce(searchKey)
    const handleSearchChange = (event) => {
        const keyword = event.target.value
        setSearchKey(keyword)
    }
    const {
        list: localFunctions,
        loading: localFunctionsLoading,
        pagination,
    } = useLocalFunctionsByAzureRole(
        rbacRole?.id,
        { searchTerm: debouncedSearchValue },
        Boolean(rbacRole?.id),
    )

    return (
        <>
            {localFunctions && localFunctions.length > 0 && (
                <>
                    <PaddedDiv
                        data-protectedsubcomponent={protectSubcomponent}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px 31px',
                        }}
                    >
                        <Box width="50%">
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    lineHeight: 1.33,
                                    color: '#9b9b9b',
                                    wordBreak: 'break-word',
                                    textTransform: 'uppercase',
                                    minWidth: '80px',
                                }}
                            >
                                {t('Common_LocalFunctionalAccess')}
                            </Typography>
                        </Box>

                        <Box
                            width={!isSmallScreen ? '308px' : '50%'}
                            minWidth="130px"
                        >
                            <StyledSearchInput
                                fullWidth
                                type="text"
                                placeholder={t('Common_Search')}
                                value={searchKey}
                                onChange={handleSearchChange}
                            />
                        </Box>
                    </PaddedDiv>
                    <Box overflow="auto">
                        <FunctionsList
                            data={localFunctions}
                            loading={localFunctionsLoading}
                        />

                        {pagination}
                    </Box>
                </>
            )}
        </>
    )
}
export default LocalSensitiveFunctions
