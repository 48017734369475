import { FC } from 'react'
import { Button as MuiButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            minWidth: '177px',
            height: '31px',

            border: `solid 1px ${theme?.palette?.primary?.main}}`,
            borderRadius: '16px',

            backgroundColor: theme?.palette?.background?.paper,
            color: theme?.palette?.primary?.main,

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',
        },
    }),
)

export interface ButtonProps {
    onClick: any
    isLoading?: boolean
    disabled?: boolean
    endIcon?: any
}

export const Button: FC<ButtonProps> = (props) => {
    const classes = useStyles()

    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={props.onClick}
            disabled={props.disabled}
            endIcon={props.endIcon}
        >
            {props.isLoading ? <Loader size={0.6} /> : props.children}
        </MuiButton>
    )
}
