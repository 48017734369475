import React from 'react'
import { styled, Typography } from '@mui/material'
import { Loader } from 'packages/eid-ui'

const ContentContainer = styled('div')(({ theme }) => ({
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme?.palette?.common?.white,
    position: 'relative',
}))

const Header = styled('div')(({ styles }) => ({
    height: '10.5vh',
    width: '100%',
    fontSize: '24px',
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'center',
    ...styles,
}))

const Content = styled('div')(({ styles }) => ({
    minHeight: '81vh',
    width: '100%',
    position: 'relative',
    ...styles,
}))

const Footer = styled('div')({
    height: '8.5vh',
    width: '100%',
    '& > p': {
        maxWidth: '315px',
        fontSize: '12px',
        lineHeight: '1.42',
        color: '#7d7c7c',
        textAlign: 'center',
        margin: 'auto',
    },
})

const NavigationButton = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '30px',
    color: '#848992',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
        color: theme?.palette?.common?.black,
    },
}))

const SkipButton = styled(NavigationButton)({
    right: '30px',
    '&::before': {
        content: '"Skip"',
    },
})

const BackButton = styled(NavigationButton)({
    left: '30px',
    '&::before': {
        content: '"Back"',
    },
})

const MobileSelectionBox = ({
    onBackClick,
    onSkipClick,
    headingText,
    footerText,
    loading,
    style,
    children,
}) => {
    return (
        <ContentContainer>
            {onBackClick && <BackButton onClick={onBackClick} />}
            {onSkipClick && <SkipButton onClick={onSkipClick} />}
            <Header styles={style && style.hedaerStyles}>{headingText}</Header>
            <Content styles={style && style.contentStyles}>
                {loading && <Loader />}
                {!loading && children}
            </Content>
            <Footer>
                {footerText && (
                    <Typography component="p">{footerText}</Typography>
                )}
            </Footer>
        </ContentContainer>
    )
}

MobileSelectionBox.Header = Header
export default MobileSelectionBox
