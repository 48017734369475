import { useResourceTypeContext } from 'resourceTypeContext'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useTheme } from '@mui/material'
import { RESOURCE_TYPE_NAMES } from 'utils'

const useAppliedFilterValues = (currentResourceType) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()

    const isManageAccess =
        history.location.pathname
            .toLowerCase()
            .indexOf('/manageAccess'.toLowerCase()) >= 0
    let credentialsTab = null
    if (currentResourceType.name === 'Credentials' && isManageAccess) {
        const isHistoryTab =
            history.location.pathname
                .toLowerCase()
                .indexOf('/history'.toLowerCase()) >= 0
        credentialsTab = isHistoryTab ? 'history' : 'manageCredentials'
    }
    const selectedTab = query.get('tab')
    const selectedTabInfo =
        isManageAccess &&
        currentResourceType &&
        currentResourceType.manageAccessTabs
            ? currentResourceType.manageAccessTabs.find((t) => {
                  return selectedTab && selectedTab.length > 0
                      ? t.path.toLowerCase() === selectedTab.toLocaleLowerCase()
                      : t.default
              })
            : null

    const [appState, dispatch] = useResourceTypeContext()

    const isGenericFilterApplied = (filter) =>
        !isNilOrEmpty(appState[filter.contextProp]) &&
        appState[filter.contextProp] !== false

    const getGenericFilterAppliedValueLabel = (filter) => {
        switch (filter.type) {
            case 'checkbox':
                return t(filter.trueValueIndicator)
            case 'nullableBoolean':
                switch (appState[filter.contextProp]) {
                    case 'true':
                        return t(filter.optionConfig.trueLabel)
                    case 'false':
                        return t(filter.optionConfig.falseLabel)
                    default:
                        return
                }
            // return appState[filter.contextProp] === 'true'
            //     ? t(filter.optionConfig.trueLabel)
            //     : t(filter.optionConfig.falseLabel)
            case 'tree':
                return appState[filter.contextProp][
                    filter.selectedValueIndicatorProp
                ]
            case 'autocomplete':
                return appState[filter.contextProp][
                    filter.optionConfig.labelProp
                ]
            case 'select':
                return appState[filter.contextProp][
                    filter.optionConfig.labelProp
                ]
            default:
                return appState[filter.contextProp]
        }
    }

    const getGenericFilterFalsyValue = (filter) => {
        switch (filter.type) {
            case 'checkbox':
                return false
            case 'text':
                return ''
            case 'checklist':
                return []
            case 'tree':
                return null
            case 'autocomplete':
                return null
            case 'select':
                return ''
            default:
                return null
        }
    }

    const getAppliedFiltersValues = () => {
        const targetSystemFilters = []
        const genericFilters = []
        const checklistFiltersValues = []
        const tagFiltersValues = []
        const tCodeFilterValue = []
        if (appState.accountStoreUsageType) {
            targetSystemFilters.push({
                name: 'targetSystem',
                label: appState.accountStoreUsageType.friendlyName,
                category: 'Generic',
                colorCode: {
                    primary: theme?.palette?.primary?.main,
                    secondary: theme?.palette?.primary?.light,
                },
                onRemove: () => {
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: {
                            accountStoreUsageType: null,
                            accountStore: null,
                        },
                    })
                },
            })
        }
        if (
            appState.accountStore &&
            currentResourceType.name !== RESOURCE_TYPE_NAMES.BUSINESS_ROLES
        ) {
            targetSystemFilters.push({
                name: 'targetSystem',
                label: appState.accountStore.friendlyName,
                category: 'Generic',
                colorCode: {
                    primary: theme?.palette?.primary?.main,
                    secondary: theme?.palette?.primary?.light,
                },
                onRemove: () => {
                    dispatch({
                        type: 'SET_MULTIPLE_PROPS',
                        payload: {
                            accountStore: null,
                        },
                    })
                },
            })
        }

        if (appState.accountStore && appState.tCode) {
            tCodeFilterValue.push(
                {
                    name: 'tCodeFilter',
                    label: appState.tCode.friendlyName,
                    category: 'Generic',
                    colorCode: {
                        primary: '#dfba00',
                        secondary: '#fdfae5',
                    },
                    onRemove: () => {
                        dispatch({
                            type: 'SET_MULTIPLE_PROPS',
                            payload: {
                                accountStore: null,
                                tCode: null,
                            },
                        })
                    },
                },
                {
                    name: 'tCodeFilter',
                    label: appState.accountStore.friendlyName,
                    category: 'Generic',
                    colorCode: {
                        primary: '#dfba00',
                        secondary: '#fdfae5',
                    },
                    onRemove: () => {
                        dispatch({
                            type: 'SET_MULTIPLE_PROPS',
                            payload: {
                                accountStore: null,
                                tCode: null,
                            },
                        })
                    },
                },
            )
        }

        for (let i = 0; i < currentResourceType.filters.length; i++) {
            const currentFilter = currentResourceType.filters[i]
            if (isManageAccess) {
                if (
                    !currentFilter.forManageAccess ||
                    currentFilter.forManageAccess === false
                ) {
                    continue
                } else {
                    if (
                        currentResourceType.manageAccessTabs &&
                        selectedTabInfo &&
                        currentFilter[selectedTabInfo.path] === false
                    ) {
                        continue
                    }
                    if (
                        credentialsTab &&
                        currentFilter[credentialsTab] === false
                    ) {
                        continue
                    }
                }
            } else {
                if (
                    !currentFilter.forRequestAccess ||
                    currentFilter.forRequestAccess === false
                ) {
                    continue
                }
            }

            if (
                currentFilter.type === 'checklist' ||
                (currentFilter.type === 'autocomplete' &&
                    currentFilter.multiselect)
            ) {
                if (isGenericFilterApplied(currentFilter)) {
                    appState[currentFilter.contextProp].forEach((i) => {
                        checklistFiltersValues.push({
                            ...currentFilter,
                            label: i[currentFilter.selectedValueIndicatorProp],
                            category: 'checklist',
                            onRemove: () => {
                                const newList = [
                                    ...appState[currentFilter.contextProp],
                                ]
                                const itemIndex = newList.findIndex(
                                    (f) => f.id === i.id,
                                )
                                newList.splice(itemIndex, 1)

                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: currentFilter.contextProp,
                                        value: newList,
                                    },
                                })
                            },
                        })
                    })
                }
            } else if (
                currentFilter.type !== 'advancedSearch' &&
                currentFilter.name !== 'TargetSystem' &&
                currentFilter.name !== 'TCodeFilter'
            ) {
                if (isGenericFilterApplied(currentFilter)) {
                    genericFilters.push({
                        ...currentFilter,
                        category: 'generic',
                    })
                }
            } else {
                if (!isNilOrEmpty(currentFilter.forms)) {
                    currentFilter.forms.filters
                        .filter((f) => isGenericFilterApplied(f))
                        .forEach((f) =>
                            genericFilters.push({
                                ...f,
                                category: 'forms',
                                colorCode: currentFilter.forms.colorCode,
                            }),
                        )
                }
                if (!isNilOrEmpty(currentFilter.tags)) {
                    if (
                        !isNilOrEmpty(appState[currentFilter.tags.contextProp])
                    ) {
                        appState[currentFilter.tags.contextProp].forEach(
                            (t) => {
                                tagFiltersValues.push({
                                    name: 'tags',
                                    label: t,
                                    category: 'tags',
                                    colorCode: currentFilter.tags.colorCode,
                                    onRemove: () => {
                                        const newTags = [
                                            ...appState[
                                                currentFilter.tags.contextProp
                                            ],
                                        ]
                                        const tagIndex = newTags.findIndex(
                                            (f) => f === t,
                                        )
                                        newTags.splice(tagIndex, 1)

                                        dispatch({
                                            type: 'SET_PROP',
                                            payload: {
                                                key:
                                                    currentFilter.tags
                                                        .contextProp,
                                                value: newTags,
                                            },
                                        })
                                    },
                                })
                            },
                        )
                    }
                }
            }
        }

        const genericFilterValues = genericFilters.map((f) => ({
            name: f.name,
            label: getGenericFilterAppliedValueLabel(f),
            category: f.category,
            colorCode: f.colorCode,
            withoutColor: f.withoutColor,
            urlOverrideProp: f.urlOverrideProp,
            contextProp: f.contextProp,
            onRemove: () =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: f.contextProp,
                        value: getGenericFilterFalsyValue(f),
                    },
                }),
        }))

        const appliedFilterValues = targetSystemFilters
            .concat(genericFilterValues)
            .concat(checklistFiltersValues)
            .concat(tagFiltersValues)
            .concat(tCodeFilterValue)

        return appliedFilterValues
    }

    const appliedFilterValues = getAppliedFiltersValues()

    return appliedFilterValues
}

export default useAppliedFilterValues
