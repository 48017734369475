import { FC, useState, useEffect } from 'react'
import { Select } from '../../eid-ui'
import { useSwrApiGet } from 'packages/core'

export interface EidSelectProps {
    url?: any
    value?: any
    placeholder?: any
    onChange?: any
    queryParams?: any
    shouldTriggerApiCall?: any
    excludeOptions?: any
    primitiveType?: any
    dedupingInterval?: any
    debounceInterval?: any
    getOptionLabel?: any
    clearIcon?: any
}

export const EidSelect: FC<EidSelectProps> = (props) => {
    const {
        url,
        value,
        onChange,
        queryParams = [],
        dedupingInterval = 2000,
        shouldTriggerApiCall = true,
        excludeOptions = [],
        primitiveType,
        getOptionLabel,
        ...rest
    } = props

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [options, setOptions] = useState([])

    const queryStringParts: any = []
    Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] !== undefined)
            queryStringParts.push(`${key}=${queryParams[key]}`)
    })

    const queryString = queryStringParts.join('&')
    const urlWithQs = queryString ? `${url}?${queryString}` : url

    const { data } = useSwrApiGet(
        () => (callEndpoint && shouldTriggerApiCall ? urlWithQs : false),
        {
            dedupingInterval,
        },
    )

    useEffect(() => {
        if (data) {
            let options = data.data

            if (primitiveType) {
                options = data.data.map((option: any) => {
                    return { id: option, value: option }
                })
            }
            if (excludeOptions.length > 0) {
                options = data.data.filter(
                    (d: any) => !excludeOptions.includes(d.id),
                )
            }

            setOptions(options)
        }
    }, [data])

    return (
        <Select
            onOpen={() => setCallEndpoint(true)}
            onClose={() => setCallEndpoint(false)}
            options={options}
            value={value}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            isLoading={callEndpoint && shouldTriggerApiCall && !data}
            {...rest}
        />
    )
}
