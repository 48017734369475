import Radio from '@mui/material/Radio'
import { List, ListItemButton, styled, Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'

const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: '208px',
        overflow: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '4px',
            '-webkit-border-radius': '3px',
            borderRadius: '3px',
            backgroundColor: '#f7f7f7',
        },

        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            borderRadius: '5px',
            border: '2px solid transparent',
            height: '20px',
            backgroundColor: '#d8d8d8',
        },
        backgroundColor: theme?.palette?.common?.white,
        '& .MuiListItemButton-root': {
            cursor: 'pointer',
            width: '100%',
            wordBreak: 'break-word',
            paddingTop: '10px',
            fontSize: '14px',

            '&:has(.Mui-checked)': {
                fontWeight: 'bold',
                backgroundColor: 'transparent',
            },
        },
    },
}))

const tooltipStyles = {
    right: '70px',
}

const DifferentiationValueSelector = ({
    loading,
    data,
    value: selectedValueId,
    onChange,
    getOptionLabel,
    checkSelectedOption,
    readOnlyOptions,
    getTooltipText,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    return (
        <List classes={classes}>
            {loading ? (
                <>
                    <Box margin="4px 8px">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                    <Box margin="4px 8px">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                </>
            ) : data.length === 0 &&
              (!readOnlyOptions || readOnlyOptions.length < 1) ? (
                <ListItemButton>{t('Common_NoDataFound')}</ListItemButton>
            ) : (
                <>
                    {readOnlyOptions &&
                        readOnlyOptions.map((o, index) => (
                            <ListItemButton key={`${o.id}${index}`}>
                                <Icon
                                    name={'Checked'}
                                    style={{ marginRight: '9px' }}
                                />
                                {getOptionLabel
                                    ? getOptionLabel(o)
                                    : o.friendlyName}
                            </ListItemButton>
                        ))}

                    {data.map((i, index) => {
                        let tooltipText = ''
                        if (getTooltipText) {
                            tooltipText = getTooltipText(i)
                        }
                        return (
                            <Tooltip
                                tooltipStyles={tooltipStyles}
                                placement="top"
                                arrow={true}
                                title={tooltipText || i.fullPath || ''}
                            >
                                <ListItemButton
                                    selected={
                                        checkSelectedOption
                                            ? checkSelectedOption(
                                                  i,
                                                  selectedValueId,
                                              ) === selectedValueId
                                            : i.id === selectedValueId
                                    }
                                    key={`${i.id}${index}`}
                                    onClick={() => onChange(i)}
                                >
                                    <StyledRadioIcon
                                        icon={<Icon name="Radio" />}
                                        checkedIcon={
                                            <Icon
                                                name="RadioFilled"
                                                color={
                                                    theme?.palette?.primary
                                                        ?.main
                                                }
                                            />
                                        }
                                        checked={
                                            checkSelectedOption
                                                ? checkSelectedOption(
                                                      i,
                                                      selectedValueId,
                                                  )
                                                : i.id === selectedValueId
                                        }
                                    />
                                    {getOptionLabel
                                        ? getOptionLabel(i)
                                        : i.friendlyName}
                                </ListItemButton>
                            </Tooltip>
                        )
                    })}
                </>
            )}
        </List>
    )
}

export default DifferentiationValueSelector
