import { Fragment, useState, useEffect } from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails, TimeDurationSelector } from 'components'
import {
    Loader,
    Tooltip,
    WarningModal,
    ToggleSwitch,
    useNotification,
} from 'packages/eid-ui'
import {
    useAccessRequestPolicy,
    useAddItemToCart,
    useCart,
    useTargetPerson,
    useRenewableAssignmentInfo,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useTimeConstrainedControl } from 'components/TimeConstrainedControl'
import { IResourceType } from 'core'
import cartHelpers from 'containers/Cart/cartHelpers'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export type RenewRevokeProps = {
    resourceType: IResourceType
    resource: any
    secondary: any
    assignment: any
    preRenew: any
    postRenew: any
    preRevoke: any
    postRevoke: any
    item: any
}

export const RenewRevoke = (props: any) => {
    const [enableApiCall, setEnableApiCall] = useState(false)
    const [toggle, setToggle] = useState(false)
    const theme = useTheme()

    const isBusinessRole = props?.resourceType?.name === 'BusinessRoles'

    const { isLoading, data: renewableData } = useRenewableAssignmentInfo(
        props.assignment?.personId,
        isBusinessRole
            ? props.assignment?.resourceAssignment?.resourceAssignmentId
            : props.assignment?.resourceAssignment?.resourceId,
        isBusinessRole,
        enableApiCall,
    )
    const isRenewable = props.assignment?.isRenewable

    const [targetPerson] = useTargetPerson()

    const isRevokable = props.assignment?.assignmentDetails?.isRevokable

    const { t } = useTranslation()

    const { data: cart } = useCart()
    const { showWarningMessage } = useNotification()

    const accessRequestPolicy = useAccessRequestPolicy(
        props.resource.requestPolicyId,
        isBusinessRole ? props.resource.id : '',
        isBusinessRole ? props.secondary.id : '',
    )
    const isTimeConstrained = accessRequestPolicy?.isTimeConstrained
    const [addItemToCart, { isLoading: isAdding }] = useAddItemToCart()

    const [showConfirm, setConfirm] = useState(false)
    useEffect(() => {
        if (enableApiCall && renewableData && renewableData.isRenewable) {
            addToCartForRenew()
        } else if (
            enableApiCall &&
            renewableData &&
            renewableData.isRenewable === false
        ) {
            showWarningMessage(t('Common_UnableToRenewMessage'))
            setEnableApiCall(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, renewableData, enableApiCall])

    const timeConstrainedActive =
        props.assignment.assignmentDetails?.timeConstraintActive

    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        violatesPolicy,
        startDateControl,
        endDateControl,
        requestAllowed,
    } = useTimeConstrainedControl(accessRequestPolicy, timeConstrainedActive)

    if (!cart || !accessRequestPolicy) return <Loader />

    const itemAlreadyInCart = props.resourceType.alreadyInCart(
        cart?.cartItems,
        props.resource,
        props.secondary,
    )

    const handleRevoke = async () => {
        const itemToRevoke = await props.preRevoke()
        if (itemToRevoke === undefined) return

        addItemToCart(itemToRevoke).then(() => {
            setConfirm(false)
            props.postRevoke()
        })
    }
    const handleRenewal = () => {
        setEnableApiCall(true)
    }

    const addToCartForRenew = () => {
        const timeConstraintDetails = {
            timeConstrained: isTimeConstrained,
            startDateUtc: selectedStartDate,
            endDateUtc: selectedEndDate,
            timebased: isTimeConstrained,
        }

        const itemToRenew = cartHelpers.accessItemToCartItem(
            props.resourceType.name !== 'AzureRoles'
                ? props.resourceType.name
                : props.resource.roleGroupType === 'Rbac'
                    ? 'AzureRbacRoles'
                    : 'AzureAdminRoles',
            targetPerson,
            props.item,
            'Add',
            timeConstraintDetails as any,
        )

        if (!itemToRenew) return

        addItemToCart(itemToRenew as any).then(() => {
            // setDisableButton(true)
            // postRenew()
        })
    }

    const getRenewTooltipTitle = () =>
        itemAlreadyInCart
            ? t('Common_ItemAlreadyInCart')
            : hasInvalidDates
                ? t('Common_InvalidDates')
                : ''

    const getRevokeTooltipTitle = () =>
        itemAlreadyInCart ? t('Common_ItemAlreadyInCart') : ''

    const showRenew = Boolean(requestAllowed && isRenewable)

    // const showRequestAgain = true
    //

    // const showRevoke = props.assignment.assignmentDetails.isRevokable

    let validationMessage = ''
    if (violatesPolicy) {
        validationMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy.maximumValueInMinutes,
        })
    }

    return (
        <Fragment>
            {isRevokable && (
                <PaddedDiv
                    style={{
                        minHeight: '104px',
                        paddingTop: '32px',
                        paddingBottom: '20px',
                        alignItems: 'center',
                    }}
                >
                    {showRenew &&
                        ((!timeConstrained && toggle) || timeConstrained) && (
                            <TimeDurationSelector
                                startDateTimeSelector={
                                    <Box display="flex" width="220px">
                                        {startDateControl}
                                    </Box>
                                }
                                endDateTimeSelector={
                                    <Box display="flex" width="220px">
                                        {endDateControl}
                                    </Box>
                                }
                                validationMessage={validationMessage}
                            />
                        )}

                    <Box
                        display="flex"
                        width="100%"
                        marginTop={showRenew ? '56px' : '0px'}
                        position="relative"
                        minHeight="50px"
                    >
                        {showRenew && (
                            <Box display={'flex'}>
                                <ItemDetails.ActionButton
                                    disabled={getRenewTooltipTitle() !== '' || props?.disabled}
                                    fontColor={theme?.palette?.common?.white}
                                    loaderColor={theme?.palette?.common?.white}
                                    bgColor={theme?.palette?.secondary?.main}
                                    width="fit-content"
                                    minWidth="120px"
                                    loading={isAdding || isLoading || props?.isRevoking}
                                    onClick={handleRenewal}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        paddingRight="10px"
                                    >
                                        <Icon
                                            name="Renew"
                                            color={
                                                theme?.palette?.common?.white
                                            }
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            style={{
                                                color:
                                                    theme?.palette?.common
                                                        ?.white,
                                            }}
                                        >
                                            {t('Common_Renew')}
                                        </Typography>
                                    </Box>
                                </ItemDetails.ActionButton>
                                {!timeConstrained && (
                                    <Box style={{ marginLeft: 10 }}>
                                        <ToggleSwitch
                                            value={toggle}
                                            label={t('Common_SetDuration')}
                                            labelColor="#5d6870"
                                            // disabled={disabled}
                                            onChange={() =>
                                                setToggle((prev: any) => !prev)
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                        <Tooltip title={getRevokeTooltipTitle()}>
                            <Box
                                position="absolute"
                                display={'flex'}
                                right={0}
                                bottom={0}
                            >
                                <ItemDetails.ActionButton
                                    fontColor={theme?.palette?.error?.main}
                                    border={`solid 1px ${theme?.palette?.error?.main}`}
                                    loaderColor={theme?.palette?.error?.main}
                                    bgColor={theme?.palette?.common?.white}
                                    width="fit-content"
                                    minWidth={'120px'}
                                    disabled={getRevokeTooltipTitle() !== '' || props?.disabled}
                                    onClick={() => setConfirm(true)}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        paddingRight="10px"
                                    >
                                        <Icon
                                            name="Close"
                                            color={theme?.palette?.error?.main}
                                            height="16px"
                                            width="16px"
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            style={{
                                                color:
                                                    theme?.palette?.error?.main,
                                            }}
                                        >
                                            {t('Common_Revoke')}
                                        </Typography>
                                    </Box>
                                </ItemDetails.ActionButton>
                                <WarningModal
                                    title={t('Common_Confirm')}
                                    description={t(props?.warningMessageKey ||
                                        'Common_ConfirmationBeforeRevocation',
                                    )}
                                    yesLabel={t('Common_Yes')}
                                    noLabel={t('Common_No')}
                                    open={showConfirm}
                                    cancel={() => setConfirm(false)}
                                    proceeding={isAdding}
                                    proceed={handleRevoke}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </PaddedDiv>
            )}
        </Fragment>
    )
}
