import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useManagementRoleManageAccess, useTargetPerson } from 'hooks'
import ManagementRoleDetails from './ManagementRoleDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const ManagementRolesDrawer = ({ id, attributes, toggleDrawer, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data } = useManagementRoleManageAccess(id, targetPerson.id)

    let item
    if (data) {
        item = {
            resource: data.resource,
            assignment: data.assignment,
        }
    }

    const itemDetails = <ManagementRoleDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default ManagementRolesDrawer
