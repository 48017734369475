import * as React from 'react'
import { Box, Button, Dialog, styled, Typography, Slide, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ItemCardSkeleton from './ItemCardSkeleton'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { CardList } from 'packages/eid-controls'
import ItemCard from './ItemCard'
import { useTranslation } from 'react-i18next'
import { Divider, IconButton } from 'packages/eid-ui'
import StickyContainer from 'components/StickyContainer'
import { ApplicationBanner } from 'components/ApplicationBanner'
import useSubcomponents from 'useSubcomponents'
import Selectors from 'containers/Selectors'
import SideDrawer from './SideDrawer'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import appConfig from 'config'
import { useAppState } from 'appContext'
import Search from 'components/Layout/ITShopHeader/Search'
import { useEffect, useRef, useState } from 'react'
import GenericButton from 'components/Button/GenericButton'
import MobileSortingMenu from 'components/MobileSorting/MobileSortingMenu'
import MobileButtons from 'components/MobileButtons'
import { useResourceTypeContext } from 'resourceTypeContext'

const maxWidth = '420px'
const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    padding: '0px 18px',
}

const ItemsContainer = styled('div')(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

const StyledButton = styled(
    ({ theme, children, label, subLabel, ...other }) => (
        <Button {...other}>
            {children ? (
                children
            ) : (
                <>
                    <Typography
                        style={{
                            color: '#363636',
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        {label}
                    </Typography>
                    <Typography
                        style={{
                            color: theme?.palette?.grey?.[900],
                            fontSize: '11px',
                            lineHeight: '13px',
                        }}
                    >
                        {subLabel}
                    </Typography>
                </>
            )}
        </Button>
    ),
)(({ theme, border, styles }) => ({
    width: '100%',
    maxWidth: '260px',
    backgroundColor: theme?.palette?.common?.white,
    border: border ? border : '1px solid #d2d2d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: theme?.palette?.common?.white,
        border: border ? border : '1px solid #d2d2d9',
        boxShadow: 'none',
    },
    ...styles,
}))
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
const useDialogStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme?.palette?.background?.default,
        padding: '16px',
    },
    container: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '8px',
    },
    closeIcon: {
        display: 'flex',
        marginRight: '-16px',
        marginTop: '-10px',
        height: '16px',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
    },
}))

const ListingPageMobile = ({
    totalCount,
    loading,
    loadingMore,
    infiniteScrollObervableDiv,
    id,
    data,
    onItemClick,
    currentResourceType,
    resourceTypeAttributes,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const history = useHistory()
    const query = useQuery()
    const CURRENT_SCREEN = 'RequestAccess'
    const [{ appConfig: appSettings }] = useAppState()
    const dialogClasses = useDialogStyles()
    const stickyContainerRef = useRef(null)
    const [stickyContainerHeight, setStickyContainerHeight] = useState(0)
    const [dispatch] = useResourceTypeContext()
    useEffect(() => {
        setStickyContainerHeight(stickyContainerRef?.current?.clientHeight)
    }, [stickyContainerRef?.current?.clientHeight])

    const {
        canSeeAtLeastOneFilter,
        canSeeSimpleTextSearch,
    } = useSubcomponents()
    const canSeeAnyFilter = canSeeAtLeastOneFilter(
        currentResourceType,
        CURRENT_SCREEN,
    )
    const showFilters = query.get('filters') === 'visible'
    const applicationId = query.get('applicationId')
    const handleCloseFilterDialog = () => {
        query.delete('filters')
        history.push(
            `${appConfig.APP_SUBPATH}${
                history.location.pathname
            }?${query.toString()}`,
        )
    }
    const stickyContentListing = (
        <>
            {applicationId && (
                <Box paddingX="8px" mb={4} mt={2}>
                    <ApplicationBanner applicationId={applicationId} />
                </Box>
            )}
            {appSettings.style.search.show && canSeeSimpleTextSearch && (
                <div
                    style={{
                        margin: '15px 16px 20px 16px',
                    }}
                    data-protectedsubcomponent={canSeeSimpleTextSearch}
                >
                    <Search />
                </div>
            )}

            <div style={{ ...flexCenter }}>
                {canSeeAnyFilter && (
                    <MobileButtons.Filter
                        label={t('Common_Filters')}
                        subLabel={t('Common_ApplyFilters')}
                        currentResourceType={currentResourceType}
                        onClick={() => {
                            query.set('filters', 'visible')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                )}
                <MobileSortingMenu
                    attributes={currentResourceType.attributes}
                />
            </div>
        </>
    )
    const listingScreen = (
        <>
            <Box
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    position: 'fixed',
                    backgroundColor: theme?.palette?.background?.default,
                    zIndex: '1100',
                }}
                ref={stickyContainerRef}
            >
                <Box maxWidth={maxWidth} width="100%" padding="12px 0px">
                    {stickyContentListing}
                </Box>
            </Box>

            <ItemsContainer top={stickyContainerHeight}>
                <CardList
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    noItemMessage={
                        <Typography variant="h5" style={{ color: '#b4b4b4' }}>
                            {t('Common_NoItemsFound', {
                                itemType: t(currentResourceType.title),
                            })}
                        </Typography>
                    }
                    loadingIndicator={
                        <>
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                        </>
                    }
                    fetcher={() => ({
                        loading,
                        loadingMore,
                        list: data,
                    })}
                    renderItem={(item) => (
                        <div
                            key={item.id}
                            style={{
                                padding: '8px',
                                height: 'inline-block',
                                width: '100%',
                            }}
                        >
                            <ItemCard
                                cardStyles={{
                                    maxWidth: '420px',
                                    margin: 'auto',
                                    '&:hover': {
                                        border: `1px solid ${theme?.palette?.secondary?.main} !important`,
                                    },
                                }}
                                buttonStyles={{
                                    '&:hover': {
                                        backgroundColor: '#02997E',
                                    },
                                }}
                                key={item.id}
                                attributes={resourceTypeAttributes}
                                cardHeaderStyles={{}}
                                item={item}
                                onItemClick={() => onItemClick(item)}
                                color={currentResourceType.color}
                                resourceType={currentResourceType}
                            />
                        </div>
                    )}
                />
            </ItemsContainer>
        </>
    )

    const filtersScreen = (
        <>
            <Dialog
                open={showFilters}
                style={{ marginTop: '60px' }}
                keepMounted
                scroll={'body'}
                TransitionComponent={Transition}
                fullScreen
                classes={dialogClasses}
                onClose={handleCloseFilterDialog}
            >
                <Box className={dialogClasses.modalHeader}>
                    <Typography className={dialogClasses.title}>
                        {t('Filters')}
                    </Typography>
                    <Box className={dialogClasses.closeIcon}>
                        <IconButton onClick={handleCloseFilterDialog} size="large">
                            <Icon name="Close" color="#959598" />
                        </IconButton>
                    </Box>
                </Box>
                <Divider color={'#d8dadd'} />

                <ItemsContainer>
                    <Selectors
                        screen={'RequestAccess'}
                        resourceType={currentResourceType.name}
                    />
                    <GenericButton
                        onClick={handleCloseFilterDialog}
                        loading={totalCount === undefined}
                        color={theme?.palette?.common?.white}
                        rootStylesProp={{
                            backgroundColor: '#3491d5',
                            borderRadius: '4px',
                            width: '100%',
                            height: '48px',
                            fontSize: '14px',
                            padding: '8px 12px 8px 12px',
                        }}
                        label={`${t('Show')} ${totalCount} ${t(
                            currentResourceType.title,
                        )}`}
                    />
                </ItemsContainer>
            </Dialog>
        </>
    )

    const itemDetailsScreen = (
        <>
            <StickyContainer
                color={theme?.palette?.common?.white}
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    height: '70px',
                }}
            >
                <Box
                    maxWidth={maxWidth}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                    padding="0px 8px"
                >
                    <StyledButton
                        onClick={() => {
                            query.delete('selected')
                            history.push(
                                `${appConfig.APP_SUBPATH}/${
                                    currentResourceType.name
                                }?${query.toString()}`,
                            )
                        }}
                        border={`1px solid ${theme?.palette?.primary?.main}`}
                        styles={{
                            color: theme?.palette?.primary?.main,
                            fontSize: '14px',
                        }}
                        startIcon={
                            <ArrowIcon
                                style={{ transform: 'rotate(-90deg)' }}
                                color={theme?.palette?.primary?.main}
                            />
                        }
                    >
                        {t('Common_BackTo', {
                            text: t(currentResourceType.title),
                        })}
                    </StyledButton>
                </Box>
            </StickyContainer>
            <ItemsContainer top={86}>
                <SideDrawer
                    id={id}
                    toggleDrawer={() => {
                        query.delete('selected')
                        dispatch({
                            type: 'UPDATE_APP_RIGHTS_FIELD_TYPES',
                            payload: null,
                        })
                        history.push(
                            `${appConfig.APP_SUBPATH}/${
                                currentResourceType.name
                            }?${query.toString()}`,
                        )
                    }}
                    attributes={resourceTypeAttributes}
                    type={currentResourceType.name}
                    color={currentResourceType.color}
                />
            </ItemsContainer>
        </>
    )

    return (
        <Box marginTop={appSettings.style.header.hide ? '130px' : '90px'}>
            <Box display={showFilters ? '' : 'none'}>{filtersScreen}</Box>
            <Box display={!showFilters ? '' : 'none'}>
                <Box display={!id ? '' : 'none'}>{listingScreen}</Box>
                <Box display={id ? '' : 'none'}>{itemDetailsScreen}</Box>
            </Box>
        </Box>
    )
}

export default ListingPageMobile
