import React, { useState } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { isNilOrEmpty, useDebounce, useIsSmallScreen } from 'packages/core'
import { Pagination } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    FunctionsList,
    ItemDetails,
    PeopleList,
    StyledSearchInput,
} from 'components'
import {
    useBusinessRoleGlobalSenstiveFunctions,
    useBusinessRoleOwners,
} from 'hooks'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const take = 10
const hasAccessToOwnerControl = 'ITShop-BusinessRoles-Details-Owners-Control'

export const MoreInformationSection = ({ businessRole }: any) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { hasAccessToControl } = useSubcomponents()
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const handlePageSelection = (value: any) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event: any) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { data: owners, isLoading: ownersLoading } = useBusinessRoleOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? businessRole.id : null,
    )

    const { latestData } = useBusinessRoleGlobalSenstiveFunctions(
        businessRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const globalSensitiveFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <Box
                            data-protectedsubcomponent={hasAccessToOwnerControl}
                            padding="0 31px 8.5px 31px"
                        >
                            <ItemDetails.Attribute
                                label={t('BusinessRoles_Owners')}
                                value={<PeopleList list={owners} />}
                                orientation={attributeOrientation}
                            />
                        </Box>
                    )}
                    {!isNilOrEmpty(searchKey) ||
                    (globalSensitiveFunctions &&
                        globalSensitiveFunctions.length > 0) ? (
                        <>
                            <PaddedDiv
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px 31px',
                                }}
                            >
                                <Box width="50%">
                                    <Typography
                                        style={{
                                            fontSize: '12px',
                                            lineHeight: 1.33,
                                            color: '#9b9b9b',
                                            wordBreak: 'break-word',
                                            textTransform: 'uppercase',
                                            minWidth: '80px',
                                        }}
                                    >
                                        {t('Common_GlobalFunctionalAccess')}
                                    </Typography>
                                </Box>

                                <Box
                                    width={!isSmallScreen ? '308px' : '50%'}
                                    minWidth="130px"
                                >
                                    <StyledSearchInput
                                        fullWidth
                                        type="text"
                                        placeholder={t('Common_Search')}
                                        value={searchKey}
                                        onChange={handleSearchChange}
                                    />
                                </Box>
                            </PaddedDiv>
                            <Box overflow="auto">
                                <FunctionsList
                                    data={globalSensitiveFunctions}
                                    loading={globalFunctionsLoading}
                                />

                                <Box
                                    padding={
                                        !isSmallScreen ? '20px 32px' : '16px'
                                    }
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={
                                        !isSmallScreen ? 'flex-start' : 'center'
                                    }
                                >
                                    <Pagination
                                        count={numberOfPages}
                                        size={
                                            !isSmallScreen ? 'large' : 'small'
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        totalCount={latestData?.totalCount}
                                        onPageSelection={handlePageSelection}
                                        itemsPerPage={perPageItems}
                                        onItemsPerPageChange={
                                            handleItemsPerPageChange
                                        }
                                        showPageSelection={true}
                                        showItemsPerPageSelection
                                        showCountStat
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box marginY="20px" />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
