import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const InfoMessageForWrongJson = () => {
    const { t } = useTranslation()
    return (
        <Box>
            <Typography
                sx={{
                    color: '#5d6870',
                    fontSize: '1.2rem !important',
                }}
            >
                {t('Common_WronglyConfuredContactAdmin')}
            </Typography>
        </Box>
    )
}

export default InfoMessageForWrongJson
