import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useApplicatiponAzureRole, useTargetPerson } from 'hooks'
import AppRoleDefinitionDetails from './AppRoleDefinitionsDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const AppRoleDefinitionsDrawer = ({
    id,
    attributes,
    toggleDrawer,
    ...rest
}) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data: azureRole } = useApplicatiponAzureRole(id, targetPerson.id)

    let item
    if (azureRole) {
        item = {
            resource: {
                ...azureRole,
                description:
                    azureRole?.description || azureRole.assignmentPointName,
                requestPolicyId: azureRole.accessRequestPolicyId,
            },
            assignment: {
                personId: targetPerson.id,
                isAssigned: azureRole.isAssigned,
                canActivateNow: azureRole.canActivateNow,
                isRenewable: azureRole.isRenewable,
                resourceAssignment: {
                    id: azureRole.applicationId,
                    friendlyName: azureRole.assignmentPointName,
                },
                assignmentDetails: {
                    timeConstraintActive: azureRole.timeConstraintActive,
                    startDateUtc: azureRole.startDateUtc,
                    endDateUtc: azureRole.endDateUtc,
                    isRevokable: azureRole.isRevocable,
                },
            },
        }
    }

    const itemDetails = <AppRoleDefinitionDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default AppRoleDefinitionsDrawer
