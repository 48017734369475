import React, { useState, useEffect } from 'react'
import { ToggleSwitch } from 'packages/eid-ui'

const CheckboxFilter = ({ value, onChange, label }) => {
    const [toggle, setToggle] = useState(value)

    useEffect(() => {
        if (value !== toggle) {
            setToggle(value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        onChange(toggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle])

    return (
        <ToggleSwitch
            checked={toggle}
            onChange={() => setToggle(!toggle)}
            value={toggle}
            label={label}
        />
    )
}

export default CheckboxFilter
