import { withRouter } from 'react-router'
import * as React from 'react'

import StickyContainer from 'components/StickyContainer'
import {
    Box,
    Button,
    Dialog,
    Divider,
    IconButton,
    Paper,
    Slide,
    styled,
    Typography,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CardList } from 'packages/eid-controls'
import AccessItemDrawer from './AccessItemDrawer'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import AccessItem from './AccessItem'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from 'resourceTypeContext'
import { getUniqueId } from './resourceTypeHelpers'
import appConfig from 'config'
import { useAppState } from 'appContext'
import useSubcomponents from 'useSubcomponents'
import Search from 'components/Layout/ITShopHeader/Search'
import ManageAccessTabs from './ManageAccessTabs'
import { useRef, useState, useEffect } from 'react'
import MobileSortingMenu from 'components/MobileSorting/MobileSortingMenu'
import MobileButtons from 'components/MobileButtons'
import Selectors from 'containers/Selectors'
import GenericButton from 'components/Button/GenericButton'

const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
}

const maxWidth = '420px'
const StyledButton = styled(
    ({ theme, children, label, subLabel, ...other }) => (
        <Button {...other}>
            {children ? (
                children
            ) : (
                <>
                    <Typography
                        style={{
                            color: '#363636',
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        {label}
                    </Typography>
                    <Typography
                        style={{
                            color: theme?.palette?.grey?.[900],
                            fontSize: '11px',
                            lineHeight: '13px',
                        }}
                    >
                        {subLabel}
                    </Typography>
                </>
            )}
        </Button>
    ),
)(({ theme, border, styles }) => ({
    width: '100%',
    maxWidth: '260px',
    backgroundColor: theme?.palette?.common?.white,
    border: border ? border : '1px solid #d2d2d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: theme?.palette?.common?.white,
        border: border ? border : '1px solid #d2d2d9',
        boxShadow: 'none',
    },
    ...styles,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
const ItemsContainer = styled('div')(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))
const useDialogStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme?.palette?.background?.default,
        padding: '16px',
    },
    container: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '8px',
    },
    closeIcon: {
        display: 'flex',
        marginRight: '-16px',
        marginTop: '-10px',
        height: '16px',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
    },
}))

const MobileVersion = ({
    id,
    list,
    loading,
    totalCount,
    loadingMore,
    currentResourceType,
    resourceTypeAttributes,
    infiniteScrollObervableDiv,
    history,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [{ appConfig: appSettings }] = useAppState()
    const dialogClasses = useDialogStyles()
    const stickyContainerRef = useRef(null)
    const [stickyContainerHeight, setStickyContainerHeight] = useState(0)
    useEffect(() => {
        setStickyContainerHeight(stickyContainerRef?.current?.clientHeight)
    }, [stickyContainerRef?.current?.clientHeight])

    const [{ manageAccess }, dispatch] = useResourceTypeContext()
    // NOTE: Not removing below code, waiting for UX design

    // const { getMyTasksUrl } = useUrlGenerator(useAllApplications)
    // const myTasksUrl = getMyTasksUrl()
    // const controls = useGetControlsAccess()
    // const hasAccessToViewPendingAccess =
    //     controls.findIndex(
    //         (c) => c.name === 'ITShop-ViewPendingAccess-Control',
    //     ) >= 0

    const query = useQuery()
    const showFilters = query.get('filters') === 'visible'
    const { canSeeSimpleTextSearch, hasAccessToTabs } = useSubcomponents()

    const { hasAccessToManageAccess } = hasAccessToTabs(
        manageAccess?.selectedTab?.requireAccess?.control,
    )

    const selectedTab = query.get('tab')

    let manageAccessTabs = currentResourceType.manageAccessTabs
    const [activeTab, setActiveTab] = useState(
        selectedTab && manageAccessTabs
            ? manageAccessTabs?.filter((t) => t.path === selectedTab)[0]
            : '',
    )
    const isSmallScreen = useIsSmallScreen()
    const handleCloseFilterDialog = () => {
        query.delete('filters')
        history.push(
            `${appConfig.APP_SUBPATH}${
                history.location.pathname
            }?${query.toString()}`,
        )
    }

    const stickyContentListing = (
        <>
            {manageAccessTabs && manageAccessTabs.length && (
                <Box
                    style={{
                        padding: isSmallScreen ? '8px 0px' : '8px',
                        width: '100%',
                    }}
                >
                    <Paper>
                        <ManageAccessTabs
                            tabs={manageAccessTabs}
                            active={
                                selectedTab
                                    ? selectedTab
                                    : manageAccessTabs.filter(
                                          (t) => t.default,
                                      )[0].path
                            }
                            activeTab={activeTab}
                            onChange={(tab) => {
                                setActiveTab(tab)
                                //TODO: Need to uncomment below functions when filters functionality fixed
                                // clearAllFilters()
                                history.push(
                                    !tab.default
                                        ? `${history.location.pathname}?tab=${tab.path}`
                                        : `${history.location.pathname}`,
                                )
                                dispatch({
                                    type: 'SET_MANAGE_ACCESS_FILTERS',
                                    payload: {
                                        selectedTab: tab,
                                    },
                                })
                            }}
                        ></ManageAccessTabs>
                    </Paper>
                </Box>
            )}

            {appSettings.style.search.show && canSeeSimpleTextSearch && (
                <div
                    style={{
                        margin: '15px 0px 20px',
                    }}
                    data-protectedsubcomponent={canSeeSimpleTextSearch}
                >
                    <Search />
                </div>
            )}
            <div style={{ ...flexCenter }}>
                <MobileButtons.Filter
                    label={t('Common_Filters')}
                    subLabel={t('Common_ApplyFilters')}
                    currentResourceType={currentResourceType}
                    onClick={() => {
                        query.set('filters', 'visible')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
                <MobileSortingMenu
                    attributes={currentResourceType.attributes}
                />
                {/* NOTE: Not removing below code, waiting for UX design */}

                {/* {myTasksUrl && hasAccessToViewPendingAccess && (
                    <StyledButton
                        label={t('Common_View')}
                        subLabel={t('ManageAccess_PendingAccess')}
                        href={myTasksUrl}
                        styles={{
                            marginLeft: '12px',
                            '& > span': {
                                display: 'flex ',
                                flexDirection: 'column',
                            },
                        }}
                    />
                )} */}
            </div>
        </>
    )

    const listingScreen = (
        <>
            <StickyContainer>
                <Box
                    maxWidth={maxWidth}
                    width="100%"
                    padding="12px 16px"
                    ref={stickyContainerRef}
                >
                    {stickyContentListing}
                </Box>
            </StickyContainer>
            <ItemsContainer top={stickyContainerHeight}>
                <CardList
                    fetcher={() => ({ loading, loadingMore, list })}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    noItemMessage={
                        <Typography variant="h5" style={{ color: '#b4b4b4' }}>
                            {t('Common_NoItemsFound', {
                                itemType: t(currentResourceType.title),
                            })}
                        </Typography>
                    }
                    renderItem={(item, index) => (
                        <div
                            key={`${getUniqueId(item, selectedTab)}-${index} `}
                            style={{
                                padding: '8px',
                                height: 'max-content',
                                width: '100%',
                            }}
                        >
                            <AccessItem
                                resourceType={currentResourceType.name}
                                cardStyles={{
                                    maxWidth: '420px',
                                    margin: 'auto',
                                    '&:hover': {
                                        border: `1px solid ${theme?.palette?.secondary?.main} !important`,
                                    },
                                }}
                                buttonStyles={{
                                    '&:hover': {
                                        backgroundColor: '#02997E',
                                    },
                                }}
                                attributes={resourceTypeAttributes}
                                cardHeaderStyles={{}}
                                item={item}
                                onItemClick={() => {
                                    query.set(
                                        'selected',
                                        getUniqueId(item, selectedTab),
                                    )
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                                color={{
                                    primary: theme?.palette?.secondary?.main,
                                }}
                            />
                        </div>
                    )}
                />
            </ItemsContainer>
        </>
    )

    const itemDetailsScreen = (
        <>
            <StickyContainer
                color={theme?.palette?.background?.paper}
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    height: '70px',
                    padding: '12px 0px',
                }}
            >
                <Box
                    maxWidth={maxWidth}
                    width="100%"
                    display="flex"
                    justifyContent="space-around"
                    padding="0px 8px"
                >
                    <StyledButton
                        onClick={() => {
                            query.delete('selected')
                            if (query.get('showSession')) {
                                query.delete('showSession')
                            }
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        border={`1px solid ${theme?.palette?.primary?.main}`}
                        styles={{
                            color: theme?.palette?.primary?.main,
                            fontSize: '14px',
                        }}
                        startIcon={
                            <ArrowIcon
                                style={{ transform: 'rotate(-90deg)' }}
                                color={theme?.palette?.primary?.main}
                            />
                        }
                    >
                        {t('Common_BackTo', {
                            text: t(currentResourceType.title),
                        })}
                    </StyledButton>
                </Box>
            </StickyContainer>
            <ItemsContainer top={70}>
                {id && hasAccessToManageAccess && (
                    <AccessItemDrawer
                        type={currentResourceType.name}
                        id={id}
                        attributes={resourceTypeAttributes}
                        toggleDrawer={() => {
                            query.delete('selected')
                            if (query.get('showSession')) {
                                query.delete('showSession')
                            }
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        color={{
                            primary: theme?.palette?.secondary?.main,
                        }}
                    />
                )}
            </ItemsContainer>
        </>
    )

    const filtersScreen = (
        <>
            <Dialog
                open={showFilters}
                style={{ marginTop: '60px' }}
                keepMounted
                scroll={'body'}
                TransitionComponent={Transition}
                fullScreen
                classes={dialogClasses}
                onClose={handleCloseFilterDialog}
            >
                <Box className={dialogClasses.modalHeader}>
                    <Typography className={dialogClasses.title}>
                        {t('Filters')}
                    </Typography>
                    <Box className={dialogClasses.closeIcon}>
                        <IconButton onClick={handleCloseFilterDialog} size="large">
                            <Icon name="Close" color="#959598" />
                        </IconButton>
                    </Box>
                </Box>
                <Divider color={'#d8dadd'} />

                <ItemsContainer>
                    <Selectors
                        screen={'ManageAccess'}
                        resourceType={currentResourceType.name}
                    />
                    <GenericButton
                        onClick={handleCloseFilterDialog}
                        loading={totalCount === undefined}
                        color={theme?.palette?.common?.white}
                        rootStylesProp={{
                            backgroundColor: '#3491d5',
                            borderRadius: '4px',
                            width: '100%',
                            height: '48px',
                            fontSize: '14px',
                            padding: '8px 12px 8px 12px',
                        }}
                        label={`${t('Show')} ${totalCount} ${t(
                            currentResourceType.title,
                        )}`}
                    />
                </ItemsContainer>
            </Dialog>
        </>
    )

    return (
        <Box marginTop={appSettings.style.header.hide ? '130px' : '90px'}>
            <Box display={showFilters ? '' : 'none'}>{filtersScreen}</Box>
            <Box display={!showFilters ? '' : 'none'}>
                <Box display={!id ? '' : 'none'}>{listingScreen}</Box>
                <Box display={id ? '' : 'none'}>{itemDetailsScreen}</Box>
            </Box>
        </Box>
    )
}

export default withRouter(MobileVersion)
