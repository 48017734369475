import React, { useState } from 'react'
import {
    FunctionsList,
    ItemDetails,
    PeopleList,
    StyledSearchInput,
} from 'components'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { Box, styled, Typography } from '@mui/material'
import { Pagination } from 'packages/eid-ui'
import {
    useApplicationOwners,
    useAzureRoleBr,
    useAzureRoleGlobalSensitiveFunctions,
    useLocalFunctionsByAzureRole,
    useTargetPerson,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../../CommonAttributes'
import { RenewRevoke } from 'components/RenewRevoke'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { Skeleton } from '@mui/material'
import { useHistory } from 'react-router'
import useSubcomponents from 'useSubcomponents'
import LocalSensitiveFunctions from 'components/Local Sensitive Functions/LocalSensitiveFunctions'
import ActivateNowButton from 'containers/ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const take = 10

const GlobalSensitiveFunctions = ({ azureRbacRole, locationId }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }

    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const { latestData } = useAzureRoleGlobalSensitiveFunctions(
        azureRbacRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        locationId,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const globalSensitiveFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            <PaddedDiv
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 31px',
                }}
            >
                <Box width="50%">
                    <Typography
                        style={{
                            fontSize: '12px',
                            lineHeight: 1.33,
                            color: '#9b9b9b',
                            wordBreak: 'break-word',
                            textTransform: 'uppercase',
                            minWidth: '80px',
                        }}
                    >
                        {t('Common_GlobalFunctionalAccess')}
                    </Typography>
                </Box>

                <Box width={!isSmallScreen ? '308px' : '50%'} minWidth="130px">
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </PaddedDiv>

            {globalFunctionsLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {globalSensitiveFunctions && (
                        <>
                            <Box overflow="auto">
                                <FunctionsList
                                    data={globalSensitiveFunctions}
                                    loading={globalFunctionsLoading}
                                />

                                <Box
                                    padding={
                                        !isSmallScreen ? '20px 32px' : '16px'
                                    }
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={
                                        !isSmallScreen ? 'flex-start' : 'center'
                                    }
                                >
                                    <Pagination
                                        count={numberOfPages}
                                        size={
                                            !isSmallScreen ? 'large' : 'small'
                                        }
                                        page={page}
                                        onChange={handlePageChange}
                                        totalCount={latestData?.totalCount}
                                        onPageSelection={handlePageSelection}
                                        itemsPerPage={perPageItems}
                                        onItemsPerPageChange={
                                            handleItemsPerPageChange
                                        }
                                        showPageSelection={true}
                                        showItemsPerPageSelection
                                        showCountStat
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const azureRbacRole = item.resource

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useAzureRoleBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
        item.assignment.resourceAssignment.locationId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer bottomPadding="0">
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('AzureRbacRoles_Scope')}
                    value={item.resource.fullPath}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_SystemType')}
                    value={azureRbacRole.resourceSystemTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_RoleType')}
                    value={azureRbacRole.roleTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                {azureRbacRole.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('Common_Tenant')}
                        value={azureRbacRole.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureRbacRole.description}
                    orientation={attributeOrientation}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const { t } = useTranslation()

    const { hasAccessToLocalSensitiveFunctionsGrid } = useSubcomponents()

    const rbacRole = item.resource

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: ownersAndApprovers, isLoading: ownersAndApproversLoading } =
        useApplicationOwners(rbacRole.id)

    return (
        <>
            {ownersAndApproversLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Owners')}
                            value={
                                <PeopleList
                                    list={ownersAndApprovers.owners ?? []}
                                />
                            }
                            orientation={attributeOrientation}
                        />
                        <ItemDetails.Attribute
                            label={t('Approvers')}
                            value={
                                <PeopleList
                                    list={ownersAndApprovers.approvers ?? []}
                                />
                            }
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <LocalSensitiveFunctions
                            rbacRole={rbacRole}
                            protectSubcomponent={
                                hasAccessToLocalSensitiveFunctionsGrid
                            }
                        />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
const AzureRbacRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('AzureRoles')

    const history = useHistory()
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    const azureRbacRoleType = registry.get(
        ResourceTypesNamespace,
        'AzureRbacRoles',
    )

    const [activeTab, setActiveTab] = useState('overview')

    const [targetPerson] = useTargetPerson()

    const location = {
        id: item.assignment.resourceAssignment?.locationId,
        friendlyName: item.assignment.resourceAssignment?.locationFriendlyName,
    }

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.azRbacRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            azureRbacRole: item.resource,
            assignmentStatus: item.assignment,
            location,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            {isManageAccess && (
                <>
                    {item?.assignment.canActivateNow ? (
                        <ActivateNowButton
                            resource={item.resource}
                            resourceType={currentResourceType}
                            forDetailsDrawer={true}
                            width="300px"
                            assignment={item?.assignment}
                        />
                    ) : (
                        <RenewRevoke
                            resourceType={azureRbacRoleType}
                            resource={item.resource}
                            scope={location}
                            assignment={item.assignment}
                            postRenew={toggleDrawer}
                            preRevoke={preRevoke}
                            postRevoke={toggleDrawer}
                            item={item}
                        />
                    )}
                </>
            )}

            <GlobalSensitiveFunctions
                azureRbacRole={item.resource}
                locationId={item.assignment.resourceAssignment?.locationId}
            />
        </>
    )
}

export default AzureRbacRoleDetails
