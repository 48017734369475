import { useState } from 'react'
import { Header } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useHelpText } from 'hooks'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    container: {
        minWidth: '320px',
        padding: '8px',
    },
})

const HelpMenu = ({ showCone }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [callEndPoint, setCallEndPoint] = useState(false)

    const { data: rawHtmlText, isLoading } = useHelpText(callEndPoint)

    const parsedHtml = ReactHtmlParser(DomPurify.sanitize(rawHtmlText))

    return (
        <Header.HelpMenu
            onOpen={() => setCallEndPoint(true)}
            onClose={() => setCallEndPoint(false)}
            displayIcon={
                <Icon name="Help" width="24px" height="24px" color="#959598" />
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            iconPosition={{
                right: '50%',
                '@media (max-width:1023px)': {
                    display: 'none',
                },
            }}
            showCone={showCone}
        >
            <Box className={classes.container}>
                {isLoading ? (
                    <Skeleton height={20} />
                ) : parsedHtml.length > 0 ? (
                    <Box>{parsedHtml}</Box>
                ) : (
                    <Box>{t('Common_NoDataFound')}</Box>
                )}
            </Box>
        </Header.HelpMenu>
    )
}

export default HelpMenu
