import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { CommonAttributes } from '../CommonAttributes'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ sharedFolder }: any) => {
    const { t } = useTranslation()
    const parsedHtml = ReactHtmlParser(
        DomPurify.sanitize(sharedFolder?.instructions),
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('SharedFolders_DisplayName')}
                    value={sharedFolder.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_ShareName')}
                    value={sharedFolder.shareName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_Computer')}
                    value={sharedFolder.computerFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('SharedFolders_DnsHostName')}
                    value={sharedFolder.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('SharedFolders_UncPath')}
                    value={sharedFolder.uncPath}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('SharedFolders_LocalPath')}
                    value={sharedFolder.localPath}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={sharedFolder.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <CommonAttributes resource={sharedFolder} />
                {parsedHtml && parsedHtml.length > 0 && (
                    <InfoBoxWithIcon
                        iconName={'InfoNew'}
                        message={parsedHtml}
                        rootStyles={{
                            width: '100%',
                            marginTop: '2.8rem',
                            marginBottom: '1.8rem',
                        }}
                    />
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
