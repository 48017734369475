import { useState } from 'react'
import { Box, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { useAccessRequestPolicy, useComputerOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import ComputerSessionRequest from 'components/ComputerSessionRequest'
import ComputerSessionHistory from 'components/ComputerSessionHistory'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import { useAppState } from 'appContext'
import { PasswordModal } from 'components/PasswordModal'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import { Divider } from 'packages/eid-ui'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import useSubcomponents from 'useSubcomponents'
const hasAccessToOwnerControl =
    'ITShop-Computers-ManageAcces-LoginSession-Details-Owners-Control'
const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const useStyles = makeStyles((theme) => ({
    tabconnectbtn: {
        paddingLeft: '30px',
        paddingTop: '50px',
        '& button': {
            minWidth: '200px',
            height: '46px',
            '& span span div': {
                width: '45px',
                display: 'flex',
                justifyContent: 'center',
            },
            '& div p': {
                fontSize: '16px !important',
                marginRight: '10px',
            },
        },
    },
    masteroverlaycostom: {
        '&  div > div': {
            minHeight: '100px',
        },
    },
    taskbadge: {
        position: 'relative',
        top: '12px',
        right: '16px',
        background: theme?.palette?.quaternary?.main,
        height: '15px',
        minWidth: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        color: theme?.palette?.common?.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
}))
const OverviewSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()
    const requestPolicy = useAccessRequestPolicy(computer?.requestPolicyId)

    const attributeOrientation = 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computer.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computer.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Infrastructure')}
                    value={computer.infrastructureName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Computers_Environment')}
                    value={computer.environmentTypeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={computer.computerTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={
                        computer?.accountStoreFriendlyName
                            ? computer.accountStoreFriendlyName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computer.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={
                        computer.isPsmEnabled ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={
                        computer.isJITEnabled === null ? (
                            '-'
                        ) : computer.isJITEnabled === true ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_StartTime')}
                    value={
                        computer.startTimeUtc
                            ? moment
                                  .utc(computer.startTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={
                        computer.endTimeUtc
                            ? moment
                                  .utc(computer.endTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={
                        computer.description ? computer.operatingSystem : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AllowSSH')}
                    value={
                        computer.allowsSsh ? t('Common_Yes') : t('Common_No')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_ResourceSystem')}
                    value={
                        computer?.resourceSystemFriendlyName
                            ? computer.resourceSystemFriendlyName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_RDPSession')}
                    value={
                        computer.allowsRdp ? t('Common_Yes') : t('Common_No')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_SshPort')}
                    value={computer.sshPort ? computer.sshPort : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {requestPolicy && (
                    <>
                        <Divider />
                        <AccessRequestPolicyDetails
                            policy={requestPolicy}
                            orientation={attributeOrientation}
                        />
                    </>
                )}
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? computer.id : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}
const LoginSessionAccessDetails = ({ item }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const query = useQuery()
    const [{ masterPassword }] = useAppState()
    const [open, setOpen] = useState('')
    const showSessionDetails = query.get('showSession')
    const [activeTab, setActiveTab] = useState(
        showSessionDetails ? 'sessionRequest' : 'overview',
    )

    const handleMasterUnlock = () => {
        setOpen('enterPassword')
    }

    return (
        <>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}
            <ItemDetails.Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                <ItemDetails.Tabs.Tab
                    value="sessionRequest"
                    label={t('Common_SessionRequest')}
                />
                <Box className={classes.taskbadge}>
                    {item?.resource?.checkOutCount}
                </Box>
                <ItemDetails.Tabs.Tab
                    value="sessionHistory"
                    label={t('Common_SessionHistory')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {activeTab === 'sessionRequest' && (
                <ComputerSessionRequest item={item.resource} />
            )}
            {activeTab === 'sessionHistory' && (
                <ComputerSessionHistory item={item.resource} />
            )}
            <Box className={classes.masteroverlaycostom}>
                <MasterLockOverlay
                    btnLabel={t('Common_Unlock')}
                    showMasterLock={!masterPassword}
                    handleMasterUnlock={() => handleMasterUnlock()}
                >
                    <Box className={classes.tabconnectbtn}>
                        <ComputerConnectBtn
                            showPrefixIcon={true}
                            data={item}
                            drawerView={true}
                            showCalenderIcon={false}
                            parentStyle={{ marginBottom: '100px' }}
                        />
                    </Box>
                </MasterLockOverlay>
            </Box>
            {/* {isRevokable && (
                <Box
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <RevokeButton
                        resourceType={computerType.name}
                        item={item}
                        buttonStyles={{ padding: '32px 31px 20px 31px' }}
                        postRevoke={toggleDrawer}
                    />
                    {isRenewable && (
                        <RenewButton
                            resourceType={computerType.name}
                            item={item}
                            buttonStyles={{ padding: '32px 31px 20px 31px' }}
                        />
                    )}
                </Box>
            )} */}
        </>
    )
}

export default LoginSessionAccessDetails
