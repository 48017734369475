import { Box, styled, Grid } from '@mui/material'
import { ItemDetails } from 'components'
import {
    useCredentialCheckouts,
    useCurrentPerson,
    useTargetPerson,
} from 'hooks'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckoutFilters from '../ComputerSessionRequest/Filters'
import CheckoutTile from './CheckoutTile'
import Pagination from '../ComputerSessionRequest/Pagination'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export interface CredentialCheckoutProps {
    item: any
}
const defaultTake = 12

export const CredentialCheckouts: FC<CredentialCheckoutProps> = (props) => {
    const { item } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const { data: currentPerson } = useCurrentPerson()

    const [isCheckedOut, setIsCheckedout] = useState<boolean>(true)
    const [selectedPerson, setSelectedPerson] = useState<any>(currentPerson)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(defaultTake) //take
    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    const handlePageSelection = (value: any) => {
        setPage(value)
    }

    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPageItems(value)
    }
    const skip = (page - 1) * perPageItems
    const [
        getCredentialCheckouts,
        { data: checkoutRequets, isLoading },
    ] = useCredentialCheckouts()

    const numberOfPages = checkoutRequets
        ? Math.ceil(checkoutRequets.totalCount / perPageItems)
        : 0

    useEffect(() => {
        const dataObject: any = {
            requestedByPersonId: selectedPerson
                ? selectedPerson.id
                : targetPerson.id,
            credentialId: item.id,
            startDateAfter: startDate,
            endDateBefore: endDate,
            checkedOut: isCheckedOut,
            skip: skip,
            take: perPageItems,
        }
        getCredentialCheckouts(dataObject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckedOut, startDate, endDate, page, selectedPerson, perPageItems])

    const handleFilterChange = (change: any) => {
        switch (change.prop) {
            case 'startDate':
                setStartDate(change.value)
                break
            case 'endDate':
                setEndDate(change.value)
                break
            case 'isCheckedOut':
                setIsCheckedout(change.value)
                break
            case 'selectedPerson':
                setSelectedPerson(change.value)
                break
        }
        setPage(1)
    }

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <CheckoutFilters
                    setEndDate={(e: string) =>
                        handleFilterChange({ prop: 'endDate', value: e })
                    }
                    setStartDate={(e: string) =>
                        handleFilterChange({ prop: 'startDate', value: e })
                    }
                    endDate={endDate}
                    startDate={startDate}
                    isCheckedOut={isCheckedOut}
                    checkboxLabel={t('Common_Yes')}
                    dateContainerLabel={t('CheckoutDatesBetween')}
                    handleCheckedClick={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) =>
                        handleFilterChange({
                            prop: 'isCheckedOut',
                            value: e.target.checked,
                        })
                    }
                    onPersonSelect={(e: any) =>
                        handleFilterChange({ prop: 'selectedPerson', value: e })
                    }
                    selectedPerson={selectedPerson}
                />
            </PaddedDiv>
            {isLoading ? (
                <PaddedDiv>
                    <ItemDetails.AttributesLoader />
                </PaddedDiv>
            ) : checkoutRequets?.data?.length &&
              checkoutRequets?.data?.length > 0 ? (
                <Fragment>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: '20px 20px 0px 20px',
                            width: '100%',
                        }}
                    >
                        {checkoutRequets.data.map(
                            (checkoutRequest: any, index: number) => (
                                <CheckoutTile
                                    parent={item}
                                    key={`credential-checkout-${index}`}
                                    item={checkoutRequest}
                                />
                            ),
                        )}
                    </Grid>
                    <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        handlePageSelection={handlePageSelection}
                        pageCount={numberOfPages}
                        totalCount={checkoutRequets?.totalCount}
                        perPageItems={perPageItems}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                    />
                </Fragment>
            ) : (
                <PaddedDiv>
                    <p>
                        {t('Common_NoItemsFound', {
                            itemType: t('Checkouts'),
                        })}
                    </p>
                </PaddedDiv>
            )}
        </ItemDetails.AttributesContainer>
    )
}

export default CredentialCheckouts
