import { FC, useState } from 'react'
import { Box, Collapse } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { useApplicationOwners } from 'hooks'
import { Skeleton } from '@mui/material';
import { PersonDetails } from './PersonDetails'
import classNames from 'classnames'

export type OwnersProps = {
    className?: any
    applicationId: string
    buttonPosition?: 'right' | 'bottom'
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: { position: 'relative' },

        buttonPositionRightTop: {},

        showMoreButton: {
            paddingTop: theme.spacing(1.5),
            '& > button': {
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
                color: theme?.palette?.primary?.main,
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },

        content: {
            display: 'flex',
        },

        collapsibleContent: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '150px',
            overflow: 'auto',
        },
    }),
)

export const Owners: FC<OwnersProps> = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const { data, isLoading } = useApplicationOwners(props.applicationId)

    if (isLoading) {
        return (
            <Box paddingRight={4}>
                <Skeleton height={40} />
            </Box>
        )
    } else {
        if (data.length === 0) {
            return <Box paddingLeft={'4px'}>-</Box>
        }

        const owner = data.length > 0 && data[0]

        const showMoreCount = data.length - 1
        return (
            <Box className={classNames(classes.root, props.className)}>
                <PersonDetails size="medium" person={owner} />

                <Collapse in={checked} collapsedSize={0}>
                    <Box className={classes.collapsibleContent}>
                        {data &&
                            data
                                .slice(1, data.length)
                                .map((o: any) => (
                                    <PersonDetails
                                        key={o.id}
                                        size="medium"
                                        person={o}
                                    />
                                ))}
                    </Box>
                </Collapse>

                {data && data.length > 1 && (
                    <Box
                        className={classNames(classes.showMoreButton, {
                            [classes.buttonPositionRightTop]:
                                props.buttonPosition === 'right',
                        })}
                    >
                        <button onClick={handleChange}>
                            {checked
                                ? `- ${t('Common_ShowLess')}`
                                : `+ ${showMoreCount} ${t('Common_More')}`}
                        </button>
                    </Box>
                )}
            </Box>
        );
    }
}
