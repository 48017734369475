import { Fragment, useEffect } from 'react'
import { styled } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Paper } from '@mui/material'
import {
    Attribute,
    AttributeLabel,
    AttributeValue,
    Divider,
} from 'packages/eid-ui'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty } from 'packages/core'
import DefaultGenericIcon from '../../../assets/images/Genric-1.svg'
import config from 'config'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'
import InfoBoxWithIcon from 'components/common/InfoBoxWithIcon'
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            miHeight: '156px',
            boxShadow: 'none !important',
        },
        Attribute: {
            flexDirection: 'row',
        },
        imgContainer: {
            margin: '0.8rem 1rem 0',
            boxShadow: 'none !important',

            '& img': {
                maxHeight: '90px',
                maxWidth: '90px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                '& img': {
                    width: '66px',
                },
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '0 3rem',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        requestInfo: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #d8dadd',
            width: '100%',
            paddingTop: '30px',
            marginTop: '30px',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            minWidth: '230px',
            maxWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: theme?.palette?.primary?.main,
                    color: theme?.palette?.common?.white,
                    '& span': {
                        color: theme?.palette?.common?.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme?.palette?.grey?.[900],
                color: theme?.palette?.common?.white,

                border: `solid 1px ${theme?.palette?.grey?.[900]}`,

                '&:hover': {
                    backgroundColor: theme?.palette?.grey?.[900],

                    '& span': {
                        //  color: theme?.palette?.common?.white,
                    },
                },
            },
        },
        actionDropdown: {
            marginTop: theme.spacing(3),
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },

            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme?.palette?.common?.white,
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: theme?.palette?.primary?.main,
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: theme?.palette?.primary?.main,
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: theme?.palette?.common?.black,
            fontSize: '14px',
            textDecoration: 'underline',
            margin: '1.6rem 0',
            padding: '0',
            minWidth: '250px',
        },
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
    }),
)

export const ApplicationOverView = (props: any) => {
    const classes = useStyles()
    const { data, policy } = props
    const [, dispatch]: any = useResourceTypeContext()
    const parsedHtml = ReactHtmlParser(DomPurify.sanitize(data?.instructions))
    useEffect(() => {
        if (data)
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'application',
                    value: data,
                },
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const { t } = useTranslation()

    const showAzureApplicationDetails =
        data &&
        data.isAzureApplication &&
        (data.azureApplicationFriendlyName ||
            data.azureApplicationDescription ||
            data.azureApplicationId ||
            data.servicePrincipleId ||
            data.applicationId)

    const azureApplicationDetails = data && (
        <Box>
            <AttributeLabel className={classes.azureApplicationDetailsLabel}>
                {t('Common_AzureApplicationDetails')}
            </AttributeLabel>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {data.azureApplicationFriendlyName && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_FriendlyName')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationDescription && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_Description')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationDescription}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureIntegrationType && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_Type_Of_Integration')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureIntegrationType}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationLine && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_Application_Line')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationLine}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureUpxId && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_Upx_ID')}
                            </AttributeLabel>
                            <AttributeValue>{data.azureUpxId}</AttributeValue>
                        </Attribute>
                    )}

                    {data.azureAuthenticationLevel && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_Authentication_Level')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureAuthenticationLevel}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.applicationId && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_AzureApplicationAppId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.applicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationId && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_AzureApplicationObjectId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.servicePrincipleId && (
                        <Attribute orientation="horizontal">
                            <AttributeLabel>
                                {t('Common_ServicePrincipleId')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.servicePrincipleId}
                            </AttributeValue>
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const applicationDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute orientation="horizontal">
                    <AttributeLabel>{t('Common_FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data && data.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute orientation="horizontal">
                    <AttributeLabel>{t('Common_TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data && data.name}</AttributeValue>
                </Attribute>
                <Attribute orientation="horizontal">
                    <AttributeLabel>{t('Common_Description')}</AttributeLabel>
                    <AttributeValue>{data && data.description}</AttributeValue>
                </Attribute>
            </Box>
            <Box>
                <Paper className={classes.imgContainer}>
                    <img
                        alt={data?.friendlyName}
                        src={
                            isNilOrEmpty(data.applicationLogoImageUrl)
                                ? `${config.APP_SUBPATH}${DefaultGenericIcon}`
                                : getFullImageUrl(data?.applicationLogoImageUrl)
                        }
                    />
                </Paper>
            </Box>
        </Fragment>
    )

    const requestDetails = policy && (
        <>
            <Divider />
            <AccessRequestPolicyDetails
                policy={policy}
                orientation="horizontal"
            />
        </>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>{applicationDetails}</Box>
            {showAzureApplicationDetails && (
                <Box>{azureApplicationDetails}</Box>
            )}
            {requestDetails}
            {parsedHtml && parsedHtml.length > 0 && (
                <InfoBoxWithIcon
                    iconName={'InfoNew'}
                    message={parsedHtml}
                    rootStyles={{
                        width: '100%',
                        marginTop: '2.8rem',
                        marginBottom: '1.8rem',
                    }}
                />
            )}
        </Fragment>
    )

    return (
        <Paper className={classes.root}>
            <Fragment>
                <Box className={classes.content}>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Box>
            </Fragment>
        </Paper>
    )
}
