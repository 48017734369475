import React, { useEffect } from 'react'
import { Loader, MaintenancePage } from 'packages/eid-ui'
import { useApplicationSettings } from 'hooks'
import { useAppState } from 'appContext'
import config from 'config'
import { isAppRunningInIframe } from 'packages/core'

const useLoadApplicationSettings = () => {
    const [{ appConfig }, dispatchAppState] = useAppState()

    let settingsOverride = config?.UI_SETTINGS_OVERRIDE
    if (isAppRunningInIframe()) {
        settingsOverride = 'ServiceNow'
    }
    const { data, isLoading } = useApplicationSettings(settingsOverride)

    useEffect(() => {
        if (data) {
            dispatchAppState({
                type: 'SET_APP_CONFIG',
                payload: data,
            })
        }
    }, [data, dispatchAppState])

    return { isReady: appConfig !== null, isLoading }
}

const withRequiredApplicationSettings = (ChildComponent) => (props) => {
    const { isLoading, isReady } = useLoadApplicationSettings()

    if (isLoading) {
        return <Loader />
    }

    if (!isReady) {
        return (
            <MaintenancePage
                title="Under Maintenance "
                description="Making things better, please stand by."
                linkText="Take me to the home page"
            />
        )
    }

    return <ChildComponent {...props} />
}

export default withRequiredApplicationSettings
