import React from 'react'
import { SelectionBox, ChipsContainer } from 'components'
import { ProcessIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useBusinessDomains } from 'hooks'

function BusinessDomainSelection({ onSelect, onBackClick, onSkipClick }) {
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const { data: businessDomains } = useBusinessDomains(targetPerson.id)

    return (
        <SelectionBox
            icon={<ProcessIcon />}
            headingText={t('OnBoarding_DomainSelection')}
            footerText={t('OnBoarding_FooterTextMessage')}
            onBackClick={onBackClick}
            onSkipClick={onSkipClick}
            style={{
                contentStyles: { minHeight: '50vh' },
                hedaerStyles: { padding: '175px 10px 0px 10px' },
            }}
        >
            {() => (
                <>
                    <ChipsContainer
                        loading={!businessDomains}
                        data={
                            businessDomains
                                ? businessDomains.filter(
                                      (d) =>
                                          d.parentId === null ||
                                          d.parentId === undefined,
                                  )
                                : []
                        }
                        onSelect={onSelect}
                    />
                </>
            )}
        </SelectionBox>
    )
}

export default BusinessDomainSelection
