import { Icon } from 'packages/eid-icons'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme?.palette?.warning?.main,
        '&:hover': {
            '& g': { fill: `${theme?.palette?.warning?.main} !important` },
            color: theme?.palette?.common?.white,
        },
    },
}))

const ConnectSwitch = ({ attribute, data }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Tooltip title={t('Common_Requestable')}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Icon name="ConnectSwitch" className={classes.root} />
            </Box>
        </Tooltip>
    )
}

export default ConnectSwitch
