import { Box, Dialog, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'
import { useUrlGenerator } from 'packages/core'
import classNames from 'classnames'
import { useAllApplications } from 'hooks'
import ComputersCheckInButton from 'components/DynamicComponents/ComputersCheckInButton'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'

const mobileScreenWidth = '767px'

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '64px 16px',
            width: 'calc(100% - 32px) !important',
            maxWidth: 'calc(100% - 32px) !important ',
        },
    },
}))

const useStyles = makeStyles((theme) => ({
    body: () => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',

        '&:focus': {
            outline: 'none',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: theme?.palette?.common?.white,
    },
    closeIcon: {
        display: 'flex',
    },
    radioBtn: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        color: `${theme?.palette?.common?.black} !important`,
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    bodyinner: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '16px',
        [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
            display: 'block',
        },
    },
    bodyleftinner: {
        maxWidth: '232px',
        width: '100%',
    },
    bodyleftinnertitle: {
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: '#9b9b9b',
        margin: 0,
    },
    bodyrightinnerresult: {
        fontSize: '14px',
        fontWeight: 500,
        color: theme?.palette?.common?.black,
        margin: 0,
    },
    bodyrightresult: {
        fontSize: '14px',
        fontWeight: 500,
        margin: 0,
    },
    requestitemheadingLink: {
        '& a': {
            color: `${theme?.palette?.primary?.main} !important`,
            textDecoration: 'none !important',
        },
    },
    tabconnectbtn: {
        '& > div div': {
            height: 'auto !important',
        },
        '& button': {
            width: '200px',
            height: '46px',
            '& span span div': {
                width: '45px',
                display: 'flex',
                justifyContent: 'center',
                height: '100% !important',
            },
            '& div p': {
                fontSize: '16px !important',
            },
        },
    },
}))

export interface ComputerSessionRequestDetailsProps {
    item: any
    open: boolean
    onClose: () => void
    title: string
    canCheckIn: boolean
    handleCheckIn: () => void
    handleConnect: () => void
    handleMasterUnlock: () => void
    masterPassword: any
    parent: any
    showStatus: boolean
}

export const ComputerSessionRequestDetails: FC<ComputerSessionRequestDetailsProps> = (
    props,
) => {
    const {
        open,
        onClose,
        title,
        item,
        canCheckIn,
        handleMasterUnlock,
        masterPassword,
        showStatus,
    } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const dialogClasses = useDialogStyles()
    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)
    const getTimeValues = (startTimeUtc: any, endTimeUtc: any) => {
        if (startTimeUtc && endTimeUtc) {
            const timeDifference = moment(endTimeUtc).diff(
                moment(startTimeUtc),
                'minutes',
            )
            const h = Math.floor(timeDifference / 60)
            const m = Math.floor(timeDifference % 60)
            const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : ''
            const mDisplay =
                m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : ''
            return hDisplay + mDisplay
        }
        return '---'
    }
    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>
                    {title ? title : '---'}
                </Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose} size="large">
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.sectionHeader}>
                {t('Common_SessionRequestDetails')}
            </Box>
            <Box className={classes.body}>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_Credentials')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.credentialFriendlyName
                                ? item.credentialFriendlyName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('CheckedOutBy')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.requestorFriendlyName
                                ? item.requestorFriendlyName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_WhoApproved')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.approverFriendlyName
                                ? item.approverFriendlyName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_StartTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.startTimeUtc
                                ? moment
                                      .utc(item.startTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_EndTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.endTimeUtc
                                ? moment
                                      .utc(item.endTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_BusinessRequest')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classNames(classes.bodyrightresult, {
                                [classes.requestitemheadingLink]:
                                    item?.businessRequestId &&
                                    item.businessRequestName,
                            })}
                        >
                            {item?.businessRequestId &&
                            item.businessRequestName ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={getMyTasksUrl(
                                        `/myRequests/businessRequests?id=${item.businessRequestId}`,
                                    )}
                                >
                                    {item.businessRequestName
                                        ? item.businessRequestName
                                        : '---'}
                                </a>
                            ) : item.businessRequestName ? (
                                item.businessRequestName
                            ) : (
                                '---'
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_BusinessRequestItem')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classNames(classes.bodyrightresult, {
                                [classes.requestitemheadingLink]:
                                    item?.businessRequestId &&
                                    item.businessRequestItemName,
                            })}
                        >
                            {item?.businessRequestItemId &&
                            item.businessRequestItemName ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={getMyTasksUrl(
                                        `/myRequests/businessRequestItems?id=${item.businessRequestItemId}`,
                                    )}
                                >
                                    {item.businessRequestItemName
                                        ? item.businessRequestItemName
                                        : '---'}
                                </a>
                            ) : item.businessRequestItemName ? (
                                item.businessRequestItemName
                            ) : (
                                '---'
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ActualCheckOutTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.userCheckedOutTimeUtc
                                ? moment
                                      .utc(item.userCheckedOutTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_ActualCheckInTime')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.userCheckedInTimeUtc
                                ? moment
                                      .utc(item.userCheckedInTimeUtc)
                                      .local()
                                      .format('L LT')
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_UserAccount')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.credentialFriendlyName
                                ? item.credentialFriendlyName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_TimeRequested')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {getTimeValues(item.startTimeUtc, item.endTimeUtc)}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_TimeUsed')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {getTimeValues(
                                item.userCheckedOutTimeUtc,
                                item.userCheckedInTimeUtc,
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_CheckoutBy')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.requestorFriendlyName
                                ? item.requestorFriendlyName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bodyinner}>
                    <Box className={classes.bodyleftinner}>
                        <Box
                            component="h2"
                            className={classes.bodyleftinnertitle}
                        >
                            {t('Common_CheckinBy')}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            component="h2"
                            className={classes.bodyrightinnerresult}
                        >
                            {item.checkedInByPersonName
                                ? item.checkedInByPersonName
                                : '---'}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {showStatus && (
                <Box>
                    <MasterLockOverlay
                        btnLabel={t('Common_Unlock')}
                        showMasterLock={!masterPassword}
                        handleMasterUnlock={() => handleMasterUnlock()}
                    >
                        <Box display="flex" width="100%" padding="24px 32px">
                            <Box minWidth="167px">
                                <Box className={classes.tabconnectbtn}>
                                    <ComputerConnectBtn
                                        parentStyle={{}}
                                        showPrefixIcon={true}
                                        data={item}
                                        drawerView={false}
                                        showCalenderIcon={false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </MasterLockOverlay>
                </Box>
            )}
            {canCheckIn && (
                <Box>
                    <MasterLockOverlay
                        btnLabel={t('Common_Unlock')}
                        showMasterLock={!masterPassword}
                        handleMasterUnlock={() => handleMasterUnlock()}
                    >
                        <Box display="flex" width="100%" padding="24px 32px">
                            <Box minWidth="167px">
                                <Box className={classes.tabconnectbtn}>
                                    <ComputersCheckInButton
                                        data={{ resource: item }}
                                        labelStyleProp={{ fontSize: '14px' }}
                                        buttonStyleProp={{ width: '145px' }}
                                        showIcon={true}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </MasterLockOverlay>
                </Box>
            )}
        </Dialog>
    );
}

export default ComputerSessionRequestDetails
