import React from 'react'
import { Switch as MuiSwitch, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: '105px',
        height: '25px',
        padding: 0,
        margin: theme.spacing(1),
        borderRadius: '13px',
        border: 'solid 1px #d2d2d9',
    },
    switchBase: {
        padding: '1px',
        '&$checked': {
            transform: 'translateX(50px)',
            color: theme?.palette?.common?.white,
            '& + $track': {
                backgroundColor: '#fbfbfd !important',
                opacity: 1,
                border: 'none',
            },
        },
    },

    track: {
        borderRadius: 26 / 2,
        backgroundColor: '#fbfbfd !important',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <MuiSwitch
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            {...props}
        />
    )
})

const Switch = ({ onChange, value, disabled, onLabel, offLabel, theme }) => {
    const icon = (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, right: -35 }}>
                <Typography style={{ color: '#d2d2d9', fofontSize: '14px' }}>
                    {onLabel}
                </Typography>
            </div>

            <div
                style={{
                    width: '50px',
                    height: '21px',
                    borderRadius: '20px',
                    backgroundColor: '#d2d2d9',
                }}
            >
                <Typography
                    style={{
                        color: theme?.palette?.common?.white,
                        fontSize: '14px',
                    }}
                >
                    {offLabel}
                </Typography>
            </div>
        </div>
    )

    const checkedIcon = (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, left: -35 }}>
                <Typography style={{ color: '#d2d2d9', fontSize: '14px' }}>
                    {offLabel}
                </Typography>
            </div>
            <div
                style={{
                    width: '50px',
                    height: '21px',
                    borderRadius: '20px',
                    backgroundColor: '#d2d2d9',
                }}
            >
                <Typography
                    style={{
                        color: theme?.palette?.common?.white,
                        fontSize: '14px',
                    }}
                >
                    {onLabel}
                </Typography>
            </div>
        </div>
    )

    return (
        <CustomSwitch
            disabled={disabled}
            checkedIcon={checkedIcon}
            icon={icon}
            checked={value}
            onChange={onChange}
        ></CustomSwitch>
    )
}

export default Switch
