import { useQuery, useMutation, queryCache } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'packages/eid-ui'
import { mockApiCall, useAxios } from 'packages/core'
import { useAppState } from 'appContext'
import moment from 'moment'

export const CREDENTIAL_KEY_PREFIX = 'CREDENTIALS'

export const useCredential = (id, isAssignmentId = false) => {
    const callApi = useAxios()

    let url = `/api/Credentials/${id}`

    if (isAssignmentId) {
        url = url + `?isAssignmentId=true`
    }

    return useQuery(
        [CREDENTIAL_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCredentialCheckouts = () => {
    const callApi = useAxios()
    return useMutation((data) =>
        callApi({
            method: 'POST',
            url: '/api/Credentials/checkOutCredentialsRecordsbri',
            data: {
                ...data,
                startDateAfter: moment(data.startDateAfter)
                    .startOf('day')
                    .set('millisecond', 0),
                endDateBefore: moment(data.endDateBefore)
                    .endOf('day')
                    .set('millisecond', 0),
            },
        }).then((data) => data),
    )
}

export const useCheckCredentialAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useCheckCredentialAccessByAssignmentId = (
    assignmentId,
    targetPersonId,
) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_ACCESS`, assignmentId, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/checkAssigmentStatusByAssignment/${assignmentId}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(assignmentId) && Boolean(targetPersonId),
        },
    )
}

export const useCheckAccessToCheckInOperation = (id, enabled) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_ACCESS`, id, enabled],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Credentials/HasAccessToCheckInOperation/${id}`,
            }).then((data) => data.data),
        {
            enabled: enabled,
        },
    )
}

export const useCredentialBusinessRequest = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            CREDENTIAL_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/credentials/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}

export const useCredentialOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${CREDENTIAL_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCredentialsHiddenProps = (id, masterPassword) => {
    const callApi = useAxios()
    return useQuery(
        [CREDENTIAL_KEY_PREFIX, 'HIDDEN_PROPS', id],
        () =>
            callApi({
                method: 'POST',
                url: `/api/common/hiddenProperties/${id}`,
                data: `"${masterPassword}"`,
            }).then((data) => data.data),
        {
            enabled: Boolean(masterPassword),
        },
    )
}

export const useCheckInCredential = (credentialId, isEnabled = true) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (checkOutId) =>
            callApi({
                method: 'POST',
                url: `/api/credentials/checkin/${checkOutId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedIn'))
                refreshCredentialsList()
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedIn'))
            },
            enabled: Boolean(credentialId) && isEnabled,
        },
    )
}

export const refreshCredentialsList = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('/api/credentials/allAssignedResources'),
    )
}

export const useCheckOutCredential = (id) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/credentials/checkout',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedOut'))
                refreshCredentialsList()
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedOut'))
            },
        },
    )
}

export const useCredentialHiddenAttributes = (url, enabled) => {
    const callApi = useAxios()
    const [{ masterPassword }] = useAppState()

    return useQuery(
        [url, enabled, 'CREDENTIAL_HIDDEN_PROPS'],
        () =>
            callApi({
                method: 'POST',
                url: url,
                data: `"${masterPassword}"`,
            }).then((data) => data.data),
        {
            enabled: enabled,
        },
    )
}

export const useCheckMasterPasswordExist = () => {
    const callApi = useAxios()

    return useQuery([CREDENTIAL_KEY_PREFIX], () =>
        callApi({
            method: 'GET',
            url: `/api/credentials/CheckMasterPasswordExist`,
        }).then((data) => data.data),
    )
}

export const useSetMasterPassword = () => {
    const { t } = useTranslation()
    const callApi = useAxios()
    const { showWarningMessage } = useNotification()

    return useMutation(
        (masterPassword) =>
            callApi({
                method: 'POST',
                url: 'api/Credentials/SetMasterPassword',
                data: `"${masterPassword}"`,
            }),
        {
            onError: () => {
                showWarningMessage(t('Common_FailedToSetMasterPassword'))
            },
        },
    )
}

export const usePasswordPolicy = (
    isChangingPassword = false,
    isResettingPassword = true,
) => {
    const callApi = useAxios()
    return useQuery([CREDENTIAL_KEY_PREFIX, 'PASSWORD_POLICY'], () =>
        callApi({
            method: 'POST',
            url: `/api/credentials/masterPasswordPolicy`,
            data: {
                isChangePassword: isChangingPassword,
                isPasswordReset: isResettingPassword,
            },
        }).then((data) => data.data),
    )
}

export const useCheckCredentialAvailableSlot = (credentialId) => {
    const { t } = useTranslation()
    const callApi = useAxios()
    const { showWarningMessage } = useNotification()

    return useMutation(
        ([startDate, endDate]) =>
            callApi({
                method: 'GET',
                url: `api/Credentials/hasAvailableSlot?credentialId=${credentialId}&startDate=${startDate}&endDate=${endDate}`,
            }),
        {
            onError: () => {
                showWarningMessage(t('CheckAvailabilityError'))
            },
        },
    )
}
