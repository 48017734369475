import React from 'react'
import { styled, useTheme } from '@mui/material'
import Button from 'components/Button'
import MyselfSrc from './myself.png'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson } from 'hooks'
import config from 'config'

const ShoppingForMyselfDiv = styled('div')({
    position: 'relative',
    width: '271px',
    '& > img': {
        width: '251px',
    },
    '& > button': {
        position: 'absolute',
        top: '234px',
        left: '88px',
    },
})

const ShoppingForMyself = ({ onSelect }) => {
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const theme = useTheme()

    return (
        <ShoppingForMyselfDiv>
            <img
                src={`${config.APP_SUBPATH}${MyselfSrc}`}
                alt={t('OnBoarding_ShoppingForMySelf')}
            />
            <Button
                color={theme?.palette?.tertiary?.main}
                onClick={() => onSelect(currentPerson)}
            >
                {t('OnBoarding_ShoppingForMySelf')}
            </Button>
        </ShoppingForMyselfDiv>
    )
}

export default ShoppingForMyself
