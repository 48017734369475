import { Box } from '@mui/material'
import './Pickers.css'

const NativeTimePicker = ({
    handleChange,
    children,
    value,
    disabled = false,
    pickerStyles = {},
}) => {
    return (
        <Box flex="1" className="textfield">
            {children}
            <input
                className="picker"
                type="time"
                value={value}
                onChange={(event) => {
                    handleChange(event.target.value)
                }}
                disabled={disabled}
                style={pickerStyles}
            ></input>
        </Box>
    )
}

export default NativeTimePicker
