import React from 'react'
import { Skeleton } from '@mui/material';
import { Box, styled } from '@mui/material'

const AttributeSkeleton = styled(Skeleton)({
    width: '45%',
    margin: '8px',
    height: 6,
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const AttributesLoader = () => {
    return (
        <Box width="70%" height="100%">
            <SkeletonContainer>
                <AttributeSkeleton animation="wave" variant="rect" />

                <AttributeSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>

            <SkeletonContainer>
                <AttributeSkeleton animation="wave" variant="rect" />

                <AttributeSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>

            <SkeletonContainer>
                <AttributeSkeleton animation="wave" variant="rect" />

                <AttributeSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>
        </Box>
    )
}

export default AttributesLoader
