import { Fragment, useEffect, useRef, useState } from 'react'
import { Box, styled, useTheme } from '@mui/material'
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from 'components/DropdownMenu'
import { useHistory } from 'react-router'
import appConfig from 'config'
import { SubPathLevel } from 'appConfiguration'
import ShoppingCartMenuIcon from 'components/Layout/ITShopHeader/ShoppingCartMenuIcon'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAppState } from 'appContext'
import EidButton from 'packages/eid-ui/EidButton'
import useSubcomponents from 'useSubcomponents'
import { useIsSmallScreen } from 'packages/core'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
        marginTop: '8px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme?.palette?.primary?.main,
        height: '4px',
        padding: '0 6px',
        boxSizing: 'content-box',
        marginLeft: '-6px',
        marginBottom: '0px',
        zIndex: 1,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
        letterSpacing: '0.022em',
    },
    '& .MuiTab-root': {
        fontSize: '14px',
        letterSpacing: '0.3px',
        margin: '-2px 0px 0 8px',
        transition: 'color 0.15s ease-in-out',

        '&:hover': {
            color: theme?.palette?.primary?.main,
        },
    },
}))

const useStyles = makeStyles(
    createStyles({
        navBarContainer: {
            height: '80px',
            marginBottom: '-4px',
            position: 'relative',
            whiteSpace: 'nowrap',

            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                top: '-4px',
                width: '100%',
                height: 'calc(100% - 2px)',
                borderBottom: '1px solid #d8d8dd',
            },

            '&::after': {
                content: '""',
                display: 'block',
                width: '200%',
                // transform: 'translateX(-50%)',
                height: '16px',
                backgroundImage:
                    'linear-gradient(to top, rgba(238, 240, 244, 0), rgba(238, 240, 244, 0.9))',
                position: 'absolute',
                left: '-50%',
                bottom: '-10px',
            },
        },
        cartMenu: {
            display: 'flex',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
        mobileCartMenu: {
            display: 'flex',
            position: 'absolute',
            top: '25px',
            right: '8px',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
    }),
)

const SubHeader = (props) => {
    const {
        showNavTabs = true,
        viewSwitcher,
        totalCount,
        resourceTypes,
        currentResourceType,
        viewPendingAccess,
        showWorkFlows = false,
        showBackButton = false,
    } = props

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()
    const theme = useTheme()
    const ref = useRef()
    const { hasAccessToTabs } = useSubcomponents()

    const {
        hasAccessToRequestAccess,
        hasAccessToManageAccess,
        hasAccessToWorkFlow,
    } = hasAccessToTabs(currentResourceType.name)

    const [{ appConfig: appSettings }] = useAppState()
    const history = useHistory()
    const {
        location: { pathname },
    } = history

    let subPaths = pathname.split('/')
    const areaName =
        subPaths.length >= 2 + SubPathLevel ? subPaths[2 + SubPathLevel] : ''

    const [activeTab, setActiveTab] = useState(
        areaName === 'manageAccess'
            ? 'manageAccess'
            : areaName === 'workflows'
            ? 'workFlows'
            : 'requestAccess',
    )
    const [_, dispatchAppState] = useAppState()

    useEffect(() => {
        dispatchAppState({
            type: 'SET_SUB-HEADER_HEIGHT',
            payload: ref?.current?.offsetHeight,
        })
    }, [])
    return (
        <Box
            ref={ref}
            height={isSmallScreen ? '128px' : '80px'}
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
            width="100%"
            className={isSmallScreen ? '' : [classes.navBarContainer]}
        >
            <Box
                display="flex"
                paddingLeft={isSmallScreen ? '8px' : '0px'}
                justifyContent="space-between"
            >
                <DropdownMenu
                    routeOptions={resourceTypes}
                    currentResourceType={currentResourceType}
                    totalCount={totalCount}
                />
                {isSmallScreen && appSettings.style.header.hide && (
                    <Box className={classes.mobileCartMenu}>
                        <ShoppingCartMenuIcon />
                    </Box>
                )}
            </Box>

            {showNavTabs && (
                <Box
                    style={{
                        width: isSmallScreen ? '100%' : 'auto',
                        backgroundColor: isSmallScreen
                            ? theme?.palette?.background?.paper
                            : 'transparent',
                    }}
                >
                    <SubHeaderTabs
                        variant="standard"
                        value={activeTab}
                        onChange={(_, value) => {
                            let route
                            if (value === 'manageAccess') {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/manageAccess`
                            } else if (value === 'workFlows') {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/workflows`
                            } else {
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}`
                            }
                            history.push(route)
                            setActiveTab(value)
                        }}
                    >
                        {hasAccessToRequestAccess && (
                            <HorizontalTabs.Tab
                                key={1}
                                value="requestAccess"
                                label={t('Common_RequestAccess')}
                                data-protectedsubcomponent={
                                    hasAccessToRequestAccess
                                }
                            />
                        )}
                        {hasAccessToManageAccess && (
                            <HorizontalTabs.Tab
                                key={2}
                                value="manageAccess"
                                label={t('Common_ManageAccess')}
                                data-protectedsubcomponent={
                                    hasAccessToManageAccess
                                }
                            />
                        )}
                        {showWorkFlows && hasAccessToWorkFlow && (
                            <HorizontalTabs.Tab
                                key={3}
                                value="workFlows"
                                label={t('Workflows')}
                                data-protectedsubcomponent={hasAccessToWorkFlow}
                            />
                        )}
                    </SubHeaderTabs>
                </Box>
            )}

            {!isSmallScreen && (
                <Box
                    display="flex"
                    position="relative"
                    marginRight={appSettings.style.header.hide ? '-30px' : '0'}
                    minWidth={appSettings.style.header.hide ? '252px' : '130px'}
                    justifyContent="end"
                    marginTop="-5px"
                >
                    {viewPendingAccess}
                    {showBackButton ? (
                        <EidButton.BackButton label={t('BackToWorkflows')} />
                    ) : (
                        viewSwitcher
                    )}
                    {appSettings.style.header.hide && (
                        <Box className={classes.cartMenu}>
                            <ShoppingCartMenuIcon />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default SubHeader
