import React from 'react'
import { useIsSmallScreen } from 'packages/core'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const ITShopHeader = ({ hideSideNavigation }) => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileMenu hideMSAppsMenu={hideSideNavigation} />
    ) : (
        !hideSideNavigation && (
            <div style={{ marginTop: '20px', marginLeft: '-7px' }}>
                <DesktopMenu />
            </div>
        )
    )
}

export default ITShopHeader
