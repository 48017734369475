import { useState, Fragment } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/styles' 
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { getURLTarget, useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useCredentialHistory } from 'hooks'
import moment from 'moment'
import { Person } from 'components/ApplicationBanner/PersonDetails'
import { useUrlGenerator } from 'packages/core'
import { useAllApplications } from 'hooks'
import { Link } from '@mui/material'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={item.externalCredentialName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Credentials_AccountLogonName')}
                    value={item.checkedOutByLogin}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={item.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckedOutBy')}
                    value={<Person personId={item.checkedOutByPersonId} />}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AllowMultipleCheckout')}
                    value={item.allowMultiCheckOut ? t('True') : t('False')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckoutTime')}
                    value={moment
                        .utc(item?.startTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_StartTime')}
                    value={moment
                        .utc(item?.startTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={moment
                        .utc(item?.endTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AccessType')}
                    value={
                        item.isPreApproved
                            ? t('CredentialPreApproved')
                            : t('CredentialOneTime')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_CredentialType')}
                    value={item.externalCredentialTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_CredentialCategory')}
                    value={item.categoryName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={item.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_BusinessRequest')}
                    value={
                        item.businessRequestId &&
                        item.businessRequestName && (
                            <Link
                                target={getURLTarget()}
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequests?id=${item.businessRequestID}`,
                                )}
                            >
                                {item.businessRequestName}
                            </Link>
                        )
                    }
                    orientation={attributeOrientation}
                />

                <ItemDetails.Attribute
                    label={t('Common_BusinessRequestItem')}
                    value={
                        item.businessRequestItemId &&
                        item.businessRequestItemName && (
                            <Link
                                target={getURLTarget()}
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequestItems?id=${item.businessRequestItemId}`,
                                )}
                            >
                                {item.businessRequestItemName}
                            </Link>
                        )
                    }
                    orientation={attributeOrientation}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const CredentialHistoryDetails = ({ item }) => {
    const { t } = useTranslation()

    const credentialHistory = item?.resource

    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection item={credentialHistory} />
            )}
        </>
    )
}

export default CredentialHistoryDetails
