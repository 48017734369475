import { Box } from '@mui/material'
import { useApplicationRoleDefinitions, useTargetPerson } from 'hooks'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { ProtectedApplicationAssignmentInfo } from '../AssignmentTile'
import { useResourceTypeContext } from 'resourceTypeContext'

const take = 3

export type ApplicationRoleDefinitionsProps = {
    applicationId: string
    applicationsType: any
    applicationTypeRegistry: any
    setShowSpacer: any
}

export const ApplicationRoleDefinitions: FC<ApplicationRoleDefinitionsProps> = (
    props: ApplicationRoleDefinitionsProps,
) => {
    const [page, setPage] = useState(1)
    const appRoleRef = useRef<HTMLDivElement>(null);

    const scrollToDiv = () => {
        if (appRoleRef.current) {
            appRoleRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const {
        applicationId,
        applicationsType,
        applicationTypeRegistry,
        setShowSpacer,
    } = props
    const { t } = useTranslation()
    const [{ selectedAppRoleOrRightToHighLight }, dispatch]: any = useResourceTypeContext()
    const [targetPerson] = useTargetPerson()

    const { data, isLoading } = useApplicationRoleDefinitions(
        applicationId,
        targetPerson.id,
        (page - 1) * take,
        take,
    )
    const pageCount = data?.totalCount ? Math.ceil(data.totalCount / take) : 0

    const bindAppRoles = () => {
        try {
            if (data?.data?.length) {
                return data.data.map((item: any) => {
                    return {
                        resource: {
                            ...item,
                            id: item?.azLocalRoleId,
                            description:
                                item?.description || item.assignmentPointName,
                            manageAccessId: item?.id
                        },
                        assignment: {
                            personId: targetPerson.id,
                            isAssigned: item.isAssigned,
                            canActivateNow: item.canActivateNow,
                            isRenewable: item.isRenewable,
                            assignmentId: item?.id,
                            resourceAssignment: {
                                id: item.applicationId,
                                friendlyName: item.assignmentPointName,
                                resourceAssignmentId: item.azLocalRoleId,
                            },
                            assignmentDetails: {
                                timeConstraintActive: item.timeConstraintActive,
                                startDateUtc: item.startDateUtc,
                                endDateUtc: item.endDateUtc,
                                isRevokable: item.isRevocable,
                            },
                        },
                    }
                })
            }
        } catch (err) { }
        return []
    }

    useEffect(() => {
        if (data?.data?.length) {
            setShowSpacer(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (data?.data?.length && selectedAppRoleOrRightToHighLight) {
            const isSelectedExist = data.data.find((x: any) => (x?.azLocalRoleId && x.azLocalRoleId === selectedAppRoleOrRightToHighLight))
            if (isSelectedExist) {
                dispatch({
                    type: 'UPDATE_SELECTED_ACCESS_LEVEL_ALREADY_ASSIGNED',
                    payload: {
                        selectedAppRoleOrRightAlreadyAssigned: true,
                        accessType: 'azLocalRoleId'
                    },
                })
                scrollToDiv()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAppRoleOrRightToHighLight])

    return (
        <Box>
            {isLoading ? (
                <Skeleton height={40} />
            ) : data?.data?.length ? (
                <Box ref={appRoleRef}>
                    <ProtectedApplicationAssignmentInfo
                        totalCount={data?.totalCount}
                        setPage={(value: any) => setPage(value)}
                        pageCount={pageCount}
                        resourceAssignment={bindAppRoles() || []}
                        title={t('Common_RoleDefinitions')}
                        resourceType={applicationsType}
                        manageAccessRedirectTab="roleDefinitions"
                        applicationId={applicationId}
                        applicationTypeRegistry={applicationTypeRegistry}
                        mappingKeyForType='azLocalRoleId'
                    />
                </Box>
            ) : null}
        </Box>
    )
}
