import { styled } from '@mui/material'
import { ItemDetails, PeopleList } from 'components'
import LocalSensitiveFunctions from 'components/Local Sensitive Functions/LocalSensitiveFunctions'
import { useApplicationOwners } from 'hooks'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const AdditionalInformationSection = ({ item }) => {
    const { t } = useTranslation()

    const { hasAccessToLocalSensitiveFunctionsGrid } = useSubcomponents()

    const rbacRole = item?.resource

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        data: ownersAndApprovers,
        isLoading: ownersAndApproversLoading,
    } = useApplicationOwners(rbacRole?.id)

    return (
        <>
            {ownersAndApproversLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Owners')}
                            value={
                                <PeopleList
                                    list={ownersAndApprovers?.owners ?? []}
                                />
                            }
                            orientation={attributeOrientation}
                        />
                        <ItemDetails.Attribute
                            label={t('Approvers')}
                            value={
                                <PeopleList
                                    list={ownersAndApprovers?.approvers ?? []}
                                />
                            }
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <LocalSensitiveFunctions
                            rbacRole={rbacRole}
                            protectSubcomponent={
                                hasAccessToLocalSensitiveFunctionsGrid
                            }
                        />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

export default AdditionalInformationSection
