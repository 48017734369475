import { Typography } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { checkIfPasswordConditionsSatisfied } from './PasswordValidationUtils'

const PasswordValidationMessage = ({
    condition,
    password,
    indentation,
    currentPerson,
}) => {
    const style = {
        display: 'flex',
        margin: '1.6rem 0rem',
        paddingLeft: `${indentation * 20}px`,
    }

    return (
        <div style={style}>
            {checkIfPasswordConditionsSatisfied(
                condition,
                password,
                currentPerson,
            ) ? (
                <Icon
                    name="CheckedRound"
                    color={'#029435'}
                    width="2.4rem"
                    height="2.4rem"
                />
            ) : (
                <Icon
                    name="Attention"
                    color={'#d0021b'}
                    width="2.4rem"
                    height="2.4rem"
                />
            )}

            <Typography fontSize={'1.4rem'} marginLeft={'1.6rem'}>
                {condition.description}
            </Typography>
        </div>
    )
}

const renderConditions = (
    conditions,
    password,
    currentPerson,
    prefix = '',
    indentation = 0,
) => {
    if (!conditions) return null

    return conditions.map((condition, index) => {
        const key = `${prefix}${condition.name}-${index}`
        return (
            <div key={key}>
                <div>
                    <PasswordValidationMessage
                        condition={condition}
                        password={password}
                        indentation={indentation}
                        currentPerson={currentPerson}
                    />
                </div>
                {/* Recursive call for nested conditions */}
                {condition.conditions &&
                    renderConditions(
                        condition.conditions,
                        password,
                        currentPerson,
                        `${key}-`,
                        indentation + 1,
                    )}
            </div>
        )
    })
}

const PasswordValidation = ({
    password,
    passwordPolicyConditions,
    currentPerson,
}) => {
    return (
        <div>
            {passwordPolicyConditions &&
                renderConditions(
                    passwordPolicyConditions,
                    password,
                    currentPerson,
                )}
        </div>
    )
}

export default PasswordValidation
