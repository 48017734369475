import CartSubmissionMessage from './CartSubmissionMessage'
import { Backdrop, Dialog, Fade, styled } from '@mui/material'

const MessageModal = styled(Dialog)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const CartSubmissionMessageModal = ({
    submissionMessage,
    setSubmissionMessage,
    successMessage,
    errorMessage,
}) => {
    return (
        <MessageModal
            open={submissionMessage.open}
            onClose={() => {
                setSubmissionMessage((prev) => ({
                    ...prev,
                    open: !submissionMessage.open,
                    items: [],
                }))
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
            disableAutoFocus
            maxWidth="md"
        >
            <Fade in={submissionMessage.open} timeout={200}>
                <CartSubmissionMessage
                    error={submissionMessage.error}
                    data={submissionMessage.data}
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                    onClose={() => {
                        setSubmissionMessage((prev) => ({
                            ...prev,
                            open: !submissionMessage.open,
                        }))
                    }}
                />
            </Fade>
        </MessageModal>
    )
}

export default CartSubmissionMessageModal
