import { Route, Switch } from 'react-router-dom'
import withRequiredLocaleData from './withRequiredLocaleData'
import {
    NotFoundPage,
    ForbiddenPage,
    SessionExpiredPage,
    MaintenancePage,
    Loader,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import App from '../App'
import appConfig from 'config'
import withRequiredApplicationSettings from './withRequiredApplicationSettings'
import withCustomTheme from 'containers/AppRoutes/withCustomTheme'
import { requireAuth } from 'packages/core'

const ns = 'public'
const AppRoutes = () => {
    const { t } = useTranslation()

    return (
        <Switch>
            <Route
                exact
                path={`${appConfig.APP_SUBPATH}/forbidden`}
                component={() => (
                    <ForbiddenPage
                        description={t(
                            `${ns}:MsCommonAnonymous_ForbiddenPageMessage`,
                        )}
                        linkText={t(`${ns}:MsCommonAnonymous_HomePage`)}
                        errorCode={'403'}
                    />
                )}
            />
            <Route
                exact
                path={`${appConfig.APP_SUBPATH}/notfound`}
                component={() => (
                    <NotFoundPage
                        description={t(
                            `${ns}:MsCommonAnonymous_NotFoundPageMessage`,
                        )}
                        linkText={t(`${ns}:MsCommonAnonymous_BackToHomePage`)}
                        errorCode={'404'}
                    />
                )}
            />
            <Route
                exact
                path={`${appConfig.APP_SUBPATH}/sessionExpired`}
                component={() => (
                    <SessionExpiredPage
                        linkText={t(`${ns}:MsCommonAnonymous_ReLogin`)}
                        title={t(`${ns}:MsCommonAnonymous_SessionExpired`)}
                        description={t(
                            `${ns}:MsCommonAnonymous_SessionExpiredMesage`,
                        )}
                    />
                )}
            />
            <Route
                exact
                path={`${appConfig.APP_SUBPATH}/underMaintenance`}
                component={() => (
                    <MaintenancePage
                        title={t(`${ns}:MsCommonAnonymous_UnderMaintenance`)}
                        description={t(
                            `${ns}:MsCommonAnonymous_UnderMaintenanceMesage`,
                        )}
                        linkText={t(`${ns}:MsCommonAnonymous_BackToHomePage`)}
                    />
                )}
            />
            <App />
        </Switch>
    )
}

//@ts-ignore
export default withRequiredLocaleData(
    requireAuth(
        withRequiredApplicationSettings(withCustomTheme(AppRoutes)),
        <Loader />,
    ),
)
