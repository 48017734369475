import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import InputWithInnerLabel from '../Components/InputWithInnerLabel'
import { ACTION_TYPES, getFieldTypeRequiredErrorMessage } from 'packages/core'
import Tooltip from 'packages/eid-ui/Tooltip'
import { Icon } from 'packages/eid-icons'
import { useTheme } from '@mui/styles'

const FreeTextMultiValue = ({
    valueType,
    fieldType,
    AzLocalRightID,
    isFormValidated,
    handleUpdate,
}) => {
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme()
    const fieldTypesValues = fieldType?.[valueType]?.length
        ? fieldType?.[valueType]
        : [
              {
                  FriendlyName: '',
                  ValueName: '',
                  isNewlyAdded: true,
                  ActionType: ACTION_TYPES.ADDED,
              },
          ]

    useEffect(() => {
        if (isFormValidated) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType])

    const handleChange = (value, itemKey, index) => {
        try {
            let selectedIndexItem = fieldTypesValues?.length
                ? [...fieldTypesValues]
                : []
            if (selectedIndexItem?.length) {
                selectedIndexItem[index][itemKey] = value
            } else {
                selectedIndexItem.push({
                    [itemKey]: value,
                    isNewlyAdded: true,
                    ActionType: ACTION_TYPES.ADDED,
                })
            }

            handleItemUpdate(selectedIndexItem)
        } catch (err) {}
    }

    const handleAddFreeTextValue = () => {
        let selectedIndexItem = fieldTypesValues?.length
            ? [...fieldTypesValues]
            : []
        selectedIndexItem.push({
            FriendlyName: '',
            ValueName: '',
            isNewlyAdded: true,
            ActionType: ACTION_TYPES.ADDED,
        })

        handleItemUpdate(selectedIndexItem)
    }

    const handleRemoveFreeTextValue = (index) => {
        let selectedIndexItem = [...fieldTypesValues]
        if (selectedIndexItem[index]?.isNewlyAdded) {
            selectedIndexItem.splice(index, 1)
        } else {
            selectedIndexItem[index] = {
                ...selectedIndexItem[index],
                ActionType: ACTION_TYPES.DELETED,
            }
        }
        handleItemUpdate(selectedIndexItem)
    }

    const handleItemUpdate = (selectedIndexItem) => {
        const valueToUpdate = {
            ...fieldType,
            [valueType]: selectedIndexItem,
            parentRightId: AzLocalRightID,
        }
        handleUpdate(valueToUpdate)
    }

    const bindLabelValueInput = (item, index, showRemove = true) => {
        if (item.ActionType === ACTION_TYPES.DELETED) {
            return null
        }
        return (
            <Box
                key={`field-type-free-multi-${index}`}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.8rem',
                    '& .MuiFormControl-root': {
                        height: '4rem',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '.4rem',
                    },
                    '& label': {
                        fontSize: '1rem !important',
                        fontWeight: 'bold',
                    },
                    '& input': {
                        cursor: 'text',
                    },
                    ...(!item?.isNewlyAdded && {
                        '& .MuiFormControl-root': {
                            backgroundColor: `${theme?.palette?.disabled?.main} !important`,
                            height: '4rem',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '.4rem',
                            cursor: 'not-allowed',
                        },
                        '& input': {
                            cursor: 'not-allowed',
                        },
                        '& label': {
                            cursor: 'not-allowed',
                        },
                        '& .MuiBox-root': {
                            boxShadow: 'none',
                        },
                    }),
                }}
            >
                <Box
                    sx={{
                        boxShadow: '0 .4rem 1.6rem 0 rgba(0, 0, 0, 0.05)',
                        width: '100%',
                        position: 'relative',
                        '& label': {
                            width: '4rem',
                        },
                        '& input': {
                            textTransform: 'inherit !important',
                        },
                    }}
                >
                    <InputWithInnerLabel
                        value={item?.FriendlyName}
                        placeholder={t('Common_Label')}
                        label={t('Common_Label')}
                        showTooltip={true}
                        sx={{ maxWidth: '34rem' }}
                        handleChange={(e) =>
                            handleChange(e.target.value, 'FriendlyName', index)
                        }
                        disabled={!item?.isNewlyAdded}
                    />
                    {isFormValidated &&
                        !item?.FriendlyName &&
                        item?.ValueName && (
                            <RequiredFieldErrorMessage
                                showIcon={false}
                                errorMsgKey={t('LabelIsMissing')}
                                sx={{
                                    position: 'absolute',
                                    bottom: '-1.7rem',
                                    left: '.5rem',
                                    fontSize: '1rem',
                                }}
                            />
                        )}
                </Box>
                <Tooltip title={item?.ValueName}>
                    <Box
                        sx={{
                            boxShadow: '0 .4rem 1.6rem 0 rgba(0, 0, 0, 0.05)',
                            width: '100%',
                            position: 'relative',
                            '& label': {
                                width: '4rem',
                            },
                            '& input': {
                                textTransform: 'inherit !important',
                            },
                        }}
                    >
                        <InputWithInnerLabel
                            value={item?.ValueName}
                            placeholder={t('Common_Value')}
                            label={t('Common_Value')}
                            handleChange={(e) =>
                                handleChange(e.target.value, 'ValueName', index)
                            }
                            sx={{ maxWidth: '34rem' }}
                            disabled={!item?.isNewlyAdded}
                        />
                        {isFormValidated &&
                            !item?.ValueName &&
                            item.FriendlyName && (
                                <RequiredFieldErrorMessage
                                    showIcon={false}
                                    errorMsgKey={t('ValueIsMissing')}
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-1.7rem',
                                        left: '.5rem',
                                        fontSize: '1rem',
                                    }}
                                />
                            )}
                    </Box>
                </Tooltip>

                <Box sx={{ cursor: 'pointer', color: 'red' }}>
                    {showRemove && (
                        <Icon
                            name="Close"
                            onClick={() => handleRemoveFreeTextValue(index)}
                        />
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <FieldItemWrapper
            headerStyle={{ alignItems: 'baseline' }}
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
            childStyle={{ width: '100%' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.8rem',
                    width: '100%',
                    '& .MuiInputBase-formControl': {
                        width: '90%',
                    },
                    '& input': {
                        boxShadow: 'none',
                        fontSize: '1.5rem',
                        textTransform: 'capitalize',
                        color: '#3b454d',
                        overflow: 'hidden',
                        width: '100%',
                    },
                    '& .MuiFormControl-root': {
                        marginTop: '0 !important',
                    },
                }}
            >
                {fieldTypesValues &&
                    fieldTypesValues?.map((item, index) =>
                        bindLabelValueInput(
                            item,
                            index,
                            fieldTypesValues.length > 1,
                        ),
                    )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '1.8rem',
                    }}
                >
                    <Box
                        onClick={handleAddFreeTextValue}
                        sx={{
                            borderRadius: '.4rem',
                            backgroundColor: theme?.palette?.primary?.main,
                            color: '#ffffff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '3.8rem',
                            padding: '1rem',
                            cursor: 'pointer',
                            float: 'right',
                            gap: '.5rem',
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.main,
                            },
                        }}
                    >
                        <Icon name="Plus" />
                        <Typography>{t('AddFreeLabelValue')}</Typography>
                    </Box>
                </Box>
                <Box paddingLeft={'.2rem'}>
                    {Boolean(errorMessage) && (
                        <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                    )}
                </Box>
            </Box>
        </FieldItemWrapper>
    )
}

export default FreeTextMultiValue
