import { Box, useTheme } from '@mui/material'
import ComputerConnectBtn from './ComputerConnectButton'

const styles = (theme) => ({
    parentClass: {
        background: theme?.palette?.secondary?.main,
        borderColor: theme?.palette?.secondary?.main,
        '&:hover': {
            backgroundColor: theme?.palette?.secondary?.main,
        },
    },
})

const ComputersStatusButton = ({ data, showPrefixIcon = false }) => {
    const theme = useTheme()

    return (
        <Box>
            <ComputerConnectBtn
                showPrefixIcon={showPrefixIcon}
                parentStyle={styles(theme).parentClass}
                data={data}
                drawerView={false}
            />{' '}
        </Box>
    )
}

ComputersStatusButton.defaultProps = {
    drawerView: false,
}

export default ComputersStatusButton
