import React, { FC, Fragment } from 'react'
import { Box, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useAllApplications } from 'hooks'
import { getURLTarget, useIsSmallScreen, useUrlGenerator } from 'packages/core'
import { defaultDataProtectedSubcomponent } from 'utils'

export type BusinessRequestItemDetails = {
    id: string
    friendlyName: string
    businessRequestID: string
    businessRequestName: string
    businessRequestNumber: number
}

export const BusinessRequestNameAttribute: FC<{
    businessRequestItem: BusinessRequestItemDetails, protectSubcomponent : string
}> = ({ businessRequestItem, protectSubcomponent = defaultDataProtectedSubcomponent }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const hasAccessToMyTasks = getMyTasksUrl()

    if (!hasAccessToMyTasks) return <Fragment />

    return (
        <Fragment>
            <Box data-protectedsubcomponent={protectSubcomponent}>
                {businessRequestItem.businessRequestID &&
                    businessRequestItem.businessRequestName && (
                        <ItemDetails.Attribute
                            label={t('Common_LastBusinessRequest')}
                            value={
                                <Link
                                    target={getURLTarget()}
                                    href={getMyTasksUrl(
                                        `/myRequests/businessRequests?id=${businessRequestItem.businessRequestID}`,
                                    )}
                                >
                                    {businessRequestItem.businessRequestName}
                                </Link>
                            }
                            orientation={attributeOrientation}
                        />
                    )}
                {businessRequestItem.id && businessRequestItem.friendlyName && (
                    <ItemDetails.Attribute
                        label={t('Common_LastBusinessRequestItem')}
                        value={
                            <Link
                                target={getURLTarget()}
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequestItems?id=${businessRequestItem.id}`,
                                )}
                            >
                                {businessRequestItem.friendlyName}
                            </Link>
                        }
                        orientation={attributeOrientation}
                    />
                )}
            </Box>
        </Fragment>
    )
}
