import React, { useState } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    useSharedFolderBr,
    useSharedFolderOwners,
    useTargetPerson,
} from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../../CommonAttributes'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router'
import useSubcomponents from 'useSubcomponents'

const hasAccessToOwnerControl =
    'ITShop-SharedFolders-ManageAccess-Details-Owners-Control'
const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const sharedFolder = item.resource
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useSharedFolderBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
        item.assignment.resourceAssignment.resourceAssignmentId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('SharedFolders_DisplayName')}
                    value={sharedFolder.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_ShareName')}
                    value={sharedFolder.shareName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AccessLevel')}
                    value={item.assignment.resourceAssignment.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_Computer')}
                    value={sharedFolder.computerFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_DnsHostName')}
                    value={sharedFolder.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_UncPath')}
                    value={sharedFolder.uncPath}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('SharedFolders_LocalPath')}
                    value={sharedFolder.localPath}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={sharedFolder.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const sharedFolder = item.resource
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useSharedFolderOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? sharedFolder.id : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {hasAccessToControl(hasAccessToOwnerControl) && (
                        <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>
                    )}
                </>
            )}
        </>
    )
}

const SharedFolderDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const history = useHistory()
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    const sharedFolderType = registry.get(
        ResourceTypesNamespace,
        'SharedFolders',
    )

    const [targetPerson] = useTargetPerson()

    const [activeTab, setActiveTab] = useState('overview')

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.sharedFolderToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            sharedFolder: item.resource,
            assignmentStatus: item.assignment,
        })

        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && <OverviewSection item={item} />}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {isManageAccess && (
                <RenewRevoke
                    resourceType={sharedFolderType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </>
    )
}

export default SharedFolderDetails
