import React from 'react'
import ActionButton from './ActionButton'
import AttributesContainer from './AttributesContainer'
import AttributesLoader from './AttributesLoader'
import Attribute from './Attribute'
import Person from './Person'
import Tabs from './Tabs'

const ItemDetails = () => {
    return <></>
}

ItemDetails.ActionButton = ActionButton
ItemDetails.AttributesContainer = AttributesContainer
ItemDetails.AttributesLoader = AttributesLoader
ItemDetails.Attribute = Attribute
ItemDetails.Person = Person
ItemDetails.Tabs = Tabs

export default ItemDetails
