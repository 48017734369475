import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import SharedFolderDetails from './SharedFolderDetails'
import { useSharedFolder } from 'hooks'

const SharedFolderDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()

    const { data: sharedFolder } = useSharedFolder(id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={sharedFolder?.friendlyName} />
            {!sharedFolder && <Loader />}
            {sharedFolder && (
                <SharedFolderDetails
                    sharedFolder={sharedFolder}
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={sharedFolder?.friendlyName} />}
        >
            {!sharedFolder && <Loader />}
            {sharedFolder && (
                <SharedFolderDetails
                    sharedFolder={sharedFolder}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </Drawer>
    )
}

export default SharedFolderDrawer
