import { Fragment, useState } from 'react'
import { Box, InputBase, Typography } from '@mui/material'
import { styled, withStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import {
    useApplicationRoleApplications,
    useApplicationRoleBr,
    useApplicationRoleLocalFunctions,
    useApplicationRoleOwnersAndApprovers,
    useTargetPerson,
} from 'hooks'
import { Pagination } from 'packages/eid-ui'
import {
    ApplicationsList,
    FunctionsList,
    ItemDetails,
    PeopleList,
    TCodesList,
} from 'components'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../../CommonAttributes'
import { RenewRevoke } from 'components/RenewRevoke'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { Skeleton } from '@mui/material'
import ActivateNowButton from '../../../ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'
import { defaultDataProtectedSubcomponent } from 'utils'

const hasAccessToOwnerControl =
    'ITShop-Groups-ManageAccess-Details-Owners-Control'
const hasAccessToApproversControl =
    'ITShop-Groups-ManageAccess-Details-Approvers-Control'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: `${theme?.palette?.common?.white} !important`,
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 ${theme?.palette?.primary?.light}`,
            borderColor: theme?.palette?.primary?.main,
            backgroundColor: theme?.palette?.common?.white,
        },
    },

    focused: {
        backgroundColor: theme?.palette?.common?.white,
    },
}))(InputBase)

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const applicationRole = item.resource

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useApplicationRoleBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
    )

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={applicationRole.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_ResourceSystem')}
                    value={applicationRole.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_AccountStore')}
                    value={applicationRole.accountStoreFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_Account')}
                    value={applicationRole.targetSystemAccount_FriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                {applicationRole.applicationProcess_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('ApplicationRoles_Process')}
                        value={applicationRole.applicationProcess_FriendlyName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {applicationRole.differentiationValue_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('ApplicationRoles_DifferentiationValue')}
                        value={
                            applicationRole.differentiationValue_FriendlyName
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_HighLevelClassification')}
                    value={applicationRole.highLevelClassification}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={applicationRole.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const take = 10

const LocalSensitiveFunctions = ({
    applicationRole,
    protectSubcomponent = defaultDataProtectedSubcomponent,
}) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useApplicationRoleLocalFunctions(
        applicationRole.id,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const localFunctions = latestData ? latestData.data : undefined

    const localFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPageItems)
        : 0

    return (
        <>
            {localFunctions && localFunctions.length > 0 && (
                <>
                    <PaddedDiv
                        data-protectedsubcomponent={protectSubcomponent}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px 31px',
                        }}
                    >
                        <Box width="50%">
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    lineHeight: 1.33,
                                    color: '#9b9b9b',
                                    wordBreak: 'break-word',
                                    textTransform: 'uppercase',
                                    minWidth: '80px',
                                }}
                            >
                                {t('Common_LocalFunctionalAccess')}
                            </Typography>
                        </Box>

                        <Box
                            width={!isSmallScreen ? '308px' : '50%'}
                            minWidth="130px"
                        >
                            <StyledInput
                                fullWidth
                                type="text"
                                placeholder={t('Common_Search')}
                                value={searchKey}
                                onChange={handleSearchChange}
                            />
                        </Box>
                    </PaddedDiv>
                    <Box overflow="auto">
                        <FunctionsList
                            data={localFunctions}
                            loading={localFunctionsLoading}
                        />

                        {!localFunctionsLoading && (
                            <Box
                                padding={!isSmallScreen ? '20px 32px' : '16px'}
                                display="flex"
                                alignItems="center"
                                justifyContent={
                                    !isSmallScreen ? 'flex-start' : 'center'
                                }
                            >
                                <Pagination
                                    count={numberOfPages}
                                    size={!isSmallScreen ? 'large' : 'small'}
                                    page={page}
                                    onChange={handlePageChange}
                                    totalCount={latestData?.totalCount}
                                    onPageSelection={handlePageSelection}
                                    itemsPerPage={perPageItems}
                                    onItemsPerPageChange={
                                        handleItemsPerPageChange
                                    }
                                    showPageSelection={true}
                                    showItemsPerPageSelection
                                    showCountStat
                                />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const { t } = useTranslation()

    const { hasAccessToLocalSensitiveFunctionsGrid, hasAccessToControl } =
        useSubcomponents()

    const applicationRole = item.resource

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: ownersAndApprovers, isLoading: ownersAndApproversLoading } =
        useApplicationRoleOwnersAndApprovers(
            hasAccessToControl(hasAccessToOwnerControl) ||
                hasAccessToControl(hasAccessToApproversControl)
                ? applicationRole.id
                : null,
        )

    return (
        <>
            {ownersAndApproversLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        {hasAccessToControl(hasAccessToOwnerControl) && (
                            <Box
                                data-protectedsubcomponent={
                                    hasAccessToOwnerControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('ApplicationRoles_Owners')}
                                    value={
                                        <PeopleList
                                            list={ownersAndApprovers.owners}
                                        />
                                    }
                                    orientation={attributeOrientation}
                                />
                            </Box>
                        )}
                        {hasAccessToControl(hasAccessToApproversControl) && (
                            <Box
                                data-protectedsubcomponent={
                                    hasAccessToApproversControl
                                }
                            >
                                <ItemDetails.Attribute
                                    label={t('Common_Approvers')}
                                    value={
                                        <PeopleList
                                            list={ownersAndApprovers.approvers}
                                        />
                                    }
                                    orientation={attributeOrientation}
                                />
                            </Box>
                        )}
                    </PaddedDiv>

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <LocalSensitiveFunctions
                            applicationRole={applicationRole}
                            protectSubcomponent={
                                hasAccessToLocalSensitiveFunctionsGrid
                            }
                        />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
const ApplicationRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('ApplicationRoles')

    const applicationRoleType = registry.get(
        ResourceTypesNamespace,
        'ApplicationRoles',
    )

    const { hasAccessToTCodesGrid, canSeeApplications } = useSubcomponents()

    const [targetPerson] = useTargetPerson()

    const [activeTab, setActiveTab] = useState('overview')

    const preRevoke = () => {
        const itemToAdd = cartHelpers.appRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            applicationRole: item.resource,
            assignmentStatus: item.assignment,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                {hasAccessToTCodesGrid && (
                    <ItemDetails.Tabs.Tab
                        value="tCodes"
                        label={t('Common_TCodes')}
                        data-protectedsubcomponent={hasAccessToTCodesGrid}
                    />
                )}
                {canSeeApplications && (
                    <ItemDetails.Tabs.Tab
                        value="applications"
                        label={t('Common_Applications')}
                        data-protectedsubcomponent={canSeeApplications}
                    />
                )}
            </ItemDetails.Tabs>
            {activeTab === 'overview' && <OverviewSection item={item} />}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            {activeTab === 'tCodes' && (
                <TCodesList resourceId={item.resource.id} />
            )}
            {activeTab === 'applications' && (
                <ApplicationsList
                    resource={item.resource}
                    useDataHook={useApplicationRoleApplications}
                />
            )}

            {item?.assignment.canActivateNow ? (
                <ActivateNowButton
                    resource={item?.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                    width="300px"
                    assignment={item?.assignment}
                />
            ) : (
                <RenewRevoke
                    resourceType={applicationRoleType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </Fragment>
    )
}

export default ApplicationRoleDetails
