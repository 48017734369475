import { makeStyles } from '@mui/styles'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { isNilOrEmpty } from 'packages/core'
import { Drawer, Loader } from 'packages/eid-ui'

const useStyles = makeStyles({
    noRightBorderRadius: {
        '& .MuiCardHeader-root': {
            borderRadius: '0',
        },
    },
})

export const ManageAccessDrawer = ({
    id,
    toggleDrawer,
    item,
    itemDetails,
    headerAttribute,
}) => {
    const classes = useStyles()

    return (
        <Drawer
            width="clamp(67%, 1007px, 90%)"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            className={classes.noRightBorderRadius}
            header={<ItemHeader title={item?.resource[headerAttribute.name]} />}
        >
            {!item && <Loader />}
            {item && itemDetails}
        </Drawer>
    )
}
