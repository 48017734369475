import config from 'config'
import moment from 'moment'

export const inIframe = () => {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export const isItemAlreadyInCart = (cart, resourceId, locationId) => {
    if (cart && cart.length > 0) {
        const item = cart[0].cartItems.filter(
            (el) =>
                el.requestableResourceId === resourceId &&
                el.locationId === locationId,
        )

        if (item && item.length > 0) {
            return true
        }
    }
    return false
}

export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}

export const sanitizeSearchString = (searchString) => {
    return searchString && typeof searchString === 'string'
        ? searchString.trim()
        : ''
}

export const isValidSearchString = (searchString) => {
    return searchString && searchString.length > 0
}

export const getFullImageUrl = (imagePath) =>
    `${config.BASE_EID_URL}${imagePath}`

export { default as genericHashLink } from './genericHashLink'

export const subHeaderHeight = 70
export const headerHeight = 70

export const bindResourceTypeNounAndVerbQueryParams = (resourceType) => {
    switch (resourceType) {
        case 'applications':
            return 'noun=ITShopMS&verb=Application'
        case 'businessRoles':
            return 'noun=ITShopMS&verb=BusinessRole'
        case 'applicationRoles':
            return 'noun=ITShopMS&verb=ApplicationRole'
        case 'azureLicenses':
            return 'noun=ITShopMS&verb=AzureLicense'
        case 'azureRoles':
            return 'noun=ITShopMS&verb=AzureRole'
        case 'managementRoles':
            return 'noun=ITShopMS&verb=ManagementRole'
        case 'mailboxes':
            return 'noun=ITShopMS&verb=Mailbox'
        case 'sharedFolders':
            return 'noun=ITShopMS&verb=SharedFolder'
        case 'computers':
            return 'noun=ITShopMS&verb=Computer'
        case 'Credentials':
        case 'credentials':
            return 'noun=ITShopMS&verb=SharedCredential'

        default:
            return 'noun=ITShopMS&verb=Application'
    }
}

export const getMasterLockFieldsWidth = (headings, tableHeadingRefs) => {
    let allRefWidth = 0
    const masterLockFields = headings.filter((x) => x.showMasterLock)
    if (masterLockFields?.length > 0) {
        masterLockFields.forEach((item) => {
            const itemIndex = headings.findIndex((x) => x.name === item.name)
            if (itemIndex >= 0) {
                allRefWidth = tableHeadingRefs.current[itemIndex]?.current
                    ?.offsetWidth
                    ? allRefWidth +
                      tableHeadingRefs.current[itemIndex].current.offsetWidth
                    : allRefWidth
            }
        })
    }
    return allRefWidth
}

export const getMasterLockLeftSpace = (headings, tableHeadingRefs) => {
    let allRefOnLeftWidth = 0
    let keysBeforeMasterPass = []
    let isMasterLockFound = false
    headings.forEach((element) => {
        if (!element.showMasterLock && !isMasterLockFound) {
            keysBeforeMasterPass.push(element.name)
        } else {
            isMasterLockFound = true
        }
    })
    keysBeforeMasterPass.forEach((item) => {
        const itemIndex = headings.findIndex((x) => x.name === item)
        if (itemIndex >= 0) {
            allRefOnLeftWidth = tableHeadingRefs.current[itemIndex]?.current
                ?.offsetWidth
                ? allRefOnLeftWidth +
                  tableHeadingRefs.current[itemIndex].current.offsetWidth
                : allRefOnLeftWidth
        }
    })
    return allRefOnLeftWidth
}

export const defaultDataProtectedSubcomponent = 'NotProtected'
export const convertToPixels = (width, element) => {
    if (!width) {
        return ''
    }
    if (typeof width === 'number') {
        return `${width}px`
    } else if (typeof width === 'string' && width.endsWith('px')) {
        return width
    } else if (typeof width === 'string' && width.endsWith('%') && element) {
        const containerWidth = element.parentNode.offsetWidth
        return `${(containerWidth * parseInt(width, 10)) / 100}px`
    }
}

export const ACCESS_LEVEL_TYPE = {
    MANAGEMENT_ROLE: 'ManagementRole',
    AZ_LOCAL_RIGHT: 'AzLocalRight',
    AZURE_ROLE: 'AzureRole',
}

export const DEFAULT_PAGE_SIZE_DETAILS = 6
export const mobileScreenWidth = '767px'

export const RESOURCE_TYPE_NAMES = {
    APPLICATIONS: 'Applications',
    BUSINESS_ROLES: 'BusinessRoles',
    APPLICATION_ROLES: 'ApplicationRoles',
    AZURE_ADMIN_ROLES: 'AzureAdminRoles',
    AZURE_LICENSES: 'AzureLicenses',
    AZURE_RBAC_ROLES: 'AzureRbacRoles',
    COMPUTERS: 'Computers',
    CREDENTIALS: 'Credentials',
    MAILBOXES: 'Mailboxes',
    MANAGEMENT_ROLES: 'ManagementRoles',
    SHARED_FOLDERS: 'SharedFolders',
    SHAREPOINT: 'Sharepoint',
}

export const getNumberOrDefault = (number, defaultValue) => {
    if (isNaN(number)) return defaultValue
    return number
}

export const sortArrayObjects = (array, sortByName, sortOrder) => {
    return [...array].sort((a, b) => {
        if (sortOrder === 'asc') {
            return a[sortByName] > b[sortByName] ? 1 : -1
        } else {
            return a[sortByName] < b[sortByName] ? 1 : -1
        }
    })
}

export const mergeByProperties = (array1, array2, prop) => {
    //Array1 is with required prop and array 2 without prop
    // Clone the array2 to avoid mutating the original array
    const updatedArray2 = array2.map((obj) => ({ ...obj }))

    // Iterate through each object in array2
    updatedArray2.forEach((obj2) => {
        // Find a matching object in array1
        const matchingObj = array1.find((obj1) => {
            const keysToCompare = Object.keys(obj2)
            // Check if all keys match
            return keysToCompare.every((key) => obj1[key] === obj2[key])
        })

        // If a matching object is found, assign its id to the current object in array2
        if (matchingObj) {
            obj2[prop] = matchingObj?.[prop]
        }
    })
    return updatedArray2
}
export const calculateRemainingTime = (currentTime, endTime) => {
    const duration = moment.duration(
        moment.utc(endTime).diff(moment.utc(currentTime)),
    )

    const hours = duration.hours().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    })
    const minutes = duration.minutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    })
    return {
        days: duration.days(),
        hours,
        minutes,
    }
}
