export const getResourceItemFromList = (id, list, currentResourceTypeName) => {
    let item = null

    switch (currentResourceTypeName) {
        case 'BusinessRoles':
        case 'Mailboxes':
        case 'AzureRoles':
        case 'SharedFolders':
            item = list?.find(
                (i) =>
                    i.assignment.resourceAssignment.resourceAssignmentId === id,
            )
            break
        case 'Computers':
            item = list.find((i) => i.resource?.id.toString() === id)
            if (!item) {
                item = list?.find(
                    (i) =>
                        i.assignment?.resourceAssignment
                            ?.resourceAssignmentId === id,
                )
            }
            break
        default:
            item = list.find((i) => i.resource.id.toString() === id)
            break
    }

    return item
}

export const getUniqueId = (item, tab = null) => {
    if (
        tab === 'loginsession' ||
        tab === 'history' ||
        tab === 'appManagementRoles'
    ) {
        return item.resource.id
    }

    if (!item?.resource?.id) {
        return item.id
    }

    switch (item.resource.resourceTypeName) {
        case 'AzLocalRight':
            return item.resource.id
        case 'BusinessRole':
        case 'MailBox':
        case 'SharedFolder':
        case 'Computer':
            return item.assignment.resourceAssignment.resourceAssignmentId
        default:
            return item.resource.id
    }
}
