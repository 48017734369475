import React, { useEffect, useState } from 'react'
import { Box, Accordion } from '@mui/material'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import AdvancedFilter from './AdvancedFilter'
import GenericFilter from './GenericFilter'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson, useDefaultFilters, useTargetPerson } from 'hooks'
import { useAppState } from 'appContext'
import windowConfig from 'config'
import { useHistory } from 'react-router'
import { RESOURCE_TYPE_NAMES } from 'utils'

const EidFilter = ({ title, colorCode, icon, type, resourceType, ...rest }) => {
    const name = rest.name
    const { t } = useTranslation()
    const { data: filterData } = useDefaultFilters(resourceType)
    const [{ urlFilters, savedFilters }] = useAppState()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreen()
    const [state, dispatch] = useResourceTypeContext()
    const { data: currentPerson } = useCurrentPerson()
    const [onBoardingData, setOnBaordingData] = useState({})
    const [targetPerson] = useTargetPerson()
    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    let payloadToDispatch = {}

    const invalidateFilters = shoppingForSomeoneElse || isManageAccess

    useEffect(() => {
        const tempOnBoardingData =
            savedFilters &&
            savedFilters?.[resourceType] &&
            savedFilters?.[resourceType]?.filters
                ? savedFilters[resourceType].filters
                : {}
        if (Object.keys(tempOnBoardingData).length > 0) {
            setOnBaordingData(tempOnBoardingData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFilters, resourceType])

    useEffect(() => {
        if (onBoardingData.tCode && name === 'TCode') {
            payloadToDispatch.tCode = invalidateFilters
                ? null
                : onBoardingData.tCode
        }
        if (
            onBoardingData.showSuggestedApplicationRoles &&
            (name === 'SuggestApplicationRoles' ||
                name === 'ShowSuggestedRoles')
        ) {
            payloadToDispatch.showSuggested = invalidateFilters
                ? null
                : onBoardingData.showSuggestedApplicationRoles
        }

        if (onBoardingData.roleGroupType) {
            payloadToDispatch.roleGroupType = invalidateFilters
                ? null
                : onBoardingData.roleGroupType
        }

        if (onBoardingData.accountStoreId) {
            payloadToDispatch.accountStoreId = invalidateFilters
                ? null
                : onBoardingData.accountStoreId
        }

        if (onBoardingData.roleType) {
            payloadToDispatch.roleType = invalidateFilters
                ? null
                : onBoardingData.roleType
        }

        if (onBoardingData.globalFunction) {
            payloadToDispatch.globalFunction = invalidateFilters
                ? null
                : onBoardingData.globalFunction
        }

        if (onBoardingData.application) {
            payloadToDispatch.application = invalidateFilters
                ? null
                : onBoardingData.application
        }

        if (onBoardingData.azureLicensePool) {
            payloadToDispatch.azureLicensePool = invalidateFilters
                ? null
                : onBoardingData.azureLicensePool
        }

        if (onBoardingData.azureTenant) {
            payloadToDispatch.azureTenant = invalidateFilters
                ? null
                : onBoardingData.azureTenant
        }

        if (onBoardingData.capability) {
            payloadToDispatch.capability = invalidateFilters
                ? null
                : onBoardingData.capability === 'true'
                ? 'true'
                : 'null'
        }

        if (onBoardingData.category) {
            payloadToDispatch.category = invalidateFilters
                ? null
                : onBoardingData.category
        }

        if (onBoardingData.environment) {
            payloadToDispatch.environment = invalidateFilters
                ? null
                : onBoardingData.environment
        }
        if (onBoardingData.infrastructure) {
            payloadToDispatch.infrastructure = invalidateFilters
                ? null
                : onBoardingData.infrastructure
        }

        if (onBoardingData.operatingSystem) {
            payloadToDispatch.operatingSystem = invalidateFilters
                ? null
                : onBoardingData.operatingSystem
        }

        if (onBoardingData.accountStoreUsageType) {
            payloadToDispatch.accountStoreUsageType = invalidateFilters
                ? null
                : onBoardingData.accountStoreUsageType
        }
        if (
            onBoardingData.accountStore &&
            resourceType !== RESOURCE_TYPE_NAMES.BUSINESS_ROLES
        ) {
            payloadToDispatch.accountStore = invalidateFilters
                ? null
                : onBoardingData.accountStore
        }
        if (onBoardingData.businessFunctions) {
            payloadToDispatch.businessFunctions = invalidateFilters
                ? null
                : onBoardingData.businessFunctions
        }
        if (onBoardingData.showPreApproved) {
            payloadToDispatch.showPreApproved = invalidateFilters
                ? null
                : onBoardingData.showPreApproved
        }

        if (onBoardingData.applicationProcess) {
            payloadToDispatch.applicationProcess = invalidateFilters
                ? null
                : onBoardingData.applicationProcess
        }

        if (onBoardingData.businessDomain) {
            payloadToDispatch.businessDomain = invalidateFilters
                ? null
                : onBoardingData.businessDomain
        }

        if (onBoardingData.referencePerson) {
            payloadToDispatch.referencePerson = invalidateFilters
                ? null
                : onBoardingData.referencePerson
        }

        if (onBoardingData.uncPath) {
            payloadToDispatch.uncPath = invalidateFilters
                ? null
                : onBoardingData.uncPath
        }

        if (onBoardingData.mailboxTypeId) {
            payloadToDispatch.mailboxTypeId = invalidateFilters
                ? null
                : onBoardingData.mailboxTypeId
        }
        if (
            onBoardingData.accountStore &&
            onBoardingData.tCode &&
            resourceType === RESOURCE_TYPE_NAMES.BUSINESS_ROLES
        ) {
            payloadToDispatch.tCode = invalidateFilters
                ? null
                : onBoardingData.tCode
            payloadToDispatch.accountStore = invalidateFilters
                ? null
                : onBoardingData.accountStore
        }
        if (
            windowConfig.ALLOW_FILTERS_OVERRIDE?.toLowerCase() === 'true' &&
            rest?.urlOverrideProp &&
            urlFilters.hasOwnProperty(rest.urlOverrideProp)
        ) {
            payloadToDispatch[rest.contextProp] = {
                id: urlFilters[rest.urlOverrideProp],
            }
        }

        if (invalidateFilters) {
            payloadToDispatch.azureLicensePool = null
            payloadToDispatch.application = null
            payloadToDispatch.globalFunction = null
            payloadToDispatch.roleType = null
            payloadToDispatch.roleGroupType = null
            payloadToDispatch.showSuggested = null
            payloadToDispatch.tCode = null
            payloadToDispatch.infrastructure = null
            payloadToDispatch.environment = null
            payloadToDispatch.category = null
            payloadToDispatch.capability = null
            payloadToDispatch.azureTenant = null
            payloadToDispatch.businessFunctions = null
            payloadToDispatch.accountStore = null
            payloadToDispatch.accountStoreUsageType = null
            payloadToDispatch.operatingSystem = null

            payloadToDispatch.mailboxTypeId = null
            payloadToDispatch.uncPath = null
            payloadToDispatch.referencePerson = null
            payloadToDispatch.businessDomain = null
            payloadToDispatch.accountStoreId = null

            //NOTE : This is Need to persist overriden filters
            if (
                windowConfig.ALLOW_FILTERS_OVERRIDE?.toLowerCase() === 'true' &&
                urlFilters.hasOwnProperty('applicationProcessId')
            ) {
                payloadToDispatch.applicationProcess = {
                    id: urlFilters['applicationProcessId'],
                }
            } else {
                payloadToDispatch.applicationProcess = null
            }
        }

        if (onBoardingData.advanced) {
            if (!invalidateFilters) {
                if (
                    onBoardingData.advanced['advancedSearch@forms@friendlyName']
                ) {
                    payloadToDispatch['advancedSearch.forms.friendlyName'] =
                        onBoardingData.advanced[
                            'advancedSearch@forms@friendlyName'
                        ]
                }

                if (onBoardingData.advanced['advancedSearch@forms@alias']) {
                    payloadToDispatch['advancedSearch.forms.alias'] =
                        onBoardingData.advanced['advancedSearch@forms@alias']
                }
                if (
                    onBoardingData.advanced[
                        'advancedSearch@forms@primaryEmailAddress'
                    ]
                ) {
                    payloadToDispatch[
                        'advancedSearch.forms.primaryEmailAddress'
                    ] =
                        onBoardingData.advanced[
                            'advancedSearch@forms@primaryEmailAddress'
                        ]
                }

                if (
                    onBoardingData.advanced[
                        'advancedSearch@forms@technicalName'
                    ]
                ) {
                    payloadToDispatch['advancedSearch.forms.technicalName'] =
                        onBoardingData.advanced[
                            'advancedSearch@forms@technicalName'
                        ]
                }

                if (onBoardingData.advanced['advancedSearch@forms@owner']) {
                    payloadToDispatch['advancedSearch.forms.owner'] =
                        onBoardingData.advanced['advancedSearch@forms@owner']
                }

                if (
                    onBoardingData.advanced[
                        'advancedSearch@forms@highLevelClassification'
                    ]
                ) {
                    payloadToDispatch[
                        'advancedSearch.forms.highLevelClassification'
                    ] =
                        onBoardingData.advanced[
                            'advancedSearch@forms@highLevelClassification'
                        ]
                }

                if (
                    onBoardingData.advanced['advancedSearch@forms@description']
                ) {
                    payloadToDispatch['advancedSearch.forms.description'] =
                        onBoardingData.advanced[
                            'advancedSearch@forms@description'
                        ]
                }

                if (
                    onBoardingData.advanced['advancedSearch@tags'] &&
                    onBoardingData.advanced['advancedSearch@tags'].length > 0
                ) {
                    payloadToDispatch['advancedSearch.tags'] =
                        onBoardingData.advanced['advancedSearch@tags']
                }
            } else {
                payloadToDispatch.advanced = {
                    'advancedSearch@forms@friendlyName': '',
                    'advancedSearch@forms@technicalName': '',
                    'advancedSearch@forms@owner': null,
                    'advancedSearch@forms@highLevelClassification': '',
                    'advancedSearch@forms@description': '',
                    'advancedSearch@tags': [],
                    alias: '',
                    primaryEmailAddress: '',
                }
            }
        }

        if (Object.keys(payloadToDispatch).length > 0) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            payloadToDispatch = {
                ...payloadToDispatch,
                initialSavedValue: true,
            }
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: payloadToDispatch,
            })
        }
    }, [onBoardingData, name, invalidateFilters])

    if (isNilOrEmpty(type)) return <></>

    const renderFilter = () => {
        switch (type) {
            case 'advancedSearch':
                return <AdvancedFilter resourceType={resourceType} {...rest} />
            default:
                return (
                    <GenericFilter
                        resourceType={resourceType}
                        type={type}
                        {...rest}
                    />
                )
        }
    }

    let expanded = false
    if (rest.contextProp) {
        if (
            !isNilOrEmpty(state[rest.contextProp]) &&
            state[rest.contextProp] !== false
        ) {
            expanded = true
        }

        if (type === 'tree') {
            if (filterData.selectedResourceLocationId) {
                expanded = true
            }
        }
    }

    return isSmallScreen ? (
        <Accordion expanded={expanded} title={t(title)}>
            <Box padding="20px 20px 20px 20px ">{renderFilter()}</Box>
        </Accordion>
    ) : (
        <Card
            cardTitle={t(title)}
            colorCode={colorCode?.primary}
            expanded={expanded}
            collapsible
            icon={
                icon && icon.name === 'AdvancedSearch' ? ( //Not removing icons from configs because it would be difficult to find icons again
                    <Icon name={icon.name} color={icon.color} />
                ) : undefined
            }
        >
            <Box padding="20px 20px 0px 20px ">{renderFilter()}</Box>
        </Card>
    )
}

export default EidFilter
