import React, { useState, useEffect } from 'react'
import { useDebounce } from 'packages/core'
import { Autocomplete } from 'packages/eid-ui'

const SelectAutocomplete = ({
    debounceInterval = 300,
    onChange,
    options = [],
    labelProps = 'FriendlyName',
    allowSearch = true,
    ...rest
}) => {
    const [data, setData] = useState(options)

    const [searchKey, setSearchKey] = useState('')
    const debouncedValue = useDebounce(searchKey, debounceInterval)

    useEffect(() => {
        if (Boolean(debouncedValue) && allowSearch) {
            // Handle Client side search
            const filteredItems = options.filter((item) =>
                item[labelProps]
                    .toLowerCase()
                    .includes(debouncedValue.toLowerCase()),
            )
            setData(filteredItems)
        } else {
            setData(options)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue, options])

    return (
        <Autocomplete
            options={data}
            onInputChange={(_, value) => setSearchKey(value)}
            onChange={onChange}
            {...rest}
        />
    )
}

export default SelectAutocomplete
