import React from 'react'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { Box, styled } from '@mui/material'
import { useManagementRoleOwners } from 'hooks'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const hasAccessToOwnerControl = 'ITShop-ManagementRoles-Details-Owners-Control';

export const MoreInformationSection = ({ managementRole }: any) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useManagementRoleOwners(
        hasAccessToControl(hasAccessToOwnerControl) ? managementRole.id : null,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                owners && (
                    <>
                        {hasAccessToControl(hasAccessToOwnerControl) && <ItemDetails.AttributesContainer bottomPadding="0">
                            <PaddedDiv data-protectedsubcomponent={hasAccessToOwnerControl}>
                                <ItemDetails.Attribute
                                    label={t('ManagementRoles_Owners')}
                                    value={<PeopleList list={owners} />}
                                    orientation={attributeOrientation}
                                />
                            </PaddedDiv>
                        </ItemDetails.AttributesContainer>}
                    </>
                )
            )}
        </>
    )
}
