import { useEffect } from 'react'
import { isNilOrEmpty } from 'packages/core'
import { EidAutocomplete } from 'packages/eid-controls'
import { useResourceTypeContext } from 'resourceTypeContext'
import config from 'config'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'

const getOptionComponent = (optionConfig) => {
    return EidAutocomplete.getOptionComponent({
        ...optionConfig,
        cdnUrl: config.BASE_EID_URL,
    })
}

const AutocompleteFilter = ({
    value,
    onChange,
    optionConfig,
    selectedValueIndicatorProp,
    requireAccess,
    queryParam,
    queryParams,
    basedOn,
    url,
    supportsServerSideSearch = true,
    excludeOptionsConfig,
    clearable = true,
    ...rest
}) => {
    const [appState] = useAppState()
    const [state] = useResourceTypeContext()

    let urlToUse
    let queryParamsToUse = {
        ...queryParams,
    }

    let basedOnValue
    if (!isNilOrEmpty(basedOn)) {
        const stateToUse = basedOn.contextType === 'global' ? appState : state
        basedOnValue = stateToUse[basedOn.contextProp]

        switch (basedOn.type) {
            case 'required':
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                }
                break
            case 'optional':
            case 'dynamicUrl':
                if (!isNilOrEmpty(basedOnValue)) {
                    urlToUse = `${url}/${basedOnValue[basedOn.valueProp]}`
                }
                break
            default:
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                } else {
                    urlToUse = url
                }
                break
        }
    } else {
        urlToUse = url
    }

    useEffect(() => {
        if (basedOn && value) {
            onChange(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basedOnValue])

    const excludeOptions = []
    if (excludeOptionsConfig) {
        const stateToUse =
            excludeOptionsConfig.contextType === 'global' ? appState : state
        var optionToExcludeValue = stateToUse[excludeOptionsConfig.contextProp]
        if (optionToExcludeValue) {
            excludeOptions.push(
                optionToExcludeValue[excludeOptionsConfig.valueProp],
            )
        }
    }

    const clearIcon = (
        <Icon name="Close" color="#959598" onClick={() => onChange(null)} />
    )

    return (
        <EidAutocomplete
            getOptionLabel={(option) => option[optionConfig.labelProp]}
            optionComponent={getOptionComponent(optionConfig)}
            value={value}
            onChange={(_, value) => {
                const valueToDispatch = value ? value : null
                onChange(valueToDispatch)
            }}
            shouldTriggerApiCall={!isNilOrEmpty(urlToUse)}
            url={urlToUse}
            queryParams={queryParamsToUse}
            filterOptions={
                supportsServerSideSearch ? (options) => options : undefined
            }
            supportsServerSideSearch={supportsServerSideSearch}
            excludeOptions={excludeOptions}
            clearIcon={clearable && !isNilOrEmpty(value) && clearIcon}
            popperStyleProps={{ zIndex: 1000 }}
            {...rest}
        />
    )
}

export default AutocompleteFilter
