import { Button as MuiButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'

const drawerButtonStyles = makeStyles((theme) => ({
    root: (labeled) => ({
        minHeight: '31px',
        border: `solid 1px ${theme?.palette?.secondary?.main}`,
        backgroundColor: theme?.palette?.common?.white,
        boxShadow: '0 0 0 0',
        whiteSpace: 'nowrap',
        color: theme?.palette?.secondary?.main,
        textTransform: 'capitalize !important',
        borderRadius: '16px',
        marginRight: `${labeled ? '5px' : '0px'}`,
        lineHeight: '1.3',
        '&:hover': {
            backgroundColor: theme?.palette?.secondary?.main,
            color: 'white',
            '& span': {
                color: theme?.palette?.common?.white,
            },
        },
    }),
}))
const RequestAccessButton = ({ onClick, label }) => {
    const { t } = useTranslation()

    const classes = drawerButtonStyles(label ? true : false)
    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={onClick}
        >
            {label ? label : t('Common_RequestAccess')}
        </MuiButton>
    )
}
export default RequestAccessButton
