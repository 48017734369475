import React, { useEffect, useRef, useState } from 'react'
import { Box, Button as MuiButton, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Loader } from 'packages/eid-ui'
import { convertToPixels } from 'utils'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        textTransform: 'capitalize !important',
        fontSize: '16px',
        color: theme?.palette?.common?.white,
        backgroundColor: props.color,
        boxShadow: 'none',
        minWidth: 'inherit',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: props.hoverColor ? props.hoverColor : props.color,
            '&::before': {
                borderRadius: 'inherit',
                content: '""',
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: props.hoverColor ? 1 : 0.17,
                backgroundColor: props.hoverColor
                    ? props.hoverColor
                    : theme?.palette?.common?.black,
                left: 0,
                top: 0,
            },
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.color,
            opacity: 0.7,
        },
        '&:focus': {
            boxShadow: 'none',
        },
        ...props.rootStylesProp,
        maxWidth: props.maxWidthButton,
    }),
    disabled: {
        color: `${theme?.palette?.common?.white} !important`,
    },
    label: {
        zIndex: 1,
    },
}))
// To use Genetic Button with dynamic width, you must give maxWidth in percentages in rootStyleProps
const GenericButton = (props) => {
    const {
        loading,
        color,
        disabled,
        onClick,
        rootStylesProp,
        hoverColor,
        loaderColor,
        label = '',
        endIcon,
        startIcon,
        ...rest
    } = props
    const buttonRef = useRef(null)
    const [maxWidthButton, setMaxWidthButton] = useState('')
    const [showTooltip, setShowTooltip] = useState(false)

    const classes = useStyles({
        color,
        loading,
        rootStylesProp,
        hoverColor,
        maxWidthButton,
    })

    const theme = useTheme()

    useEffect(() => {
        const buttonElement = buttonRef.current
        const textElement = buttonElement.firstChild?.firstChild
        if (textElement.offsetWidth < textElement.scrollWidth) {
            setShowTooltip(true)
        }

        const maxWidthPixels = convertToPixels(
            rootStylesProp?.maxWidth,
            buttonElement,
        )
        setMaxWidthButton(maxWidthPixels)
        const measureWidth = () => {
            if (textElement instanceof HTMLElement) {
                return textElement.getBoundingClientRect().width
            }
            return 0
        }

        if (
            measureWidth() >
            Math.min(
                parseFloat(maxWidthPixels),
                parseFloat(buttonElement.getBoundingClientRect().width),
            )
        ) {
            setShowTooltip(true)
        }
    }, [label, rootStylesProp?.maxWidth, window?.innerWidth])

    return (
        <MuiButton
            ref={buttonRef}
            disabled={disabled}
            classes={classes}
            variant="contained"
            onClick={onClick}
            title={showTooltip ? label : ''}
            endIcon={endIcon}
            startIcon={startIcon}
            {...rest}
        >
            {loading ? (
                <Loader
                    color={
                        loaderColor
                            ? loaderColor
                            : theme?.palette?.common?.white
                    }
                />
            ) : (
                <Box
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {label}
                </Box>
            )}
        </MuiButton>
    )
}
export default GenericButton
