export const initialState = {
    tags: null,
    searchTerm: '',
    sorting: {
        sortOrder: null,
        sortBy: null,
    },
    manageAccess: {
        selectedTab: null,
    },
    showOnlyAzureApplications: false,
    credentialCheckoutHistory: {
        startDateFilter: null,
        endDateFilter: null,
    },
    page: 1,
    leftPaneTabsCount: {
        historyCount: 0,
        loginSessionCount: 0,
        membershipCount: 0,
        credentialsCount: 0,
        secretsCount: 0,
    },
    showTimeConstrained: false,
    accountStore: null,
    accountStoreUsageType: null,
    showExpiringAccess: false,
    requestedByPerson: null,
    computer: null,
    appRightsFieldTypes: null,
    selectedAppRoleOrRightToHighLight: null,
    selectdAccessLevelAppRoleRight: {
        selectedAppRoleOrRightAlreadyAssigned: false,
        accessType: ''
    }
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }

        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_TAGS':
            return {
                ...state,
                tags: payload,
            }
        case 'SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: payload,
            }

        case 'SET_MANAGE_ACCESS_FILTERS':
            return {
                ...state,
                manageAccess: {
                    ...state.manageAccess,
                    ...payload,
                },
            }
        case 'SET_CHECKOUT_HISTORY_FILTERS':
            return {
                ...state,
                credentialCheckoutHistory: {
                    ...state.credentialCheckoutHistory,
                    [payload.key]: payload.value,
                },
            }

        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            }
        case 'LEFT_PANE_TAB_COUNT':
            return {
                ...state,
                leftPaneTabsCount: {
                    ...state.leftPaneTabsCount,
                    [payload.key]: payload.value,
                },
            }
        case 'UPDATE_APP_RIGHTS_FIELD_TYPES':
            return {
                ...state,
                appRightsFieldTypes: payload,
            }
        case 'UPDATE_SELECTED_ACCESS_LEVEL_APP_ROLE_OR_RIGHT':
            return {
                ...state,
                selectedAppRoleOrRightToHighLight: payload,
            }
            case 'UPDATE_SELECTED_ACCESS_LEVEL_ALREADY_ASSIGNED':
            return {
                ...state,
                selectdAccessLevelAppRoleRight: payload,
            }
        default:
            return state
    }
}

export default reducer
