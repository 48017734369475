import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useAzureRole, useTargetPerson } from 'hooks'
import { AzureRoles } from './AzureRoles'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const AzureRolesDrawer = ({ id, attributes, toggleDrawer, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data: azureRole } = useAzureRole(id, targetPerson.id)

    let item
    if (azureRole) {
        item = {
            resource: azureRole,
            assignment: {
                personId: targetPerson.id,
                isAssigned: azureRole.isAssigned,
                canActivateNow: azureRole.canActivateNow,
                isRenewable: azureRole.isRenewable,
                resourceAssignment: {
                    id: azureRole.applicationId,
                    friendlyName: azureRole.applicationFriendlyName,
                },
                assignmentDetails: {
                    isRevokable: azureRole.isRevocable,
                    timeConstraintActive: azureRole.timeConstraintActive,
                    startDateUtc: azureRole.startDateUtc,
                    endDateUtc: azureRole.endDateUtc,
                },
            },
        }
    }

    const itemDetails = <AzureRoles item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default AzureRolesDrawer
