import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Pagination } from 'packages/eid-ui'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    paginationSection: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '24px 11px 0',

        '& .MuiSelect-select': {
            border: 'none',
            boxShadow: 'none',
        },
        '& .MuiInputBase-root': {
            border: 'solid 1px #ebebed',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px !important',
            padding: '0 6px',
            background: theme?.palette?.background?.paper,
        },
    },
}))

const FieldTypePagination = ({
    numberOfPages,
    handlePageChange,
    page,
    pageSize,
    setPage,
    setPageSize,
    totalCount = 0,
}) => {
    const classes = useStyles()
    let minimumRowsPerPage
    if( pageSize < 10){
        minimumRowsPerPage = pageSize
    }
    return (
        <Box className={classes.paginationSection}>
            <Pagination
                count={numberOfPages}
                size="large"
                siblingCount={0}
                page={page}
                onChange={handlePageChange}
                showItemsPerPageSelection={true}
                itemsPerPage={pageSize}
                onPageSelection={(gotoPage) => setPage(gotoPage)}
                onItemsPerPageChange={(perPage) => setPageSize(perPage)}
                showPageSelection={true}
                minimumRowsPerPage = {minimumRowsPerPage}
                totalCount={totalCount}
                showCountStat={true}
            />
        </Box>
    )
}

export default FieldTypePagination
