import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ItemHeader from 'containers/ManageAccess/ItemHeader'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useCheckSharedFolderAccessByAssignmentId,
    useSharedFolder,
    useTargetPerson,
} from 'hooks'
import SharedFolderDetails from './SharedFolderDetails'
import { ManageAccessDrawer } from 'components/Drawer/ManageAccessDrawer'

const SharedFoldersDrawer = ({ id, attributes, toggleDrawer, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    const headerAttribute = attributes.find((x) => x.isHeader)

    const [targetPerson] = useTargetPerson()

    const { data: assignmentStatus } = useCheckSharedFolderAccessByAssignmentId(
        id,
        targetPerson.id,
    )

    const { data: sharedFolder } = useSharedFolder(id, true)

    let item
    if (sharedFolder && assignmentStatus) {
        item = {
            resource: sharedFolder,
            assignment: assignmentStatus,
        }
    }

    const itemDetails = <SharedFolderDetails item={item} {...rest} />

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={item?.resource?.[headerAttribute.name]} />
            {!item && <Loader />}
            {item && itemDetails}
        </ItemsDetailsMobileWrapper>
    ) : (
        <ManageAccessDrawer
            id={id}
            toggleDrawer={toggleDrawer}
            item={item}
            itemDetails={itemDetails}
            headerAttribute={headerAttribute}
        />
    )
}

export default SharedFoldersDrawer
