import { useState } from 'react'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
import { PasswordModal } from '../../components/PasswordModal'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

const MasterPasswordLock = ({ style }) => {
    const [{ masterPassword }] = useAppState()
    const { t } = useTranslation()
    const theme = useTheme()

    const [masterPasswordOpen, setMasterPasswordOpen] = useState('')

    if (masterPassword) return <></>

    return (
        <PasswordModal
            open={masterPasswordOpen}
            setOpen={setMasterPasswordOpen}
        >
            <div
                style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    display: 'flex',
                    background: `${theme?.palette?.background?.default}d1`,
                    zIndex: 1,
                    ...style,
                }}
                onClick={() =>
                    setMasterPasswordOpen(
                        masterPassword ? 'addToCart' : 'enterPassword',
                    )
                }
            >
                <Button
                    style={{
                        width: 90,
                        background: theme?.palette?.tertiary?.main,
                        color: 'white',
                        fontSize: 14,
                        borderRadius: '10px',
                        margin: 'auto',
                        boxShadow: `0 4px 16px 0 ${theme?.palette?.tertiary?.light}`,
                        padding: '2px 8px 2px 3px',
                        overflowWrap: 'anywhere',
                        '&:hover': {
                            background: theme?.palette?.tertiary?.main,
                        },
                    }}
                    startIcon={
                        <Icon
                            style={{
                                height: 15,
                                width: 12,
                                marginRight: '-4px',
                            }}
                            name={'LockRound'}
                        ></Icon>
                    }
                    onClick={() =>
                        setMasterPasswordOpen(
                            masterPassword ? 'addToCart' : 'enterPassword',
                        )
                    }
                >
                    <span
                        style={{
                            fontSize: 12,
                            marginTop: '3px',
                        }}
                    >
                        {t('Common_Unlock')}
                    </span>
                </Button>
            </div>
        </PasswordModal>
    )
}

export default MasterPasswordLock
