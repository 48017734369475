import { Button } from 'components'
import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCheckIfMasterPasswordExist, useComputerCheckIn } from 'hooks'
import { useNotification } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'

const styles = (theme) => ({
    normal: {
        backgroundColor: theme?.palette?.primary?.main,
        color: theme?.palette?.common?.white,
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        borderRadius: '5px',
        padding: '5px 8px 4px',
    },
    disabled: {
        backgroundColor: '#red !important',
        border: 'solid 1px #c5cbda',
        color: `${theme?.palette?.common?.white} !important`,
        opacity: '0.5',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
})

const CredentialStatusButton = ({
    data,
    labelStyleProp = {},
    buttonStyleProp = {},
    showIcon = false,
}) => {
    const item = data?.resource
    const { t } = useTranslation()
    const [checkInComputer, { isLoading }] = useComputerCheckIn()
    const { masterPasswordExists } = useCheckIfMasterPasswordExist()
    const { showWarningMessage } = useNotification()
    const theme = useTheme()

    const handleCheckInClick = () => {
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        checkInComputer(item.checkOutId)
    }
    return (
        <Button
            onClick={() => handleCheckInClick()}
            color={
                !item.canCheckIn || isLoading
                    ? '#c5cbda'
                    : theme?.palette?.primary?.main
            }
            disabled={!item.canCheckIn || isLoading}
            style={{ ...styles(theme).normal, ...buttonStyleProp }}
            rootStylesProp={{
                opacity: !item.canCheckIn || isLoading ? 0.5 : 1,
            }}
        >
            {showIcon && (
                <Icon
                    style={{ marginRight: '10px' }}
                    name="CheckOut"
                    color={theme?.palette?.common?.white}
                    direction={'right'}
                />
            )}
            <Typography style={{ ...styles(theme).label, ...labelStyleProp }}>
                {t('Common_CheckIn')}
            </Typography>
        </Button>
    )
}

CredentialStatusButton.defaultProps = {
    drawerView: false,
}

export default CredentialStatusButton
