import { MenuItem, ListItemText, ListItemIcon, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreen } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from 'resourceTypeContext'
import useSubcomponents from 'useSubcomponents'

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        height: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px',
        color: 'black',

        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: `${theme?.palette?.primary?.light} !important`,
        borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
}))
const menuStyles = {
    paddingRight: '0px',
    marginTop: '5px',
}
const listStyles = {
    paddingTop: '0px',
    paddingBottom: '0px',
}

const ManageAccessTabsItem = ({
    label,
    icon,
    selected,
    onClick,
    contextProp,
    showCount,
    isSmallScreen,
    isDropDownMenuItem,
    showBorder,
    requireAccessKey,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const [state] = useResourceTypeContext()

    const { hasAccessToTabs } = useSubcomponents()

    const { hasAccessToManageAccess } = hasAccessToTabs(requireAccessKey)

    return (
        <>
            {hasAccessToManageAccess ? (
                <MenuItem
                    style={{
                        border:
                            showBorder &&
                            `1px solid ${theme?.palette?.primary?.main}`,
                    }}
                    onClick={onClick}
                    classes={classes}
                    selected={selected}
                    data-protectedsubcomponent={hasAccessToManageAccess}
                >
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                    <ListItemText>
                        <>{t(label)}</>
                        {(selected || isSmallScreen) &&
                            showCount &&
                            !isDropDownMenuItem && (
                                <div
                                    style={{
                                        marginRight: isSmallScreen ? 0 : '16px',
                                        float: 'right',
                                        fontSize: '14px',
                                        color: '#919193',
                                        display: 'flex',
                                    }}
                                >
                                    {state.leftPaneTabsCount[contextProp]}

                                    {isSmallScreen && !isDropDownMenuItem && (
                                        <Icon
                                            style={{
                                                float: 'right',
                                                width: '40px',
                                                height: '22px',
                                            }}
                                            name="Arrow"
                                        />
                                    )}
                                </div>
                            )}
                    </ListItemText>
                </MenuItem>
            ) : null}
        </>
    )
}

const ManageAccessTabs = ({ active, onChange, tabs, activeTab }) => {
    const isSmallScreen = useIsSmallScreen()
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [menuWidth, setMenuWidth] = useState('fit-content')
    useEffect(() => {
        if (anchorRef && anchorRef.current) {
            setMenuWidth(anchorRef.current.offsetWidth)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorRef?.current])
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }
    return isSmallScreen ? (
        <>
            <div ref={anchorRef}>
                <ManageAccessTabsItem
                    key={`${activeTab?.path}`}
                    label={activeTab?.label}
                    contextProp={activeTab?.contextProp}
                    showCount={activeTab?.showCount}
                    onClick={() => {
                        setOpen((pre) => !pre)
                    }}
                    icon={<Icon name={activeTab?.icon}></Icon>}
                    isSmallScreen={isSmallScreen}
                    showBorder={open}
                ></ManageAccessTabsItem>
            </div>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                showCone={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                width={'max-content'}
                rootStyles={{ width: menuWidth, ...menuStyles }}
                listStyles={listStyles}
            >
                {tabs
                    .filter((x) => x.display === true)
                    .map((tab, index) => (
                        <ManageAccessTabsItem
                            key={`${tab.path}_${index}`}
                            label={tab.label}
                            onClick={() => {
                                setOpen(false)
                                if (tab.path !== activeTab.path) {
                                    onChange(tab)
                                }
                            }}
                            selected={active === tab.path}
                            contextProp={tab.contextProp}
                            showCount={tab.showCount}
                            icon={<Icon name={tab.icon}></Icon>}
                            isDropDownMenuItem={true}
                        />
                    ))}
            </Dropdown>
        </>
    ) : (
        <>
            {tabs
                .filter((x) => x.display === true)
                .map((tab, index) => (
                    <ManageAccessTabsItem
                        key={`${tab.path}_${index}`}
                        label={tab.label}
                        onClick={() => {
                            onChange(tab)
                        }}
                        selected={active === tab.path}
                        contextProp={tab.contextProp}
                        showCount={tab.showCount}
                        icon={<Icon name={tab.icon}></Icon>}
                        requireAccessKey={tab?.requireAccess?.control}
                    />
                ))}
        </>
    )
}

export default ManageAccessTabs
