import { useTranslation } from 'react-i18next'
import { Dropdown } from 'packages/eid-ui'
import { Box, useTheme } from '@mui/material'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useState, useRef } from 'react'
import { Button } from '@mui/material'
import useSubcomponents from 'useSubcomponents'
import { ArrowIcon, Icon } from 'packages/eid-icons'


const OnboardApplication = ({
    accessibleStaticWorkflows,
    protectedSubcomponent,
}) => {
    const { t } = useTranslation()
    const ref = useRef(null)
    const { hasAccessToControl } = useSubcomponents()
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const handleClick = (event) => {
        setOpen(true)
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }
    const onboardButtonStyle = {

        width: '100%',
        backgroundColor: open
            ? theme.palette?.primary?.main
            : theme.palette?.primary?.contrastText,
        paddingRight: '2rem',
        paddingLeft: '2rem',
        justifyContent: 'space-between',
        border: `0.1rem solid ${theme.palette?.primary?.main}`,
        color: open

            ? theme.palette?.primary?.contrastText
            : theme.palette?.primary?.main,
        '&:hover': {
            backgroundColor: open
                ? theme.palette?.primary?.main
                : theme.palette?.primary,
            color: open
                ? theme.palette?.primary?.contrastText
                : theme.palette?.primary?.main,
        },
        cursor: 'pointer',
    }
    return (
        <>
            {hasAccessToControl(protectedSubcomponent) && (
                <Box
                    data-protectedsubcomponent={
                        protectedSubcomponent
                    }
                >
                    <Button
                        onClick={handleClick}
                        sx={onboardButtonStyle}
                        ref={ref}
                    >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon
                                name="Plus"
                                color={
                                    open
                                        ? theme.palette?.primary?.contrastText
                                        : theme.palette?.primary?.main
                                }
                            />
                            <span
                                style={{
                                    paddingLeft: '1.5rem',
                                    textTransform: 'none',
                                    fontSize: '1.6rem',
                                }}
                            >
                                {t('OnboardApplication')}
                            </span>
                        </span>
                        <ArrowIcon
                            direction={open ? 'up' : 'down'}

                            color={
                                open
                                    ? theme.palette?.primary?.contrastText
                                    : theme.palette?.primary?.main
                            }
                        />
                    </Button>
                    <Dropdown
                        open={open}
                        handleClose={handleClose}
                        anchorEl={anchorEl}
                        width={ref?.current?.offsetWidth}
                        anchorOriginPosition={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        anchorTransformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        rootStyles={{
                            marginTop: '0.5rem',
                            borderRadius: '0.5rem',
                        }}
                        listStyles={{
                            paddingBottom: '0rem',
                            paddingTop: '0rem',
                            '& > .gradient-border': {
                                border: 'none',
                            },
                        }}
                    >
                        {accessibleStaticWorkflows.length > 0 &&
                            accessibleStaticWorkflows.map((wf, index) => (
                                <Box
                                    data-protectedsubcomponent={
                                        protectedSubcomponent
                                    }
                                >
                                    <EidWorkflowButton
                                        key={`${wf.displayName_LocaleKey}_${index}`}
                                        title={t(wf.displayName_LocaleKey)}
                                        name={wf.requestWorkflowName}
                                        path={wf.config?.path}
                                        iconName={'Plus'}
                                        customStyles={{
                                            border: 'none !important',
                                            paddingLeft: '1.3rem !important',
                                            '&:hover': {
                                                boxShadow: 'none !important',
                                                backgroundColor:
                                                    'rgba(48, 127, 193, 0.07) !important',
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                    </Dropdown>
                </Box>
            )}
        </>
    )
}
export default OnboardApplication

