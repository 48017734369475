import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'

const TextWithIcon = ({ message, iconName, styles = {} }) => {
    return (
        <Box sx={{ display: 'flex', maxWidth: '50rem', ...styles }}>
            <Box sx={{ marginTop: '0.3rem' }}>
                <Icon name={iconName} />
            </Box>
            <Box
                sx={{
                    marginLeft: '1rem',
                    color: '#3b454d',
                    fontSize: '1.2rem',
                }}
            >
                {message}
            </Box>
        </Box>
    )
}

export default TextWithIcon
