import {
    isNilOrEmpty,
    useGetControlsAccess,
    useGetPagesAccess,
} from 'packages/core'
import { useAppState } from 'appContext'
import appConfiguration from 'appConfiguration'
import { find } from 'ramda'

const Configuration = () => {
    const pages = useGetPagesAccess()
    const controls = useGetControlsAccess()
    const [{ appConfig }] = useAppState()

    const configuration = appConfiguration

    const manageAccess = {
        name: 'ManageAccess',
        ...configuration.manageAccess,
    }


    const resourceTypesWithAccess = configuration.resourceTypes
        .filter(
            (r) =>
                isNilOrEmpty(r.requireAccess?.page) ||
                pages.findIndex((x) => x.name === r.requireAccess.page) > -1,
        )
        .sort((a, b) => a.order - b.order)


    const resourceTypesOrdering = appConfig.menuItemsOrdering
    const orderedResourceTypes = []
    resourceTypesOrdering?.forEach(res => {
        const resourceType = resourceTypesWithAccess.find(x => x.resourceTypeName.toLowerCase() === res.toLowerCase())
        if(resourceType){
        orderedResourceTypes.push(resourceType)
        }
    });

    let orderedResourcesCount = orderedResourceTypes.length

    for (let i = 1; i <= orderedResourcesCount; i++) {
        orderedResourceTypes[i - 1].order = i
    }

    const unOrderedResourceTypes = resourceTypesWithAccess.filter((x) => !resourceTypesOrdering?.includes(x.resourceTypeName))
    for (let i = 0; i < unOrderedResourceTypes.length ; i++) {
        orderedResourcesCount++
        unOrderedResourceTypes[i].order = orderedResourcesCount
    }

    const resourceTypesWithAccessAndOrder = [...orderedResourceTypes, ...unOrderedResourceTypes]
    const getResourceType = (name) =>
        find(
            (x) => x.name.toLowerCase() === name.toLowerCase(),
            resourceTypesWithAccess,
        )

    const getResourceTypeAttributes = (
        resourceType,
        forManageAccess = false,
    ) => {
        const result = getResourceType(resourceType)
            .attributes.filter(
                (a) =>
                    a.forManageAccess === undefined ||
                    a.forManageAccess === forManageAccess,
            )
            .filter((a) => {
                if (isNilOrEmpty(a.requireAccess)) {
                    return true
                }
                if (
                    controls.findIndex(
                        (x) => x.name === a.requireAccess.control,
                    ) > -1
                ) {
                    return true
                }
                return false
            })

        return result
    }

    return {
        manageAccess,
        resourceTypes: resourceTypesWithAccessAndOrder,
        getResourceType,
        getResourceTypeAttributes,
    }
}

export default Configuration
